<i18n>
{
  "en": {
    "prev": "Previous page",
    "page": "Page",
    "next": "Next page",
    "of": "of"
  },
  "pt-BR": {
    "prev": "Página anterior",
    "page": "Página",
    "next": "Próxima página",
    "of": "de"
  }
}
</i18n>

<template>
  <div class="we-pagination">
    <we-icon-button
      icon="chevron-left"
      iconSize="small"
      :name="$t('prev')"
      @click="prev"
    />
    <we-input
      type="pagination"
      v-model="pages.current_page"
      :items="items"
      placeholder="1"
      @input="emitValue"
      :tooltipName="$t('page')"
    />
    <we-icon-button
      icon="chevron-right"
      iconSize="small"
      :name="$t('next')"
      @click="next"
    />
    <div class="size">
      <div class="from-to">{{ pages.from }}-{{ pages.to }}</div>
      <div class="total-pages">{{ $t("of") }} {{ pages.total }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Object,
      required: true
    },
    storage: String
  },
  computed: {
    items() {
      let number = 1;
      let numberArr = [];

      while (number <= this.pages.last_page) {
        numberArr.push(number);
        number++;
      }

      return numberArr;
    }
  },
  methods: {
    emitValue(val) {
      this.$emit("change", val);
    },
    prev() {
      if (this.pages.current_page > 1) {
        this.pages.current_page--;
        this.emitValue(this.pages.current_page);
      }
    },
    next() {
      if (this.pages.current_page < this.pages.last_page) {
        this.pages.current_page++;
        this.emitValue(this.pages.current_page);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.we-pagination {
  display: flex;
  align-items: center;

  .we-input {
    width: fit-content;
    min-width: 40px;
  }

  .size {
    margin-left: 8px;
    font-size: 14px;
    font-weight: $medium;
    color: var(--text-2);
    display: flex;
    align-items: center;

    @include mobile {
      font-size: 12px;
      line-height: 12px;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
    }

    .from-to {
      margin-right: 5px;

      @include mobile {
        font-size: 13px;
        color: var(--text-1);
        margin-bottom: 2px;
      }
    }
  }
}
</style>
