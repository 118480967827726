var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"we-deal-steps"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:i,staticClass:"step",class:{
      finished: i < _vm.currentIndex,
      current: _vm.isCurrent(i)
    }},[_c('div',{staticClass:"left-arrow"}),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.tooltipIsActive(step) || !_vm.isDesktop},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"step-text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("hoursToDaysAndHours")(step.days))+" ")]),_c('div',_vm._g(_vm._b({staticClass:"step-text mobile"},'div',attrs,false),on),[_vm._v(" "+_vm._s(step.tooltip)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("hoursToDaysAndHoursExtended")(step.days))+" "+_vm._s(_vm.$t("on-stage"))+" "+_vm._s(step.tooltip)+" ")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.tooltipIsActive(step) || !_vm.isDesktop},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"move-here",on:{"click":function($event){return _vm.$emit('selectStep', step)}}},'div',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}}),_c('span',{staticClass:"action-text"},[_vm._v(" "+_vm._s(_vm.isCurrent(i) ? _vm.$t("here") : _vm.$t("move"))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("move-here")))])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }