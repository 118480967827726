import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "label-1": "Demo",
    "label-2": "Resource",
    "label-3": "Learn in details",
    "label-5": "For devs",
    "learning-item-1": "App introduction",
    "learning-item-2": "Workflows",
    "learning-item-3": "Tasks",
    "learning-item-4": "Custom fields",
    "learning-item-5": "Web forms",
    "learning-item-6": "Users permission",
    "help-item-1": "Tutorials and articles",
    "help-item-3": "API documentation"
  },
  "pt-BR": {
    "label-1": "Demonstração",
    "label-2": "Recurso",
    "label-3": "Aprenda em detalhes",
    "label-5": "Para devs",
    "learning-item-1": "Introdução ao App",
    "learning-item-2": "Automações",
    "learning-item-3": "Tarefas",
    "learning-item-4": "Campos Customizados",
    "learning-item-5": "Formulários Web",
    "learning-item-6": "Permissões de Usuário",
    "help-item-1": "Artigos e tutoriais",
    "help-item-3": "Documentação da API"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    learningItems: [
      {
        icon: "chalkboard-user",
        label: i18n.t("label-1"),
        name: i18n.t("learning-item-1"),
        url:
          "https://www.youtube.com/watch?v=XRY444WDzt8&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=1",
        time: "10:01"
      },
      {
        icon: "wand-magic-sparkles",
        label: i18n.t("label-2"),
        name: i18n.t("learning-item-2"),
        url:
          "https://www.youtube.com/watch?v=JlEO6W1GbVE&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=2",
        time: "6:41"
      },
      {
        icon: "tasks",
        label: i18n.t("label-2"),
        name: i18n.t("learning-item-3"),
        url:
          "https://www.youtube.com/watch?v=APSvGbdyZh4&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=3",
        time: "5:55"
      },
      {
        icon: "folder-plus",
        label: i18n.t("label-2"),
        name: i18n.t("learning-item-4"),
        url:
          "https://www.youtube.com/watch?v=x12kgwRKtPo&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=4",
        time: "6:44"
      },
      {
        icon: "file-lines",
        label: i18n.t("label-2"),
        name: i18n.t("learning-item-5"),
        url:
          "https://www.youtube.com/watch?v=hfyBGKWN1M4&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=5",
        time: "9:00"
      },
      {
        icon: "key",
        label: i18n.t("label-2"),
        name: i18n.t("learning-item-6"),
        url:
          "https://www.youtube.com/watch?v=tXQHtyetPr0&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=6",
        time: "6:12"
      }
    ],
    helpItems: [
      {
        icon: "book",
        label: i18n.t("label-3"),
        name: i18n.t("help-item-1"),
        url: "https://wepipe.tawk.help"
      },
      {
        icon: "server",
        label: i18n.t("label-5"),
        name: i18n.t("help-item-3"),
        url: "https://wepipe.readme.io"
      }
    ]
  },
  getters: {
    getLearningItems: state => state.learningItems,
    getHelpItems: state => state.helpItems
  }
};
