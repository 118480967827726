import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "weekday-1": "Monday",
    "weekday-2": "Tuesday",
    "weekday-3": "Wednesday",
    "weekday-4": "Thursday",
    "weekday-5": "Friday",
    "weekday-6": "Saturday",
    "weekday-7": "Sunday",
    "workflow-related-to-1": "Related contacts",
    "workflow-related-to-2": "Related companies",
    "workflow-related-to-3": "Related users",
    "workflow-related-to-4": "Task responsible",
    "workflow-related-to-5": "Task creator",
    "workflow-related-to-6": "Others",
    "workflow-entity-1": "Contact",
    "workflow-entity-2": "Company",
    "workflow-entity-3": "Deal",
    "workflow-entity-4": "User",
    "workflow-entity-5": "Task",
    "workflow-entity-6": "Product",
    "workflow-entity-7": "Bill",
    "workflow-execute-when-1": "Create",
    "workflow-execute-when-2": "Update",
    "workflow-execute-when-3": "Update stage",
    "workflow-execute-when-4": "Delete",
    "workflow-execute-when-5": "Frequently",
    "workflow-execute-when-6": "Complete all tasks",
    "workflow-execute-when-7": "Expire",
    "workflow-execute-when-8": "Close to expire",
    "workflow-actions-1": "Send email",
    "workflow-actions-2": "Send confirmation email",
    "workflow-actions-3": "Send SMS",
    "workflow-actions-14": "Send WhatsApp Message",
    "workflow-actions-15": "Send Discord Message",
    "workflow-actions-16": "Send Slack Message",
    "workflow-actions-4": "Change field value",
    "workflow-actions-17": "Calculate field value",
    "workflow-actions-5": "Add tag",
    "workflow-actions-13": "Remove tag",
    "workflow-actions-12": "Remove tags",
    "workflow-actions-6": "Add task",
    "workflow-actions-7": "Duplicate card",
    "workflow-actions-8": "Execute webhook",
    "workflow-actions-9": "Add bill",
    "workflow-actions-10": "Add note",
    "workflow-actions-11": "Send notification",
    "workflow-frequency-1": "Hourly",
    "workflow-frequency-2": "Daily",
    "workflow-frequency-3": "Weekly",
    "workflow-frequency-4": "Monthly",
    "workflow-notification-to-1": "Responsible user",
    "workflow-notification-to-2": "Creator",
    "workflow-notification-to-3": "Other",
    "workflow-json-error": "The JSON field isn't formated properly.",
    "create-workflow-success": "Workflow created with success!",
    "create-workflow-error": "Wasn't possible to create workflow!",
    "edit-workflow-success": "Workflow updated with success!",
    "edit-workflow-error": "Wasn't possible to update workflow!",
    "delete-workflow-success": "Workflow deleted with success!",
    "delete-workflow-error": "Wasn't possible to delete workflow!"
  },
  "pt-BR": {
    "weekday-1": "Segunda-feira",
    "weekday-2": "Terça-feira",
    "weekday-3": "Quarta-feira",
    "weekday-4": "Quinta-feira",
    "weekday-5": "Sexta-feira",
    "weekday-6": "Sábado",
    "weekday-7": "Domingo",
    "workflow-related-to-1": "Contatos relacionados",
    "workflow-related-to-2": "Empresas relacionadas",
    "workflow-related-to-3": "Usuário responsável",
    "workflow-related-to-4": "Responsável da tarefa",
    "workflow-related-to-5": "Criador da tarefa",
    "workflow-related-to-6": "Outros",
    "workflow-entity-1": "Contato",
    "workflow-entity-2": "Empresa",
    "workflow-entity-3": "Card",
    "workflow-entity-4": "Usuário",
    "workflow-entity-5": "Tarefa",
    "workflow-entity-6": "Produto",
    "workflow-entity-7": "Financeiro",
    "workflow-execute-when-1": "Criar",
    "workflow-execute-when-2": "Atualizar campos",
    "workflow-execute-when-3": "Mover de estágio",
    "workflow-execute-when-4": "Deletar",
    "workflow-execute-when-5": "Frequentemente",
    "workflow-execute-when-6": "Finalizar todas as tarefas",
    "workflow-execute-when-7": "Atrasar",
    "workflow-execute-when-8": "Próximo a atrasar",
    "workflow-actions-1": "Enviar e-mail",
    "workflow-actions-2": "Enviar e-mail de confirmação",
    "workflow-actions-3": "Enviar sms",
    "workflow-actions-14": "Enviar mensagem no WhatsApp",
    "workflow-actions-15": "Enviar mensagem no Discord",
    "workflow-actions-16": "Enviar mensagem no Slack",
    "workflow-actions-4": "Alterar valor de um campo",
    "workflow-actions-17": "Calcular valor de um campo",
    "workflow-actions-5": "Adicionar etiqueta",
    "workflow-actions-13": "Remover etiqueta",
    "workflow-actions-12": "Remover etiquetas",
    "workflow-actions-6": "Adicionar tarefa",
    "workflow-actions-7": "Duplicar card",
    "workflow-actions-8": "Executar webhook",
    "workflow-actions-9": "Adicionar lançamento financeiro",
    "workflow-actions-10": "Adicionar nota",
    "workflow-actions-11": "Enviar notificação na wepipe",
    "workflow-frequency-1": "De hora em hora",
    "workflow-frequency-2": "Diariamente",
    "workflow-frequency-3": "Semanalmente",
    "workflow-frequency-4": "Mensalmente",
    "workflow-notification-to-1": "Usuário responsável",
    "workflow-notification-to-2": "Criador do registro",
    "workflow-notification-to-3": "Outro",
    "workflow-json-error":
      "Algum dos campos de JSON não está formatado corretamente.",
    "create-workflow-success": "A automação foi criada com sucesso!",
    "create-workflow-error": "Não foi possível criar a automação!",
    "edit-workflow-success": "A automação foi atualizada com sucesso!",
    "edit-workflow-error": "Não foi possível atualizar a automação!",
    "delete-workflow-success": "A automação foi deletada com sucesso!",
    "delete-workflow-error": "Não foi possível deletar a automação!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    workflows: [],
    workflowGroups: [],
    workflow: null,
    createWorkflowEntity: "deal",
    weekdaysList: [
      {
        text: i18n.t("weekday-1"),
        value: 1
      },
      {
        text: i18n.t("weekday-2"),
        value: 2
      },
      {
        text: i18n.t("weekday-3"),
        value: 3
      },
      {
        text: i18n.t("weekday-4"),
        value: 4
      },
      {
        text: i18n.t("weekday-5"),
        value: 5
      },
      {
        text: i18n.t("weekday-6"),
        value: 6
      },
      {
        text: i18n.t("weekday-7"),
        value: 7
      }
    ],
    workflowConditions: [
      {
        id: 0,
        filterField: "",
        operator: "=",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      }
    ],
    workflowRelatedToList: [
      {
        text: i18n.t("workflow-related-to-1"),
        value: "contacts",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-related-to-2"),
        value: "companies",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-related-to-3"),
        value: "user",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-related-to-4"),
        value: "creator_user",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "deal",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-related-to-5"),
        value: "responsible_user",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "deal",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-related-to-6"),
        value: "others",
        entitiesDisabled: []
      }
    ],
    workflowEntityList: [
      {
        text: i18n.t("workflow-entity-3"),
        value: "deal"
      },
      {
        text: i18n.t("workflow-entity-1"),
        value: "contact"
      },
      {
        text: i18n.t("workflow-entity-2"),
        value: "company"
      },
      {
        text: i18n.t("workflow-entity-4"),
        value: "user"
      },
      {
        text: i18n.t("workflow-entity-5"),
        value: "task"
      },
      {
        text: i18n.t("workflow-entity-6"),
        value: "product"
      },
      {
        text: i18n.t("workflow-entity-7"),
        value: "bill"
      }
    ],
    workflowExecuteWhenList: [
      {
        text: i18n.t("workflow-execute-when-1"),
        value: "create",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-execute-when-2"),
        value: "update",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-execute-when-4"),
        value: "delete",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-execute-when-3"),
        value: "stage_update",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-execute-when-5"),
        value: "frequently",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-execute-when-6"),
        value: "finish_tasks",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-execute-when-7"),
        value: "expire",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-execute-when-8"),
        value: "close_to_expire",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      }
    ],
    workflowActionsList: [
      {
        text: i18n.t("workflow-actions-1"),
        value: "send_email",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-2"),
        value: "send_confirmation_email",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-3"),
        value: "send_sms",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-14"),
        value: "send_whatsapp",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-15"),
        value: "send_discord",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-16"),
        value: "send_slack",
        entitiesDisabled: []
      },
      // {
      //   text: i18n.t("workflow-actions-11"),
      //   value: "send_notification",
      //   entitiesDisabled: []
      // },
      {
        text: i18n.t("workflow-actions-4"),
        value: "change_field_value",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-17"),
        value: "calculate_field_value",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-actions-5"),
        value: "add_tag",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-13"),
        value: "remove_tag",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-12"),
        value: "remove_tags",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-6"),
        value: "add_task",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-10"),
        value: "add_note",
        entitiesDisabled: ["product"]
      },
      {
        text: i18n.t("workflow-actions-9"),
        value: "add_bill",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-7"),
        value: "duplicate",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-actions-8"),
        value: "webhook",
        entitiesDisabled: []
      }
    ],
    workflowFrequencyList: [
      {
        text: i18n.t("workflow-frequency-1"),
        value: "hourly"
      },
      {
        text: i18n.t("workflow-frequency-2"),
        value: "daily"
      },
      {
        text: i18n.t("workflow-frequency-3"),
        value: "weekly"
      },
      {
        text: i18n.t("workflow-frequency-4"),
        value: "monthly"
      }
    ],
    workflowNotificationToList: [
      {
        text: i18n.t("workflow-notification-to-1"),
        value: "responsible_user",
        entitiesDisabled: [
          "contact",
          "company",
          "user",
          "task",
          "product",
          "bill"
        ]
      },
      {
        text: i18n.t("workflow-notification-to-2"),
        value: "creator_user",
        entitiesDisabled: []
      },
      {
        text: i18n.t("workflow-notification-to-3"),
        value: "other",
        entitiesDisabled: []
      }
    ],
    workflowWebhookMethodsList: [
      {
        text: "POST",
        value: "post"
      },
      {
        text: "PUT",
        value: "put"
      }
    ]
  },
  getters: {
    getWorkflows: state => state.workflows,
    getWorkflowGroups: state => state.workflowGroups,
    getWorkflow: state => state.workflow,
    getWeekdaysList: state => state.weekdaysList,
    getCreateWorkflowEntity: state => state.createWorkflowEntity,
    getWorkflowConditions: state => state.workflowConditions,
    getWorkflowRelatedToList: state => state.workflowRelatedToList,
    getWorkflowEntityList: state => state.workflowEntityList,
    getWorkflowExecuteWhenList: state => state.workflowExecuteWhenList,
    getWorkflowActionsList: state => state.workflowActionsList,
    getWorkflowFrequencyList: state => state.workflowFrequencyList,
    getWorkflowWebhookMethodsList: state => state.workflowWebhookMethodsList,
    getWorkflowNotificationToList: state => state.workflowNotificationToList
  },
  mutations: {
    setWorkflows: (state, payload) => {
      state.workflows = payload;
    },
    setWorkflowGroups: (state, payload) => {
      state.workflowGroups = payload;
    },
    setWorkflow: (state, payload) => {
      state.workflow = payload;
    },
    setCreateWorkflowEntity: (state, payload) => {
      state.createWorkflowEntity = payload;
    },
    setWorkflowConditions: (state, payload) => {
      state.workflowConditions = payload;
    },
    addWorkflowCondition: (state, payload) => {
      state.workflowConditions.push(payload);
    },
    removeWorkflowCondition: (state, index) => {
      state.workflowConditions.splice(index, 1);
    },
    resetWorkflowConditions: state => {
      state.workflowConditions = [
        {
          id: 0,
          filterField: "",
          operator: "=",
          valueField: {
            value: "",
            type: "text",
            mask: ""
          }
        }
      ];
    }
  },
  actions: {
    workflowsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/workflows`, {
            params: payload?.params || {}
          })
          .then(resp => {
            const workflows = resp.data;

            commit("setWorkflows", workflows);
            resolve(resp);
          });
      });
    },
    workflowGroupsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/workflows/groups`, {
            params: payload?.params || {}
          })
          .then(resp => {
            const groups = resp.data;

            commit("setWorkflowGroups", groups);
            resolve(resp);
          });
      });
    },
    workflowRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(`/workflows/${id}`).then(resp => {
          let workflow = resp.data;

          if (
            typeof workflow.settings.headers === "object" &&
            workflow.settings.headers !== null
          ) {
            workflow.settings.headers = JSON.stringify(
              workflow.settings.headers,
              undefined,
              4
            );
          }

          if (
            typeof workflow.settings.body === "object" &&
            workflow.settings.body !== null
          ) {
            workflow.settings.body = JSON.stringify(
              workflow.settings.body,
              undefined,
              4
            );
          }

          if (workflow?.settings?.message?.length > 0) {
            workflow.settings.message = Vue.options.filters.plainTextVariableToMention(
              workflow.settings.message
            );
          }

          if (workflow.frequency_condition >= 0) {
            workflow.frequency_condition = workflow.frequency_condition.toString();
          }

          commit("setWorkflow", workflow);
          resolve(resp);
        });
      });
    },
    createWorkflowRequest({ commit }, payload) {
      const jsonFormatError = () => {
        const snackBar = {
          title: i18n.t("create-workflow-error"),
          description: i18n.t("workflow-json-error"),
          status: "error"
        };

        commit("addSnackBar", snackBar);
      };

      return new Promise(resolve => {
        if (payload.settings.headers?.length > 0) {
          if (Vue.options.filters.isJsonString(payload.settings.headers)) {
            payload.settings.headers = JSON.parse(payload.settings.headers);
          } else {
            jsonFormatError();
            resolve(null);
            return;
          }
        }

        if (payload.settings.body?.length > 0) {
          if (Vue.options.filters.isJsonString(payload.settings.body)) {
            payload.settings.body = JSON.parse(payload.settings.body);
          } else {
            jsonFormatError();
            resolve(null);
            return;
          }
        }

        if (payload.settings?.message?.length > 0) {
          payload.settings.message = Vue.options.filters.mentionToPlainTextVariable(
            payload.settings.message
          );
        }

        Vue.prototype.$http
          .post(`/workflows`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-workflow-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-workflow-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    editWorkflowRequest({ commit }, payload) {
      const jsonFormatError = () => {
        const snackBar = {
          title: i18n.t("edit-workflow-error"),
          description: i18n.t("workflow-json-error"),
          status: "error"
        };

        commit("addSnackBar", snackBar);
      };

      return new Promise(resolve => {
        if (payload.settings.headers?.length > 0) {
          if (Vue.options.filters.isJsonString(payload.settings.headers)) {
            payload.settings.headers = JSON.parse(payload.settings.headers);
          } else {
            jsonFormatError();
            resolve(null);
            return;
          }
        }

        if (payload.settings.body?.length > 0) {
          if (Vue.options.filters.isJsonString(payload.settings.body)) {
            payload.settings.body = JSON.parse(payload.settings.body);
          } else {
            jsonFormatError();
            resolve(null);
            return;
          }
        }

        if (payload.settings?.message?.length > 0) {
          payload.settings.message = Vue.options.filters.mentionToPlainTextVariable(
            payload.settings.message
          );
        }

        Vue.prototype.$http
          .put(`/workflows/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("edit-workflow-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("edit-workflow-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteWorkflowRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/workflows/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-workflow-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-workflow-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
