import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "products-error": "Wasn't possible to load products!",
    "product-error": "Wasn't possible to load product data!",
    "create-product-error": "Wasn't possible to create product!",
    "update-product-success": "Product updated with success!",
    "update-product-error": "Wasn't possible to update product!",
    "update-product-picture-error":
      "Wasn't possible to update product picture!",
    "delete-product-picture-error":
      "Wasn't possible to delete product picture!",
    "delete-product-success": "Product deleted with success!",
    "delete-product-error": "Wasn't possible to delete product!",
    "find-products-error": "Wasn't possible to find products!",
    "search-products-error": "Wasn't possible to search products!",
    "filter-products-error": "Wasn't possible to filter products!",
    "product-color-1": "Primary",
    "product-color-2": "Yellow",
    "product-color-3": "Red",
    "product-color-4": "Green",
    "product-color-5": "Orange",
    "product-color-6": "Indigo",
    "product-color-7": "Blue",
    "product-color-8": "Black"
  },
  "pt-BR": {
    "products-error": "Não foi possível carregar os produtos!",
    "product-error": "Não foi possível carregar os dados do produto!",
    "create-product-error": "Não foi possível criar o produto!",
    "update-product-success": "Produto atualizado com sucesso!",
    "update-product-error": "Não foi possível atualizar o produto!",
    "update-product-picture-error":
      "Não foi possível atualizar a foto do produto!",
    "delete-product-picture-error":
      "Não foi possível deletar a foto do produto!",
    "delete-product-success": "Produto deletado com sucesso!",
    "delete-product-error": "Não foi possível deletar o produto!",
    "find-products-error": "Não foi possível pesquisar produtos!",
    "search-products-error": "Não foi possível realizar a pesquisa!",
    "filter-products-error": "Não foi possível realizar o filtro!",
    "product-color-1": "Padrão",
    "product-color-2": "Amarelo",
    "product-color-3": "Vermelho",
    "product-color-4": "Verde",
    "product-color-5": "Laranja",
    "product-color-6": "Turquesa",
    "product-color-7": "Azul",
    "product-color-8": "Preto"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    products: [],
    product: {},
    productsPages: {
      last: 1
    },
    productsSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    productCustomFields: null,
    productColors: [
      {
        name: i18n.t("product-color-1"),
        color: "primary"
      },
      {
        name: i18n.t("product-color-2"),
        color: "warning"
      },
      {
        name: i18n.t("product-color-3"),
        color: "error"
      },
      {
        name: i18n.t("product-color-4"),
        color: "success"
      },
      {
        name: i18n.t("product-color-5"),
        color: "orange"
      },
      {
        name: i18n.t("product-color-6"),
        color: "indigo"
      },
      {
        name: i18n.t("product-color-7"),
        color: "blue"
      },
      {
        name: i18n.t("product-color-8"),
        color: "info"
      }
    ]
  },
  getters: {
    getProducts: state => state.products,
    getProduct: state => state.product,
    getProductCustomFields: state => state.productCustomFields,
    getProductsPages: state => state.productsPages,
    getProductsSort: state => state.productsSort,
    getProductColors: state => state.productColors
  },
  mutations: {
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setProduct: (state, payload) => {
      state.product = payload;
    },
    setProductsPages: (state, payload) => {
      state.productsPages = payload;
    },
    setProductsSort: (state, payload) => {
      state.productsSort = payload;
    },
    createProductCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.productCustomFields = { ...object, ...state.productCustomFields };
    },
    setProductCustomFieldsValues: (state, payload) => {
      state.productCustomFields = payload;
    },
    clearProductCustomFieldsValues: state => {
      if (state.productCustomFields) {
        Object.keys(state.productCustomFields).forEach(index => {
          state.productCustomFields[index] = "";
        });
      }
    }
  },
  actions: {
    productsRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/products`, {
            params: {
              page: page,
              ...orderBy
            }
          })
          .then(resp => {
            const products = resp.data.data;

            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            if (!payload.updateLocal) {
              commit("setProductsPages", page);
              commit("setProducts", products);
            }

            resolve({ products, page });
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("products-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    moreProductsRequest({ getters, commit }, payload) {
      const page = payload > 1 ? payload : 1;

      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/products`, {
            params: {
              page: page
            }
          })
          .then(resp => {
            const products = [...getters.getProducts, ...resp.data.data];
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setProductsPages", page);

            commit("setProducts", products);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    productRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/products/${id}`)
          .then(resp => {
            let product = resp.data;
            product.price = Math.round(product.price * 1e2) / 1e2;
            commit("setProduct", product);
            commit("clearProductCustomFieldsValues");
            if (product.custom_fields) {
              commit("setProductCustomFieldsValues", product.custom_fields[0]);
            }
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("product-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createProductRequest({ commit }, product) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/products`, product)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-product-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateProductRequest({ commit }, product) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/products/${product.id}`, product)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-product-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-product-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateProductPictureRequest({ state, commit }, payload) {
      let formData = new FormData();
      formData.append("product_picture", payload);

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/products/${state.product.id}/uploadPicture`, formData)
          .then(resp => {
            commit("setProduct", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-product-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteProductPictureRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/products/${id}/removePicture`)
          .then(resp => {
            commit("setProduct", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-product-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteProductRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/products/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-product-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-product-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    findProductsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/products/find`, {
            params: { ...payload }
          })
          .then(resp => {
            const products = resp.data.data;

            if (!payload?.updateLocal) {
              commit("setProducts", products);
            }

            resolve(resp.data);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("find-products-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    searchProductsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/products/search`, { params: payload })
          .then(resp => {
            const products = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setProductsPages", page);

            commit("setProducts", products);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-products-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterProductsRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/products/export",
        entity: "products",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/products/filter?paginate=true`, payload)
          .then(resp => {
            const products = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setProductsPages", page);

            commit("setProducts", products);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-products-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
