export default {
  state: {
    installPwa: {
      available: false,
      deferredPrompt: null
    }
  },
  getters: {
    getInstallPwa: state => state.installPwa
  },
  mutations: {
    setInstallPwa: (state, payload) => {
      state.installPwa = payload;
    }
  }
};
