<i18n>
{
  "en": {
    "title": "Update filtered data",
    "field": "Field",
    "value": "Value",
    "remove": "Remove field",
    "add": "Add field",
    "update": "Save updates",
    "true": "True",
    "false": "False",
    "update-error-title": "Wasn't possible to update!",
    "update-error-description": "Check if you selected at least one field type and try again.",
    "bulk-update": "All the records will be bulk updated when you confirm."
  },
  "pt-BR": {
    "title": "Editar registros filtrados",
    "field": "Campo",
    "value": "Valor",
    "remove": "Remover campo",
    "add": "Adicionar campo",
    "update": "Salvar alterações",
    "true": "Verdadeiro",
    "false": "Falso",
    "update-error-title": "Não foi possível atualizar!",
    "update-error-description": "Verifique se selecionou pelo menos um tipo de campo e tente novamente.",
    "bulk-update": "Ao confirmar essa ação todos os registros serão atualizados em massa."
  }
}
</i18n>

<template>
  <div id="mass_update" v-if="open">
    <confirm-modal
      :open="confirmUpdateOpened"
      type="confirm"
      :description="$t('bulk-update')"
      @close="
        confirmUpdateOpened = false;
        fieldsOpened = true;
      "
      @confirm="doMassUpdate"
      :total-records="totalRecords"
      :loading="btnLoading"
    />
    <modal-action @close="closeMassUpdate" v-if="fieldsOpened">
      <template #view>
        <div class="modal" id="mass_update">
          <div class="modal-header">
            <div class="name">
              <div class="icon">
                <font-awesome-icon icon="edit" />
              </div>
              {{ $t("title") }}
            </div>
            <div class="options">
              <we-icon-button icon="times" @click="closeMassUpdate" />
            </div>
          </div>
          <div class="modal-body">
            <section>
              <div class="fields" v-for="(field, i) in fields" :key="field.id">
                <we-input
                  type="global-select"
                  v-model="field.filterField"
                  :label="$t('field')"
                  @input="
                    changeFilterField(field);
                    resetValueAndMask(field);
                  "
                  :items="fieldsListByComponent"
                  :clearable="false"
                  cy="filter-field"
                />
                <we-input-user
                  v-if="field.valueField.type === 'users'"
                  :label="$t('value')"
                  :value="field.valueField.value"
                  @input="field.valueField.value = $event.id"
                />
                <we-input-products
                  v-else-if="field.valueField.type === 'products'"
                  :label="$t('value')"
                  :value="field.valueField.value"
                  @input="field.valueField.value = $event.id"
                />
                <we-input-contacts
                  v-else-if="field.valueField.type === 'contacts'"
                  :label="$t('value')"
                  :value="field.valueField.value"
                  @input="field.valueField.value = $event.id"
                />
                <we-input-companies
                  v-else-if="field.valueField.type === 'companies'"
                  :label="$t('value')"
                  :value="field.valueField.value"
                  @input="field.valueField.value = $event.id"
                />
                <we-input
                  v-else
                  :type="field.valueField.type"
                  v-model="field.valueField.value"
                  v-maska="field.valueField.mask"
                  :items="field.valueField.items"
                  :clearable="false"
                  :label="$t('value')"
                  hide-details
                  cy="filter-value"
                />
                <we-icon-button
                  :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                  :name="$t('remove')"
                  @click="removeField(i)"
                />
              </div>
              <we-button
                class="disabled"
                :text="$t('add')"
                icon="plus"
                @click="addField"
                cy="add-filter"
              />
            </section>
          </div>
          <div class="bottom">
            <we-button
              class="green"
              :text="`${$t('update')} ${name.toLowerCase()}`"
              icon="check"
              @click="
                confirmUpdateOpened = true;
                fieldsOpened = false;
              "
              cy="update-modal"
            />
          </div>
          <we-loading-overflow :loading="loading" />
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    totalRecords: Number
  },
  data() {
    return {
      idNumber: 1,
      loading: false,
      btnLoading: false,
      searchLoading: false,
      customFieldsInputList: [],
      customFields: [],
      confirmUpdateOpened: false,
      fieldsOpened: true
    };
  },
  computed: {
    costCenters() {
      return this.$store.getters.getCostCenters;
    },
    fields() {
      return this.$store.getters.getMassUpdateFields;
    },
    name() {
      return this.$options.filters.entityToPtBr(this.component);
    },
    component() {
      if (this.$route.name === "pipelines") {
        return "pipeline";
      } else if (this.$route.name === "deals") {
        return "deal";
      } else if (this.$route.name === "companies") {
        return "company";
      } else if (this.$route.name === "contacts") {
        return "contact";
      } else if (this.$route.name === "users") {
        return "user";
      } else if (this.$route.name === "productsConfigs") {
        return "product";
      } else if (this.$route.name === "bills") {
        return "bill";
      } else if (this.$route.name === "tasksList") {
        return "taskslist";
      } else if (this.$route.name === "tasksCalendar") {
        return "calendar";
      }

      return "";
    },
    fieldsListByComponent() {
      let list = [];

      if (["pipeline"].includes(this.component)) {
        list = this.$store.getters.getPipelineFields;
      } else if (["deal"].includes(this.component)) {
        list = this.$store.getters.getDealFields;
      } else if (["company"].includes(this.component)) {
        list = this.$store.getters.getCompanyFields;
      } else if (["user"].includes(this.component)) {
        list = this.$store.getters.getUserFields;
      } else if (["product"].includes(this.component)) {
        list = this.$store.getters.getProductFields;
      } else if (["bill"].includes(this.component)) {
        list = this.$store.getters.getBillFields;
      } else if (["taskslist", "calendar"].includes(this.component)) {
        list = this.$store.getters.getTaskFields;
      } else {
        list = this.$store.getters.getContactFields;
      }
      list = list.filter(
        e => e.value !== "created_at" && e.value !== "updated_at"
      );
      return [...list, ...this.customFieldsInputList];
    },
    billMethodsList() {
      return this.$store.getters.getBillMethodsList;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billTypesList() {
      return this.$store.getters.getBillTypesList;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    currentPipelineIndex() {
      return this.$store?.getters?.getCurrentPipelineIndex;
    },
    currentPipeline() {
      return this.pipelines[this.currentPipelineIndex];
    },
    pipelinesList() {
      let pipelines = [];
      this.pipelines.forEach(pipeline => {
        pipelines.push({
          text: pipeline.name,
          value: pipeline.id
        });
      });
      return pipelines;
    },
    allStagesList() {
      let allStages = [];
      this.pipelines.forEach(pipeline => {
        pipeline.stages.forEach(stage => {
          allStages.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });
      return allStages;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    taskTypesList() {
      return this.$store.getters.getTasks?.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "updateFilteredRecords",
      "contactsRequest",
      "companiesRequest",
      "productsRequest",
      "dealsRequest",
      "billsRequest",
      "usersRequest",
      "customFieldsRequest",
      "costCentersRequest",
      "calendarTasksRequest",
      "tasksRequest"
    ]),
    closeMassUpdate() {
      this.$emit("confirm");
      this.$emit("close");
      this.$store.commit("resetMassUpdateFields");
      this.confirmUpdateOpened = false;
      this.fieldsOpened = true;
    },
    isCheckbox(field) {
      return field.includes("is_");
    },
    resetValueAndMask(field) {
      field.valueField.value = null;
      field.valueField.mask = "";
    },
    changeFilterField(field) {
      field.valueField.type = "text";

      const customFieldRelated = this.customFields.find(
        e => e.name === field.filterField
      );

      if (customFieldRelated) {
        field.valueField.type = customFieldRelated.type;
        field.valueField.mask =
          field.valueField.type === "text" ? customFieldRelated.mask : "";

        if (customFieldRelated.values?.length > 0) {
          field.valueField.items = customFieldRelated.values;
        }
      }

      if (this.isCheckbox(field.filterField)) {
        field.valueField.type = "global-select";
        field.valueField.items = [
          {
            text: this.$t("true"),
            value: "true"
          },
          {
            text: this.$t("false"),
            value: "false"
          }
        ];
      }

      if (
        [
          "start",
          "end",
          "birthdate",
          "closed_at",
          "paid_at",
          "due_date"
        ].includes(field.filterField)
      ) {
        field.valueField.type = "date";
      }

      if (["total", "subtotal", "amount"].includes(field.filterField)) {
        field.valueField.type = "money";
      }

      if (["document"].includes(field.filterField)) {
        if (this.component === "company") {
          field.valueField.mask = "##.###.###/####-##";
        } else {
          field.valueField.mask = this.$root.$t("cpf-mask");
        }
      }

      if (["phone1"].includes(field.filterField)) {
        field.valueField.mask = this.$root.$t("phone-mask");
      }

      if (["task_type_id"].includes(field.filterField)) {
        field.valueField.type = "global-select";
        field.valueField.items = this.taskTypesList;
      }

      if (["user_id", "responsible_user_id"].includes(field.filterField)) {
        field.valueField.type = "users";
      }

      if (["product_id"].includes(field.filterField)) {
        field.valueField.type = "products";
      }

      if (["contact_id"].includes(field.filterField)) {
        field.valueField.type = "contacts";
      }

      if (["company_id"].includes(field.filterField)) {
        field.valueField.type = "companies";
      }

      if (["cost_center"].includes(field.filterField)) {
        field.valueField.type = "global-select";
        field.valueField.items = this.costCenters;
      }

      if (this.component === "bill") {
        if (["method"].includes(field.filterField)) {
          field.valueField.type = "global-select";
          field.valueField.items = this.billMethodsList;
        }

        if (["status"].includes(field.filterField)) {
          field.valueField.type = "global-select";
          field.valueField.items = this.billStatusList;
        }

        if (["type"].includes(field.filterField)) {
          field.valueField.type = "global-select";
          field.valueField.items = this.billTypesList;
        }
      }

      if (["pipeline_stage_id"].includes(field.filterField)) {
        field.valueField.type = "global-select";
        field.valueField.items = this.allStagesList;
      }

      if (["pipeline_id"].includes(field.filterField)) {
        field.valueField.type = "global-select";
        field.valueField.items = this.pipelinesList;
      }

      return field;
    },
    addField() {
      const fieldObject = {
        id: this.idNumber++,
        filterField: "",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      };

      this.$store.commit("addUpdateFields", fieldObject);
    },
    removeField(index) {
      this.$store.commit("removeUpdateFields", index);
    },
    async doMassUpdate() {
      this.btnLoading = true;
      this.$store.commit("resetUsersSelected");

      if (!this.fields || this.fields?.length <= 0) {
        const snackBar = {
          title: this.$t("update-error-title"),
          description: this.$t("update-error-description"),
          status: "error"
        };
        this.btnLoading = false;
        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");

        return;
      }

      let payload = {};
      this.fields
        .filter(e => e.filterField.trim()?.length > 0)
        .forEach(field => {
          payload[field.filterField] = field.valueField.value;

          if (this.isCheckbox(field.filterField)) {
            payload[field.filterField] = field.valueField.value === "true";
          }
        });

      await this.updateFilteredRecords(payload);
      this.closeMassUpdate();
      this.$store.commit("setFilterActive", true);
      this.btnLoading = false;
    }
  },
  async mounted() {
    if (["pipeline"].includes(this.component)) {
      await this.customFieldsRequest({ entity: "deal" });
    } else {
      await this.customFieldsRequest({ entity: this.component });
    }

    if (this.component === "bill") {
      this.costCentersRequest();
    }

    if (["calendar", "taskslist"].includes(this.component)) {
      await this.tasksRequest();
    }

    this.customFields = this.$store.getters.getCustomFields;

    this.customFieldsInputList = this.customFields.map(e => {
      return {
        text: e.label || e.name,
        value: e.name
      };
    });
  }
};
</script>

<style lang="scss">
#action_modal {
  .modal#mass_update {
    .modal-body {
      overflow-y: auto;
      position: relative;

      section {
        margin-bottom: 24px;

        .title {
          display: flex;
          align-items: center;
          font-weight: $semibold;
          color: var(--text-2);
          font-size: 16px !important;
          margin-bottom: 16px;

          .icon {
            width: 30px;
            display: flex;
            align-items: center;

            svg {
              height: 18px;
              width: auto;
            }
          }
        }

        .fields {
          padding-left: 0;
          display: grid;
          grid-template-columns: 200px 220px 40px;
          align-items: flex-end;
          gap: 16px;
          margin-bottom: 14px;

          @include mobile {
            display: block;
            border-bottom: 1px solid var(--line);
            margin-bottom: 20px;
          }

          .we-input {
            @include mobile {
              margin-bottom: 20px;
            }
          }

          .we-icon-button {
            @include mobile {
              margin-bottom: 20px;
            }
          }

          .we-input.pagination .label {
            padding-left: 13px;
          }
        }

        button {
          margin: 0 auto;
          margin-top: 32px;
          grid-column-start: 3;
          grid-column-end: 3;

          &.icon {
            margin-top: 0;
            margin-bottom: 1px;
          }
        }
      }
    }
  }
}
</style>
