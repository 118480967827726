<template>
  <div class="board-plugin">
    <we-nav-x
      class="only-mobile"
      v-if="menu && menu.length > 0"
      :items="menu"
      :activeIndex="activeMenu"
      @select="$emit('selectMenu', $event)"
    />
    <v-form ref="form" class="nav-and-content" @submit.prevent>
      <nav class="board-menu">
        <div class="sticky">
          <div
            class="menu-item"
            v-for="(item, i) in menu"
            :key="item.name"
            @click="$emit('selectMenu', i)"
            :class="{ active: i === activeMenu }"
          >
            <font-awesome-icon class="icon" :icon="item.icon" />
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </nav>
      <div class="board-content">
        <slot name="content" />
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    menu: Array,
    activeMenu: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
.board-plugin {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  position: relative;

  @include mobile {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .we-nav-x {
    display: none;
    background: var(--background-2);
    border-bottom: 1px solid var(--line);
    flex-shrink: 0;
    position: sticky;
    top: 0;
    background: var(--background-1);
    z-index: 1;

    @include mobile {
      display: block;
    }
  }

  .nav-and-content {
    width: 100%;
    max-width: 778px;
    margin: 0 auto;
    padding: 0 15px;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: minmax(0, 208px) minmax(0, 1fr);
    gap: 40px;

    @include mobile {
      display: block;
      flex: 1;
    }

    .board-menu {
      @include mobile {
        display: none;
      }

      .sticky {
        position: sticky;
        top: 0;
        padding-top: 80px;
      }

      .menu-item {
        height: 40px;
        display: grid;
        grid-template-columns: 16px 1fr;
        gap: 16px;
        align-items: center;
        color: var(--text-2);
        padding: 4px 16px;
        cursor: pointer;
        border-radius: 7px;
        margin-bottom: 8px;

        &:hover {
          background: var(--background-1-hover);
        }

        &.active {
          background: var(--background-1-hover);
          color: var(--text-1);
        }

        .icon {
          width: 18px;
          height: 18px;
        }

        .item-name {
          font-size: 13px;
          font-weight: $semibold;
        }
      }
    }

    .board-content {
      padding-top: 80px;
      position: relative;

      @include mobile {
        padding-top: 24px;
      }
      .we-block {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
