export default {
  state: {
    returnReasons: [
      {
        text: "Escolhi o produto errado",
        value: "WRONG_SELECTED"
      },
      {
        text: "Enviaram o produto errado",
        value: "WRONG_SENT"
      },
      {
        text: "Não preciso mais do produto",
        value: "NOT_NEEDED"
      },
      {
        text: "Não senti diferença ao usar o produto",
        value: "NOT_DIFFERENCE"
      },
      {
        text: "Me arrependi de ter comprado",
        value: "REGRET"
      },
      {
        text: "O produto está danificado ou com defeito",
        value: "DAMAGED"
      },
      {
        text: "O produto me causou uma reação indesejada",
        value: "UNWANTED_REACTION"
      },
      {
        text: "O produto demorou mais que o tempo previsto",
        value: "DELAYED"
      },
      {
        text: "O produto não está de acordo com a descrição",
        value: "NOT_MATCH"
      },
      {
        text: "Outro",
        value: "OTHER"
      }
    ]
  },
  getters: {
    getReturnReasons: state => {
      return state.returnReasons;
    }
  }
};
