import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "cf-entity-1": "Card",
    "cf-entity-2": "Contact",
    "cf-entity-3": "Company",
    "cf-entity-4": "User",
    "cf-entity-5": "Product",
    "cf-entity-6": "Bill",
    "cf-type-1": "Text",
    "cf-type-2": "Number",
    "cf-type-3": "Email",
    "cf-type-4": "Password",
    "cf-type-5": "Textarea",
    "cf-type-6": "Select",
    "cf-type-7": "Select (with search)",
    "cf-type-8": "Tags",
    "cf-type-9": "Multiselect",
    "cf-type-10": "Date",
    "cf-type-11": "Time",
    "cf-type-12": "Money",
    "cf-type-13": "Link",
    "cf-type-14": "Div",
    "cf-type-15": "Global select",
    "cf-layout-1": "One column",
    "cf-layout-2": "Two columns",
    "create-cf-success": "Custom field was created with success!",
    "create-cf-error": "Wasn't possible to create custom field!",
    "edit-cf-success": "Custom field was updated with success!",
    "edit-cf-error": "Wasn't possible to updated custom field!",
    "delete-cf-success": "Custom field was deleted with success!",
    "delete-cf-error": "Wasn't possible to delete custom field!"
  },
  "pt-BR": {
    "cf-entity-1": "Card",
    "cf-entity-2": "Contato",
    "cf-entity-3": "Empresa",
    "cf-entity-4": "Usuário",
    "cf-entity-5": "Produto",
    "cf-entity-6": "Financeiro",
    "cf-type-1": "Text",
    "cf-type-2": "Number",
    "cf-type-3": "Email",
    "cf-type-4": "Password",
    "cf-type-5": "Textarea",
    "cf-type-6": "Select",
    "cf-type-7": "Select (com pesquisa)",
    "cf-type-8": "Tags",
    "cf-type-9": "Multiselect",
    "cf-type-10": "Date",
    "cf-type-11": "Time",
    "cf-type-12": "Money",
    "cf-type-13": "Link",
    "cf-type-14": "Separator",
    "cf-type-15": "Global select",
    "cf-layout-1": "Uma coluna",
    "cf-layout-2": "Duas colunas",
    "create-cf-success": "O campo foi criado com sucesso!",
    "create-cf-error": "Não foi possível criar o campo!",
    "edit-cf-success": "O campo foi atualizado com sucesso!",
    "edit-cf-error": "Não foi possível atualizar o campo!",
    "delete-cf-success": "O campo foi deletado com sucesso!",
    "delete-cf-error": "Não foi possível deletar o campo!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    customFields: [],
    customFieldGroups: [],
    customField: {},
    currentCustomFieldsEntity: "deal",
    customFieldConditions: [
      {
        id: 0,
        filterField: "",
        operator: "=",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      }
    ],
    customFieldEntityList: [
      {
        text: i18n.t("cf-entity-1"),
        value: "deal"
      },
      {
        text: i18n.t("cf-entity-2"),
        value: "contact"
      },
      {
        text: i18n.t("cf-entity-3"),
        value: "company"
      },
      {
        text: i18n.t("cf-entity-4"),
        value: "user"
      },
      {
        text: i18n.t("cf-entity-5"),
        value: "product"
      },
      {
        text: i18n.t("cf-entity-6"),
        value: "bill"
      }
    ],
    customFieldTypeList: [
      {
        text: i18n.t("cf-type-1"),
        value: "text"
      },
      {
        text: i18n.t("cf-type-2"),
        value: "number"
      },
      {
        text: i18n.t("cf-type-3"),
        value: "email"
      },
      {
        text: i18n.t("cf-type-4"),
        value: "password"
      },
      {
        text: i18n.t("cf-type-5"),
        value: "textarea"
      },
      {
        text: i18n.t("cf-type-6"),
        value: "select"
      },
      {
        text: i18n.t("cf-type-7"),
        value: "select-search"
      },
      {
        text: i18n.t("cf-type-8"),
        value: "tags"
      },
      {
        text: i18n.t("cf-type-9"),
        value: "multiselect"
      },
      {
        text: i18n.t("cf-type-10"),
        value: "date"
      },
      {
        text: i18n.t("cf-type-11"),
        value: "time"
      },
      {
        text: i18n.t("cf-type-12"),
        value: "money"
      },
      {
        text: i18n.t("cf-type-13"),
        value: "link"
      },
      {
        text: i18n.t("cf-type-14"),
        value: "div"
      },
      {
        text: i18n.t("cf-type-15"),
        value: "global-select"
      }
    ],
    customFieldLayoutList: [
      {
        text: i18n.t("cf-layout-1"),
        value: "single-column"
      },
      {
        text: i18n.t("cf-layout-2"),
        value: "double-column"
      }
    ]
  },
  getters: {
    getCustomFields: state => state.customFields,
    getCustomFieldGroups: state => state.customFieldGroups,
    getCustomField: state => state.customField,
    getCurrentCustomFieldsEntity: state => state.currentCustomFieldsEntity,
    getContactCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "contact"),
    getCompanyCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "company"),
    getUserCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "user"),
    getDealCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "deal"),
    getBillCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "bill"),
    getProductlCustomFieldsInputs: state =>
      state.customFields.filter(e => e.entity === "product"),
    getCustomFieldConditions: state => state.customFieldConditions,
    getCustomFieldEntityList: state => state.customFieldEntityList,
    getCustomFieldTypeList: state => state.customFieldTypeList,
    getCustomFieldLayoutList: state => state.customFieldLayoutList
  },
  mutations: {
    setCustomFields: (state, payload) => {
      state.customFields = payload || [];
    },
    setCustomFieldGroups: (state, payload) => {
      state.customFieldGroups = payload;
    },
    setCustomField: (state, payload) => {
      state.customField = payload;
    },
    setCurrentCustomFieldsEntity: (state, payload) => {
      state.currentCustomFieldsEntity = payload;
    },
    setCustomFieldConditions: (state, payload) => {
      state.customFieldConditions = payload;
    },
    addCustomFieldCondition: (state, payload) => {
      state.customFieldConditions.push(payload);
    },
    removeCustomFieldCondition: (state, index) => {
      state.customFieldConditions.splice(index, 1);
    },
    resetCustomFieldConditions: state => {
      state.customFieldConditions = [
        {
          id: 0,
          filterField: "",
          operator: "=",
          valueField: {
            value: "",
            type: "text",
            mask: ""
          }
        }
      ];
    }
  },
  actions: {
    customFieldsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customFields`, {
            params: payload
          })
          .then(resp => {
            const customFields = resp.data;

            commit("setCustomFields", customFields);

            const contactCustomFields = customFields.filter(
              e => e.entity === "contact"
            );
            const companyCustomFields = customFields.filter(
              e => e.entity === "company"
            );
            const userCustomFields = customFields.filter(
              e => e.entity === "user"
            );
            const dealCustomFields = customFields.filter(
              e => e.entity === "deal"
            );
            const productCustomFields = customFields.filter(
              e => e.entity === "product"
            );
            const billCustomFields = customFields.filter(
              e => e.entity === "bill"
            );

            commit("createContactCustomFields", contactCustomFields);
            commit("createCompanyCustomFields", companyCustomFields);
            commit("createUserCustomFields", userCustomFields);
            commit("createDealCustomFields", dealCustomFields);
            commit("createProductCustomFields", productCustomFields);
            commit("createBillCustomFields", billCustomFields);
            resolve(resp);
          });
      });
    },
    customFieldGroupsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customFields/groups`, {
            params: payload?.params || {}
          })
          .then(resp => {
            const groups = resp.data;

            commit("setCustomFieldGroups", groups);
            resolve(resp);
          });
      });
    },
    customFieldRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(`/customFields/${id}`).then(resp => {
          let customField = resp.data;

          commit("setCustomField", customField);
          resolve(resp);
        });
      });
    },
    createCustomFieldRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/customFields`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-cf-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-cf-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    editCustomFieldRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/customFields/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("edit-cf-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("edit-cf-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteCustomFieldRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/customFields/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-cf-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-cf-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
