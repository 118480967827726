<i18n>
{
  "en": {
    "import-csv-error-title": "Wasn't possible to upload",
    "import-csv-error-description": "Looks like the file isn't on CSV format."
  },
  "pt-BR": {
    "import-csv-error-title": "Não foi possível carregar o arquivo",
    "import-csv-error-description": "Parece que o arquivo não está no formato CSV."
  }
}
</i18n>

<template>
  <div class="we-csv-map">
    <we-file-upload
      @change="onFileChange"
      height="120px"
      padding="0"
      accept=".csv"
      :class="{ uploaded: file != null }"
      :uploadText="file ? 'Arquivo anexado com sucesso' : 'Anexar arquivo CSV'"
      :info="file ? file.name : ''"
    />
  </div>
</template>

<script>
import Papa from "papaparse";
import Encoding from "encoding-japanese";

export default {
  data() {
    return {
      file: null
    };
  },
  methods: {
    onFileChange(file) {
      if (
        file.type !== "text/csv" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        const snackBar = {
          title: this.$t("import-csv-error-title"),
          description: this.$t("import-csv-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        return;
      }
      this.file = file;
      this.readFile(output => {
        this.csvToJson(output);
      });
    },
    readFile(callback) {
      if (!(this.file instanceof File)) return;

      let reader = new FileReader();
      reader.onload = function(evt) {
        const codes = new Uint8Array(evt.target.result);
        const detectedEncoding = Encoding.detect(codes);

        let unicodeString = Encoding.convert(codes, {
          to: "unicode",
          from: detectedEncoding,
          type: "string"
        });

        const papaResponse = Papa.parse(unicodeString, {
          skipEmptyLines: true
        });

        callback(papaResponse.data);
      };
      reader.onerror = function() {};
      reader.readAsArrayBuffer(this.file);
    },
    csvToJson(data) {
      if (!data || data.length <= 0) return;

      const headers = data.shift();

      const recordsLength = data.length;
      const columnsLength = headers.length;
      const array = [];

      for (let i = 0; i < recordsLength; i++) {
        let object = {};

        for (let j = 0; j < columnsLength; j++) {
          object[headers[j]] = data[i][j];
        }

        array.push(object);
      }

      this.input({ headers, data: array });
    },
    input(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style></style>
