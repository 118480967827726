import Vue from "vue";

import ModalAction from "@/components/layouts/ModalAction.vue";
import ModalEntity from "@/components/layouts/ModalEntity.vue";
import BoardConfig from "@/components/layouts/BoardConfig.vue";
import BoardForm from "@/components/layouts/BoardForm.vue";
import BoardList from "@/components/layouts/BoardList.vue";
import BoardPlugin from "@/components/layouts/BoardPlugin.vue";
import ConfirmModal from "@/views/Global/ConfirmModal.vue";
import MassUpdate from "@/views/Global/MassUpdate.vue";
import NewVersion from "@/views/Global/NewVersion.vue";
import FilterSave from "@/views/Filter/Save.vue";
import AddImage from "@/views/Global/AddImage.vue";
import AddLink from "@/views/Global/AddLink.vue";
import NavTop from "@/components/layouts/NavTop.vue";

Vue.component("nav-top", NavTop);
Vue.component("modal-action", ModalAction);
Vue.component("modal-entity", ModalEntity);
Vue.component("board-config", BoardConfig);
Vue.component("board-form", BoardForm);
Vue.component("board-list", BoardList);
Vue.component("board-plugin", BoardPlugin);
Vue.component("confirm-modal", ConfirmModal);
Vue.component("mass-update", MassUpdate);
Vue.component("new-version", NewVersion);
Vue.component("filter-save", FilterSave);
Vue.component("add-image", AddImage);
Vue.component("add-link", AddLink);

import WeAction from "@/components/ui/WeAction.vue";
import WeBalloon from "@/components/ui/WeBalloon.vue";
import WeBlock from "@/components/ui/WeBlock.vue";
import WeButton from "@/components/ui/WeButton.vue";
import WeCardIcon from "@/components/ui/WeCardIcon.vue";
import WeDataField from "@/components/ui/WeDataField.vue";
import WeFileUpload from "@/components/ui/WeFileUpload.vue";
import WeIconButton from "@/components/ui/WeIconButton.vue";
import WeIconTooltip from "@/components/ui/WeIconTooltip.vue";
import WeImageUpload from "@/components/ui/WeImageUpload.vue";
import WeInput from "@/components/ui/WeInput.vue";
import WeLoadingOverflow from "@/components/ui/WeLoadingOverflow.vue";
import WeNavX from "@/components/ui/WeNavX.vue";
import WePictureButton from "@/components/ui/WePictureButton.vue";
import WeProductIcon from "@/components/ui/WeProductIcon.vue";
import WeRoundedPicture from "@/components/ui/WeRoundedPicture.vue";
import WeShadow from "@/components/ui/WeShadow.vue";
import WeSnackBar from "@/components/ui/WeSnackBar.vue";
import WeSteps from "@/components/ui/WeSteps.vue";
import WeTable from "@/components/ui/WeTable.vue";
import WeTable2 from "@/components/ui/WeTable2.vue";
import WeTextTooltip from "@/components/ui/WeTextTooltip.vue";

import WePagination from "@/components/ui-local/WePagination.vue";
import WeInputContacts from "@/components/ui-local/WeInputContacts.vue";
import WeInputCompanies from "@/components/ui-local/WeInputCompanies.vue";
import WeInputProducts from "@/components/ui-local/WeInputProducts.vue";
import WeInputUser from "@/components/ui-local/WeInputUser.vue";
import WeInputDeals from "@/components/ui-local/WeInputDeals.vue";
import DropFile from "@/components/ui-local/WeDropFile.vue";
import Activities from "@/components/ui-local/WeActivities.vue";
import WeDealTask from "@/components/ui-local/WeDealTask.vue";
import WeDealContact from "@/components/ui-local/WeDealContact.vue";
import WeDealCalculator from "@/components/ui-local/WeDealCalculator.vue";
import WeNotFound from "@/components/ui-local/WeNotFound.vue";
import WeProfile from "@/components/ui-local/WeProfile.vue";
import WeNotification from "@/components/ui-local/WeNotification.vue";
import WeNotificationCard from "@/components/ui-local/WeNotificationCard.vue";
import WeLightbox from "@/components/ui-local/WeLightbox.vue";
import WeLabel from "@/components/ui-local/WeLabel.vue";
import WeLabelRemove from "@/components/ui-local/WeLabelRemove.vue";
import WeEditor from "@/components/ui-local/WeEditor.vue";
import WeCodeEditor from "@/components/ui-local/WeCodeEditor.vue";
import WeNestedMenu from "@/components/ui-local/WeNestedMenu.vue";
import WeDealSteps from "@/components/ui-local/WeDealSteps.vue";
import WeUserSelector from "@/components/ui-local/WeUserSelector.vue";
import WePictureChange from "@/components/ui-local/WePictureChange.vue";
import WePipelineCard from "@/components/ui-local/WePipelineCard.vue";
import WeSmsTax from "@/components/ui-local/WeSmsTax.vue";
import WeConfigItem from "@/components/ui-local/WeConfigItem.vue";
import WeInstallPwa from "@/components/ui-local/WeInstallPwa.vue";
import WePrivacyPolicy from "@/components/ui-local/WePrivacyPolicy.vue";
import WeTrialRemaining from "@/components/ui-local/WeTrialRemaining.vue";
import WeCsvMap from "@/components/ui-local/WeCsvMap.vue";
import WeInvoice from "@/components/ui-local/WeInvoice.vue";

Vue.component("drop-file", DropFile);
Vue.component("activities", Activities);
Vue.component("we-icon-button", WeIconButton);
Vue.component("we-picture-button", WePictureButton);
Vue.component("we-rounded-picture", WeRoundedPicture);
Vue.component("we-balloon", WeBalloon);
Vue.component("we-button", WeButton);
Vue.component("we-pagination", WePagination);
Vue.component("we-input", WeInput);
Vue.component("we-input-contacts", WeInputContacts);
Vue.component("we-input-companies", WeInputCompanies);
Vue.component("we-input-products", WeInputProducts);
Vue.component("we-input-user", WeInputUser);
Vue.component("we-input-deals", WeInputDeals);
Vue.component("we-loading-overflow", WeLoadingOverflow);
Vue.component("we-text-tooltip", WeTextTooltip);
Vue.component("we-icon-tooltip", WeIconTooltip);
Vue.component("we-nav-x", WeNavX);
Vue.component("we-deal-task", WeDealTask);
Vue.component("we-deal-contact", WeDealContact);
Vue.component("we-deal-calculator", WeDealCalculator);
Vue.component("we-shadow", WeShadow);
Vue.component("we-product-icon", WeProductIcon);
Vue.component("we-action", WeAction);
Vue.component("we-not-found", WeNotFound);
Vue.component("we-profile", WeProfile);
Vue.component("we-table", WeTable);
Vue.component("we-notification", WeNotification);
Vue.component("we-notification-card", WeNotificationCard);
Vue.component("we-data-field", WeDataField);
Vue.component("we-file-upload", WeFileUpload);
Vue.component("we-lightbox", WeLightbox);
Vue.component("we-label", WeLabel);
Vue.component("we-label-remove", WeLabelRemove);
Vue.component("we-editor", WeEditor);
Vue.component("we-code-editor", WeCodeEditor);
Vue.component("we-nested-menu", WeNestedMenu);
Vue.component("we-deal-steps", WeDealSteps);
Vue.component("we-user-selector", WeUserSelector);
Vue.component("we-picture-change", WePictureChange);
Vue.component("we-pipeline-card", WePipelineCard);
Vue.component("we-sms-tax", WeSmsTax);
Vue.component("we-config-item", WeConfigItem);
Vue.component("we-install-pwa", WeInstallPwa);
Vue.component("we-privacy-policy", WePrivacyPolicy);
Vue.component("we-trial-remaining", WeTrialRemaining);
Vue.component("we-card-icon", WeCardIcon);
Vue.component("we-csv-map", WeCsvMap);
Vue.component("we-block", WeBlock);
Vue.component("we-steps", WeSteps);
Vue.component("we-image-upload", WeImageUpload);
Vue.component("we-snack-bar", WeSnackBar);
Vue.component("we-table-2", WeTable2);
Vue.component("we-invoice", WeInvoice);
