<template>
  <div class="we-picture-button">
    <we-rounded-picture
      :picture="picture"
      :direction="direction"
      :name="name"
      :text="$options.filters.firstLetters(name)"
      size="32"
    />
  </div>
</template>

<script>
export default {
  props: {
    direction: {
      default: "top",
      type: String
    },
    picture: String,
    name: String
  }
};
</script>

<style lang="scss">
.we-picture-button {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  cursor: pointer;

  &:hover {
    background: var(--action);
  }

  .picture {
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      top: 0;
      position: absolute;
      width: 100%;
      height: auto;
    }
  }
}
</style>
