<i18n>
{
  "en": {
    "title": "The 14-day of free trial has ended",
    "description": "Subscribe to Grow or Scale plan and get access to all features or continue using wepipe free plan.",
    "description-not-admin": "We have verified that you are an Agent user. Contact your System Administrator to upgrade wepipe plan.",
    "pipeline": "pipeline",
    "pipelines": "pipelines",
    "custom-fields": "custom fields",
    "automations": "automations",
    "remove-1": "Remove",
    "remove-2": "to keep on free plan",
    "of": "of",
    "upgrade": "Do upgrade",
    "free-plan": "Keep on free plan",
    "update-customer-error": "Wasn't possible to return to free trial",
    "update-customer-error-description": "Please open our website and start a new chat."
  },
  "pt-BR": {
    "title": "O período de 14 dias gratuitos encerrou!",
    "description": "Faça a assinatura do plano Grow ou Scale para ter acesso a mais recursos ou continue usando a wepipe no plano gratuito.",
    "description-not-admin": "Nós verificamos que você é um usuário do tipo Agente. Contate o Administrador do sistema para fazer o upgrade de plano.",
    "pipeline": "pipeline",
    "pipelines": "pipelines",
    "custom-fields": "campos customizados",
    "automations": "automações",
    "remove-1": "Remova",
    "remove-2": "para continuar no plano gratuito",
    "of": "de",
    "upgrade": "Fazer upgrade",
    "free-plan": "Continuar no gratuito",
    "update-customer-error": "Não foi possível continuar no plano gratuito",
    "update-customer-error-description": "Por favor, entre no nosso site e abra uma conversa no chat relatando o ocorrido."
  }
}
</i18n>

<template>
  <modal-action :columns="1" v-if="freeTrialEndedOpened">
    <template #view>
      <div class="modal" id="free_trial_ended">
        <div class="modal-body">
          <div class="we-title">{{ $t("title") }}</div>
          <div class="we-description">
            {{ $t("description") }}
          </div>
          <template v-if="!isAdmin">
            <img
              src="@/assets/undraw/upgrade.svg"
              :alt="$t('title')"
              width="184"
              height="134"
            />
            <div class="we-description">
              {{ $t("description-not-admin") }}
            </div>
          </template>
          <template v-if="isAdmin">
            <div class="remove-data">
              <div class="remove-title">
                <span
                  :class="{ 'red--text': pipelines.length > total.pipelines }"
                >
                  {{ pipelines.length }}
                </span>
                {{ $t("of") }} {{ total.pipelines }} {{ $t("pipeline") }}
              </div>
              <div class="remove-description">
                {{ $t("remove-1") }}
                {{ (pipelines.length - total.pipelines) | minZero }}
                {{ $t("pipelines") }}
                {{ $t("remove-2") }}
              </div>
              <div class="remove-list">
                <we-label-remove
                  v-for="(pipeline, i) in pipelines"
                  :key="pipeline.id"
                  :text="pipeline.name"
                  @remove="deletePipeline(i)"
                />
              </div>
            </div>
            <div class="remove-data">
              <div class="remove-title">
                <span
                  :class="{
                    'red--text': customFields.length > total.customFields
                  }"
                >
                  {{ customFields.length }}
                </span>
                {{ $t("of") }} {{ total.customFields }}
                {{ $t("custom-fields") }}
              </div>
              <div class="remove-description">
                {{ $t("remove-1") }}
                {{ (customFields.length - total.customFields) | minZero }}
                {{ $t("custom-fields") }}
                {{ $t("remove-2") }}
              </div>
              <div class="remove-list">
                <we-label-remove
                  v-for="(customField, i) in customFields"
                  :key="customField.id"
                  :text="customField.label"
                  @remove="deleteCustomField(i)"
                />
              </div>
            </div>
            <div class="remove-data">
              <div class="remove-title">
                <span
                  :class="{ 'red--text': workflows.length > total.workflows }"
                >
                  {{ workflows.length }}
                </span>
                {{ $t("of") }} {{ total.workflows }} {{ $t("automations") }}
              </div>
              <div class="remove-description">
                {{ $t("remove-1") }}
                {{ (workflows.length - total.workflows) | minZero }}
                {{ $t("automations") }}
                {{ $t("remove-2") }}
              </div>
              <div class="remove-list">
                <we-label-remove
                  v-for="(workflow, i) in workflows"
                  :key="workflow.id"
                  :text="workflow.name"
                  @remove="deleteWorkflow(i)"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="bottom double-action" v-if="isAdmin">
          <we-button
            class="primary"
            :disabled="freePlanDisabled"
            :text="$t('free-plan')"
            @click="updateToFreePlan"
            :loading="loadingFreeBtn"
          />
          <we-button
            class="green"
            icon="rocket"
            :text="$t('upgrade')"
            @click="payNow"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      loadingFreeBtn: false,
      total: {
        pipelines: 5,
        customFields: 5,
        workflows: 5
      },
      pipelines: [],
      customFields: [],
      workflows: []
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    isAdmin() {
      return this.$store.getters.getLoggedUserIsAdmin;
    },
    freeTrialEndedOpened() {
      return this.$store.getters.getFreeTrialEndedOpened;
    },
    freePlanDisabled() {
      if (this.loading) return true;
      if (this.pipelines.length > this.total.pipelines) return true;
      if (this.customFields.length > this.total.customFields) return true;
      if (this.workflows.length > this.total.workflows) return true;

      return false;
    }
  },
  methods: {
    ...mapActions([
      "loggedUserRequest",
      "deleteUserRequest",
      "pipelinesRequest",
      "deletePipelineRequest",
      "customFieldsRequest",
      "deleteCustomFieldRequest",
      "workflowsRequest",
      "deleteWorkflowRequest",
      "updateCustomerRequest"
    ]),
    async updateToFreePlan() {
      if (!this.freePlanDisabled) {
        this.loadingFreeBtn = true;
        const payload = { ...this.customer };
        payload.subscription_plan = "start";

        if (!payload.company) {
          payload.company = payload.domain || "Company name";
        }

        if (!payload.document) payload.document = "00.000.000/0000-00";
        if (!payload.phone1) payload.phone1 = "(00) 00000-0000";

        const response = await this.updateCustomerRequest(payload);
        const updateSuccess = response.data;

        if (updateSuccess != null) {
          this.$store.commit("closeFreeTrialEnded");
        } else {
          const snackBar = {
            title: this.$t("update-customer-error"),
            description: this.$t("update-customer-error-description"),
            status: "error"
          };

          this.$store.commit("setSnackBar", snackBar);
          this.$store.commit("addSnackBar");
        }

        this.loadingFreeBtn = false;
      }
    },
    async deletePipeline(i) {
      const response = await this.deletePipelineRequest(this.pipelines[i].id);
      if (response.status === 200) {
        this.pipelines.splice(i, 1);
      }
    },
    async deleteCustomField(i) {
      const response = await this.deleteCustomFieldRequest(
        this.customFields[i].id
      );
      if (response.status === 200) {
        this.customFields.splice(i, 1);
      }
    },
    async deleteWorkflow(i) {
      const response = await this.deleteWorkflowRequest(this.workflows[i].id);
      if (response.status === 200) {
        this.workflows.splice(i, 1);
      }
    },
    payNow() {
      this.$store.commit("setBlockedSystem", true);
      this.$store.commit("openUpgradePlan");
      this.closeModal();
    },
    closeModal() {
      this.$store.commit("closeFreeTrialEnded");
    }
  },
  async mounted() {
    this.loading = true;

    if (!this.loggedUser || Object.keys(this.loggedUser).length === 0) {
      await this.loggedUserRequest();
    }

    if (!this.isAdmin) {
      this.loading = false;
      return;
    }

    const [
      pipelinesResponse,
      customFieldsResponse,
      workflowsResponse
    ] = await Promise.all([
      this.pipelinesRequest(),
      this.customFieldsRequest(),
      this.workflowsRequest()
    ]);

    if (pipelinesResponse.data) {
      this.pipelines = pipelinesResponse.data;
    }

    if (customFieldsResponse.data) {
      this.customFields = customFieldsResponse.data;
    }

    if (workflowsResponse.data) {
      this.workflows = workflowsResponse.data;
    }
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
#action_modal {
  z-index: 8;

  .modal#free_trial_ended {
    width: 100%;
    max-width: 708px;
    height: fit-content;

    @include mobile {
      height: 100%;
      display: block;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      max-height: 594px;
      overflow-y: auto;

      @include mobile {
        max-height: 100%;
        padding: 15px;
        padding-bottom: 80px;
      }

      .we-title {
        font-size: 24px;
        font-weight: $semibold;
        margin-bottom: 22px;

        @include mobile {
          text-align: center;
        }
      }

      .we-description {
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 32px;
        color: var(--text-2);
        text-align: center;
        max-width: 543px;
      }

      img {
        margin-bottom: 32px;
      }

      .remove-data {
        margin-bottom: 24px;
        width: 100%;
        .remove-title {
          font-size: 18px;
          font-weight: $semibold;
        }

        .remove-description {
          font-size: 14px;
          color: var(--text-2);
          margin-bottom: 12px;
        }

        .remove-list {
          display: flex;
          flex-wrap: wrap;
          .we-label-remove {
            margin-right: 8px;
            margin-bottom: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
