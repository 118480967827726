<i18n>
{
  "en": {
    "complete-task": "Complete task",
    "task-completed": "Task completed",
    "show-more": "Show more",
    "options": "Options",
    "edit": "Edit",
    "delete": "Delete",
    "to": "to"
  },
  "pt-BR": {
    "complete-task": "Finalizar tarefa",
    "task-completed": "Tarefa finalizada",
    "show-more": "Mostrar informações",
    "options": "Opções",
    "edit": "Editar",
    "delete": "Deletar",
    "to": "até"
  }
}
</i18n>

<template>
  <div
    class="we-deal-task"
    :class="{ 'task-completed': task.is_finished }"
    v-if="task.task_type"
  >
    <div class="main-content">
      <div class="we-title">
        <v-tooltip top :disabled="!isDesktop">
          <template v-slot:activator="{ on }">
            <div class="action" v-on="on">
              <v-checkbox
                v-model="task.is_finished"
                color="success"
                hide-details
                @change="changeTaskCompletion"
              />
            </div>
          </template>
          <span v-if="task.is_finished">{{ $t("task-completed") }}</span>
          <span v-else>{{ $t("complete-task") }}</span>
        </v-tooltip>
        <div class="color" :class="task.task_type.color"></div>
        <div class="name-and-remaining">
          <we-text-tooltip class="name" :text="getTaskName(task)" />
          <div class="time-remaining">
            <div class="remaining">
              {{ task.end | dateToTimeRemaining }}
            </div>
            <we-label
              v-if="!task.is_finished"
              class="medium"
              :class="{
                warning:
                  $options.filters.timeRemaining(task.end) === 'DELIVER_SOON',
                error: $options.filters.timeRemaining(task.end) === 'LATE'
              }"
            >
              <template #text>
                {{ $root.$t($options.filters.timeRemaining(task.end)) }}
              </template>
            </we-label>
          </div>
        </div>
      </div>
      <div class="actions">
        <span class="chevron-button" :class="{ rotate: opened }">
          <we-icon-button
            @click="opened = !opened"
            icon="chevron-down"
            :name="$t('show-more')"
          />
        </span>
        <we-balloon class="dir-left">
          <template #action>
            <we-icon-button icon="ellipsis-h" :name="$t('options')" />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="editTask">
              <font-awesome-icon icon="edit" />
              {{ $t("edit") }}
            </div>
            <div class="balloon-item" @click="deleteTask">
              <font-awesome-icon
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
              />
              {{ $t("delete") }}
            </div>
          </template>
        </we-balloon>
      </div>
    </div>
    <div class="more-content" v-if="opened">
      <div class="deal-task-item">
        <div class="icon">
          <font-awesome-icon :icon="{ prefix: 'far', iconName: 'clock' }" />
        </div>
        <div class="text">
          <span class="capitalize">{{ task.start | dealTaskDate }}</span>
          {{ $t("to") }}
          <span class="capitalize">{{ task.end | dealTaskDate }}</span>
        </div>
      </div>
      <div class="deal-task-item" v-if="task.status">
        <div class="icon">
          <font-awesome-icon icon="tag" />
        </div>
        {{ task.status }}
      </div>
      <div class="deal-task-item" v-if="task.note">
        <div class="icon">
          <font-awesome-icon icon="align-left" />
        </div>
        {{ task.note }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    task: Object
  },
  data() {
    return {
      opened: false,
      taskCompleted: false
    };
  },
  methods: {
    ...mapActions([
      "dealTasksRequest",
      "deleteDealTaskRequest",
      "updateDealTaskRequest"
    ]),
    getTaskName(task) {
      return task?.name ? task?.name : task?.task_type?.name;
    },
    editTask() {
      if (this.$route.name === "pipelineDeal") {
        this.$router.push({
          name: "editDealTask",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "tasksDeal") {
        this.$router.push({
          name: "editDealTaskInTasks",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "deal") {
        this.$router.push({
          name: "editDealTaskInDeals",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "contactDeal") {
        this.$router.push({
          name: "editDealTaskInContactDeals",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "dealInTasksList") {
        this.$router.push({
          name: "editDealTaskInTasksList",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "dealInCalendar") {
        this.$router.push({
          name: "editDealTaskInCalendar",
          params: { taskId: this.task.id }
        });
      }
    },
    async changeTaskCompletion(val) {
      this.$emit("update");
      if (val) {
        this.task.is_finished = true;
      } else {
        this.task.is_finished = false;
      }
      const payload = {
        id: this.task.id,
        status: this.task.status,
        start: this.task.start,
        end: this.task.end,
        note: this.task.note,
        deal_id: this.task?.deal_id,
        creator_user_id: this.task?.creator_user_id,
        responsible_user_id: this.task?.responsible_user_id,
        task_type_id: this.task.task_type.id,
        is_finished: this.task.is_finished
      };
      this.updateDealTaskRequest(payload);
    },
    async deleteTask() {
      await this.deleteDealTaskRequest(this.task.id);
      await this.dealTasksRequest(this.$route.params.dealId);
    }
  }
};
</script>

<style lang="scss">
.we-deal-task {
  padding: 0 8px 0 10px;
  border: 1px solid var(--line);
  border-radius: 7px;

  &.task-completed {
    border: 1px solid var(--green);
  }
  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
    padding: 4px 0;

    .we-title {
      display: flex;
      align-items: center;

      @include mobile {
        max-width: calc(100% - 84px);
      }

      .action {
        height: 24px;
      }

      .v-input--selection-controls {
        margin: 0px 4px 0px 0px;
        padding-top: 0px;
      }

      .color {
        height: 25px;
        min-width: 4px;
        border-radius: 4px;
        background: var(--green);
        margin-right: 13px;

        @include mobile {
          margin-right: 7px;
        }
      }

      .name-and-remaining {
        min-width: 0%;
        .time-remaining {
          font-size: 13px;
          color: var(--text-2);
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .remaining {
            margin-right: 8px;
            text-transform: capitalize;
          }
        }
      }

      .name {
        max-width: 250px;
        font-size: 14px;
        color: var(--text-1);
        font-weight: $regular;

        @include mobile {
          max-width: 100%;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .we-icon-button {
        @include mobile {
          height: 42px;
          width: 42px;
        }
      }

      .chevron-button {
        transition: all 0.2s;
        &.rotate {
          transform: rotate(180deg);
        }
      }

      .we-balloon .balloon {
        min-width: 180px;
      }
    }
  }

  .more-content {
    padding: 12px 5px 8px 5px;
    border-top: 1px solid var(--line);

    .deal-task-item {
      font-size: 14px;
      color: var(--text-2);
      margin-bottom: 9px;
      display: flex;

      .capitalize {
        text-transform: capitalize;
      }

      .icon {
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        color: var(--text-2);

        svg {
          height: 16px;
          width: auto;
          color: var(--text-2);
        }
      }
    }
  }
}
</style>
