<template>
  <div class="we-mention-list">
    <template v-if="items.length">
      <button
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        {{ item.name }}
      </button>
    </template>
    <div class="item" v-else>
      Variável não encontrada
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    command: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: 0
    };
  },
  watch: {
    items() {
      this.selectedIndex = 0;
    }
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },
    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },
    enterHandler() {
      this.selectItem(this.selectedIndex);
    },
    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command({ id: item.value });
      }
    }
  }
};
</script>

<style lang="scss">
.we-mention-list {
  padding: 7px 0;
  position: relative;
  border-radius: 4px;
  background: var(--background-1);
  color: var(--text-1);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 28%);

  .item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 8px 16px;
    background: var(--background-1);
    color: var(--text-1);
    min-height: 36px;
    font-weight: $medium;

    &:hover {
      background: var(--background-2);
    }

    &.is-selected {
      background: var(--primary-bg);
      color: var(--primary);
    }
  }
}
</style>
