import store from "@/store";

export default {
  state: {
    freeTrialEndedOpened: false
  },
  getters: {
    getFreeTrialEndedOpened: state => state.freeTrialEndedOpened
  },
  mutations: {
    openFreeTrialEnded: state => {
      state.freeTrialEndedOpened = true;
    },
    closeFreeTrialEnded: state => {
      store.commit("ableSnackBar", true);
      state.freeTrialEndedOpened = false;
    }
  }
};
