import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAo1QYAcS_nzzesHv0365L4c7QZIhS3hd0",
  authDomain: "wepipe-app.firebaseapp.com",
  projectId: "wepipe-app",
  storageBucket: "wepipe-app.appspot.com",
  messagingSenderId: "572830358581",
  appId: "1:572830358581:web:de0321efdff01dc609657c"
};

initializeApp(firebaseConfig);

const db = getFirestore();
Vue.prototype.$db = db;
