import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  setDoc
} from "firebase/firestore";
import Vue from "vue";

export default {
  state: {
    notifications: [],
    unreadNotifications: 0
  },
  getters: {
    getNotifications: state => state.notifications,
    getUnreadNotifications: state => state.unreadNotifications
  },
  mutations: {
    setNotifications: (state, payload) => {
      state.notifications = payload;
    },
    setUnreadNotifications: (state, payload) => {
      state.unreadNotifications = payload.filter(e => !e.read).length || 0;
    }
  },
  actions: {
    realtimeNotifications({ commit, getters }) {
      const loggedUserId = getters.getLoggedUser?.id || null;

      if (loggedUserId) {
        const notificationRef = collection(Vue.prototype.$db, "notifications");
        const q = query(notificationRef, where("user_id", "==", loggedUserId));
        onSnapshot(q, querySnapshot => {
          const notifications = [];
          querySnapshot.forEach(doc => {
            notifications.push({ id: doc.id, ...doc.data() });
          });
          commit("setNotifications", notifications.reverse());
          commit("setUnreadNotifications", notifications.reverse());
        });
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async createNotification() {
      const hash = Math.random()
        .toString(36)
        .substring(7);
      await setDoc(doc(Vue.prototype.$db, "notifications", hash), {
        created_at: new Date(),
        user_id: 3,
        route: {
          name: "contact",
          params: {
            contactId: 1
          }
        },
        icon:
          "https://wepipe.s3.sa-east-1.amazonaws.com/mDZiiUKoy5AzzVUT4hY9Cnbu5TU0CAs0AhGBrj5i.jpg",
        read: false,
        description: "[Firebase] Criando notificação aleatória"
      });
    },
    // eslint-disable-next-line no-empty-pattern
    async updateNotification({}, payload) {
      if (payload?.id?.length > 0) {
        const notificationRef = doc(
          Vue.prototype.$db,
          "notifications",
          payload.id
        );
        await updateDoc(notificationRef, { ...payload });
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteNotification({}, payload) {
      if (payload?.id?.length > 0) {
        await deleteDoc(doc(Vue.prototype.$db, "notifications", payload.id));
      }
    }
  }
};
