import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "export-error": "Wasn't possible to export!",
    "export-filtered-error": "Wasn't possible to export the filter result!",
    "export-snack-title": "The file is being generated",
    "export-snack-description":
      "The CSV of records is downloading automatically but can delay some minutes."
  },
  "pt-BR": {
    "export-error": "Não foi possível exportar o arquivo!",
    "export-filtered-error":
      "Não foi possível exportar os resultados do filtro!",
    "export-snack-title": "O arquivo está sendo preparado",
    "export-snack-description":
      "O CSV dos registros será baixado automaticamente mas pode demorar alguns minutos."
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

const exportSnackBar = commit => {
  const snackBar = {
    title: i18n.t("export-snack-title"),
    description: i18n.t("export-snack-description"),
    time: 9999999,
    status: "success"
  };
  commit("setSnackBar", snackBar);
  commit("addSnackBar");
};

export default {
  state: {
    exportFile: {},
    exportParams: {}
  },
  getters: {
    getExportFile: state => state.exportFile,
    getExportParams: state => state.exportParams
  },
  mutations: {
    setExportFile: (state, payload) => {
      state.exportFile = payload;
    },
    setExportParams: (state, payload) => {
      state.exportParams = payload;
    }
  },
  actions: {
    exportRequest({ commit }, payload) {
      exportSnackBar(commit);
      const entityName = payload.entity;
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/${entityName}/export`)
          .then(resp => {
            const url = encodeURI("data:text/csv;charset=utf-8," + resp.data);
            const link = document.createElement("a");
            const date = new Date()
              .toLocaleDateString("pt-Br", {
                dateStyle: "short"
              })
              .split("/")
              .join("-");

            link.href = url;
            link.setAttribute("download", `wepipe-${entityName}-${date}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("export-error"),
              description: ""
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    exportFilteredAsCsvRequest({ commit, getters }, entity) {
      exportSnackBar(commit);
      const params = getters.getExportParams;
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(params.endpoint, params.payload)
          .then(resp => {
            const url = encodeURI("data:text/csv;charset=utf-8," + resp.data);
            const link = document.createElement("a");
            const date = new Date()
              .toLocaleDateString("pt-Br", {
                dateStyle: "short"
              })
              .split("/")
              .join("-");

            link.href = url;
            link.setAttribute("download", `wepipe-${entity}-${date}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("export-filtered-error"),
              description: ""
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    exportLogMessages({ commit }) {
      exportSnackBar(commit);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/logs/messages/export`)
          .then(resp => {
            const url = encodeURI("data:text/csv;charset=utf-8," + resp.data);
            const link = document.createElement("a");
            const date = new Date()
              .toLocaleDateString("pt-Br", {
                dateStyle: "short"
              })
              .split("/")
              .join("-");

            link.href = url;
            link.setAttribute("download", `wepipe-messages-${date}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("export-error"),
              description: ""
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
