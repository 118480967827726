import Vue from "vue";
import VueRouter from "vue-router";
import Default from "@/components/layouts/Default.vue";
const Filter = () =>
  import(/* webpackChunkName: "filter" */ "@/views/Filter/Index.vue");
const Import = () =>
  import(/* webpackChunkName: "import" */ "@/views/Global/Import.vue");
const Contacts = () =>
  import(/* webpackChunkName: "contacts" */ "@/views/Contacts/Index.vue");
const Contact = () =>
  import(/* webpackChunkName: "contacts" */ "@/views/Contacts/Contact.vue");
const CreateContact = () =>
  import(/* webpackChunkName: "contacts" */ "@/views/Contacts/Create.vue");
const Pipelines = () =>
  import(/* webpackChunkName: "pipelines" */ "@/views/Pipelines/Index.vue");
const Deals = () =>
  import(/* webpackChunkName: "deals" */ "@/views/Deals/Index.vue");
const Deal = () =>
  import(/* webpackChunkName: "deal" */ "@/views/Deals/Deal.vue");
const CreateDeal = () =>
  import(/* webpackChunkName: "deal" */ "@/views/Deals/Create.vue");
const Task = () =>
  import(/* webpackChunkName: "deal" */ "@/views/Deals/Task.vue");
const Tasks = () =>
  import(/* webpackChunkName: "tasks" */ "@/views/Tasks/Index.vue");
const TasksCalendar = () =>
  import(/* webpackChunkName: "tasks" */ "@/views/Tasks/Calendar.vue");
const TasksList = () =>
  import(/* webpackChunkName: "tasks" */ "@/views/Tasks/TasksList.vue");
const Users = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Users/Index.vue");
const User = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Users/User.vue");
const CreateUser = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Users/Create.vue");
const Dashboards = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboards/Index.vue"
  );
const DashboardDeals = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboards/Deals.vue"
  );
const DashboardSales = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboards/Sales.vue"
  );
const DashboardTasks = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboards/Tasks.vue"
  );
const DashboardFinancial = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboards/Financial.vue"
  );
const Workflows = () =>
  import(
    /* webpackChunkName: "workflows" */ "@/views/Admin/Workflows/Index.vue"
  );
const Workflow = () =>
  import(
    /* webpackChunkName: "workflows" */ "@/views/Admin/Workflows/Edit.vue"
  );
const Bills = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Bills/Index.vue");
const Bill = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Bills/Bill.vue");
const CreateBill = () =>
  import(/* webpackChunkName: "users" */ "@/views/Admin/Bills/Create.vue");
const Configurations = () =>
  import(
    /* webpackChunkName: "configurations" */ "@/views/Admin/Configurations/Index.vue"
  );
const PipelinesConfigs = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Pipelines/Index.vue"
  );
const CreatePipeline = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Pipelines/CreatePipeline.vue"
  );
const EditPipeline = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Pipelines/EditPipeline.vue"
  );
const Stage = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Pipelines/Stage.vue"
  );
const CustomFieldsConfigs = () =>
  import(
    /* webpackChunkName: "customfieldsconfigs" */ "@/views/Admin/Configurations/CustomFields/Index.vue"
  );
const CustomField = () =>
  import(
    /* webpackChunkName: "customfieldsconfigs" */ "@/views/Admin/Configurations/CustomFields/Edit.vue"
  );
const ProductsConfigs = () =>
  import(
    /* webpackChunkName: "productsconfigs" */ "@/views/Admin/Configurations/Products/Index.vue"
  );
const CreateProduct = () =>
  import(
    /* webpackChunkName: "productsconfigs" */ "@/views/Admin/Configurations/Products/Create.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "productsconfigs" */ "@/views/Admin/Configurations/Products/Edit.vue"
  );
const TaskTypeConfigs = () =>
  import(
    /* webpackChunkName: "tasksconfigs" */ "@/views/Admin/Configurations/Tasks/Index.vue"
  );
const TaskType = () =>
  import(
    /* webpackChunkName: "tasksconfigs" */ "@/views/Admin/Configurations/Tasks/Task.vue"
  );
const WebformsConfigs = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Webforms/Index.vue"
  );
const CreateWebform = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Webforms/Create.vue"
  );
const EditWebform = () =>
  import(
    /* webpackChunkName: "pipelinesconfigs" */ "@/views/Admin/Configurations/Webforms/Edit.vue"
  );
const LoginDefault = () =>
  import(/* webpackChunkName: "login" */ "@/components/layouts/Login.vue");
const Login = () =>
  import(/* webpackChunkName: "login" */ "@/views/Login/Index.vue");
const Code = () =>
  import(/* webpackChunkName: "login" */ "@/views/Login/Code.vue");
const Password = () =>
  import(/* webpackChunkName: "login" */ "@/views/Login/Password.vue");
const Companies = () =>
  import(/* webpackChunkName: "companies" */ "@/views/Companies/Index.vue");
const Company = () =>
  import(/* webpackChunkName: "companies" */ "@/views/Companies/Company.vue");
const CreateCompany = () =>
  import(/* webpackChunkName: "companies" */ "@/views/Companies/Create.vue");
const Messages = () =>
  import(/* webpackChunkName: "messages" */ "@/views/Messages/Index.vue");
const Records = () =>
  import(/* webpackChunkName: "records" */ "@/views/Records/Index.vue");
const Profile = () =>
  import(/* webpackChunkName: "profile" */ "@/views/Profile/Index.vue");
const Returns = () =>
  import(
    /* webpackChunkName: "plugins" */ "@/views/Admin/Plugins/Returns/Index.vue"
  );
const CreateReturns = () =>
  import(
    /* webpackChunkName: "plugins" */ "@/views/Admin/Plugins/Returns/Create.vue"
  );
const EditReturns = () =>
  import(
    /* webpackChunkName: "plugins" */ "@/views/Admin/Plugins/Returns/Edit/Index.vue"
  );
const CreateAccount = () =>
  import(/* webpackChunkName: "plugins" */ "@/views/CreateAccount/Index.vue");

import store from "@/store";
Vue.use(store);

const ifAuthenticated = (to, from, next) => {
  store.commit("setQueryParams", to.query);

  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: "login",
    params: { company: store.getters.getDomain }
  });
};

const ifAuthenticatedOnLogin = (to, from, next) => {
  store.commit("setQueryParams", to.query);
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: "home"
  });
};

const ifHasPermission = (to, from, next) => {
  store.commit("setQueryParams", to.query);
  const loggedUser = store.getters.getLoggedUser;
  if (store.getters.isAuthenticated) {
    const encodedRole = localStorage.getItem("we-UR") || "";
    const decodedRole = loggedUser.role || atob(encodedRole);

    if (to.meta.roles && to.meta.roles.includes(decodedRole)) {
      next();
      return;
    } else if (["admin", "manager"].includes(decodedRole)) {
      next();
      return;
    } else {
      next({
        name: "pipelines"
      });
    }

    return;
  }

  next({
    name: "login",
    params: { company: store.getters.getDomain }
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Default,
    beforeEnter: ifAuthenticated,
    redirect: "pipelines",
    children: [
      {
        path: "/records",
        name: "records",
        beforeEnter: ifAuthenticated,
        component: Records
      },
      {
        path: "/profile",
        name: "profile",
        beforeEnter: ifAuthenticated,
        component: Profile
      },
      {
        path: "/pipelines",
        name: "pipelines",
        beforeEnter: ifAuthenticated,
        component: Pipelines,
        children: [
          {
            beforeEnter: ifHasPermission,
            name: "createPipelineOnboarding",
            path: "create",
            component: CreatePipeline
          },
          {
            name: "pipelineDeal",
            path: "deals/:dealId",
            component: Deal,
            children: [
              {
                name: "createDealTask",
                path: "tasks/create",
                component: Task
              },
              {
                name: "editDealTask",
                path: "tasks/:taskId/edit",
                component: Task
              },
              {
                name: "createDealContact",
                path: "create/contacts",
                component: CreateContact
              },
              {
                name: "createDealCompany",
                path: "create/companies",
                component: CreateCompany
              }
            ]
          },
          {
            name: "createDealInPipelines",
            path: "new-deal",
            component: CreateDeal
          },
          {
            name: "filterDealInPipeline",
            path: "filter/deals",
            component: Filter
          }
        ]
      },
      {
        path: "/messages",
        name: "messages",
        beforeEnter: ifAuthenticated,
        component: Messages
      },
      {
        path: "/tasks",
        beforeEnter: ifAuthenticated,
        component: Tasks,
        name: "tasks",
        redirect: "tasks/calendar",
        children: [
          {
            name: "tasksCalendar",
            path: "/tasks/calendar",
            component: TasksCalendar,
            children: [
              {
                name: "editTaskInCalendar",
                path: ":taskId/edit",
                component: Task
              },
              {
                name: "createTaskInCalendar",
                path: "create",
                component: Task
              },
              {
                name: "filterTaskInCalendar",
                path: "filter",
                component: Filter
              },
              {
                name: "dealInCalendar",
                path: "deals/:dealId",
                component: Deal,
                children: [
                  {
                    name: "createDealTaskInCalendar",
                    path: "tasks/create",
                    component: Task
                  },
                  {
                    name: "editDealTaskInCalendar",
                    path: "tasks/:taskId/edit",
                    component: Task
                  }
                ]
              }
            ]
          },
          {
            name: "tasksList",
            path: "/tasks/list",
            component: TasksList,
            children: [
              {
                name: "editTaskInTasksList",
                path: "task/:taskId/edit",
                component: Task
              },
              {
                name: "createTaskInTasksList",
                path: "create",
                component: Task
              },
              {
                name: "filterTaskInTasksList",
                path: "filter",
                component: Filter
              },
              {
                name: "dealInTasksList",
                path: "deals/:dealId",
                component: Deal,
                children: [
                  {
                    name: "createDealTaskInTasksList",
                    path: "tasks/create",
                    component: Task
                  },
                  {
                    name: "editDealTaskInTasksList",
                    path: "tasks/:taskId/edit",
                    component: Task
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "/deals",
        name: "deals",
        beforeEnter: ifAuthenticated,
        component: Deals,
        children: [
          {
            name: "deal",
            path: ":dealId",
            component: Deal,
            children: [
              {
                name: "createDealTaskInDeals",
                path: "tasks/create",
                component: Task
              },
              {
                name: "editDealTaskInDeals",
                path: "tasks/:taskId/edit",
                component: Task
              }
            ]
          },
          {
            name: "createDealInDeals",
            path: "/new-deal",
            component: CreateDeal
          },
          {
            name: "filterDeal",
            path: "/filter/deals",
            component: Filter
          },
          {
            name: "importDeals",
            path: "/import/deals",
            component: Import
          }
        ]
      },
      {
        path: "/contacts",
        name: "contacts",
        beforeEnter: ifAuthenticated,
        component: Contacts,
        children: [
          {
            name: "contact",
            path: ":contactId",
            component: Contact,
            children: [
              {
                name: "contactDeal",
                path: "deals/:dealId",
                component: Deal,
                children: [
                  {
                    name: "createDealTaskInContactDeals",
                    path: "tasks/create",
                    component: Task
                  },
                  {
                    name: "editDealTaskInContactDeals",
                    path: "tasks/:taskId/edit",
                    component: Task
                  }
                ]
              }
            ]
          },
          {
            name: "createContact",
            path: "/create/contacts",
            component: CreateContact
          },
          {
            name: "filterContact",
            path: "/filter/contacts",
            component: Filter
          },
          {
            name: "importContacts",
            path: "/import/contacts",
            component: Import
          }
        ]
      },
      {
        path: "/companies",
        name: "companies",
        beforeEnter: ifAuthenticated,
        component: Companies,
        children: [
          {
            name: "company",
            path: ":companyId",
            component: Company
          },
          {
            name: "createCompany",
            path: "/create/companies",
            component: CreateCompany
          },
          {
            name: "filterCompany",
            path: "/filter/companies",
            component: Filter
          },
          {
            name: "importCompanies",
            path: "/import/companies",
            component: Import
          }
        ]
      }
    ]
  },
  {
    path: "/admin",
    name: "admin",
    component: Default,
    beforeEnter: ifHasPermission,
    redirect: "/admin/configs",
    children: [
      {
        path: "users",
        name: "users",
        beforeEnter: ifHasPermission,
        component: Users,
        children: [
          {
            name: "user",
            path: ":userId",
            component: User
          },
          {
            name: "createUser",
            path: "/admin/create/users",
            component: CreateUser
          },
          {
            name: "filterUser",
            path: "/admin/filter/users",
            component: Filter
          },
          {
            name: "importUsers",
            path: "/import/users",
            component: Import
          }
        ]
      },
      {
        path: "dashboard",
        beforeEnter: ifHasPermission,
        component: Dashboards,
        children: [
          {
            name: "dashboardDeals",
            path: "/",
            component: DashboardDeals
          },
          {
            name: "dashboardSales",
            path: "sales",
            component: DashboardSales
          },
          {
            name: "dashboardTasks",
            path: "tasks",
            component: DashboardTasks
          },
          {
            name: "dashboardFinancial",
            path: "financial",
            component: DashboardFinancial
          }
        ]
      },
      {
        path: "workflows",
        name: "workflows",
        beforeEnter: ifHasPermission,
        component: Workflows
      },
      {
        name: "createWorkflow",
        path: "workflows/create",
        beforeEnter: ifHasPermission,
        component: Workflow
      },
      {
        name: "editWorkflow",
        path: "workflows/:id/edit",
        beforeEnter: ifHasPermission,
        component: Workflow
      },
      {
        path: "bills",
        name: "bills",
        beforeEnter: ifHasPermission,
        meta: {
          roles: ["admin"]
        },
        component: Bills,
        children: [
          {
            name: "bill",
            path: ":billId",
            component: Bill
          },
          {
            name: "createBill",
            path: "/admin/create/bills",
            component: CreateBill
          },
          {
            name: "filterBill",
            path: "/filter/bills",
            component: Filter
          },
          {
            name: "importBills",
            path: "/import/bills",
            component: Import
          }
        ]
      },
      {
        path: "configs",
        name: "configurations",
        component: Configurations
      },
      {
        name: "pipelinesConfigs",
        path: "configs/pipelines",
        component: PipelinesConfigs,
        children: [
          {
            name: "createPipeline",
            path: "create",
            component: CreatePipeline
          },
          {
            name: "editPipeline",
            path: ":pipelineId/edit",
            component: EditPipeline
          },
          {
            name: "createStage",
            path: ":pipelineId/stages/create",
            component: Stage
          },
          {
            name: "editStage",
            path: ":pipelineId/stages/:stageId/edit",
            component: Stage
          }
        ]
      },
      {
        name: "customFieldsConfigs",
        path: "configs/custom-fields",
        component: CustomFieldsConfigs,
        children: [
          {
            name: "createCustomField",
            path: "create",
            component: CustomField
          },
          {
            name: "editCustomField",
            path: ":id/edit",
            component: CustomField
          }
        ]
      },
      {
        name: "productsConfigs",
        path: "configs/products",
        component: ProductsConfigs,
        children: [
          {
            name: "createProduct",
            path: "create",
            component: CreateProduct
          },
          {
            name: "editProduct",
            path: ":id/edit",
            component: EditProduct
          },
          {
            name: "filterProduct",
            path: "/filter/products",
            component: Filter
          },
          {
            name: "importProducts",
            path: "/import/products",
            component: Import
          }
        ]
      },
      {
        name: "tasksConfigs",
        path: "configs/tasks",
        component: TaskTypeConfigs,
        children: [
          {
            name: "createTask",
            path: "create",
            component: TaskType
          },
          {
            name: "editTask",
            path: ":id/edit",
            component: TaskType
          }
        ]
      },
      {
        name: "webformsConfigs",
        path: "configs/webforms",
        component: WebformsConfigs,
        children: [
          {
            name: "createWebform",
            path: "create",
            component: CreateWebform
          }
        ]
      },
      {
        name: "editWebform",
        path: "configs/webforms/:id/edit",
        component: EditWebform
      },
      {
        path: "plugins/returns",
        name: "returns",
        component: Returns,
        children: [
          {
            name: "createReturnsForm",
            path: "create",
            component: CreateReturns
          }
        ]
      },
      {
        name: "editReturnsForm",
        path: "plugins/returns/:id/edit",
        component: EditReturns
      }
    ]
  },
  {
    path: "/:company/login",
    beforeEnter: ifAuthenticatedOnLogin,
    component: LoginDefault,
    children: [
      {
        path: "",
        name: "login",
        component: Login
      },
      {
        path: "/:company/code",
        name: "code",
        component: Code
      },
      {
        path: "/password",
        name: "password",
        component: Password
      }
    ]
  },
  {
    path: "/create-account",
    component: LoginDefault,
    children: [
      {
        path: "",
        name: "createAccount",
        component: CreateAccount
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = "wepipe";
  store.commit("setLightboxOpened", false);
  next();
});

export default router;
