import { VueRenderer } from "@tiptap/vue-2";
import tippy from "tippy.js";
import MentionList from "@/components/ui-local/WeMentionList.vue";
import store from "@/store";
import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    of: "of",
    "of-deal": "of deal",
    "of-contact": "of contact",
    "of-company": "of company",
    "of-pipeline": "of pipeline",
    "of-pipeline-stage": "of pipeline stage",
    "custom-field": "Custom field",
    id: "Id",
    code: "Code",
    name: "Name",
    value: "Value",
    url: "Url",
    attachments: "Attachments",
    products: "Products",
    owner: "Owner name",
    "owner-phone": "Owner phone",
    "owner-email": "Owner email",
    created_at: "Creation date",
    surname: "Surname",
    "company-name": "Name"
  },
  "pt-BR": {
    of: "de",
    "of-deal": "do card",
    "of-contact": "do contato",
    "of-company": "da empresa",
    "of-pipeline": "do pipeline",
    "of-pipeline-stage": "do estágio do pipeline",
    "custom-field": "Campo customizado",
    id: "Id",
    code: "Código",
    name: "Nome",
    value: "Valor",
    url: "Link de acesso",
    attachments: "Anexos",
    products: "Produtos",
    owner: "Nome do responsável",
    "owner-phone": "Telefone do responsável",
    "owner-email": "E-mail do responsável",
    created_at: "Data de criação",
    surname: "Sobrenome",
    "company-name": "Nome (razão social)"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  items: ({ query }) => {
    let customFields = store.getters.getCustomFields || [];

    customFields = customFields
      .filter(e => !["link", "div"].includes(e.type))
      .map(e => {
        return {
          name: `${e.label} (${i18n.t("custom-field")} ${i18n.t("of")} ${
            e.entity
          })`,
          value: e.name
        };
      });

    return [
      {
        name: `${i18n.t("id")} ${i18n.t("of-deal")}`,
        value: "id"
      },
      {
        name: `${i18n.t("code")} ${i18n.t("of-deal")}`,
        value: "code"
      },
      {
        name: `${i18n.t("name")} ${i18n.t("of-deal")}`,
        value: "name"
      },
      {
        name: `${i18n.t("value")} ${i18n.t("of-deal")}`,
        value: "amount"
      },
      {
        name: `${i18n.t("url")} ${i18n.t("of-deal")}`,
        value: "url"
      },
      {
        name: `${i18n.t("attachments")} ${i18n.t("of-deal")}`,
        value: "attachments"
      },
      {
        name: `${i18n.t("products")} ${i18n.t("of-deal")}`,
        value: "products"
      },
      {
        name: `${i18n.t("owner")}`,
        value: "owner"
      },
      {
        name: `${i18n.t("owner-phone")}`,
        value: "owner_phone"
      },
      {
        name: `${i18n.t("owner-email")}`,
        value: "owner_email"
      },
      {
        name: `${i18n.t("created_at")} ${i18n.t("of-deal")}`,
        value: "created_at"
      },
      {
        name: `${i18n.t("name")} ${i18n.t("of-contact")}`,
        value: "contact_first_name"
      },
      {
        name: `${i18n.t("surname")} ${i18n.t("of-contact")}`,
        value: "contact_last_name"
      },
      {
        name: `${i18n.t("company-name")} ${i18n.t("of-company")}`,
        value: "company"
      },
      {
        name: `${i18n.t("name")} ${i18n.t("of-pipeline")}`,
        value: "pipeline_name"
      },
      {
        name: `${i18n.t("name")} ${i18n.t("of-pipeline-stage")}`,
        value: "pipeline_stage_name"
      },
      ...customFields
    ]
      .filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 10);
  },
  char: "{",
  render: () => {
    let component;
    let popup;

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
          // using vue 2:
          parent: this,
          propsData: props
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.querySelector("#app"),
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start"
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      }
    };
  }
};
