import store from "@/store";

export default {
  state: {
    upgradePlanOpened: false,
    blockedSystem: false
  },
  getters: {
    getUpgradePlanOpened: state => state.upgradePlanOpened,
    getUpgradePlan: state => state.upgradePlan,
    getBlockedSystem: state => state.blockedSystem
  },
  mutations: {
    setUpgradePlan: (state, payload) => {
      state.upgradePlan = payload;
    },
    openUpgradePlan: state => {
      state.upgradePlanOpened = true;
    },
    closeUpgradePlan: state => {
      store.commit("ableSnackBar", true);
      state.upgradePlanOpened = false;
    },
    setBlockedSystem: (state, payload) => {
      state.blockedSystem = payload;
    }
  }
};
