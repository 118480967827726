<i18n>
{
  "en": {
    "ago": "ago",
    "delete": "Delete"
  },
  "pt-BR": {
    "ago": "atrás",
    "delete": "Deletar"
  }
}
</i18n>

<template>
  <div class="we-notification-card">
    <we-icon-button
      class="notification-delete small"
      :icon="['far', 'trash-alt']"
      :name="$t('delete')"
      @click="remove(notification)"
    />
    <router-link
      class="notification-link"
      :to="notification.route"
      @click.native="openNotification(notification)"
    >
      <div class="not-read" :class="{ active: !notification.read }"></div>
      <we-rounded-picture :picture="notification.icon" size="38" />
      <div class="notification-content">
        <div class="notification-message">
          {{ notification.description }}
        </div>
        <div class="notification-history">
          {{ getPastTime(notification.created_at) }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    notification: Object
  },
  methods: {
    ...mapActions(["updateNotification", "deleteNotification"]),
    getPastTime(timestamp) {
      const notificationDate = timestamp.toDate();
      const todayDate = new Date();

      const diffTime = Math.abs(todayDate - notificationDate);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

      return (
        this.$options.filters.hoursToDaysAndHoursExtended(diffHours) +
        ` ${this.$t("ago")}`
      );
    },
    openNotification(notification) {
      notification.read = true;
      this.$emit("click");
      this.updateNotification(notification);
    },
    remove(notification) {
      this.deleteNotification(notification);
    }
  }
};
</script>

<style lang="scss">
.we-notification-card {
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--background-1-hover);

    .notification-delete {
      display: block;
    }
  }

  .notification-delete {
    position: absolute;
    top: 8px;
    right: 8px;
    display: none;
  }

  .notification-link {
    display: flex;
  }

  .not-read {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;

    &.active {
      &::before {
        background: var(--orange);
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 60%;
      background: var(--line);
      border-radius: 4px;
    }
  }

  .notification-content {
    margin-left: 15px;
    font-weight: $regular;
    max-width: 330px;
    .notification-message {
      font-size: 14px;
      line-height: 21px;
      color: var(--text-1);
    }

    .notification-history {
      font-size: 13px;
      line-height: 19.5px;
      color: var(--text-2);
    }
  }
}
</style>
