<template>
  <div class="we-label-remove">
    <div class="label-text">{{ text }}</div>
    <div class="label-action">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="18"
        width="3"
      ></v-progress-circular>
      <we-icon-button
        v-else
        @click="
          $emit('remove');
          loading = true;
        "
        :icon="['far', 'trash-alt']"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    index: Number
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>

<style lang="scss">
.we-label-remove {
  border-radius: 7px;
  border: 1px solid var(--line);
  height: 38px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  &:last-child {
    margin-right: 0;
  }

  .label-text {
    font-size: 14px;
    font-weight: $medium;
    margin-right: 8px;
  }

  .label-action {
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
