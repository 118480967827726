import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "import-file-error": "Wasn't possible to import!",
    "import-file-success": "The import request was successful!"
  },
  "pt-BR": {
    "import-file-error": "Não foi possível concluir a importação!",
    "import-file-success":
      "A solicitação de importação foi realizada com sucesso!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    importHeaders: null
  },
  getters: {
    getImportHeaders: state => state.importHeaders
  },
  mutations: {
    setImportHeaders: (state, payload) => {
      state.importHeaders = payload;
    }
  },
  actions: {
    importHeadersRequest({ commit }, entity) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/${entity}/import`)
          .then(resp => {
            const headers = resp.data.map(e => {
              e.related_column = null;
              e.enabled = true;

              return e;
            });
            commit("setImportHeaders", headers);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    importRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/${payload.entity}/import`, payload.data)
          .then(resp => {
            const snackBar = {
              title: i18n.t("import-file-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("import-file-error"),
              time: 10000
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
