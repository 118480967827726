<i18n>
{
  "en": {
    "options": "Options",
    "open-original": "Open original",
    "delete": "Delete",
    "sent": "Sent",
    "not-sent": "The file couldn't upload",
    "drop-here": "Drop files here!",
    "upload-here": "Click here to upload",
    "click-to-select": "Or click to select from computer"
  },
  "pt-BR": {
    "options": "Opções",
    "open-original": "Abrir original",
    "delete": "Deletar",
    "sent": "Enviado",
    "not-sent": "O arquivo não pôde ser enviado",
    "drop-here": "Arraste os arquivos aqui!",
    "upload-here": "Anexar um novo arquivo",
    "click-to-select": "Ou clique para selecionar do computador"
  }
}
</i18n>

<template>
  <div class="drop-file">
    <input
      type="file"
      multiple
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      @change="sendFiles"
      ref="files"
      accept=".pdf,.jpg,.jpeg,.png"
      style="display:none;"
    />
    <div class="drop-file-grid" v-if="files && files.length > 0">
      <div class="file" v-for="(file, i) in files" :key="i">
        <div class="file-picture">
          <div class="file-hover">
            <div class="file-shadow" @click="openLightbox(file)"></div>
            <div class="file-actions">
              <we-balloon>
                <template #action>
                  <we-icon-button
                    icon="ellipsis-h"
                    :name="$t('options')"
                    color="icon-white"
                  />
                </template>
                <template #balloon>
                  <!-- <div class="balloon-item" @click="downloadFile(file)">
                    <font-awesome-icon icon="download" />
                    Fazer download
                  </div> -->
                  <a
                    :href="file.url"
                    target="_blank"
                    rel="noopener"
                    class="balloon-item"
                  >
                    <font-awesome-icon icon="external-link-alt" />
                    {{ $t("open-original") }}
                  </a>
                  <div class="balloon-item" @click="removeFile(file.id)">
                    <font-awesome-icon
                      :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                    />
                    {{ $t("delete") }}
                  </div>
                </template>
              </we-balloon>
            </div>
            <div class="file-uploaded-at">
              {{ $t("sent") }}: {{ file.updated_at | dateTime }}
            </div>
          </div>
          <picture v-if="fileIsImage(file)">
            <img :src="file.url" :alt="file.name" />
          </picture>
          <div v-else class="file-extension">
            {{ file.name | fileExtension }}
          </div>
        </div>
        <div class="file-name">
          <we-text-tooltip :text="file.name" lines="2" />
        </div>
        <div class="file-more">
          <we-label class="gray">
            <template #text>
              {{ file.size | fileSize }}
            </template>
          </we-label>
          <we-label class="primary">
            <template #text>
              {{ file.name | fileExtension }}
            </template>
          </we-label>
        </div>
      </div>
      <div
        class="file file-upload"
        v-for="(file, i) in filesToUpload"
        :key="i + 999999999"
      >
        <div class="file-picture">
          <div class="file-loading">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </div>
          <picture v-if="fileIsImage(file)">
            <img :src="file.url" :alt="file.name" />
          </picture>
          <div v-else class="file-extension">
            {{ file.name | fileExtension }}
          </div>
        </div>
        <div class="file-name">
          <we-text-tooltip :text="file.name" />
        </div>
        <div class="file-more">
          <we-label class="gray">
            <template #text>
              {{ file.size | fileSize }}
            </template>
          </we-label>
          <we-label class="primary">
            <template #text>
              {{ file.name | fileExtension }}
            </template>
          </we-label>
        </div>
      </div>
      <div
        class="file file-error"
        v-for="(file, i) in filesWithError"
        :key="i + 99999999999"
      >
        <div class="file-picture">
          <div class="file-hover">
            <div class="file-shadow" @click="openLightbox(file)"></div>
            <div class="file-actions">
              <we-icon-button
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                :name="$t('delete')"
                color="icon-white"
                @click="removeFileWithError(i)"
              />
            </div>
            <div class="file-uploaded-at">
              {{ $t("not-sent") }}
            </div>
          </div>
          <picture v-if="fileIsImage(file)">
            <img :src="file.url" :alt="file.name" />
          </picture>
          <div v-else class="file-extension">
            {{ file.name | fileExtension }}
          </div>
        </div>
        <div class="file-name">
          <we-text-tooltip :text="file.name" />
        </div>
        <div class="file-more">
          <we-label class="gray">
            <template #text>
              {{ file.size | fileSize }}
            </template>
          </we-label>
          <we-label class="primary">
            <template #text>
              {{ file.name | fileExtension }}
            </template>
          </we-label>
        </div>
      </div>
    </div>

    <div class="drop-here" @click="$refs.files.click()">
      <div class="we-title only-desktop">
        {{ $t("drop-here") }}
      </div>
      <div class="we-title only-mobile">
        {{ $t("upload-here") }}
      </div>
      <div class="subtitle">
        {{ $t("click-to-select") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    entity: String,
    recordId: Number
  },
  data() {
    return {
      imagesToShow: 2
    };
  },
  computed: {
    files() {
      return this.$store.getters.getFiles;
    },
    filesToUpload() {
      return this.$store.getters.getFilesToUpload;
    },
    filesWithError() {
      return this.$store.getters.getFilesWithError;
    }
  },
  methods: {
    ...mapActions(["filesRequest", "createFileRequest", "deleteFileRequest"]),
    async allFilesRequest() {
      const payload = {
        entity: this.entity,
        record_id: this.recordId
      };

      this.filesRequest(payload);
    },
    openLightbox(file) {
      this.$store.commit("setLightbox", { files: this.files, selected: file });
    },
    async downloadFile(file) {
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      const blob = await fetch(file.url).then(r => r.blob());

      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("download", file.name);
      a.click();

      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
    fileIsImage(file) {
      const extension = this.$options.filters.fileExtension(file.name);

      return ["png", "jpeg", "jpg", "gif", "webp", "svg"].includes(extension);
    },
    async removeFile(fileId) {
      await this.deleteFileRequest(fileId);
      await this.allFilesRequest();
    },
    removeFileWithError(i) {
      this.$store.commit("removeFileWithError", i);
    },
    sendFiles(event) {
      this.onChange(event.target.files);
    },
    onChange(files) {
      let filelist = [...files];
      filelist.map((e, i) => {
        e["id"] = i;
        e.url = URL.createObjectURL(e);
        return e;
      });
      this.$store.commit("setFilesToUpload", filelist);
      filelist.forEach(e => {
        const payload = {
          entity: this.entity,
          record_id: this.recordId,
          file: e
        };
        this.createFileRequest(payload);
        document.getElementById("attachments").scrollIntoView();
      });
    },
    pasteFile(event) {
      this.onChange(event.clipboardData.files);
    }
  },
  mounted() {
    document.addEventListener("paste", this.pasteFile);
  },
  destroyed() {
    document.removeEventListener("paste", this.pasteFile);
  }
};
</script>

<style lang="scss">
.drop-file-grid {
  display: grid;
  grid-template-columns: $column-size $column-size;
  gap: 10px;
  margin-bottom: 12px;

  .file {
    width: 100%;

    &.file-error {
      .file-picture {
        &::before {
          border: 2px solid var(--red);
        }
      }
    }

    .file-picture {
      height: 140px;
      width: 100%;
      position: relative;
      border-radius: 7px;
      overflow: hidden;
      transition: ease-in-out 0.2s;

      @include mobile {
        height: 124px;
      }

      &::before {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--inherit);
        border-radius: 7px;
      }

      &:hover {
        .file-hover {
          opacity: 1;
        }
      }

      .file-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .file-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        transition: ease-in-out 0.2s;

        .file-shadow {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0.3;
          cursor: pointer;
        }

        .file-actions {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          z-index: 1;
          padding-top: 5px;
          padding-right: 5px;
          pointer-events: none;

          * {
            pointer-events: all;
          }
        }

        .file-uploaded-at {
          text-align: center;
          font-size: 11px;
          color: var(--white);
          font-weight: $semibold;
          z-index: 1;
          padding-bottom: 4px;
        }
      }

      picture {
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          object-position: center center;
          width: 100%;
          height: 100%;
        }
      }

      .file-extension {
        background: var(--background-1-hover);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: $semibold;
        color: var(--text-2);
      }
    }

    .file-name {
      font-size: 13px;
      color: var(--text-2);
      font-weight: $medium;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      max-height: 19px;
      overflow: hidden;

      .we-text-tooltip {
        display: block;
      }
    }

    .file-more {
      display: flex;
      align-items: center;

      #we-label {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.drop-here {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: var(--text-2);
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  background: var(--action);

  &:hover {
    background: var(--action-hover);
  }

  .we-title {
    font-size: 14px;
    font-weight: $medium;
  }

  .subtitle {
    font-size: 13px;
    font-weight: $regular;

    @include mobile {
      display: none;
    }
  }
}
</style>
