<i18n>
{
  "en": {
    "limit-time": "Time limit on stage",
    "lead-time": "Time on stage",
    "no-product": "No product",
    "responsible": "Owner",
    "deal-value": "Card value",
    "products-sum": "Products sum"
  },
  "pt-BR": {
    "limit-time": "Tempo limite do estágio",
    "lead-time": "Tempo no estágio",
    "no-product": "Sem produto",
    "responsible": "Responsável",
    "deal-value": "Valor do card",
    "products-sum": "Soma dos produtos"
  }
}
</i18n>

<template>
  <v-card
    class="we-pipeline-card"
    outlined
    @contextmenu="$emit('rightClick', { event: $event, deal })"
    @click="openDeal(deal.id)"
  >
    <we-text-tooltip :delay="500" class="name" :text="deal.name" lines="2" />
    <div class="tags" v-if="deal.tags">
      <div class="tag" v-for="(tag, i) in deal.tags" :key="i">
        {{ tag }}
      </div>
    </div>
    <div class="bottom">
      <div class="product" :class="getProductClass(deal.products)">
        <we-product-icon :class="getProductColor(deal.products)" size="14" />
        <div class="type">
          <we-text-tooltip
            :delay="500"
            :text="getFirstProductName(deal.products)"
            maxWidth="fit-content"
          >
            <template #tooltip-content>
              <div class="tooltip-products">
                <div
                  class="tooltip-product"
                  v-for="product in deal.products"
                  :key="product.id"
                >
                  <we-rounded-picture
                    :picture="product.picture"
                    :name="product.name"
                    :text="$options.filters.firstLetters(product.name)"
                    type="product"
                    size="40"
                  />
                  <div class="product-data">
                    <div class="product-name">
                      {{ product.name }} ({{ product.amount }})
                    </div>
                    <div class="product-price">
                      {{ product.price | valueToReais }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="tooltip-values">
                <div class="tooltip-value">
                  {{ $t("deal-value") }}:
                  <span>{{ deal.amount | valueToReais }}</span>
                </div>
                <div class="tooltip-value">
                  {{ $t("products-sum") }}:
                  <span>{{ sumProducts(deal.products) | valueToReais }}</span>
                </div>
              </div>
            </template>
          </we-text-tooltip>
          <span class="quantity">
            {{ getProductsLength(deal.products) }}
          </span>
        </div>
      </div>
      <div class="icons">
        <we-icon-tooltip
          :delay="500"
          :color="getHoursInStageStatus(deal)"
          v-if="stage.timeout > 0"
          :icon="getHoursIcon(deal)"
        >
          <template #text>
            <div class="deal-time">
              <div class="stage-timeout">
                {{ $t("limit-time") }}: {{ stage.timeout }} horas
              </div>
              <div class="stage-time">
                {{ $t("lead-time") }}: {{ getHoursInStage(deal) }} horas
              </div>
            </div>
          </template>
        </we-icon-tooltip>
        <we-icon-tooltip
          :delay="500"
          class="tasks-icon"
          v-if="dealTasks && dealTasks.length > 0"
          icon="check-double"
          :color="tasksCompleted(dealTasks)"
        >
          <template #text>
            <div class="deal-tasks">
              <div class="task" v-for="task in dealTasks" :key="task.id">
                <font-awesome-icon
                  icon="square-check"
                  :class="{ 'green--text': task.is_finished }"
                />
                <div class="task-content">
                  <div class="task-name">
                    {{ task.name || task.task_type.name }}
                  </div>
                  <div class="time-remaining">
                    <div class="remaining">
                      {{ task.end | dateToTimeRemaining }}
                    </div>
                    <we-label
                      v-if="!task.is_finished"
                      class="medium"
                      :class="{
                        warning:
                          $options.filters.timeRemaining(task.end) ===
                          'DELIVER_SOON',
                        error:
                          $options.filters.timeRemaining(task.end) === 'LATE'
                      }"
                    >
                      <template #text>
                        {{ $root.$t($options.filters.timeRemaining(task.end)) }}
                      </template>
                    </we-label>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </we-icon-tooltip>
        <we-rounded-picture
          :picture="getUserPicture(deal.user)"
          :name="`${$t('responsible')}: ${getUserName(deal.user)}`"
          :text="$options.filters.firstLetters(getUserName(deal.user))"
          size="24"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    deal: Object,
    stage: Object
  },
  computed: {
    dealTasks() {
      return this.deal.tasks.filter(e => e.task_type != null);
    }
  },
  methods: {
    tasksCompleted(tasks) {
      const foundLate = tasks.some(
        e =>
          !e.is_finished &&
          this.$options.filters.timeRemaining(e.end) === "LATE"
      );

      if (foundLate) return "error--text";

      const foundDeliverSoon = tasks.some(
        e =>
          !e.is_finished &&
          this.$options.filters.timeRemaining(e.end) === "DELIVER_SOON"
      );

      if (foundDeliverSoon) return "warning--text";

      const foundNotCompleted = tasks.some(e => e.is_finished === false);

      if (!foundNotCompleted) return "success--text";

      return "";
    },
    openDeal(id) {
      this.$router.push({ name: "pipelineDeal", params: { dealId: id } });
    },
    sumProducts(products) {
      return (
        products?.reduce(
          (sum, product) => sum + product.price * product.amount,
          0
        ) || 0
      );
    },
    getProductColor(products) {
      if (products && products?.length > 0) {
        return products[0]?.color + "--text";
      }

      return "no-product";
    },
    getFirstProductName(products) {
      return products.length > 0 ? products[0].name : this.$t("no-product");
    },
    getProductClass(products) {
      return products.length > 0 ? "" : "no-product";
    },
    getProductsLength(products) {
      return products && products?.length > 1 ? ` +${products.length}` : "";
    },
    getHoursInStageStatus(deal) {
      const hours = this.getHoursInStage(deal);
      const time = this.stage.timeout / hours;

      let fill = "start";

      if (hours >= this.stage.timeout) {
        fill = "end";
      } else if (time > 1 && time <= 2) {
        fill = "half";
      } else {
        fill = "start";
      }

      return fill;
    },
    getHoursIcon(deal) {
      const status = this.getHoursInStageStatus(deal);
      return `hourglass-${status}`;
    },
    getHoursInStage(deal) {
      const today = new Date();

      let hours = 0;

      if (deal?.last_stage_updated_at?.length > 0) {
        const lastUpdate = new Date(deal.last_stage_updated_at);

        hours = Math.abs(today - lastUpdate) / 36e5;
      }

      return Math.floor(hours);
    },
    getUserPicture(user) {
      return user ? user.picture : "";
    },
    getUserName(user) {
      return user ? `${user.first_name} ${user.last_name}` : "";
    }
  }
};
</script>

<style lang="scss">
#app {
  .we-pipeline-card {
    background: var(--background-1);
    border-radius: 7px;
    padding: 12px;
    border: 1px solid var(--line);
    transition: 0.2s;
    cursor: pointer;
    margin-bottom: 8px;

    .we-icon-tooltip {
      cursor: pointer;
    }

    .v-ripple__container {
      background: var(--background-2);
      opacity: 0.1;
    }

    &::before {
      content: none;
    }

    &:hover {
      border: 1px solid var(--primary);
      background: var(--background-2);
      transition: 0.2s;
    }

    &:active {
      border: 1px solid var(--line) !important;
    }

    &.ghost {
      opacity: 0.5;
      background: rgba(var(--green-rgb), 0.3);
      border: 1px solid var(--green);

      div {
        opacity: 0;
      }
    }

    &.sortable-chosen {
      opacity: 1;
    }

    .name {
      font-size: 14px;
      color: var(--text-1);
      margin-bottom: 8px;
      user-select: none;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;

      .tag {
        display: flex;
        align-items: center;
        padding: 2.5px 6px;
        font-size: 13px;
        line-height: 15px;
        color: var(--text-2);
        border-radius: 3px;
        background: var(--action);
        font-weight: $medium;
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product {
        display: grid;
        grid-template-columns: 14px minmax(0, 1fr);
        gap: 6px;
        align-items: center;
        width: 100%;
        color: var(--text-2);

        &.no-product {
          color: var(--text-3);
          opacity: 0.5;
        }

        .type {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: $medium;
          display: flex;
          align-items: center;
          width: 100%;

          .quantity {
            margin-left: 3px;
            font-size: 12px;
            position: relative;
            bottom: -1px;
          }
        }
      }

      .icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 8px;

        .we-icon-tooltip {
          svg {
            &.start {
              color: var(--text-3);
            }
            &.half {
              color: rgba(var(--yellow-rgb), 0.7);
            }

            &.end {
              color: rgba(var(--red-rgb), 0.7);
            }
          }

          &:last-child {
            margin-right: 12px;
          }
        }

        svg {
          height: 16px;
          width: auto;
          margin-right: 8px;
          color: var(--text-3);
        }

        .picture {
          width: 24px;
          height: 24px;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;
            left: 0;
          }
        }

        .rounded-picture {
          .text {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
