<i18n>
{
  "en": {
    "button-1": "Plan upgrade",
    "button-3": "Preferences",
    "button-4": "Logout"
  },
  "pt-BR": {
    "button-1": "Upgrade de plano",
    "button-3": "Preferências",
    "button-4": "Sair da conta"
  }
}
</i18n>

<template>
  <v-menu rounded bottom left offset-y content-class="we-profile">
    <template v-slot:activator="{ attrs, on }">
      <div class="menu-btn" v-bind="attrs" v-on="on">
        <we-picture-button
          direction="bottom"
          :picture="loggedUser.picture"
          :name="`${loggedUser.first_name} ${loggedUser.last_name}`"
        />
      </div>
    </template>
    <v-list>
      <div class="top-section">
        <div class="data">
          <we-picture-change
            size="90"
            :name="loggedUser.first_name"
            :picture="loggedUser.picture"
            :loading="imgLoading"
            @onFileChange="onFileChange"
            @delete="deleteUserImage(loggedUser.id)"
          />
        </div>
        <div class="actions">
          <div class="user-name">
            <we-text-tooltip
              class="name"
              :text="`${loggedUser.first_name} ${loggedUser.last_name}`"
              lines="1"
            />
          </div>
          <div class="user-email">
            <we-text-tooltip class="name" :text="loggedUser.email" lines="1" />
          </div>
          <div class="actions"></div>
          <we-button
            v-if="isAdmin"
            class="primary"
            :text="$t('button-1')"
            icon="rocket"
            @click="$store.commit('openUpgradePlan')"
          />
          <we-button
            @click="$router.push({ name: 'profile' })"
            class="disabled"
            :text="$t('button-3')"
            icon="sliders-h"
          />
          <we-button
            @click="logout"
            class="disabled"
            :text="$t('button-4')"
            icon="sign-out-alt"
          />
        </div>
      </div>
      <we-privacy-policy />
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      picture: {},
      imgLoading: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    }
  },
  methods: {
    ...mapActions(["updateUserPictureRequest", "deleteUserPictureRequest"]),
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;

      const payload = {
        picture: this.picture,
        id: this.loggedUser.id
      };

      const resp = await this.updateUserPictureRequest(payload);
      if (resp.data) {
        this.$store.commit("setLoggedUser", resp.data);
      }

      this.imgLoading = false;
    },
    async deleteUserImage(id) {
      this.imgLoading = true;

      const resp = await this.deleteUserPictureRequest(id);
      if (resp.data) {
        this.$store.commit("setLoggedUser", resp.data);
      }

      this.imgLoading = false;
    },
    async logout() {
      await this.$store.dispatch("authLogout");
      this.$router.push({
        name: "login",
        params: { company: this.$store.getters.getDomain }
      });
    }
  }
};
</script>

<style lang="scss">
.we-profile {
  max-width: 300px;
  width: 100%;

  &.rounded {
    border-radius: 7px !important;
  }

  .v-list {
    padding: 0;

    .top-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 27px;
      border-bottom: 1px solid var(--line);

      .actions {
        width: 100%;
        overflow: hidden;

        .user-name {
          position: relative;
          line-height: 24px;
          font-size: 16px;
          color: var(--text-1);
          font-weight: $regular;
          display: flex;
          justify-content: center;
        }

        .user-email {
          position: relative;
          line-height: 21px;
          font-size: 14px;
          color: var(--text-2);
          font-weight: $regular;
          margin-bottom: 23px;
          display: flex;
          justify-content: center;
        }
      }

      .we-button {
        margin: 0 auto;
        margin-bottom: 8px;
        width: 100%;
        max-width: 195px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .we-privacy-policy {
      padding: 13px 0;
    }

    .data {
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;
      pointer-events: none;
      z-index: 1;

      .we-picture-change {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
