var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ collapsed: _vm.collapsed },attrs:{"id":"nav-left"}},[_c('div',{staticClass:"nav-sections"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'pipelines' }}},[(_vm.customer && _vm.customer.brand)?_c('img',{attrs:{"src":_vm.customer.brand,"alt":((_vm.customer.company) + " Logo")}}):(_vm.$vuetify.theme.dark)?_c('img',{attrs:{"src":require("@/assets/logo-white.svg"),"alt":"Wepipe Logo"}}):_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"Wepipe Logo"}})])],1),_c('nav',{staticClass:"menu"},_vm._l((_vm.defaultMenu),function(item){return _c('router-link',{key:item.routeName,staticClass:"item",class:{
          'show-mobile': item.showMobile,
          mobile: item.showDesktop === false
        },attrs:{"to":{ name: item.routeName },"active-class":"active"}},[_c('v-tooltip',{attrs:{"disabled":!_vm.collapsed,"content-class":"we-icon-tooltip-content","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"item-content"},'div',attrs,false),on),[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1),_c('span',{staticClass:"menu-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.inProgress)?_c('we-label',{staticClass:"small orange",attrs:{"text":_vm.$t('soon')}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),1),(_vm.isAdmin)?_c('nav',{staticClass:"menu admin"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("admin")))]),_vm._l((_vm.filteredAdminMenu),function(item){return _c('router-link',{key:item.routeName,staticClass:"item",class:{
          'show-mobile': item.showMobile,
          'only-mobile': item.showDesktop === false
        },attrs:{"to":{ name: item.routeName },"active-class":"active"}},[_c('v-tooltip',{attrs:{"disabled":!_vm.collapsed,"content-class":"we-icon-tooltip-content","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"item-content"},'div',attrs,false),on),[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1),_c('span',{staticClass:"menu-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.inProgress)?_c('we-label',{staticClass:"small orange",attrs:{"text":_vm.$t('soon')}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])],1)})],2):_vm._e(),_c('div',{staticClass:"customer-app"},[_c('div',{staticClass:"item domain"},[_c('span',{staticClass:"app-label"},[_vm._v(" "+_vm._s(_vm.$t("domain"))+": ")]),_c('b',[_vm._v(_vm._s(_vm.domain))])]),_c('div',{staticClass:"item version"},[_c('span',{staticClass:"app-label"},[_vm._v(" "+_vm._s(_vm.$t("version"))+": ")]),_c('b',[_vm._v(_vm._s(_vm.version))])])])]),_c('div',{staticClass:"collapsible-bar",on:{"click":_vm.collapseMenu}},[_c('div',{staticClass:"circle-button"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }