import store from "@/store";
import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "deals-error": "Wasn't possible to load cards!",
    "search-deals-error": "Wasn't possible to search cards!",
    "find-deals-error": "Wasn't possible to find cards!",
    "filter-deals-error": "Wasn't possible to filter cards!",
    "deal-error": "Wasn't possible to load card data!",
    "create-deal-error": "Wasn't possible to create card!",
    "update-deal-success": "Card updated with success!",
    "update-deal-error": "Wasn't possible to update card!",
    "update-deal-stage-1": "The card",
    "update-deal-stage-2": "was moved to stage",
    "update-deal-stage-3": "with success!",
    "update-deal-stage-error": "Wasn't possible to update card stage!",
    "delete-deal-error": "Wasn't possible to delete card!",
    "deal-tasks-error": "Wasn't possible to load deal tasks!",
    "create-deal-task-success": "Deal task was created with success!",
    "create-deal-task-error": "Wasn't possible to create deal task!",
    "update-deal-task-success": "Deal task was updated with success!",
    "update-deal-task-error": "Wasn't possible to update deal task!",
    "delete-deal-task-success": "Deal task was deleted with success!",
    "delete-deal-task-error": "Wasn't possible to delete deal task!"
  },
  "pt-BR": {
    "deals-error": "Não foi possível carregar os cards!",
    "search-deals-error": "Não foi possível pesquisar os cards!",
    "find-deals-error": "Não foi possível carregar os cards!",
    "filter-deals-error": "Não foi possível filtrar os cards!",
    "deal-error": "Não foi possível carregar o card!",
    "create-deal-error": "Não foi possível criar o card!",
    "update-deal-success": "Card atualizado com sucesso!",
    "update-deal-error": "Não foi possível atualizar o card!",
    "update-deal-stage-1": "O card",
    "update-deal-stage-2": "foi movido para o estágio",
    "update-deal-stage-3": "com sucesso!",
    "update-deal-stage-error": "Não foi possível mover o card!",
    "delete-deal-error": "Não foi possível deletar o card!",
    "deal-tasks-error": "Não foi possível carregar as tarefas do card!",
    "create-deal-task-success": "A tarefa foi criada com sucesso!",
    "create-deal-task-error": "Não foi possível criar a tarefa do card!",
    "update-deal-task-success": "A tarefa foi atualizada com sucesso!",
    "update-deal-task-error": "Não foi possível atualizar a tarefa do card!",
    "delete-deal-task-success": "A tarefa foi deletada com sucesso!",
    "delete-deal-task-error": "Não foi possível deletar a tarefa do card!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    deals: [],
    currentDeal: {
      products: []
    },
    currentDealTask: {},
    dealCustomFields: null,
    dealTasks: [],
    dealsPages: {
      last: 1
    },
    dealsSort: {
      orderBy: "",
      direction: "",
      name: ""
    }
  },
  getters: {
    getDeals: state => state.deals,
    getCurrentDeal: state => state.currentDeal,
    getCurrentDealTask: state => state.currentDealTask,
    getCurrentDealProductTotal: state => {
      let total = 0;

      state.currentDeal?.products?.forEach(e => {
        total += Number(e.price) * Number(e.amount);
      });

      return total;
    },
    getDealTasks: state => state.dealTasks,
    getDealCustomFields: state => state.dealCustomFields,
    getDealsPages: state => state.dealsPages,
    getDealsSort: state => state.dealsSort
  },
  mutations: {
    setDeals: (state, payload) => {
      state.deals = payload;
    },
    setDealsPages: (state, payload) => {
      state.dealsPages = payload;
    },
    setDealsSort: (state, payload) => {
      state.dealsSort = payload;
    },
    setCurrentDeal: (state, payload) => {
      state.currentDeal = payload;
    },
    setCurrentDealUsers: (state, payload) => {
      state.currentDeal.user = payload ? payload : {};
    },
    setCurrentDealProducts: (state, payload) => {
      const products = payload.map(e => {
        if (!e.amount) {
          e.amount = 1;
        }

        if (state.currentDeal.products.some(f => f.id === e.id)) {
          return state.currentDeal.products.find(f => f.id === e.id);
        }

        return e;
      });

      state.currentDeal.products = products;
    },
    setCurrentDealContacts: (state, payload) => {
      state.currentDeal.contacts = payload;
    },
    setCurrentDealDeals: (state, payload) => {
      const dealsWithRelatedId = payload.map(e => {
        e.pivot = {
          related_deal_id: e.id
        };

        return e;
      });
      state.currentDeal.deals = dealsWithRelatedId;
    },
    setCurrentDealCompanies: (state, payload) => {
      state.currentDeal.companies = payload;
    },
    setDealTasks: (state, payload) => {
      state.dealTasks = payload;
    },
    setDealCustomFieldsValues: (state, payload) => {
      state.dealCustomFields = payload;
    },
    clearDealCustomFieldsValues: state => {
      if (state.dealCustomFields) {
        Object.keys(state.dealCustomFields).forEach(index => {
          state.dealCustomFields[index] = "";
        });
      }
    },
    createDealCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.dealCustomFields = { ...object, ...state.dealCustomFields };
    },
    setCurrentDealTask: (state, payload) => {
      state.currentDealTask = payload;
    }
  },
  actions: {
    dealsRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards`, {
            params: {
              page: page,
              ...orderBy
            }
          })
          .then(resp => {
            const deals = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setDealsPages", page);

            commit("setDeals", deals);
            resolve({ deals, page });
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    moreDealsRequest({ getters, commit }, payload) {
      const page = payload > 1 ? payload : 1;

      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards`, {
            params: {
              page: page
            }
          })
          .then(resp => {
            const deals = [...getters.getDeals, ...resp.data.data];
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setDealsPages", page);

            commit("setDeals", deals);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    searchDealsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards/search`, { params: payload })
          .then(resp => {
            const deals = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setDealsPages", page);

            commit("setDeals", deals);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    findDealsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards/find`, {
            params: { ...payload }
          })
          .then(resp => {
            const deals = resp.data.data;

            if (!payload?.updateLocal) {
              commit("setDeals", deals);
            }

            resolve(resp.data);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("find-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterDealsRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/cards/export",
        entity: "deals",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/cards/filter?paginate=true`, payload)
          .then(resp => {
            const deals = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setDealsPages", page);

            commit("setDeals", deals);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    dealRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards/${id}`)
          .then(resp => {
            let deal = resp.data;

            deal.companies.map(company => {
              company["id"] = company.pivot.company_id;
            });

            deal.contacts.map(contact => {
              contact["id"] = contact.pivot.contact_id;
            });

            deal.deals?.map(deal => {
              deal["id"] = deal.pivot.related_deal_id;
            });

            deal.products.map(product => {
              product["id"] = product.pivot.product_id;
            });

            deal.amount = Number(deal.amount).toFixed(2);

            if (!deal.tags) {
              deal.tags = [];
            }

            commit("clearDealCustomFieldsValues");

            if (deal.custom_fields) {
              commit("setDealCustomFieldsValues", deal.custom_fields[0]);
            }

            commit("setCurrentDeal", deal);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("deal-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createDealRequest({ commit }, deal) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/cards`, deal)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentDeal", data);
            store.dispatch("dealsRequest", {
              page: 1,
              updateLocal: true
            });
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-deal-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateDealRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/cards/${payload.id}`, payload)
          .then(resp => {
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-deal-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-deal-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateDealStageRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/cards/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: `${i18n.t("update-deal-stage-1")} <b>
              ${payload.name}</b> 
              ${i18n.t("update-deal-stage-2")} <b>
              ${payload.stage_name}</b> 
              ${i18n.t("update-deal-stage-3")}`,
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-deal-stage-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteDealRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/cards/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-deal-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    dealTasksRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards/${id}/tasks`)
          .then(resp => {
            const tasks = resp.data;

            commit("setDealTasks", tasks);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("deal-tasks-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    dealTaskRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(`/tasks/${id}`).then(resp => {
          let task = resp.data;

          task.startDate = task.start.split(" ")[0];
          task.startHour = task.start.split(" ")[1];
          task.endDate = task.end.split(" ")[0];
          task.endHour = task.end.split(" ")[1];

          commit("setCurrentDealTask", task);
          resolve(resp);
        });
      });
    },
    createDealTaskRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/tasks`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-deal-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-deal-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateDealTaskRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/tasks/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-deal-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-deal-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteDealTaskRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/tasks/${payload}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-deal-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-deal-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
