<template>
  <div id="board-config">
    <slot name="config-content" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#board-config {
  background: var(--background-2);
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  @include mobile {
    background: var(--background-1);
  }

  .config-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 30px;
    padding: 30px;

    @include mobile {
      padding: 0;
      padding-bottom: 140px;
      display: block;
    }
  }
}
</style>
