import { getMessaging, getToken } from "firebase/messaging";

export default {
  created() {
    if (process.env.NODE_ENV === "production") {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey:
          "BNwPe__d7CPDMfZWT95jrRP32KasEwGj9wKN9yWam3k2YRmMP5XTe1yx5p8Payf6Gri0MIxnUUKXuK8s8Fyl7Zc"
      })
        .then(deviceToken => {
          if (deviceToken) {
            this.$store.commit("setFirebaseRegistrationId", deviceToken);
          }
        })
        .catch(err => {
          console.error("An error occurred while retrieving token. ", err);
        });
    }
  }
};
