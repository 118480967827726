import store from "@/store";
import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "create-lead-error": "Wasn't possible to register.",
    "update-lead-error": "Wasn't possible to continue.",
    "domain-error": "The chosen domain is being used."
  },
  "pt-BR": {
    "create-lead-error": "Não foi possível cadastrar.",
    "update-lead-error": "Não foi possível continuar.",
    "domain-error": "O domínio escolhido já está em uso."
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    lead: {
      email: "",
      domain: ""
    },
    domain: "wepipe",
    hash: ""
  },
  getters: {
    getLead: state => state.lead,
    getHash: state => state.hash
  },
  mutations: {
    setLead: (state, payload) => {
      state.lead = payload;
    },
    setHash: (state, payload) => {
      state.hash = payload;
    }
  },
  actions: {
    createLeadRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/leads`, payload)
          .then(resp => {
            commit("setLead", resp.data);
            commit("setHash", resp.data.hash);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-lead-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateLeadRequest({ commit, getters }, payload) {
      const hash = getters.getHash;

      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/leads/${hash}`, payload)
          .then(resp => {
            commit("setLead", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-lead-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createCustomerRequest(NULL, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/customers`, payload)
          .then(resp => {
            store.commit("setDomain", resp.data.domain);
            localStorage.setItem("domain", resp.data.domain);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    validateDomain({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customers/domains/${payload}/check`)
          .then(() => {
            resolve(true);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("domain-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
