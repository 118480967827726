import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "bill-method-1": "Banking billet",
    "bill-method-2": "Credit card",
    "bill-method-3": "Debit card",
    "bill-method-4": "Digital wallet",
    "bill-method-5": "Cashback",
    "bill-method-6": "Check",
    "bill-method-7": "Store credit",
    "bill-method-8": "Virtual credit",
    "bill-method-9": "Banking deposit",
    "bill-method-10": "Money",
    "bill-method-11": "Instant payment",
    "bill-method-12": "Fidelity program",
    "bill-method-13": "Without payment",
    "bill-method-14": "Banking transfer",
    "bill-method-15": "Food voucher",
    "bill-method-16": "Fuel voucher",
    "bill-method-17": "Gift voucher",
    "bill-method-18": "Meal voucher",
    "bill-method-19": "Others",
    "bill-status-1": "Pending",
    "bill-status-2": "Confirmed",
    "bill-status-3": "Received",
    "bill-status-4": "Received in cash",
    "bill-status-5": "Refund requested",
    "bill-status-6": "Refunded",
    "bill-status-7": "Overdue",
    "bill-type-1": "Expense",
    "bill-type-2": "Revenue",
    "bills-error": "Wasn't possible to load bills!",
    "bill-error": "Wasn't possible to load bill data!",
    "create-bill-error": "Wasn't possible to create bill!",
    "update-bill-success": "Bill updated with success!",
    "update-bill-error": "Wasn't possible to update bill!",
    "delete-bill-error": "Wasn't possible to delete bill!",
    "search-bills-error": "Wasn't possible to search bills!",
    "filter-bills-error": "Wasn't possible to filter bills!",
    "cost-center-error": "Wasn't possible to load cost centers!"
  },
  "pt-BR": {
    "bill-method-1": "Boleto bancário",
    "bill-method-2": "Cartão de crédito",
    "bill-method-3": "Cartão de débito",
    "bill-method-4": "Carteira digital",
    "bill-method-5": "Cashback",
    "bill-method-6": "Cheque",
    "bill-method-7": "Crédito da loja",
    "bill-method-8": "Crédito virtual",
    "bill-method-9": "Depósito bancário",
    "bill-method-10": "Dinheiro",
    "bill-method-11": "Pix - Pagamento instantâneo",
    "bill-method-12": "Programa de fidelidade",
    "bill-method-13": "Sem pagamento",
    "bill-method-14": "Transferência bancária",
    "bill-method-15": "Vale alimentação",
    "bill-method-16": "Vale combustível",
    "bill-method-17": "Vale presente",
    "bill-method-18": "Vale refeição",
    "bill-method-19": "Outros",
    "bill-status-1": "Aguardando pagamento",
    "bill-status-2": "Pagamento confirmado",
    "bill-status-3": "Recebida",
    "bill-status-4": "Recebida em dinheiro",
    "bill-status-5": "Estorno solicitado",
    "bill-status-6": "Estornada",
    "bill-status-7": "Vencida",
    "bill-type-1": "Conta a pagar",
    "bill-type-2": "Conta a receber",
    "bills-error": "Não foi possível carregar os lançamentos!",
    "bill-error": "Não foi possível carregar os dados do lançamento!",
    "create-bill-error": "Não foi possível criar o lançamento!",
    "update-bill-success": "Lançamento atualizado com sucesso!",
    "update-bill-error": "Não foi possível atualizar o lançamento!",
    "delete-bill-error": "Não foi possível deletar o lançamento!",
    "search-bills-error": "Não foi possível realizar a pesquisa!",
    "filter-bills-error": "Não foi possível realizar o filtro!",
    "cost-center-error": "Não foi possível carregar os centros de custo!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    bills: [],
    currentBill: {
      type: "",
      status: "",
      tags: []
    },
    billsPages: {
      last: 1
    },
    billsSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    costCenters: [],
    billCustomFields: null
  },
  getters: {
    getBills: state => state.bills,
    getBillCustomFields: state => state.billCustomFields,
    getCurrentBill: state => state.currentBill,
    getCostCenters: state => state.costCenters,
    getBillsPages: state => state.billsPages,
    getBillsSort: state => state.billsSort,
    getBillMethodsList: () => [
      {
        text: i18n.t("bill-method-1"),
        value: "banking_billet"
      },
      {
        text: i18n.t("bill-method-2"),
        value: "credit_card"
      },
      {
        text: i18n.t("bill-method-3"),
        value: "debit_card"
      },
      { text: i18n.t("bill-method-4"), value: "digital_wallet" },
      { text: i18n.t("bill-method-5"), value: "cashback" },
      { text: i18n.t("bill-method-6"), value: "check" },
      { text: i18n.t("bill-method-7"), value: "store_credit" },
      { text: i18n.t("bill-method-8"), value: "virtual_credit" },
      {
        text: i18n.t("bill-method-9"),
        value: "banking_deposit"
      },
      { text: i18n.t("bill-method-10"), value: "cash" },
      { text: i18n.t("bill-method-11"), value: "instant_payment" },
      { text: i18n.t("bill-method-12"), value: "fidelity_program" },
      { text: i18n.t("bill-method-13"), value: "without_payment" },
      { text: i18n.t("bill-method-14"), value: "banking_transfer" },
      { text: i18n.t("bill-method-15"), value: "food_voucher" },
      { text: i18n.t("bill-method-16"), value: "fuel_voucher" },
      { text: i18n.t("bill-method-17"), value: "gift_voucher" },
      { text: i18n.t("bill-method-18"), value: "meal_voucher" },
      { text: i18n.t("bill-method-19"), value: "other" }
    ],
    getBillStatusList: () => [
      {
        text: i18n.t("bill-status-1"),
        value: "pending"
      },
      {
        text: i18n.t("bill-status-2"),
        value: "confirmed"
      },
      {
        text: i18n.t("bill-status-3"),
        value: "received"
      },
      { text: i18n.t("bill-status-4"), value: "received_in_cash" },
      { text: i18n.t("bill-status-5"), value: "refund_requested" },
      { text: i18n.t("bill-status-6"), value: "refunded" },
      { text: i18n.t("bill-status-7"), value: "overdue" }
    ],
    getBillTypesList: () => [
      { text: i18n.t("bill-type-1"), value: "expense" },
      { text: i18n.t("bill-type-2"), value: "revenue" }
    ],
    getBillColors: () => {
      return {
        pending: "blue",
        confirmed: "indigo",
        received: "green",
        received_in_cash: "green",
        refund_requested: "warning",
        refunded: "orange",
        overdue: "red"
      };
    }
  },
  mutations: {
    setBills: (state, payload) => {
      state.bills = payload;
    },
    setCostCenters: (state, payload) => {
      state.costCenters = payload;
    },
    setBillsPages: (state, payload) => {
      state.billsPages = payload;
    },
    setBillsSort: (state, payload) => {
      state.billsSort = payload;
    },
    setCurrentBill: (state, payload) => {
      state.currentBill = payload;
    },
    createBillCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.billCustomFields = { ...object, ...state.billCustomFields };
    },
    setBillCustomFieldsValues: (state, payload) => {
      state.billCustomFields = payload;
    },
    clearBillCustomFieldsValues: state => {
      if (state.billCustomFields) {
        Object.keys(state.billCustomFields).forEach(index => {
          state.billCustomFields[index] = "";
        });
      }
    }
  },
  actions: {
    billsRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/bills`, {
            params: {
              page: page,
              ...orderBy
            }
          })
          .then(resp => {
            const bills = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setBillsPages", page);

            commit("setBills", bills);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("bills-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    billRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/bills/${id}`)
          .then(resp => {
            const bill = resp.data;
            commit("setCurrentBill", bill);
            commit("clearBillCustomFieldsValues");
            if (bill.custom_fields) {
              commit("setBillCustomFieldsValues", bill.custom_fields[0]);
            }
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("bill-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createBillRequest({ commit }, bill) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/bills`, bill)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentBill", data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-bill-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateBillRequest({ state, commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/bills/${state.currentBill.id}`, payload)
          .then(resp => {
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-bill-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-bill-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteBillRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/bills/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-bill-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    searchBillsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/bills/search`, { params: payload })
          .then(resp => {
            const bills = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setBillsPages", page);

            commit("setBills", bills);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-bills-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterBillsRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/bills/export",
        entity: "bills",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/bills/filter?paginate=true`, payload)
          .then(resp => {
            const bills = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setBillsPages", page);

            commit("setBills", bills);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-bills-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    costCentersRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/bills/costCenters`)
          .then(resp => {
            const costCenters = resp.data;

            commit("setCostCenters", costCenters);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("cost-center-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
