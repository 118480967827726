<template>
  <div class="board-list">
    <router-view />
    <div class="functions">
      <div class="filters">
        <div class="items">
          <slot name="filters" />
        </div>
      </div>
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="list">
      <slot name="list" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.board-list {
  background: var(--background-2);
  width: 100%;
  height: 100%;

  &.products {
    .list {
      .labels,
      .items .card-list {
        grid-template-columns: minmax(340px, 1fr) $column-size $column-size;
      }
    }
  }

  &.contacts,
  &.companies {
    .list {
      .labels,
      .items .card-list {
        @include mobile {
          padding-right: 0;
        }
      }
    }
  }

  &.contacts,
  &.companies,
  &.users {
    .list {
      .labels,
      .items .card-list {
        grid-template-columns: minmax(340px, 1fr) $column-size $column-size $column-size $column-size;

        @include mobile {
          grid-template-columns: minmax(0, 1fr) 50px 50px;
        }
      }
    }
  }

  &.tasks {
    .list {
      .labels {
        padding-left: 52px;
        grid-template-columns: $column-size $column-size $column-size $column-size $column-size;
      }
      .items .card-list {
        grid-template-columns: 30px $column-size $column-size $column-size $column-size $column-size;

        @include mobile {
          padding-left: 9px;
        }

        .column {
          &:first-child {
            @include mobile {
              flex: none;
            }
          }
        }
      }
    }
  }

  &.deals {
    .list {
      .labels,
      .items .card-list {
        grid-template-columns: $column-size $column-size $column-size $column-size $column-size;
      }
    }
  }

  &.bills {
    .list {
      .labels,
      .items .card-list {
        grid-template-columns:
          minmax(240px, 1fr) $column-size $column-size $column-size $column-size
          $column-size
          $column-size;
      }

      .items .card-list {
        .we-icon-tooltip {
          margin: 0 8px;
          cursor: pointer;

          svg {
            height: 16px;
          }
        }
      }
    }
  }

  .functions {
    padding: 13px 50px;
    border-bottom: 1px solid var(--line);
    display: flex;
    justify-content: space-between;

    @include mobile {
      padding: 5px 15px;
      width: 100%;
    }

    .filters {
      @include mobile {
        flex: 1;
      }
      .items {
        display: flex;
        align-items: center;

        @include mobile {
          justify-content: space-between;
          width: 100%;
        }

        .we-pagination {
          margin-right: 18px;
        }

        .we-button {
          margin-right: 8px;

          @include mobile {
            margin: 0;
            margin-left: 8px;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: flex-end;

      @include mobile {
        display: none;
      }

      .item {
        height: 27px;
        padding: 0 9px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: $semibold;
        color: var(--text-2);
        border-radius: 5px;
        margin-left: 8px;
        cursor: pointer;

        &.highlight {
          color: var(--blue);
        }

        &:hover {
          background: var(--action);
        }

        svg {
          height: 16px;
          width: auto;
          margin-right: 7px;
        }
      }
    }
  }

  .list {
    padding: 0 50px;
    height: 100%;
    max-height: calc(100vh - 136px);
    overflow-y: auto;
    position: relative;

    @include mobile {
      padding: 20px 15px;
      padding-bottom: 150px;
    }

    .we-loading-overflow {
      @include mobile {
        position: fixed;
      }
    }

    .labels {
      padding: 0 14px;
      padding-top: 14px;
      padding-bottom: 14px;
      display: grid;
      grid-template-columns: minmax(340px, 1fr) $column-size $column-size;
      gap: 8px;

      @include mobile {
        display: none;
      }

      .label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:hover {
          .name,
          .icon svg {
            color: var(--text-1);
          }
        }

        &.asc {
          .name {
            color: var(--primary);
          }

          .icon svg[data-icon="sort-up"] {
            color: var(--primary);
          }

          .icon svg[data-icon="sort-down"] {
            color: var(--action-hover);
          }

          &:hover {
            .icon svg[data-icon="sort-down"] {
              color: var(--text-1);
            }
          }
        }

        &.desc {
          .name {
            color: var(--primary);
          }

          .icon svg[data-icon="sort-down"] {
            color: var(--primary);
          }

          .icon svg[data-icon="sort-up"] {
            color: var(--action-hover);
          }

          &:hover {
            .icon svg[data-icon="sort-up"] {
              color: var(--text-1);
            }
          }
        }

        &.disabled {
          cursor: auto;

          &:hover {
            .name,
            .icon svg {
              color: var(--text-2);
            }
          }

          .icon {
            display: none;
          }
        }

        .name {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: $semibold;
          color: var(--text-2);
        }

        .icon {
          position: relative;
          width: 12px;
          margin-left: 8px;
          height: 16px;

          svg {
            height: 13px;
            position: absolute;
            top: 0;
            right: 0;
            color: var(--text-2);
          }
        }
      }
    }

    .items {
      .card-list {
        padding: 11px 14px;
        background: var(--background-1);
        border-radius: 10px;
        border: 1px solid var(--line);
        display: grid;
        grid-template-columns: minmax(340px, 1fr) $column-size $column-size;
        gap: 8px;
        margin-bottom: 12px;
        cursor: pointer;
        box-shadow: none;
        min-height: 45px;

        @include mobile {
          border-radius: 7px;
          padding: 8px 14px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          gap: 0;
        }

        & > div {
          &:first-child {
            @include mobile {
              flex: 1;
            }
          }
        }

        &:hover {
          border: 1px solid var(--primary);
        }

        &.task-completed {
          border: 1px solid var(--green);
        }

        &:hover {
          border: 1px solid var(--primary);
        }

        .column {
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 14px;
          font-weight: $regular;
          color: var(--text-1);

          @include mobile {
            min-width: 0;
          }

          .we-button {
            @include mobile {
              width: 100%;
            }
          }

          .task-color {
            min-width: 5px;
            border-radius: 5px;
            height: 100%;
            margin-right: 6px;

            @include mobile {
              height: 24px;
              min-width: 4px;
            }
          }

          .source-img {
            height: 20px;
            width: auto;
            margin-right: 8px;
          }

          .v-input--selection-controls {
            margin-top: 0px;
            padding-top: 0px;
          }

          .related-contacts {
            margin-left: 5px;
            white-space: nowrap;
          }

          &.price {
            font-weight: $semibold;
            color: var(--red);
          }

          &.profile {
            display: grid;
            grid-template-columns: 36px minmax(0, 1fr);
            gap: 14px;

            @include mobile {
              gap: 8px;
            }

            .data {
              .name {
                font-weight: $medium;
              }
              .value {
                color: var(--green);
                font-weight: $semibold;
              }

              .email {
                @include mobile {
                  font-size: 12px;
                }
              }
            }
          }

          &.user {
            .rounded-picture {
              margin-right: 8px;
              .text {
                font-size: 10px;
              }
            }

            .name {
              order: 3;
            }
          }
        }
      }
    }
  }
}
</style>
