import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "create-note-error": "Wasn't possible to create note!",
    "delete-note-error": "Wasn't possible to delete note!"
  },
  "pt-BR": {
    "create-note-error": "Não foi possível criar a nota!",
    "delete-note-error": "Não foi possível deletar a nota!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    notes: []
  },
  getters: {
    getNotes: state => state.notes.filter(e => e?.user)
  },
  mutations: {
    setNotes: (state, payload) => {
      state.notes = payload;
    }
  },
  actions: {
    notesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/notes`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const notes = resp.data;

            commit("setNotes", notes);
            resolve(resp);
          });
      });
    },
    createNoteRequest({ commit }, note) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/notes`, note)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-note-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteNoteRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/notes/${payload.id}`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-note-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
