<template>
  <div class="we-invoice" :class="{ active }">
    <div class="invoice-summary">
      <div class="date-plan">
        <div class="date">
          <span class="date-text">
            Março de 2022
          </span>
          <we-label class="medium rounded-pill" text="Pendente" />
        </div>
        <div class="plan">
          Plano Grow (10 usuários)
        </div>
      </div>
      <div class="amount">
        <span class="value">
          R$ 100,00
        </span>
        <we-icon-button icon="chevron-down" @click="active = !active" />
      </div>
    </div>
    <div class="invoice-content">
      <div class="invoice-grid">
        <we-data-field label="Usuários" value="10" />
        <we-data-field label="Status" value="Pendente" />
        <we-data-field label="Data da fatura" value="25 de Fev. de 2022" />
        <we-data-field label="Valor total" value="R$ 100,00" />
        <we-data-field label="ID Asaas" value="EKG2SJFN" />
        <we-data-field label="Data de pagamento" value="27 de Fev. de 2022" />
      </div>
      <div class="invoice-actions">
        <we-button
          class="disabled"
          text="Ver todos os detalhes"
          icon="external-link-alt"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    };
  }
};
</script>

<style lang="scss">
.we-invoice {
  &.active {
    .invoice-summary {
      border-radius: 5px 5px 0 0;

      .amount {
        .we-icon-button {
          transform: rotate(180deg);
        }
      }
    }

    .invoice-content {
      display: block;
    }
  }

  .invoice-summary {
    padding: 15px 24px;
    display: flex;
    align-items: center;
    border: 1px solid var(--line);
    border-radius: 5px;

    @include mobile {
      padding: 15px 15px;
    }

    .date-plan {
      flex: 1;

      .date {
        display: flex;
        align-items: center;

        .date-text {
          font-size: 14px;
          font-weight: $semibold;
          color: var(--text-1);
          margin-right: 8px;
        }
      }

      .plan {
        font-size: 14px;
        font-weight: $medium;
        color: var(--text-2);
      }
    }

    .amount {
      display: flex;
      align-items: center;

      .value {
        font-size: 16px;
        font-weight: $semibold;
        color: var(--text-1);
        padding-right: 8px;
      }

      .we-icon-button {
        transition: 0.2s all;
      }
    }
  }

  .invoice-content {
    border: 1px solid var(--line);
    border-top: none;
    padding: 15px 24px;
    background: var(--background-2);
    border-radius: 0 0 5px 5px;
    display: none;

    @include mobile {
      padding: 15px 15px;
    }

    .invoice-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 16px;
      margin-bottom: 16px;
    }

    .invoice-actions {
      .we-button {
        margin: 0 auto;
      }
    }
  }
}
</style>
