<i18n>
{
  "en": {
    "title-confirm": "Are you sure to confirm?",
    "title-delete": "Are you sure to delete?",
    "description": "When removing this record, it will be deleted from the database and, as determined by the LGPD, it will not be possible to recover it in the future.",
    "description-records": "When removing these records they will be deleted from the database and, as determined by the LGPD, it will not be possible to recover them in the future.",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "delete": "Delete"
  },
  "pt-BR": {
    "title-confirm": "Deseja fazer essa ação?",
    "title-delete": "Deseja realmente deletar?",
    "description": "Ao remover esse registro ele será apagado da base de dados e, por determinação da LGPD, não será possível recuperá-lo no futuro.",
    "description-records": "Ao remover esses registros eles serão apagados da base de dados e, por determinação da LGPD, não será possível recuperá-los no futuro.",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "delete": "Deletar"
  }
}
</i18n>

<template>
  <modal-action @close="closeModal" v-if="open">
    <template #view>
      <div class="modal" id="confirm_delete">
        <div class="modal-body">
          <div class="we-title">
            <template v-if="title">
              {{ title }}
            </template>
            <template v-else-if="isConfirm">
              {{ $t("title-confirm") }}
            </template>
            <template v-else>
              {{ $t("title-delete") }}
            </template>
          </div>
          <div class="delete-records" v-if="totalRecords">
            <span v-if="type === 'confirm'">
              Você está prestes a atualizar <b>{{ totalRecords }}</b> registros
            </span>
            <span v-else>
              Você está prestes a deletar <b>{{ totalRecords }}</b> registros
            </span>
          </div>
          <div v-if="Number(totalRecords) > 1" class="we-description">
            {{ description || $t("description-records") }}
          </div>
          <div v-else class="we-description">
            {{ description || $t("description") }}
          </div>
          <img
            class="modal-img"
            v-if="isConfirm"
            src="@/assets/undraw/confirm.svg"
            :alt="$t('title-confirm')"
            height="103"
            width="87"
          />
          <img
            v-else
            class="modal-img"
            src="@/assets/undraw/delete.svg"
            :alt="$t('title-delete')"
            width="184"
            height="103"
          />
        </div>
        <div class="bottom double-action">
          <we-button
            class="disabled"
            :text="$t('cancel')"
            @click="closeModal"
          />
          <we-button
            :class="isConfirm ? 'primary' : 'error'"
            :text="isConfirm ? $t('confirm') : $t('delete')"
            @click="confirm"
            :loading="loading"
            cy="confirm-modal"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    type: {
      type: String,
      default: "delete"
    },
    totalRecords: [Number, String],
    open: {
      type: Boolean,
      default: false
    },
    title: String,
    description: String
  },
  computed: {
    isConfirm() {
      return this.type === "confirm";
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
#action_modal {
  z-index: 8;

  .modal#confirm_delete {
    width: 100%;
    max-width: 420px;
    min-height: 340px;
    height: fit-content;

    @include mobile {
      display: block;
      position: fixed;
      bottom: 15px;
      left: 15px;
      width: calc(100vw - 30px);
      max-width: 100%;
      height: 300px;
      border-radius: 10px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 42px 45px;

      @include mobile {
        padding: 30px 30px;
      }

      .we-title {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        font-style: normal;
        font-weight: $semibold;
        color: var(--text-1);
        margin-bottom: 17px;

        @include mobile {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .delete-records {
        font-size: 16px;
        text-align: center;
        font-style: italic;
        margin-bottom: 12px;
      }

      .we-description {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        font-weight: $regular;
        color: var(--text-2);
        margin-bottom: 32px;

        @include mobile {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }

      .we-button {
        width: 100%;
        margin-bottom: 32px;
      }

      .terms {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 19px;
        font-weight: $regular;
        color: var(--text-1);
        gap: 6px;

        a {
          color: var(--text-1);
          font-weight: $semibold;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .bottom {
      @include mobile {
        position: absolute;
        background: var(--background-1);
        border-top: none;
        height: 70px;
      }
    }
  }
}
</style>
