export default {
  state: {
    lightbox: [],
    lightboxOpened: false,
    lightboxSelected: 0
  },
  getters: {
    getLightbox: state => state.lightbox,
    getLightboxOpened: state => state.lightboxOpened,
    getLightboxSelected: state => state.lightboxSelected
  },
  mutations: {
    setLightbox: (state, payload) => {
      const setSelected = payload.files.map(e => {
        e.selected = e.id === payload.selected.id;

        return e;
      });
      state.lightbox = setSelected;
      state.lightboxSelected = setSelected.findIndex(e => e.selected) || 0;
      state.lightboxOpened = true;
    },
    setLightboxOpened: (state, payload) => {
      state.lightboxOpened = payload;
    },
    setLightboxSelected: (state, payload) => {
      state.lightboxSelected = payload;
    }
  }
};
