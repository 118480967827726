<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :position-x="positionX"
    :position-y="positionY"
    :open-on-hover="isOpenOnHover"
    :transition="transition"
    :value="value"
    :absolute="absolute"
    content-class="we-balloon"
  >
    <template v-slot:activator="{ on }">
      <div
        v-if="isSubMenu"
        :class="$options._parentVnode.data.class"
        class="balloon-item has-submenu"
        v-on="on"
      >
        <div class="icon" v-if="icon">
          <font-awesome-icon :icon="icon" />
        </div>
        {{ name }}
        <div class="submenu-icon">
          <font-awesome-icon icon="chevron-right" />
        </div>
      </div>
    </template>
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-divider v-if="item.isDivider" :key="index" />
        <we-nested-menu
          v-else-if="item.menu && item.menu.length > 0"
          :key="index"
          :name="item.name"
          :class="item.class"
          :icon="item.icon"
          :menu-items="item.menu"
          @we-nested-menu-click="emitClickEvent"
          :is-open-on-hover="false"
          :offset-x="true"
          :offset-y="false"
          :is-sub-menu="true"
          :absolute="false"
        />
        <div
          v-else
          class="balloon-item"
          :class="item.class"
          :key="index"
          @click="emitClickEvent(item)"
        >
          <div class="icon" v-if="item.icon">
            <font-awesome-icon :icon="item.icon" />
          </div>
          <div class="text">{{ item.name }}</div>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    name: String,
    icon: String,
    menuItems: Array,
    positionX: Number,
    positionY: Number,
    value: Boolean,
    absolute: { type: Boolean, default: false },
    color: { type: String, default: "secondary" },
    offsetX: { type: Boolean, default: false },
    offsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: "scale-transition" }
  },
  methods: {
    emitClickEvent(item) {
      this.$emit("we-nested-menu-click", item);
      this.$emit("input", false);
    }
  }
};
</script>

<style></style>
