<i18n>
{
  "en": {
    "question": "How likely are you to recommend wepipe to other companies or workmates?",
    "label-1": "Not at all likely",
    "label-2": "Extremely likely",
    "comment": "What we need to receive a 10?",
    "send": "Send",
    "congrats": "Wepipe thanks you for voting 🎉",
    "thanks-title": "Thanks for voting!",
    "thanks-description": "Wepipe will use your feedback to improve our products and services."
  },
  "pt-BR": {
    "question": "De 0 a 10, o quanto você recomendaria a wepipe para outras empresas ou colegas de trabalho?",
    "label-1": "Pouco provável",
    "label-2": "Muito provável",
    "comment": "O que precisamos para receber 10?",
    "send": "Enviar",
    "congrats": "A wepipe agradece pelo voto 🎉",
    "thanks-title": "Obrigado por votar!",
    "thanks-description": "A wepipe usará seu feedback para melhorar nossos produtos e serviços."
  }
}
</i18n>

<template>
  <div id="nps">
    <transition appear name="fade">
      <we-shadow />
    </transition>
    <transition appear name="slide-create">
      <div class="view">
        <div class="modal">
          <we-icon-button
            class="big-mobile"
            icon="times"
            @click="closeWithoutVote"
          />
          <div class="question">
            {{ $t("question") }}
          </div>
          <div class="options">
            <div
              v-for="i in 10"
              class="number"
              :class="{ active: i === rating }"
              @click="rating = i"
              :key="i"
            >
              {{ i }}
            </div>
          </div>
          <div class="labels">
            <div class="option-label">
              {{ $t("label-1") }}
            </div>
            <div class="option-label">
              {{ $t("label-2") }}
            </div>
          </div>
          <div class="bottom-fields" v-if="rating">
            <we-input
              v-if="rating < 10"
              type="text"
              v-model="comment"
              :placeholder="$t('comment')"
            />
            <div v-else class="congratulations">
              {{ $t("congrats") }}
            </div>
            <we-button class="primary" :text="$t('send')" @click="sendNps" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      rating: null,
      comment: null,
      loadingBtn: false
    };
  },
  methods: {
    ...mapActions(["sendNpsRequest"]),
    closeWithoutVote() {
      this.sendNpsRequest({
        rating: null,
        comment: null
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
    async sendNps() {
      this.loadingBtn = true;
      const npsResponse = await this.sendNpsRequest({
        rating: this.rating,
        comment: this.comment
      });

      if (npsResponse.status != 200) {
        const snackBar = {
          title: this.$t("thanks-title"),
          description: this.$t("thanks-description"),
          status: "success"
        };
        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
      this.closeModal();
      this.loadingBtn = false;
    }
  }
};
</script>

<style lang="scss">
#nps {
  position: absolute;
  .shadow {
    z-index: 6;
  }

  .view {
    position: fixed;
    background: var(--inherit);
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 7;
    overflow-y: auto;
    padding: 15px;

    .modal {
      height: fit-content;
      max-width: 530px;
      width: 100%;
      background: var(--background-1);
      border-radius: 10px;
      padding: 24px;
      box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.25);
      z-index: 4;
      overflow: hidden;
      pointer-events: initial;
      position: absolute;
      bottom: 20px;

      @include sm {
        bottom: 15px;
        left: 15px;
        padding: 15px;
        width: calc(100% - 30px);
      }

      .we-icon-button {
        position: absolute;
        top: 8px;
        right: 8px;
      }

      .question {
        font-size: 16px;
        font-weight: $medium;
        margin-bottom: 16px;
        padding-right: 50px;

        @include sm {
          font-size: 14px;
        }
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        @include sm {
          overflow-x: auto;
          width: calc(100% + 30px);
          margin-left: -15px;
        }

        .number {
          height: 38px;
          width: 38px;
          min-width: 38px;
          margin: 2.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background: var(--action);
          font-size: 14px;
          color: var(--text-2);
          font-weight: $semibold;
          cursor: pointer;

          &:first-child {
            margin-left: 0;

            @include sm {
              margin-left: 15px;
            }
          }

          &:last-child {
            margin-right: 0;

            @include sm {
              margin-right: 15px;
            }
          }

          &:hover {
            background: var(--action-hover);
          }

          &.active {
            background: var(--primary);
            color: var(--background-1);
          }
        }
      }

      .labels {
        display: flex;
        justify-content: space-between;

        .option-label {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: $medium;
          color: var(--text-2);
          letter-spacing: 1px;
          color: var(--text-3);
        }
      }

      .bottom-fields {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 120px;
        gap: 16px;
        margin-top: 16px;

        @include sm {
          grid-template-columns: 1fr;
        }

        .congratulations {
          font-size: 16px;
          font-weight: $medium;
          color: var(--text-2);
          min-height: 38px;
          display: flex;
          align-items: center;

          @include sm {
            justify-content: center;
          }
        }

        .we-button {
          grid-column: 2;

          @include sm {
            grid-column: auto;
          }
        }
      }
    }
  }
}
</style>
