import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCog,
  faBold,
  faStrikethrough,
  faUnderline,
  faItalic,
  faListUl,
  faListOl,
  faParagraph,
  faHeadset,
  faUndoAlt,
  faRedoAlt,
  faColumns,
  faEnvelope,
  faLanguage,
  faPalette,
  faCalendarDays,
  faCoins,
  faFilter,
  faBroom,
  faFileCsv,
  faFilePdf,
  faFileUpload,
  faFileCode,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHandshake,
  faUsers,
  faUserCircle,
  faUserTie,
  faAlignLeft,
  faUserPlus,
  faSearch,
  faArrowsAltV,
  faCheckCircle,
  faPlus,
  faMinus,
  faAward,
  faFlagCheckered,
  faPaperclip,
  faEllipsisH,
  faSort,
  faTags,
  faDollarSign,
  faBullhorn,
  faFilterCircleDollar,
  faCubes,
  faPhone,
  faTimes,
  faStar,
  faCode,
  faCodeBranch,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faUser,
  faSignal,
  faShare,
  faHistory,
  faCaretDown,
  faCheckSquare,
  faExternalLinkAlt,
  faLink,
  faToggleOff,
  faTimesCircle,
  faSyncAlt,
  faSortUp,
  faSortDown,
  faGlobeAmericas,
  faDownload,
  faTasks,
  faEdit,
  faSave,
  faTag,
  faFolderPlus,
  faSignOutAlt,
  faCamera,
  faCheck,
  faCheckDouble,
  faExclamation,
  faSquareCheck,
  faServer,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faTruck,
  faMagnifyingGlass,
  faTimeline,
  faCube,
  faSlidersH,
  faHashtag,
  faComments,
  faRocket,
  faBan,
  faEye,
  faPlay,
  faReceipt,
  faChalkboardUser,
  faPeopleCarryBox,
  faIdCardClip,
  faChartBar,
  faBuilding,
  faPrint,
  faImage,
  faCalculator,
  faList,
  faMobileAlt,
  faKey,
  faBook,
  faFileLines,
  faFileImport,
  faNoteSticky,
  faCloudArrowUp,
  faBrush,
  faUnlockKeyhole,
  faFileInvoiceDollar,
  faWandMagicSparkles,
  faDesktop,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faCreditCard,
  faTrashAlt,
  faAddressCard,
  faQuestionCircle,
  faClock,
  faCopy,
  faSquare,
  faCalendarCheck,
  faIdCard
} from "@fortawesome/free-regular-svg-icons";
import {
  faWhatsapp,
  faLinkedinIn,
  faDiscord,
  faInstagram,
  faFacebookF,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faDesktop,
  faCog,
  faBold,
  faStrikethrough,
  faUnderline,
  faItalic,
  faListUl,
  faListOl,
  faParagraph,
  faHeadset,
  faUndoAlt,
  faRedoAlt,
  faColumns,
  faEnvelope,
  faLanguage,
  faPalette,
  faCalendarDays,
  faCalendarCheck,
  faCube,
  faSlidersH,
  faHashtag,
  faComments,
  faRocket,
  faBan,
  faEye,
  faPlay,
  faReceipt,
  faChalkboardUser,
  faPeopleCarryBox,
  faIdCardClip,
  faChartBar,
  faCheck,
  faCheckDouble,
  faExclamation,
  faSquareCheck,
  faServer,
  faCoins,
  faFilter,
  faBroom,
  faFileCsv,
  faFilePdf,
  faFileUpload,
  faFileCode,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHandshake,
  faUsers,
  faUserCircle,
  faUserTie,
  faAlignLeft,
  faUserPlus,
  faSearch,
  faArrowsAltV,
  faCheckCircle,
  faPlus,
  faMinus,
  faAward,
  faFlagCheckered,
  faPaperclip,
  faEllipsisH,
  faSort,
  faBuilding,
  faPrint,
  faMobileAlt,
  faTags,
  faDollarSign,
  faBullhorn,
  faFilterCircleDollar,
  faCubes,
  faPhone,
  faTimes,
  faStar,
  faCode,
  faCodeBranch,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faUser,
  faSignal,
  faShare,
  faHistory,
  faCaretDown,
  faCheckSquare,
  faExternalLinkAlt,
  faLink,
  faToggleOff,
  faTimesCircle,
  faSyncAlt,
  faSortUp,
  faSortDown,
  faGlobeAmericas,
  faDownload,
  faTasks,
  faEdit,
  faSave,
  faTag,
  faFolderPlus,
  faSignOutAlt,
  faCamera,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faTruck,
  faTimeline,
  faBell,
  faCreditCard,
  faTrashAlt,
  faAddressCard,
  faQuestionCircle,
  faClock,
  faCopy,
  faSquare,
  faWandMagicSparkles,
  faIdCard,
  faImage,
  faCalculator,
  faWhatsapp,
  faLinkedinIn,
  faInstagram,
  faFacebookF,
  faTwitter,
  faDiscord,
  faList,
  faMagnifyingGlass,
  faKey,
  faBook,
  faFileLines,
  faFileImport,
  faNoteSticky,
  faCloudArrowUp,
  faBrush,
  faUnlockKeyhole,
  faLock,
  faFileInvoiceDollar
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
