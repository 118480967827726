import Vue from "vue";
import Vuex from "vuex";

// API Requests
import contacts from "@/store/requests/contacts";
import companies from "@/store/requests/companies";
import customFields from "@/store/requests/customFields";
import customer from "@/store/requests/customer";
import notes from "@/store/requests/notes";
import files from "@/store/requests/files";
import webforms from "@/store/requests/webforms";
import returns from "@/store/requests/returns";
import logs from "@/store/requests/logs";
import messages from "@/store/requests/messages";
import tags from "@/store/requests/tags";
import tasks from "@/store/requests/tasks";
import pipelines from "@/store/requests/pipelines";
import auth from "@/store/requests/auth";
import deals from "@/store/requests/deals";
import cep from "@/store/requests/cep";
import exportFiles from "@/store/requests/export";
import users from "@/store/requests/users";
import products from "@/store/requests/products";
import settings from "@/store/requests/settings";
import dashboards from "@/store/requests/dashboards";
import importData from "@/store/requests/import";
import invoices from "@/store/requests/invoices";
import workflows from "@/store/requests/workflows";
import bills from "@/store/requests/bills";
import filters from "@/store/requests/filters";
import nps from "@/store/requests/nps";
import templates from "@/store/requests/templates";
import leads from "@/store/requests/leads";

// Firestore
import notifications from "@/store/firestore/notifications";

// Components
import freeTrialEnded from "@/store/components/freeTrialEnded";
import pendingPayment from "@/store/components/pendingPayment";
import lightbox from "@/store/components/lightbox";
import snackBar from "@/store/components/snackBar";
import upgradePlan from "@/store/components/upgradePlan";
import userSelector from "@/store/components/userSelector";
import installPwa from "@/store/components/installPwa";
import onboarding from "@/store/components/onboarding";
import queryParams from "@/store/components/queryParams";
import tutorials from "@/store/components/tutorials";

// Plugins
import returnsPlugin from "@/store/plugins/returns";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    lightbox,
    auth,
    contacts,
    companies,
    customFields,
    customer,
    notes,
    files,
    filters,
    webforms,
    returns,
    logs,
    messages,
    tags,
    tasks,
    pipelines,
    deals,
    cep,
    exportFiles,
    users,
    products,
    settings,
    templates,
    leads,
    snackBar,
    dashboards,
    upgradePlan,
    importData,
    invoices,
    workflows,
    bills,
    freeTrialEnded,
    pendingPayment,
    userSelector,
    installPwa,
    notifications,
    onboarding,
    nps,
    queryParams,
    tutorials,
    returnsPlugin
  }
});
