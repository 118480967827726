import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "dashboard-load-error": "Wasn't possible to load dashboard data"
  },
  "pt-BR": {
    "dashboard-load-error": "Não foi possível carregar os dados"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    deals: {},
    sales: {},
    tasks: {},
    financial: {},
    dashboardFilter: {
      pipeline_id: 0,
      from_date: "",
      to_date: ""
    }
  },
  getters: {
    getDealsDashboard: state => state.deals,
    getSalesDashboard: state => state.sales,
    getTasksDashboard: state => state.tasks,
    getFinancialDashboard: state => state.financial,
    getDashboardFilter: state => state.dashboardFilter
  },
  mutations: {
    setDealsDashboard: (state, payload) => {
      state.deals = payload;
    },
    setSalesDashboard: (state, payload) => {
      state.sales = payload;
    },
    setTasksDashboard: (state, payload) => {
      state.tasks = payload;
    },
    setFinancialDashboard: (state, payload) => {
      state.financial = payload;
    },
    setDashboardFilter: (state, payload) => {
      state.dashboardFilter = payload;
    }
  },
  actions: {
    dealsDashboardRequest({ commit, getters }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/dashboards/cards`, {
            params: getters.getDashboardFilter
          })
          .then(resp => {
            let response = { ...resp.data };

            const dashboardColors = [
              "#9BA1FD",
              "#92DBB0",
              "#8FC8FF",
              "#FFA3AB",
              "#FFD281",
              "#FFB1A4"
            ];

            let lineChart = {
              labels: [],
              dealData: [],
              winData: [],
              lossData: []
            };

            response.deals_per_day.forEach(e => {
              lineChart.labels.push(
                Vue.options.filters.brDateTextWithoutYear(e.date)
              );
              lineChart.dealData.push(e.total);
              lineChart.winData.push(e.wins);
              lineChart.lossData.push(e.losses);
            });

            let barChart = {
              labels: [],
              dealData: [],
              winData: [],
              lossData: []
            };

            response.deals_per_user.forEach(e => {
              barChart.labels.push(e.user);
              barChart.dealData.push(e.total);
              barChart.winData.push(e.wins);
              barChart.lossData.push(e.losses);
            });

            let dealsPerProduct = {
              data: [],
              labels: [],
              colors: []
            };

            let aux = 0;

            const sorteredDealsPerProduct = response.deals_per_product.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredDealsPerProduct = [];

            let othersProductTotal = 0;

            sorteredDealsPerProduct.forEach((e, i) => {
              if (i < 14) {
                filteredDealsPerProduct.push(e);
              } else {
                othersProductTotal += e.total;
              }
            });

            filteredDealsPerProduct.push({
              product: "Outros",
              total: othersProductTotal
            });

            filteredDealsPerProduct
              .filter(e => e.total > 0)
              .forEach(e => {
                dealsPerProduct.data.push(e.total);
                dealsPerProduct.labels.push(e.product);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.product === "Outros") {
                  dealsPerProduct.colors.push("#dcdfea");
                } else {
                  dealsPerProduct.colors.push(color);
                }
              });

            let dealsPerCompany = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredDealsPerCompany = response.deals_per_company.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredDealsPerCompany = [];

            let othersCompanyTotal = 0;

            sorteredDealsPerCompany.forEach((e, i) => {
              if (i < 14) {
                filteredDealsPerCompany.push(e);
              } else {
                othersCompanyTotal += e.total;
              }
            });

            filteredDealsPerCompany.push({
              company: "Outras",
              total: othersCompanyTotal
            });

            filteredDealsPerCompany
              .filter(e => e.total > 0)
              .forEach(e => {
                dealsPerCompany.data.push(e.total);
                dealsPerCompany.labels.push(e.company);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.company === "Outras") {
                  dealsPerCompany.colors.push("#dcdfea");
                } else {
                  dealsPerCompany.colors.push(color);
                }
              });

            let dealsPerTag = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredDealsPerTag = response.deals_per_tag.sort((a, b) => {
              return Number(a.total) < Number(b.total) ? 1 : -1;
            });

            let filteredDealsPerTag = [];

            let othersTagTotal = 0;

            sorteredDealsPerTag.forEach((e, i) => {
              if (i < 14) {
                filteredDealsPerTag.push(e);
              } else {
                othersTagTotal += e.total;
              }
            });

            filteredDealsPerTag.push({
              tag: "Outras",
              total: othersTagTotal
            });

            filteredDealsPerTag
              .filter(e => e.total > 0)
              .forEach(e => {
                dealsPerTag.data.push(e.total);
                dealsPerTag.labels.push(e.tag);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.tag === "Outras") {
                  dealsPerTag.colors.push("#dcdfea");
                } else {
                  dealsPerTag.colors.push(color);
                }
              });

            let dealsPerLostReason = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredDealsPerLostReason = response.deals_per_lost_reason.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredDealsPerLostReason = [];

            let othersLostReasonTotal = 0;

            sorteredDealsPerLostReason.forEach((e, i) => {
              if (i < 14) {
                filteredDealsPerLostReason.push(e);
              } else {
                othersLostReasonTotal += e.total;
              }
            });

            filteredDealsPerLostReason.push({
              lost_reason: "Outros",
              total: othersLostReasonTotal
            });

            filteredDealsPerLostReason
              .filter(e => e.total > 0)
              .forEach(e => {
                dealsPerLostReason.data.push(e.total);
                dealsPerLostReason.labels.push(e.lost_reason);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.lost_reason === "Outros") {
                  dealsPerLostReason.colors.push("#dcdfea");
                } else {
                  dealsPerLostReason.colors.push(color);
                }
              });

            response.lineChart = lineChart;
            response.barChart = barChart;
            response.dealsPerProduct = dealsPerProduct;
            response.dealsPerCompany = dealsPerCompany;
            response.dealsPerTag = dealsPerTag;
            response.dealsPerLostReason = dealsPerLostReason;

            let winDays = Math.floor(Number(response.avarage_time_to_win) / 24);
            let winHours = Number(response.avarage_time_to_win) % 24;
            response.avarage_time_to_win =
              winDays > 0 ? `${winDays}d${winHours}h` : `${winHours}h`;
            let loseDays = Math.floor(
              Number(response.avarage_time_to_lose) / 24
            );
            let loseHours = Number(response.avarage_time_to_lose) % 24;
            response.avarage_time_to_lose =
              loseDays > 0 ? `${loseDays}d${loseHours}h` : `${loseHours}h`;

            commit("setDealsDashboard", response);

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("dashboard-load-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    salesDashboardRequest({ commit, getters }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/dashboards/sales`, {
            params: getters.getDashboardFilter
          })
          .then(resp => {
            let response = { ...resp.data };

            const dashboardColors = [
              "#9BA1FD",
              "#92DBB0",
              "#8FC8FF",
              "#FFA3AB",
              "#FFD281",
              "#FFB1A4"
            ];

            let lineChart = {
              labels: [],
              amountData: []
            };

            response.amount_per_day.forEach(e => {
              lineChart.labels.push(
                Vue.options.filters.brDateTextWithoutYear(e.date)
              );
              lineChart.amountData.push(
                Vue.options.filters.valueToReais(e.total)
              );
            });

            let amountPerProduct = {
              data: [],
              labels: [],
              colors: []
            };

            let aux = 0;

            const sorteredAmountPerProduct = response.amount_per_product.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerProduct = [];

            let othersProductTotal = 0;

            sorteredAmountPerProduct.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerProduct.push(e);
              } else {
                othersProductTotal += e.total;
              }
            });

            filteredAmountPerProduct.push({
              product: "Outros",
              total: othersProductTotal
            });

            filteredAmountPerProduct
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerProduct.data.push(e.total);
                amountPerProduct.labels.push(e.product);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.product === "Outros") {
                  amountPerProduct.colors.push("#dcdfea");
                } else {
                  amountPerProduct.colors.push(color);
                }
              });

            let amountPerCompany = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerCompany = response.amount_per_company.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerCompany = [];

            let othersCompanyTotal = 0;

            sorteredAmountPerCompany.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerCompany.push(e);
              } else {
                othersCompanyTotal += e.total;
              }
            });

            filteredAmountPerCompany.push({
              company: "Outras",
              total: othersCompanyTotal
            });

            filteredAmountPerCompany
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerCompany.data.push(e.total);
                amountPerCompany.labels.push(e.company);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.company === "Outras") {
                  amountPerCompany.colors.push("#dcdfea");
                } else {
                  amountPerCompany.colors.push(color);
                }
              });

            let amountPerTag = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerTag = response.amount_per_tag.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerTag = [];

            let othersTagTotal = 0;

            sorteredAmountPerTag.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerTag.push(e);
              } else {
                othersTagTotal += e.total;
              }
            });

            filteredAmountPerTag.push({
              tag: "Outras",
              total: othersTagTotal
            });

            filteredAmountPerTag
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerTag.data.push(e.total);
                amountPerTag.labels.push(e.tag);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.tag === "Outras") {
                  amountPerTag.colors.push("#dcdfea");
                } else {
                  amountPerTag.colors.push(color);
                }
              });

            let amountPerUser = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerUser = response.amount_per_user.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerUser = [];

            let othersUserTotal = 0;

            sorteredAmountPerUser.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerUser.push(e);
              } else {
                othersUserTotal += e.total;
              }
            });

            filteredAmountPerUser.push({
              user: "Outras",
              total: othersUserTotal
            });

            filteredAmountPerUser
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerUser.data.push(e.total);
                amountPerUser.labels.push(e.user);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.user === "Outras") {
                  amountPerUser.colors.push("#dcdfea");
                } else {
                  amountPerUser.colors.push(color);
                }
              });

            let winDays = Math.floor(Number(response.avarage_time_to_win) / 24);
            let winHours = Number(response.avarage_time_to_win) % 24;
            response.avarage_time_to_win =
              winDays > 0 ? `${winDays}d${winHours}h` : `${winHours}h`;
            let loseDays = Math.floor(
              Number(response.avarage_time_to_lose) / 24
            );
            let loseHours = Number(response.avarage_time_to_lose) % 24;
            response.avarage_time_to_lose =
              loseDays > 0 ? `${loseDays}d${loseHours}h` : `${loseHours}h`;

            response.lineChart = lineChart;
            response.amountPerProduct = amountPerProduct;
            response.amountPerCompany = amountPerCompany;
            response.amountPerTag = amountPerTag;
            response.amountPerUser = amountPerUser;

            commit("setSalesDashboard", response);

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("dashboard-load-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    tasksDashboardRequest({ commit, getters }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/dashboards/tasks`, {
            params: getters.getDashboardFilter
          })
          .then(resp => {
            let response = { ...resp.data };

            const dashboardColors = [
              "#9BA1FD",
              "#92DBB0",
              "#8FC8FF",
              "#FFA3AB",
              "#FFD281",
              "#FFB1A4"
            ];

            let barChart = {
              labels: [],
              finished: [],
              pending: [],
              overdue: []
            };

            response.total_status_per_responsible.forEach(e => {
              barChart.labels.push(e.responsible_user);
              barChart.finished.push(e.total_finished);
              barChart.pending.push(e.total_pending);
              barChart.overdue.push(e.total_overdue);
            });

            let table = {
              labels: [],
              avarageTimeToFinish: []
            };

            response.total_status_per_responsible.forEach(e => {
              table.labels.push(e.responsible_user);
              table.avarageTimeToFinish.push(e.avarage_time_to_finish);
            });

            let totalPerType = {
              data: [],
              labels: [],
              colors: []
            };

            let aux = 0;

            const sorteredTotalPerType = response.total_per_type.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredTotalPerType = [];

            let othersTotalPerType = 0;

            sorteredTotalPerType.forEach((e, i) => {
              if (i < 14) {
                filteredTotalPerType.push(e);
              } else {
                othersTotalPerType += e.total;
              }
            });

            filteredTotalPerType.push({
              task_name: "Outros",
              total: othersTotalPerType
            });

            filteredTotalPerType
              .filter(e => e.total > 0)
              .forEach(e => {
                totalPerType.data.push(e.total);
                totalPerType.labels.push(e.task_name);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.task_name === "Outros") {
                  totalPerType.colors.push("#dcdfea");
                } else {
                  totalPerType.colors.push(color);
                }
              });

            let totalPerResponsible = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredTotalPerResponsible = response.total_per_responsible.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredTotalPerResponsible = [];

            let othersTotalPerResponsible = 0;

            sorteredTotalPerResponsible.forEach((e, i) => {
              if (i < 14) {
                filteredTotalPerResponsible.push(e);
              } else {
                othersTotalPerResponsible += e.total;
              }
            });

            filteredTotalPerResponsible.push({
              responsible_user: "Outras",
              total: othersTotalPerResponsible
            });

            filteredTotalPerResponsible
              .filter(e => e.total > 0)
              .forEach(e => {
                totalPerResponsible.data.push(e.total);
                totalPerResponsible.labels.push(e.responsible_user);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.responsible_user === "Outras") {
                  totalPerResponsible.colors.push("#dcdfea");
                } else {
                  totalPerResponsible.colors.push(color);
                }
              });

            let totalPerCreator = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredTotalPerCreator = response.total_per_creator.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredTotalPerCreator = [];

            let othersTotalPerCreator = 0;

            sorteredTotalPerCreator.forEach((e, i) => {
              if (i < 14) {
                filteredTotalPerCreator.push(e);
              } else {
                othersTotalPerCreator += e.total;
              }
            });

            filteredTotalPerCreator.push({
              responsible_user: "Outras",
              total: othersTotalPerCreator
            });

            filteredTotalPerCreator
              .filter(e => e.total > 0)
              .forEach(e => {
                totalPerCreator.data.push(e.total);
                totalPerCreator.labels.push(e.responsible_user);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.responsible_user === "Outras") {
                  totalPerCreator.colors.push("#dcdfea");
                } else {
                  totalPerCreator.colors.push(color);
                }
              });

            let totalPerSource = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredTotalPerSource = response.total_per_source.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredTotalPerSource = [];

            let othersTotalPerSource = 0;

            sorteredTotalPerSource.forEach((e, i) => {
              if (i < 14) {
                filteredTotalPerSource.push(e);
              } else {
                othersTotalPerSource += e.total;
              }
            });

            filteredTotalPerSource.push({
              source: "Outras",
              total: othersTotalPerSource
            });

            filteredTotalPerSource
              .filter(e => e.total > 0)
              .forEach(e => {
                totalPerSource.data.push(e.total);
                totalPerSource.labels.push(e.source);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.source === "Outras") {
                  totalPerSource.colors.push("#dcdfea");
                } else {
                  totalPerSource.colors.push(color);
                }
              });

            response.barChart = barChart;
            response.table = table;
            response.totalPerType = totalPerType;
            response.totalPerResponsible = totalPerResponsible;
            response.totalPerCreator = totalPerCreator;
            response.totalPerSource = totalPerSource;

            let winDays = Math.floor(
              Number(response.avarage_time_to_finish) / 24
            );
            let winHours = Number(response.avarage_time_to_finish) % 24;
            response.avarage_time_to_finish =
              winDays > 0 ? `${winDays}d${winHours}h` : `${winHours}h`;

            commit("setTasksDashboard", response);

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("dashboard-load-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    financialDashboardRequest({ commit, getters }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/dashboards/financial`, {
            params: getters.getDashboardFilter
          })
          .then(resp => {
            let response = { ...resp.data };

            const dashboardColors = [
              "#9BA1FD",
              "#92DBB0",
              "#8FC8FF",
              "#FFA3AB",
              "#FFD281",
              "#FFB1A4"
            ];

            let barChart = {
              labels: [],
              revenue: [],
              expense: [],
              balance: []
            };

            response.cashflow.forEach(e => {
              barChart.labels.push(e.month);
              barChart.revenue.push(e.revenue);
              barChart.expense.push(e.expense);
              barChart.balance.push(e.balance);
            });

            let table = {
              labels: [],
              revenue: [],
              expense: [],
              balance: []
            };

            response.cashflow.forEach(e => {
              table.labels.push(e.month);
              table.revenue.push(Vue.options.filters.valueToReais(e.revenue));
              table.expense.push(Vue.options.filters.valueToReais(e.expense));
              table.balance.push(Vue.options.filters.valueToReais(e.balance));
            });

            let amountPerCostCenter = {
              data: [],
              labels: [],
              colors: []
            };

            let aux = 0;

            const sorteredAmountPerCostCenter = response.amount_per_costcenter.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerCostCenter = [];

            let othersAmountPerCostCenter = 0;

            sorteredAmountPerCostCenter.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerCostCenter.push(e);
              } else {
                othersAmountPerCostCenter += e.total;
              }
            });

            filteredAmountPerCostCenter.push({
              cost_center: "Outros",
              total: othersAmountPerCostCenter
            });

            filteredAmountPerCostCenter
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerCostCenter.data.push(e.total);
                amountPerCostCenter.labels.push(e.cost_center);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.cost_center === "Outros") {
                  amountPerCostCenter.colors.push("#dcdfea");
                } else {
                  amountPerCostCenter.colors.push(color);
                }
              });

            let amountPerType = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerType = response.amount_per_type.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerType = [];

            let othersTypeTotal = 0;

            sorteredAmountPerType.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerType.push(e);
              } else {
                othersTypeTotal += e.total;
              }
            });

            filteredAmountPerType.push({
              type: "Outros",
              total: othersTypeTotal
            });

            filteredAmountPerType
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerType.data.push(e.total);
                amountPerType.labels.push(e.type);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.type === "Outros") {
                  amountPerType.colors.push("#dcdfea");
                } else {
                  amountPerType.colors.push(color);
                }
              });

            let amountPerMethod = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerMethod = response.amount_per_method.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerMethod = [];

            let othersMethodTotal = 0;

            sorteredAmountPerMethod.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerMethod.push(e);
              } else {
                othersMethodTotal += e.total;
              }
            });

            filteredAmountPerMethod.push({
              method: "Outras",
              total: othersMethodTotal
            });

            filteredAmountPerMethod
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerMethod.data.push(e.total);
                amountPerMethod.labels.push(e.method);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.method === "Outras") {
                  amountPerMethod.colors.push("#dcdfea");
                } else {
                  amountPerMethod.colors.push(color);
                }
              });

            let amountPerStatus = {
              data: [],
              labels: [],
              colors: []
            };

            const sorteredAmountPerStatus = response.amount_per_status.sort(
              (a, b) => {
                return Number(a.total) < Number(b.total) ? 1 : -1;
              }
            );

            let filteredAmountPerStatus = [];

            let othersStatusTotal = 0;

            sorteredAmountPerStatus.forEach((e, i) => {
              if (i < 14) {
                filteredAmountPerStatus.push(e);
              } else {
                othersStatusTotal += e.total;
              }
            });

            filteredAmountPerStatus.push({
              status: "Outras",
              total: othersStatusTotal
            });

            filteredAmountPerStatus
              .filter(e => e.total > 0)
              .forEach(e => {
                amountPerStatus.data.push(e.total);
                amountPerStatus.labels.push(e.status);
                const color = dashboardColors[aux];
                aux++;
                if (aux >= dashboardColors.length) {
                  aux = 0;
                }
                if (e.status === "Outras") {
                  amountPerStatus.colors.push("#dcdfea");
                } else {
                  amountPerStatus.colors.push(color);
                }
              });

            response.table = table;
            response.barChart = barChart;
            response.amountPerCostCenter = amountPerCostCenter;
            response.amountPerType = amountPerType;
            response.amountPerMethod = amountPerMethod;
            response.amountPerStatus = amountPerStatus;

            commit("setFinancialDashboard", response);

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("dashboard-load-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
