import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "nps-error": "Wasn't possible check the CEP!"
  },
  "pt-BR": {
    "nps-error": "Não foi possível verificar o CEP!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    nps: {}
  },
  getters: {
    getNps: state => state.nps
  },
  mutations: {
    setNps: (state, payload) => {
      state.nps = payload;
    }
  },
  actions: {
    npsRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/nps`)
          .then(resp => {
            const nps = resp.data;

            commit("setNps", nps);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    sendNpsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/nps`, payload)
          .then(resp => {
            const nps = resp.data;

            commit("setNps", nps);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    }
  }
};
