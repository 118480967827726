export default {
  state: {
    onboardingOpened: false
  },
  getters: {
    getOnboardingOpened: state => state.onboardingOpened
  },
  mutations: {
    setOnboardingOpened: (state, payload) => {
      state.onboardingOpened = payload;
    }
  }
};
