import Vue from "vue";

export default {
  state: {
    tags: []
  },
  getters: {
    getTags: state => state.tags
  },
  mutations: {
    setTags: (state, payload) => {
      state.tags = payload;
    }
  },
  actions: {
    tagsRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cards/tags`)
          .then(resp => {
            const tags = resp.data;

            const filtered = tags.map(e => e.trim());
            const uniques = [...new Set(filtered)];
            commit("setTags", uniques);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    }
  }
};
