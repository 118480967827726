import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "create-invoice-error": "Wasn't possible to finish the plan upgrade!",
    "invoice-error": "Wasn't possible to load the plan upgrade!"
  },
  "pt-BR": {
    "create-invoice-error": "Não foi possível finalizar o pagamento!",
    "invoice-error": "Não foi possível carregar o pagamento!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    invoices: [],
    invoice: {}
  },
  getters: {
    getInvoices: state => state.invoices,
    getInvoice: state => state.invoice
  },
  mutations: {
    setInvoices: (state, payload) => {
      state.invoices = payload;
    },
    setInvoice: (state, payload) => {
      state.invoice = payload;
    }
  },
  actions: {
    invoicesRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customers/invoices`)
          .then(resp => {
            const invoices = resp.data.data;

            commit("setInvoices", invoices);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("invoice-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createInvoiceRequest({ commit }, invoice) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/customers/invoices`, invoice)
          .then(resp => {
            commit("setInvoice", resp);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-invoice-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
