<template>
  <div
    id="layout-default"
    :class="{ 'collapsed-app': collapse, 'without-nav': !mobileHidden }"
  >
    <nav-left @collapse="collapse = $event" v-if="mobileHidden" />
    <div class="we-board">
      <router-view />
    </div>
    <nps v-if="npsOpened" @close="npsOpened = false" />
    <upgrade-plan v-if="upgradePlanOpened" />
    <onboarding />
    <free-trial-ended />
    <pending-payment />
  </div>
</template>

<script>
import NavLeft from "@/components/layouts/NavLeft.vue";
import { mapActions } from "vuex";
import Vue from "vue";
import Tawk from "vue-tawk";
import UpgradePlan from "@/views/Global/UpgradePlan.vue";
import FreeTrialEnded from "@/views/Global/FreeTrialEnded.vue";
import PendingPayment from "@/views/Global/PendingPayment.vue";
import Onboarding from "@/views/Global/Onboarding.vue";
import Nps from "@/views/Global/Nps.vue";

export default {
  components: {
    NavLeft,
    UpgradePlan,
    FreeTrialEnded,
    PendingPayment,
    Onboarding,
    Nps
  },
  data() {
    return {
      collapse: false,
      npsOpened: false
    };
  },
  computed: {
    upgradePlanOpened() {
      return this.$store.getters.getUpgradePlanOpened;
    },
    mobileHidden() {
      if (this.isDesktop) return true;

      return ["pipelines", "tasksList", "records", "profile"].includes(
        this.$route.name
      );
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    customer() {
      return this.$store.getters.getCustomer;
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    queryParams() {
      return this.$store.getters.getQueryParams;
    }
  },
  methods: {
    ...mapActions([
      "loggedUserRequest",
      "customerRequest",
      "realtimeNotifications",
      "customerStatusRequest",
      "npsRequest"
    ]),
    setDefaultCustomerLanguage(customer) {
      const language = customer?.data[0]?.language || null;
      if (language && language.includes("en")) {
        localStorage.setItem("we-locale", "en");
        this.$root.$i18n.locale = "en";
      } else {
        localStorage.setItem("we-locale", "pt-BR");
        this.$root.$i18n.locale = "pt-BR";
      }
    },
    activeTawkTo() {
      if (process.env.NODE_ENV != "production") return;

      if (this.customer?.subscription_plan != "start" && this.isDesktop) {
        Vue.use(Tawk, {
          tawkSrc: "https://embed.tawk.to/6027f328918aa261273e959e/1eue0rm2t"
        });
      }
    },
    async checkNps() {
      const npsResponse = await this.npsRequest();
      if (npsResponse?.response?.status === 404) {
        this.npsOpened = true;
      }
    }
  },
  async mounted() {
    if (!this.loggedUser || Object.keys(this.loggedUser).length === 0) {
      await this.loggedUserRequest();
      this.realtimeNotifications();
    }
    this.customerStatusRequest();
    const customerResponse = await this.customerRequest();
    this.setDefaultCustomerLanguage(customerResponse);
    // this.templatesRequest();
    this.activeTawkTo();
    this.checkNps();

    if (this.$route.query) {
      this.$store.commit("setQueryParams", this.$route.query);
    }

    if (this.queryParams.upgrade_plan === "true") {
      this.$store.commit("openUpgradePlan");
    }
  }
};
</script>

<style lang="scss">
#layout-default {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-1);
  display: grid;
  grid-template-columns: 225px minmax(0, 1fr);

  &.collapsed-app {
    grid-template-columns: 60px minmax(0, 1fr);

    @include mobile {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 1fr) 60px;
    }
  }

  &.without-nav {
    @include mobile {
      grid-template-rows: 1fr;
    }
  }

  @include mobile {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) 60px;
  }

  .we-board {
    width: 100%;
    height: 100%;

    @include mobile {
      grid-row: 1;
    }

    & > div {
      @include mobile {
        display: block;
      }
    }
  }
}
</style>
