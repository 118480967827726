<template>
  <div id="we-label">
    {{ text }}
    <slot v-if="!text" name="text" />
  </div>
</template>

<script>
export default {
  props: {
    text: [String, Number]
  }
};
</script>

<style lang="scss" scoped>
#we-label {
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 1px 6px;
  font-size: 13px;
  color: var(--background-1);
  background: var(--text-2);
  font-weight: $medium;
  text-transform: uppercase;
  max-width: 100%;
  width: fit-content;

  @include mobile {
    height: 17px;
    font-size: 11px;
  }

  &.grey,
  &.gray {
    color: var(--background-1) !important;
  }

  &.rounded-pill {
    padding: 0 12px;
  }

  &.small {
    font-size: 9px;
    line-height: 14px;
    padding: 0 4px;
    height: fit-content;

    &.rounded-pill {
      padding: 0 6px;
    }
  }

  &.medium {
    font-size: 11px;
    line-height: 16px;
    padding: 0 4px;
    height: fit-content;

    &.rounded-pill {
      padding: 0 8px;
    }
  }
}
</style>
