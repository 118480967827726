import store from "@/store";

export default {
  state: {
    pendingPaymentOpened: false
  },
  getters: {
    getPendingPaymentOpened: state => state.pendingPaymentOpened
  },
  mutations: {
    openPendingPayment: state => {
      state.pendingPaymentOpened = true;
    },
    closePendingPayment: state => {
      store.commit("ableSnackBar", true);
      state.pendingPaymentOpened = false;
    }
  }
};
