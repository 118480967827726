<template>
  <div id="we-code-editor">
    <div class="editor-label" v-if="label">
      {{ label }}
    </div>
    <prism-editor
      class="my-editor"
      :value="copyValue"
      :highlight="highlighter"
      @input="changeCode"
      line-numbers
    ></prism-editor>
    <div class="editor-error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "@/styles/prism/dracula.css";

const prettier = require("prettier/standalone");
const plugins = [require("prettier/parser-html")];

export default {
  props: {
    label: String,
    value: [String, Object],
    language: {
      type: String,
      default: "js"
    }
  },
  components: {
    PrismEditor
  },
  data() {
    return {
      checkValue: {},
      error: null,
      copyValue: null
    };
  },
  methods: {
    highlighter(value) {
      return highlight(value, languages[this.language]);
    },
    changeCode(value) {
      this.copyValue = value;
      this.handleError(value);
      this.$emit("input", value);
    },
    handleError(value) {
      if (this.language != "js") return;

      if (this.$options.filters.isJsonString(value)) {
        this.error = null;
      } else {
        this.error = "O JSON não está formatado corretamente";
      }
    },
    format() {
      if (!this.language) return;

      if (this.language === "markup") {
        this.copyValue = prettier.format(this.copyValue, {
          parser: "html",
          plugins
        });

        return;
      }
    }
  },
  mounted() {
    this.copyValue = this.value;

    if (!this.copyValue) return;

    this.handleError(this.copyValue);
    this.format();
  }
};
</script>

<style lang="scss">
#we-code-editor {
  height: fit-content;
  .editor-label {
    font-size: 12px;
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 4px;
    padding-left: 13px;
    text-align: left;
    line-height: 1;
    letter-spacing: initial;
    display: flex;
    align-items: center;
  }
  .my-editor {
    background: #0d0d0d;
    color: var(--white);
    border-radius: 7px;
    font-family: JetBrainsMono, monospace;
    font-size: 0.9rem;
    line-height: 1.8;
    padding: 5px;
    caret-color: var(--white);
  }

  .prism-editor__textarea:focus {
    outline: none;
  }

  .editor-error {
    font-size: 12px;
    padding-left: 12px;
    color: var(--red);
    font-weight: $semibold;
  }
}
</style>
