<i18n>
{
  "en": {
    "title": "Products Calculator",
    "remove": "Remove",
    "total": "Total"
  },
  "pt-BR": {
    "title": "Calculadora de Produtos",
    "remove": "Remover",
    "total": "Total"
  }
}
</i18n>

<template>
  <v-menu
    rounded
    left
    offset-x
    content-class="we-deal-calculator"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on">
        <we-icon-button
          direction="bottom"
          :name="$t('title')"
          icon="calculator"
        />
      </div>
    </template>
    <v-list class="calculator-menu">
      <div class="calculator-head">
        <font-awesome-icon icon="calculator" />
        <div class="we-title">
          {{ $t("title") }}
        </div>
      </div>
      <div class="calculator-body">
        <div class="product-list">
          <div
            class="product-item"
            v-for="(product, i) in products"
            :key="product.id"
          >
            <we-rounded-picture
              :picture="product.picture"
              :name="product.name"
              :text="$options.filters.firstLetters(product.name)"
              type="product"
              size="50"
            />
            <div class="product-content">
              <we-text-tooltip class="product-name" :text="product.name" />
              <div
                class="product-tags"
                v-if="product.tags && product.tags.length > 0"
              >
                <we-label class="gray" v-for="tag in product.tags" :key="tag">
                  <template #text>
                    {{ getTagName(tag) }}
                  </template>
                </we-label>
              </div>
              <div class="product-actions">
                <div class="price-quantity">
                  <div class="product-price">
                    {{ product.price | valueToReais }}
                  </div>
                  <div class="quantity-selector">
                    <div class="quantity-button" @click="sub(product)">
                      <font-awesome-icon icon="minus" />
                    </div>
                    <input
                      type="text"
                      v-maska="'###'"
                      v-model="product.amount"
                      @blur="checkAmount($event, product)"
                    />
                    <div class="quantity-button" @click="add(product)">
                      <font-awesome-icon icon="plus" />
                    </div>
                  </div>
                </div>
                <we-icon-button
                  @click="remove(i)"
                  class="small"
                  :icon="['far', 'trash-alt']"
                  :name="$t('remove')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calculator-bottom">
        <div class="total">
          {{ $t("total") }}:
          <span class="price">{{ total | valueToReais }}</span>
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    products() {
      return this.$store.getters.getCurrentDeal?.products || [];
    },
    total() {
      return this.$store.getters.getCurrentDealProductTotal;
    },
    returnReasons() {
      return this.$store.getters.getReturnReasons;
    }
  },
  methods: {
    getTagName(tag) {
      const isReturnReason = this.returnReasons.some(e => e.value === tag);

      if (isReturnReason) {
        const reason = this.returnReasons.find(e => e.value === tag);

        return reason.text;
      }

      return tag;
    },
    remove(i) {
      this.products.splice(i, 1);
      this.updateComputed();
    },
    add(product) {
      product.amount++;
      this.updateComputed();
    },
    sub(product) {
      if (product.amount > 1) {
        product.amount--;
      }
      this.updateComputed();
    },
    checkAmount(e, product) {
      if (e.target.value <= 0) {
        product.amount = 1;
      }
      this.updateComputed();
    },
    updateComputed() {
      this.$emit("updateProducts", this.products);
      this.$store.commit("setCurrentDealProducts", this.products);
    }
  }
};
</script>

<style lang="scss">
.we-deal-calculator {
  .calculator-menu {
    display: grid;
    grid-template-rows: 51px fit-content(252px) 51px;
    padding: 0;
    width: 356px;

    .calculator-head {
      background: var(--background-2);
      border-bottom: 1px solid var(--line);
      padding: 10px 23px;
      display: flex;
      align-items: center;

      svg {
        height: 20px;
        width: auto;
        margin-right: 12px;
        color: var(--text-2);
      }

      .we-title {
        font-size: 16px;
        color: var(--text-2);
        font-weight: $medium;
      }
    }

    .calculator-body {
      padding: 24px 23px;
      overflow-y: auto;
      .product-list {
        .product-item {
          display: grid;
          grid-template-columns: 60px minmax(0, 1fr);
          gap: 12px;
          margin-bottom: 14px;
          height: fit-content;
          min-height: 60px;

          &:last-child {
            margin-bottom: 0;
          }

          .product-picture {
            width: 100%;
            height: 50px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center center;
            }
          }

          .product-content {
            .product-name {
              font-size: 14px;
              font-weight: $medium;
              color: var(--text-1);
              margin-bottom: 4px;
            }

            .product-tags {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 4px;

              #we-label {
                margin: 0 4px 4px 0;
              }
            }

            .product-actions {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .price-quantity {
                display: flex;
                align-items: center;
              }

              .quantity-selector {
                display: flex;
                align-items: center;
                pointer-events: all;
                border: 1px solid var(--line);
                border-radius: 18px;
                height: 36px;
                overflow: hidden;
                margin-left: 16px;

                &:hover {
                  border-color: var(--text-2);
                }

                .quantity-button {
                  width: 36px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 3px;
                  cursor: pointer;

                  &:hover {
                    background: var(--primary-bg);

                    svg {
                      color: var(--primary);
                    }
                  }

                  svg {
                    width: 11px;
                    height: auto;
                    color: var(--text-2);
                  }
                }

                input {
                  border: none;
                  background: var(--inherit);
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: $semibold;
                  color: var(--text-2);
                  width: 31px;
                  text-align: center;
                  -moz-appearance: textfield;

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  &:focus {
                    border: none;
                    outline: none !important;
                    box-shadow: none !important;
                  }
                }
              }

              .product-price {
                color: var(--green);
                font-weight: $medium;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .calculator-bottom {
      background: var(--background-2);
      border-top: 1px solid var(--line);
      padding: 10px 23px;
      display: flex;
      align-items: center;

      .total {
        font-size: 18px;
        color: var(--text-1);
        text-align: center;
        width: 100%;

        .price {
          color: var(--green);
          font-weight: $medium;
        }
      }
    }
  }
}
</style>
