import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "update-index-success": "Position of element updated with success!",
    "update-index-error": "Wasn't possible to update the position of element!"
  },
  "pt-BR": {
    "update-index-success": "A posição do elemento foi atualizada com sucesso!",
    "update-index-error": "Não foi possível atualizar a posição do elemento!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updateIndexRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/settings`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-index-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-index-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
