import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "pipelines-error": "Wasn't possible to load workflow!",
    "pipeline-error": "Wasn't possible to load workflows!",
    "create-pipeline-success": "Workflow was created with success!",
    "create-pipeline-error": "Wasn't possible to create workflow!",
    "edit-pipeline-success": "Workflow was updated with success",
    "edit-pipeline-error": "Wasn't possible to update workflow!",
    "delete-pipeline-success": "Workflow was deleted with success",
    "delete-pipeline-error": "Wasn't possible to delete workflow!",
    "stages-error": "Wasn't possible to load stages!",
    "stage-error": "Wasn't possible to load stage!",
    "create-stage-success": "Stage was created with success",
    "create-stage-error": "Wasn't possible to create stage!",
    "edit-stage-success": "Stage was updated with success",
    "edit-stage-error": "Wasn't possible to update stage!",
    "delete-stage-success": "Stage was deleted with success",
    "delete-stage-error": "Wasn't possible to delete stage!",
    "stage-deals-error": "Wasn't possible to load cards!"
  },
  "pt-BR": {
    "pipelines-error": "Não foi possível carregar o pipeline!",
    "pipeline-error": "Não foi possível carregar os dados do pipeline!",
    "create-pipeline-success": "O pipeline foi criado com sucesso!",
    "create-pipeline-error": "Não foi possível criar o pipeline!",
    "edit-pipeline-success": "O pipeline foi editado com sucesso!",
    "edit-pipeline-error": "Não foi possível editar o pipeline!",
    "delete-pipeline-success": "O pipeline foi deletado com sucesso!",
    "delete-pipeline-error": "Não foi possível deletar o pipeline!",
    "stages-error": "Não foi possível carregar os estágios!",
    "stage-error": "Não foi possível carregar o estágio!",
    "create-stage-success": "O estágio foi criado com sucesso!",
    "create-stage-error": "Não foi possível criar o estágio!",
    "edit-stage-success": "A ordem dos estágios foi alterada com sucesso!",
    "edit-stage-error": "Não foi possível mudar a ordem dos estágios!",
    "delete-stage-success": "O estágio foi deletado com sucesso!",
    "delete-stage-error": "Não foi possível deletar o estágio!",
    "stage-deals-error": "Não foi possível carregar os cards!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    pipeline: {
      lost_reason_options: []
    },
    pipelines: [],
    pipelinePages: {},
    stage: {},
    stages: [],
    stageDeals: [],
    pipelineFilterResults: 0,
    pipelineFiltered: false,
    currentPipelineIndex: 0,
    stageDealsQuery: null
  },
  getters: {
    getPipeline: state => state.pipeline,
    getPipelines: state => state.pipelines,
    getPipelinesWithoutLost: state => {
      return state.pipelines.map(pipeline => {
        pipeline.stages.pop();
        return pipeline;
      });
    },
    getStage: state => state.stage,
    getStages: state => state.stages,
    getStageDeals: state => state.stageDeals,
    getStageDealsQuery: state => state.stageDealsQuery,
    getPipelineFilterResults: state => state.pipelineFilterResults,
    getPipelineFiltered: state => state.pipelineFiltered,
    getCurrentPipelineIndex: state => state.currentPipelineIndex
  },
  mutations: {
    setPipeline: (state, payload) => {
      state.pipeline = payload;
    },
    setPipelines: (state, payload) => {
      state.pipelines = payload;
    },
    addStageDeals: (state, payload) => {
      state.stageDeals = [...state.stageDeals, payload];

      let counter = 0;

      state.stageDeals.forEach(stage => {
        stage.data.forEach(() => {
          counter++;
        });
      });

      state.pipelineFilterResults = counter > 0 ? counter - 1 : 0;
    },
    addStageDealsPage: (state, payload) => {
      const index = state.stageDeals.findIndex(e => e.id === payload.id);
      state.stageDeals[index].data = [
        ...state.stageDeals[index].data,
        ...payload.data
      ];
    },
    resetStageDeals: (state, id) => {
      const filteredStageDeals = state.stageDeals.filter(e => e.id != id);
      state.stageDeals = filteredStageDeals;
    },
    setStage: (state, payload) => {
      state.stage = payload;
    },
    setStages: (state, payload) => {
      state.stages = payload;
    },
    setStageDealsQuery: (state, payload) => {
      state.stageDealsQuery = payload;
    },
    resetPipelineResults: state => {
      state.pipelineFilterResults = 0;
      state.pipelineFiltered = false;
    },
    setCurrentPipelineIndex: (state, payload) => {
      state.currentPipelineIndex = payload;
    }
  },
  actions: {
    pipelinesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/pipelines`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const pipelines = resp.data;

            pipelines.map(pipeline => {
              if (
                pipeline.schema != null &&
                !("show_deal_contacts" in pipeline.schema)
              ) {
                pipeline.schema.show_deal_contacts = true;
              }

              return pipeline;
            });

            commit("setPipelines", pipelines);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("pipelines-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    pipelineRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/pipelines/${id}`)
          .then(resp => {
            const pipeline = resp.data;

            if (!pipeline.schema) {
              pipeline.schema = {
                show_deal_amount: false,
                show_deal_products: false,
                show_deal_deals: false,
                show_deal_companies: false,
                show_deal_contacts: false
              };
            }

            if (!("show_deal_contacts" in pipeline.schema)) {
              pipeline.schema.show_deal_contacts = true;
            }

            commit("setPipeline", pipeline);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("pipeline-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createPipelineRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/pipelines`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-pipeline-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-pipeline-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    editPipelineRequest({ commit }, payload) {
      if (payload.sum_field.type) {
        payload.sum_field = payload.sum_field.name;
      }

      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/pipelines/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("edit-pipeline-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("edit-pipeline-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deletePipelineRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/pipelines/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-pipeline-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-pipeline-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    stagesRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/pipelines/${id}/stages`)
          .then(resp => {
            let stages = resp.data;
            commit("setStages", stages);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("stages-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    stageRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/pipelines/${payload.pipelineId}/stages/${payload.id}`)
          .then(resp => {
            const stage = resp.data;
            commit("setStage", stage);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("stage-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createStageRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/pipelines/${payload.pipelineId}/stages`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-stage-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-stage-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    editStageRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/pipelines/${payload.pipelineId}/stages/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("edit-stage-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("edit-stage-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteStageRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/pipelines/${payload.pipelineId}/stages/${payload.id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-stage-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-stage-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    stageDealsRequest({ commit, getters }, payload) {
      commit("resetStageDeals", payload.id);
      commit("resetPipelineResults");
      let searchEndpoint = "";
      let defaultParams = {
        page: payload.page
      };
      if (getters.getStageDealsQuery) {
        searchEndpoint = getters.getStageDealsQuery.endpoint;
        defaultParams = {
          ...defaultParams,
          ...getters.getStageDealsQuery.payload
        };

        if (searchEndpoint.includes("/filter")) {
          return new Promise(resolve => {
            Vue.prototype.$http
              .post(
                `/pipelines/${payload.pipelineId}/stages/${payload.id}/cards/filter?paginate=true`,
                { filters: getters.getStageDealsQuery.payload.filters }
              )
              .then(resp => {
                const deals = {
                  ...resp.data,
                  id: payload.id
                };
                commit("addStageDeals", deals);
                resolve(resp);
              })
              .catch(err => {
                const snackBar = {
                  title: i18n.t("stage-deals-error")
                };
                commit("setSnackBar", snackBar);
                commit("addSnackBar");
                resolve(err);
              });
          });
        }
      }
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(
            `/pipelines/${payload.pipelineId}/stages/${payload.id}/cards${searchEndpoint}`,
            {
              params: {
                ...defaultParams
              }
            }
          )
          .then(resp => {
            const deals = {
              ...resp.data,
              id: payload.id
            };
            commit("addStageDeals", deals);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("stage-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    stageDealsPageRequest({ commit, getters }, payload) {
      let searchEndpoint = "";
      let defaultParams = {
        page: payload.page
      };
      if (getters.getStageDealsQuery) {
        searchEndpoint = getters.getStageDealsQuery.endpoint;
        defaultParams = {
          ...defaultParams,
          ...getters.getStageDealsQuery.payload
        };

        if (searchEndpoint.includes("/filter")) {
          return new Promise(resolve => {
            Vue.prototype.$http
              .post(
                `/pipelines/${payload.pipelineId}/stages/${payload.id}/cards/filter?paginate=true&page=${payload.page}`,
                {
                  filters: getters.getStageDealsQuery.payload.filters
                }
              )
              .then(resp => {
                const deals = {
                  ...resp.data,
                  id: payload.id
                };
                commit("addStageDealsPage", deals);
                resolve(resp);
              })
              .catch(err => {
                const snackBar = {
                  title: i18n.t("stage-deals-error")
                };
                commit("setSnackBar", snackBar);
                commit("addSnackBar");
                resolve(err);
              });
          });
        }
      }
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(
            `/pipelines/${payload.pipelineId}/stages/${payload.id}/cards${searchEndpoint}`,
            {
              params: {
                ...defaultParams
              }
            }
          )
          .then(resp => {
            const deals = {
              ...resp.data,
              id: payload.id
            };
            commit("addStageDealsPage", deals);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("stage-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
