<i18n>
{
  "en": {
    "title": "Change password",
    "input-1": "Code from email",
    "input-2": "New password",
    "input-3": "Confirm new password",
    "update": "Update password",
    "rule-1": "The code has to be 6 chars or numbers",
    "rule-2": "At least 6 chars",
    "rule-3": "Passwords don't match"
  },
  "pt-BR": {
    "title": "Alterar senha",
    "input-1": "Código",
    "input-2": "Nova senha",
    "input-3": "Confirme a nova senha",
    "update": "Salvar nova senha",
    "rule-1": "O código deve possuir 6 caracteres ou números",
    "rule-2": "Mínimo 6 caracteres",
    "rule-3": "As senhas não conferem"
  }
}
</i18n>

<template>
  <modal-action @close="closeChangePassword" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="key" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeChangePassword" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form">
            <div class="fields">
              <we-input
                type="text"
                :label="$t('input-1')"
                :rules="codeRules"
                mask="XXXXXX"
                is-required
                v-model="recovery.password_code"
              />
              <we-input
                :label="$t('input-2')"
                type="password"
                is-required
                v-model="recovery.password"
              />
              <we-input
                :label="$t('input-3')"
                type="password"
                is-required
                :rules="confirmPasswordRules"
                v-model="passwordConfirm"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('update')"
            :loading="loadingBtn"
            @click="createUser"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loadingBtn: false,
      loading: true,
      codeRules: [
        password_code =>
          (password_code && password_code.length == 6) || this.$t("rule-1")
      ],
      passwordRules: [
        password => (password && password.length >= 6) || this.$t("rule-2")
      ],
      confirmPasswordRules: [
        confirmPassword =>
          confirmPassword === this.recovery.password || this.$t("rule-3")
      ],
      recovery: {
        password_code: "",
        password: ""
      },
      passwordConfirm: ""
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    domain() {
      return this.$store.getters.getDomain;
    }
  },
  methods: {
    ...mapActions(["recoveryCodeRequest", "updatePasswordRequest"]),
    closeChangePassword() {
      this.$emit("close");
    },
    async createUser() {
      this.loadingBtn = true;

      const validated = this.$refs.form.validate();

      if (validated) {
        this.$store.commit("setCurrentUser", this.loggedUser);

        let formData = new FormData();

        for (var e in this.recovery) {
          formData.append(e, this.recovery[e]);
        }

        const response = await this.updatePasswordRequest(formData);

        if (response?.status === 200) {
          await this.$store.dispatch("authLogout");
          this.$router.push({
            name: "login",
            params: { company: this.domain },
            query: { email: this.loggedUser.email }
          });
        }
      }

      this.loadingBtn = false;
    }
  },
  async mounted() {
    let formData = new FormData();
    formData.append("email", this.loggedUser.email);
    formData.append("domain", this.domain);
    await this.recoveryCodeRequest(formData);

    this.loading = false;
  }
};
</script>
