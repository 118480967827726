import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "task-error": "Wasn't possible to load task types!",
    "create-task-success": "Task created with success!",
    "create-task-error": "Wasn't possible to create task!",
    "edit-task-success": "Task updated with success!",
    "edit-task-error": "Wasn't possible to update task!",
    "delete-task-success": "Task deleted with success!",
    "delete-task-error": "Wasn't possible to delete task!",
    "filter-tasks-error": "Wasn't possible to filter tasks!"
  },
  "pt-BR": {
    "task-error": "Não foi possível carregar os tipos de tarefa!",
    "create-task-success": "A tarefa foi criado com sucesso!",
    "create-task-error": "Não foi possível criar a tarefa!",
    "edit-task-success": "A tarefa foi atualizada com sucesso!",
    "edit-task-error": "Não foi possível atualizar a tarefa!",
    "delete-task-success": "A tarefa foi deletada com sucesso!",
    "delete-task-error": "Não foi possível deletar a tarefa!",
    "filter-tasks-error": "Não foi possível realizar o filtro!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    tasks: [],
    tasksPages: {
      last: 1
    },
    tasksSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    task: {},
    calendarTasks: [],
    filteredTasks: [],
    tasksFilterParams: [],
    calendarViewDate: []
  },
  getters: {
    getTasks: state => state.tasks,
    getTasksPages: state => state.tasksPages,
    getTasksSort: state => state.tasksSort,
    getTask: state => state.task,
    getCalendarTasks: state => state.calendarTasks,
    getFilteredTasks: state => state.filteredTasks,
    getTasksFilterParams: state => state.tasksFilterParams,
    getCalendarViewDate: state => state.calendarViewDate
  },
  mutations: {
    setTasks: (state, payload) => {
      state.tasks = payload;
    },
    setTasksPages: (state, payload) => {
      state.tasksPages = payload;
    },
    setTasksSort: (state, payload) => {
      state.tasksSort = payload;
    },
    setTask: (state, payload) => {
      state.task = payload;
    },
    setCalendarTasks: (state, payload) => {
      state.calendarTasks = payload;
    },
    setFilteredTasks: (state, payload) => {
      state.filteredTasks = payload.map(e => {
        if (e.name?.length <= 0 && e.task_type?.name?.length <= 0) {
          e.name = e.task_type.name;
        }

        if (e.task_type === null) {
          e.task_type = {
            color: "grey",
            id: 3,
            name: "Tipo de tarefa excluído"
          };
        }

        return e;
      });
    },
    setTasksFilterParams: (state, payload) => {
      state.tasksFilterParams = payload;
    },
    setCalendarViewDate: (state, payload) => {
      state.calendarViewDate = payload;
    }
  },
  actions: {
    tasksRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/taskTypes`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const tasks = resp.data;

            commit("setTasks", tasks);
            resolve(resp);
          });
      });
    },
    calendarTasksRequest({ commit, getters }, payload) {
      let filters = payload?.filters ? payload?.filters : [];

      const exportParams = {
        endpoint: "/tasks/export",
        entity: "tasks",
        payload: { filters: filters }
      };
      commit("setExportParams", exportParams);

      filters = [...filters, ...getters.getCalendarViewDate];

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/tasks/filter?paginate=false`, {
            filters: filters
          })
          .then(resp => {
            const calendarTasks = resp.data
              .filter(e => e.task_type)
              .map(e => {
                e.color = e.task_type.color;

                if (e.name?.length <= 0) {
                  e.name = e.task_type.name;
                }

                if (
                  e.source?.length > 0 &&
                  e.source != "other" &&
                  e.source != "wepipe"
                ) {
                  e.color = "grey lighten-5";
                }

                e.startHour = Vue.options.filters.dealTaskHour(e.start);

                return e;
              });

            commit("setCalendarTasks", calendarTasks);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-tasks-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    taskRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/taskTypes/${id}`)
          .then(resp => {
            const task = resp.data;

            commit("setTask", task);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("task-types-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createTaskRequest({ commit }, task) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/taskTypes`, task)
          .then(resp => {
            const snackBar = {
              title: i18n.t("create-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    editTaskRequest({ commit }, task) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/taskTypes/${task.id}`, task)
          .then(resp => {
            const snackBar = {
              title: i18n.t("edit-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("edit-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteTaskRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/taskTypes/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-task-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-task-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterFiltersRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/filters/export",
        entity: "filters",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/filters/filter?paginate=true`, payload)
          .then(resp => {
            const tasks = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setTasksPages", page);
            commit("setFilteredTasks", tasks);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-tasks-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterTasksRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/tasks/export",
        entity: "tasks",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/tasks/filter?paginate=true`, payload)
          .then(resp => {
            const tasks = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setTasksPages", page);
            commit("setFilteredTasks", tasks);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-tasks-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
