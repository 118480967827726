export default {
  state: {
    usersIdSelected: []
  },
  getters: {
    getUsersIdSelected: state => state.usersIdSelected
  },
  mutations: {
    setUsersSelected: (state, payload) => {
      if (state.usersIdSelected.includes(payload)) {
        state.usersIdSelected.splice(
          state.usersIdSelected.findIndex(e => e === payload),
          1
        );
      } else {
        state.usersIdSelected = [...state.usersIdSelected, payload];
      }
    },
    resetUsersSelected: state => {
      state.usersIdSelected = [];
    }
  }
};
