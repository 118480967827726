<i18n>
{
  "en": {
    "close": "Close",
    "open-original": "Open original",
    "prev": "Previous",
    "next": "Next"
  },
  "pt-BR": {
    "close": "Fechar",
    "open-original": "Abrir original",
    "prev": "Anterior",
    "next": "Próxima"
  }
}
</i18n>

<template>
  <div id="we-lightbox" v-if="opened">
    <div class="resizable">
      <VueDragResize
        :isActive="false"
        :w="isDesktop ? 500 : 300"
        :h="isDesktop ? 500 : 300"
        :x="15"
        :y="15"
        :minw="200"
        :minh="200"
        v-on:resizing="resize"
        v-on:dragging="resize"
      >
        <we-icon-button
          class="close"
          :name="$t('close')"
          icon="times"
          @click="close"
        />
        <picture v-if="fileIsImage(selectedPicture)">
          <img :src="selectedPicture.url" :alt="selectedPicture.name" />
        </picture>
        <div v-else class="file-extension">
          {{ selectedPicture.name | fileExtension }}
          <we-button
            class="primary"
            :text="$t('open-original')"
            icon="external-link-alt"
            @click="openOriginal(selectedPicture)"
          />
        </div>
      </VueDragResize>
    </div>
    <div class="lightbox-menu">
      <div class="actions">
        <div class="quantity">
          <we-icon-button
            @click="prev"
            :name="$t('prev')"
            icon="chevron-left"
            iconSize="small"
          />
          <div class="number">{{ selected + 1 }} / {{ max }}</div>
          <we-icon-button
            @click="next"
            :name="$t('next')"
            icon="chevron-right"
            iconSize="small"
          />
        </div>
        <we-icon-button
          class="action"
          :name="$t('open-original')"
          icon="external-link-alt"
          @click="openOriginal(selectedPicture)"
        />
        <we-icon-button
          class="action"
          :name="$t('close')"
          icon="times"
          @click="close"
        />
      </div>
      <div class="infos">
        <we-text-tooltip class="name" :text="selectedPicture.name" />
        <div class="more-infos">
          <we-label class="gray">
            <template #text>
              {{ selectedPicture.size | fileSize }}
            </template>
          </we-label>
          <we-label class="primary">
            <template #text>
              {{ selectedPicture.name | fileExtension }}
            </template>
          </we-label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
export default {
  components: {
    VueDragResize
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0
    };
  },
  computed: {
    opened() {
      return this.$store.getters.getLightboxOpened;
    },
    lightbox() {
      return this.$store.getters.getLightbox;
    },
    max() {
      return this.lightbox?.length || 0;
    },
    selectedPicture() {
      return this.lightbox[this.selected] || {};
    },
    selected() {
      return this.$store.getters.getLightboxSelected;
    }
  },
  methods: {
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    next() {
      if (this.selected < this.max - 1) {
        this.$store.commit("setLightboxSelected", this.selected + 1);
      } else {
        this.$store.commit("setLightboxSelected", 0);
      }
    },
    prev() {
      if (this.selected > 0) {
        this.$store.commit("setLightboxSelected", this.selected - 1);
      } else {
        this.$store.commit("setLightboxSelected", this.lightbox.length - 1);
      }
    },
    openOriginal(file) {
      window.open(file.url, "_blank");
    },
    close() {
      this.$store.commit("setLightboxOpened", false);
    },
    fileIsImage(file) {
      const extension = this.$options.filters.fileExtension(file.name);

      return ["png", "jpeg", "jpg", "gif", "webp", "svg"].includes(extension);
    }
  },
  created() {
    document.addEventListener("keydown", event => {
      const keyName = event.key;

      if (keyName === "ArrowRight") {
        this.next();
      }

      if (keyName === "ArrowLeft") {
        this.prev();
      }

      if (keyName === "Escape") {
        this.close();
      }
    });
  },
  destroyed() {
    document.removeEventListener("keydown", event => {
      const keyName = event.key;

      if (keyName === "ArrowRight") {
        this.next();
      }

      if (keyName === "ArrowLeft") {
        this.prev();
      }

      if (keyName === "Escape") {
        this.close();
      }
    });
  }
};
</script>

<style lang="scss">
#we-lightbox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999998;

  .resizable {
    pointer-events: all;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .vdr {
      background: var(--background-1-hover);
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    }

    picture {
      width: 100%;
      height: 100%;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }

    .file-extension {
      background: var(--background-1-hover);
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: $semibold;
      color: var(--text-2);

      .we-button {
        margin-top: 8px;
      }
    }
  }

  .lightbox-menu {
    position: fixed;
    bottom: 15px;
    left: 15px;
    background: var(--background-1);
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    padding: 10px 18px;
    pointer-events: all;
    max-width: 268px;

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .quantity {
        display: flex;
        align-items: center;

        .number {
          font-weight: $medium;
          color: var(--text-2);
          width: 60px;
          text-align: center;
        }
      }

      .action {
        margin-left: 6px;
      }
    }

    .infos {
      .name {
        font-size: 13px;
        color: var(--text-2);
        margin-bottom: 3px;
        font-weight: $semibold;
        display: block;
        max-height: 19px;
        overflow: hidden;
      }

      .more-infos {
        display: flex;
        flex-wrap: wrap;

        #we-label {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
