<i18n>
{
  "en": {
    "show": "Show",
    "activity-1": "Notes",
    "activity-2": "Logs",
    "activity-3": "Leads",
    "activity-4": "Contacts",
    "activity-5": "Emails",
    "delete": "Delete",
    "note": "note",
    "type-something": "Type something",
    "cancel": "Cancel",
    "send": "Send",
    "load-more": "Load more",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "estimated-value": "Estimated value",
    "messages-not-found-title": "We didn't find any related emails",
    "messages-not-found-description": "This deal doesn't have any email fired by an automation.",
    "contact-not-found-title": "We didn't find any related contact",
    "contact-not-found-description": "You probably forgot to attach this company to some contact",
    "deal-not-found-title": "We didn't find any related deal",
    "deal-not-found-description": "You probably forgot to attach this contact to some deal",
    "of-stage": "of stage",
    "to-stage": "to stage",
    "at": "at",
    "processed": "Processed",
    "delivered": "Processed",
    "opened": "Opened",
    "not-processed": "Not processed",
    "not-delivered": "Not delivered",
    "not-opened": "Not opened",
    "owner": "Owner",
    "system": "System",
    "the-system": "The system",
    "created-automatically": "the card automatically by webform or integration"
  },
  "pt-BR": {
    "show": "Mostrar",
    "activity-1": "Comentários",
    "activity-2": "Histórico",
    "activity-3": "Cards",
    "activity-4": "Contatos",
    "activity-5": "E-mails",
    "delete": "Deletar",
    "note": "nota",
    "type-something": "Digite alguma coisa",
    "cancel": "Cancelar",
    "send": "Enviar",
    "load-more": "Carregar mais",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "estimated-value": "Valor estimado",
    "messages-not-found-title": "Nenhum e-mail relacionado",
    "messages-not-found-description": "Esse card não possui e-mails disparados por automação.",
    "contact-not-found-title": "Nenhum contato relacionado",
    "contact-not-found-description": "Você ainda não atrelou um contato à essa empresa",
    "deal-not-found-title": "Nenhum card encontrada",
    "deal-not-found-description": "Você ainda não atrelou esse contato à um card",
    "of-stage": "do estágio",
    "to-stage": "para o estágio",
    "at": "às",
    "processed": "Enviado",
    "delivered": "Entregue",
    "opened": "Aberto",
    "not-processed": "Não enviado",
    "not-delivered": "Não entregue",
    "not-opened": "Não aberto",
    "owner": "Responsável",
    "system": "Sistema",
    "the-system": "O sistema",
    "created-automatically": "o card automaticamente pelo formulário web ou por integração"
  }
}
</i18n>

<template>
  <div class="we-activities">
    <div class="activities-menu">
      {{ $t("show") }}:
      <we-action
        :text="$t('activity-1')"
        :class="{ active: activitiesMenu === 'notes' }"
        @click="allNotesRequest"
      />
      <we-action
        :text="$t('activity-2')"
        :class="{ active: activitiesMenu === 'history' }"
        @click="historyRequest"
      />
      <we-action
        v-if="relatedMessages"
        :text="$t('activity-5')"
        :class="{ active: activitiesMenu === 'messages' }"
        @click="emailsRequest"
      />
      <we-action
        v-if="relatedDeals"
        :text="$t('activity-3')"
        :class="{ active: activitiesMenu === 'relatedDeals' }"
        @click="entityDealsRequest"
      />
      <we-action
        v-if="relatedContacts"
        :text="$t('activity-4')"
        :class="{ active: activitiesMenu === 'relatedContacts' }"
        @click="entityContactsRequest"
      />
    </div>
    <div class="loading-skeleton" v-if="loadingActivities">
      <div class="skeleton-message" v-for="i in 5" :key="i">
        <div class="skeleton-box picture"></div>
        <div class="skeleton-box"></div>
      </div>
    </div>
    <div class="notes" v-if="activitiesMenu === 'notes'">
      <div class="notes-list">
        <div class="note" v-for="(note, i) in entityNotes" :key="i">
          <we-rounded-picture
            :picture="note.user.picture"
            :name="note.user.first_name"
            :text="
              $options.filters.firstLetters(
                `${note.user.first_name} ${note.user.last_name}`
              )
            "
            size="32"
          />
          <div class="data">
            <div class="name">
              {{ note.user.first_name }} {{ note.user.last_name }}
              <span class="time">{{ note.created_at | dateTime }}</span>
            </div>
            <we-balloon class="right">
              <template #action>
                <span class="remove">{{ $t("delete") }}</span>
              </template>
              <template #balloon>
                <div class="balloon-item" @click="removeNote(note.id)">
                  <font-awesome-icon
                    :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                  />
                  {{ $t("delete") }} {{ $t("note") }}
                </div>
              </template>
            </we-balloon>
            <div class="message">
              {{ note.note }}
            </div>
          </div>
        </div>
      </div>
      <div class="message-input">
        <we-rounded-picture
          :picture="loggedUser.picture"
          :name="loggedUser.first_name"
          :text="
            $options.filters.firstLetters(
              `${loggedUser.first_name} ${loggedUser.last_name}`
            )
          "
          size="32"
        />
        <we-input
          v-model="note"
          type="textarea"
          :placeholder="$t('type-something')"
          rows="1"
        />
      </div>
      <div class="actions" v-if="note.length > 0">
        <we-button
          class="disabled small"
          :text="$t('cancel')"
          @click="note = ''"
        />
        <we-button
          class="primary small"
          :text="$t('send')"
          :loading="btnNoteLoading"
          @click="sendNote"
        />
      </div>
    </div>
    <div class="history" v-if="activitiesMenu === 'history'">
      <div class="log" v-for="(log, i) in entityLogs" :key="i">
        <template v-if="!log.user">
          <we-rounded-picture :name="$t('system')" text="SI" size="32" />
          <div class="data">
            <div class="text">
              {{ $t("the-system") }}
              <span class="action">{{ log.action | logAction }}</span>
              {{ $t("created-automatically") }}
              <span v-html="getActionSchema(log)"></span>
            </div>
            <div class="date">
              {{ log.created_at | dateTime }}
            </div>
          </div>
        </template>
        <template v-else>
          <we-rounded-picture
            :picture="log.user.picture"
            :name="log.user.first_name"
            :text="
              $options.filters.firstLetters(
                `${log.user.first_name} ${log.user.last_name}`
              )
            "
            size="32"
          />
          <div class="data">
            <div class="text">
              {{ log.user.first_name }} {{ log.user.last_name }}
              <span class="action">{{ log.action | logAction }}</span>
              {{ log.entity | logEntity }}
              <span v-html="getActionSchema(log)"></span>
            </div>
            <div class="date">
              {{ log.created_at | dateTime }}
            </div>
          </div>
        </template>
      </div>
      <we-button
        @click="moreLogs"
        v-if="logPages.current < logPages.last"
        class="disabled fill"
        :text="$t('load-more')"
        icon="chevron-down"
        :loading="loadingButton"
      />
    </div>
    <div class="history email" v-if="activitiesMenu === 'messages'">
      <we-not-found class="small" v-if="entityMessages.length <= 0">
        <template #title>{{ $t("messages-not-found-title") }}</template>
        <template #description>
          {{ $t("messages-not-found-description") }}
        </template>
        <template #picture>
          <img
            src="@/assets/undraw/not-found-contacts.svg"
            alt="Onboarding"
            width="230"
          />
        </template>
      </we-not-found>
      <div class="log" v-for="(message, i) in entityMessages" :key="i">
        <div class="circle-icon">
          <font-awesome-icon icon="envelope" />
        </div>
        <div class="data">
          <div class="email">Para: {{ message.to }}</div>
          <div class="text">
            <span class="action">{{ message.subject }}</span>
          </div>
          <we-steps
            small
            :steps="[
              {
                text: getProcessedMessage(message),
                icon: 'share'
              },
              {
                text: getDeliveredMessage(message),
                icon: 'envelope'
              },
              {
                text: getOpenedMessage(message),
                icon: 'eye'
              }
            ]"
            :active-step="emailStatus(message)"
          />
        </div>
      </div>
      <we-button
        @click="moreMessages"
        v-if="messagePages.current < messagePages.last"
        class="disabled fill"
        :text="$t('load-more')"
        icon="chevron-down"
        :loading="loadingButton"
      />
    </div>
    <div class="related-deals" v-if="activitiesMenu === 'relatedDeals'">
      <we-not-found class="small" v-if="entityDeals.length <= 0">
        <template #title>{{ $t("deal-not-found-title") }}</template>
        <template #description>
          {{ $t("deal-not-found-description") }}
        </template>
        <template #picture>
          <img
            src="@/assets/undraw/not-found-contacts.svg"
            alt="Onboarding"
            width="230"
          />
        </template>
      </we-not-found>
      <div
        class="deal"
        v-for="(deal, i) in entityDeals"
        :key="i"
        @click="openDeal(deal.id)"
      >
        <we-rounded-picture
          :picture="getUserPicture(deal.user)"
          :name="`${$t('owner')}: ${getUserName(deal.user)}`"
          :text="$options.filters.firstLetters(getUserName(deal.user))"
          size="32"
        />
        <div class="data">
          <we-text-tooltip class="name" :text="deal.name" lines="1" />
          <div class="icons">
            <we-icon-tooltip :icon="['far', 'clock']">
              <template #text>
                {{ $t("created-at") }}: {{ deal.created_at | dateTime }}
                <br />
                {{ $t("updated-at") }}: {{ deal.updated_at | dateTime }}
              </template>
            </we-icon-tooltip>
            <we-icon-tooltip
              v-if="deal.products && deal.products.length > 0"
              icon="cubes"
            >
              <template #text>
                {{ getAllProductsNames(deal.products) }}
              </template>
            </we-icon-tooltip>
            <we-icon-tooltip
              v-if="deal.tags && deal.tags.length > 0"
              icon="tags"
            >
              <template #text>
                {{ getAllTagsNames(deal.tags) }}
              </template>
            </we-icon-tooltip>
            <we-icon-tooltip v-if="dealAmount(deal) > 0" icon="dollar-sign">
              <template #text>
                {{ $t("estimated-value") }}:
                {{ dealAmount(deal) | valueToReais }}
              </template>
            </we-icon-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="related-contacts" v-if="activitiesMenu === 'relatedContacts'">
      <we-not-found class="small" v-if="entityContacts.length <= 0">
        <template #title>{{ $t("contact-not-found-title") }}</template>
        <template #description>
          {{ $t("contact-not-found-description") }}
        </template>
        <template #picture>
          <img
            src="@/assets/undraw/not-found-contacts.svg"
            alt="Onboarding"
            width="230"
          />
        </template>
      </we-not-found>
      <we-deal-contact
        v-for="contact in entityContacts"
        :key="contact.id"
        :contact="contact"
        :fromDeal="false"
      >
        <template #action>
          <div class="contact">
            <we-rounded-picture
              :picture="getContactPicture(contact)"
              :name="getContactName(contact)"
              :text="$options.filters.firstLetters(getContactName(contact))"
              size="42"
            />
            <div class="contact-data">
              <we-text-tooltip
                class="name"
                :text="`${contact.first_name} ${contact.last_name}`"
                lines="1"
              />
              <we-text-tooltip class="email" :text="contact.email" />
            </div>
          </div>
        </template>
      </we-deal-contact>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    entity: String,
    recordId: Number,
    relatedMessages: Boolean,
    relatedDeals: Boolean,
    relatedContacts: Boolean
  },
  data() {
    return {
      btnNoteLoading: false,
      activitiesMenu: "notes",
      loadingActivities: false,
      loadingButton: false,
      note: ""
    };
  },
  computed: {
    entityNotes() {
      return this.$store.getters.getNotes;
    },
    entityLogs() {
      return this.$store.getters.getLogs;
    },
    logPages() {
      return this.$store.getters.getLogPages;
    },
    entityMessages() {
      return this.$store.getters.getMessages;
    },
    messagePages() {
      return this.$store.getters.getMessagePages;
    },
    entityDeals() {
      return this.$store.getters.getContactDeals;
    },
    entityContacts() {
      return this.$store.getters.getCompanyContacts;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    }
  },
  methods: {
    ...mapActions([
      "notesRequest",
      "createNoteRequest",
      "deleteNoteRequest",
      "logsRequest",
      "contactDealsRequest",
      "companyContactsRequest",
      "messagesRequest"
    ]),
    getProcessedMessage(message) {
      if (message.processed_at) {
        return `${this.$t("processed")} ${this.$t(
          "at"
        )} ${this.$options.filters.dateTime(message.processed_at)}`;
      }

      return this.$t("not-processed");
    },
    getDeliveredMessage(message) {
      if (message.delivered_at) {
        return `${this.$t("delivered")} ${this.$t(
          "at"
        )} ${this.$options.filters.dateTime(message.delivered_at)}`;
      }

      return this.$t("not-delivered");
    },
    getOpenedMessage(message) {
      if (message.opened_at) {
        return `${this.$t("opened")} ${this.$t(
          "at"
        )} ${this.$options.filters.dateTime(message.opened_at)}`;
      }

      return this.$t("not-opened");
    },
    emailStatus(message) {
      if (message.opened_at) return 3;
      if (message.delivered_at) return 2;
      if (message.delivered_at) return 1;
      return 0;
    },
    getActionSchema(log) {
      if (log.action === "move") {
        return `${this.$t("of-stage")} <span class="action">${
          log.schema.from_stage
        }</span> ${this.$t("to-stage")} <span class="action">${
          log.schema.to_stage
        }</span>`;
      }

      return "";
    },
    dealAmount(deal) {
      return deal?.products?.reduce((a, b) => a + b.price, 0);
    },
    async allNotesRequest() {
      const payload = {
        entity: this.entity,
        record_id: this.recordId
      };

      this.activitiesMenu = "notes";

      this.notesRequest(payload);
    },
    async sendNote() {
      const payload = {
        entity: this.entity,
        record_id: this.recordId,
        note: this.note
      };
      this.btnNoteLoading = true;
      await this.createNoteRequest(payload);
      await this.allNotesRequest();
      this.note = "";
      this.btnNoteLoading = false;
    },
    async removeNote(id) {
      const payload = {
        entity: this.entity,
        record_id: this.recordId,
        id: id
      };
      this.loadingActivities = true;
      await this.deleteNoteRequest(payload);
      await this.allNotesRequest();
      this.loadingActivities = false;
    },
    async historyRequest() {
      this.$store.commit("resetLogs");
      const payload = {
        entity: this.entity,
        record_id: this.recordId
      };
      this.activitiesMenu = "history";
      this.loadingActivities = true;
      await this.logsRequest(payload);
      this.loadingActivities = false;
    },
    async emailsRequest() {
      this.$store.commit("resetMessages");
      const payload = {
        entity: this.entity,
        record_id: this.recordId
      };
      this.activitiesMenu = "messages";
      this.loadingActivities = true;
      await this.messagesRequest(payload);
      this.loadingActivities = false;
    },
    async moreLogs() {
      const page =
        this.logPages.current < this.logPages.last
          ? this.logPages.current + 1
          : this.logPages.last + 1;

      const payload = {
        entity: this.entity,
        record_id: this.recordId,
        page: page
      };

      this.loadingButton = true;
      await this.logsRequest(payload);
      this.loadingButton = false;
    },
    async moreMessages() {
      const page =
        this.messagePages.current < this.messagePages.last
          ? this.messagePages.current + 1
          : this.messagePages.last + 1;

      const payload = {
        entity: this.entity,
        record_id: this.recordId,
        page: page
      };

      this.loadingButton = true;
      await this.messagesRequest(payload);
      this.loadingButton = false;
    },
    async entityDealsRequest() {
      const payload = this.recordId;
      this.loadingActivities = true;

      this.activitiesMenu = "relatedDeals";

      await this.contactDealsRequest(payload);
      this.loadingActivities = false;
    },
    openDeal(id) {
      this.$router.push({ name: "contactDeal", params: { dealId: id } });
    },
    getAllProductsNames(products) {
      return products && products?.length > 0
        ? products.map(e => e.name).join(", ")
        : "Sem produto";
    },
    getAllTagsNames(tags) {
      return tags && tags?.length > 0
        ? tags.map(e => e).join(", ")
        : "Sem produto";
    },
    getUserPicture(user) {
      return user ? user.picture : "";
    },
    getUserName(user) {
      return user ? `${user.first_name} ${user.last_name}` : "";
    },
    async entityContactsRequest() {
      const payload = this.recordId;
      this.loadingActivities = true;

      this.activitiesMenu = "relatedContacts";

      await this.companyContactsRequest(payload);
      this.loadingActivities = false;
    },
    getContactPicture(contact) {
      return contact ? contact.picture : "";
    },
    getContactName(contact) {
      return contact ? `${contact.first_name} ${contact.last_name}` : "";
    }
  }
};
</script>

<style lang="scss">
.loading-skeleton {
  .skeleton-message {
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 22px;
    margin-bottom: 18px;

    .skeleton-box {
      display: inline-block;
      width: 100%;
      height: 47px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      background-color: var(--action);

      &.picture {
        width: 32px;
        height: 32px;
        border-radius: 32px;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
      }

      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    }
  }
}

.we-activities {
  .activities-menu {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 14px;
    color: var(--text-2);
    font-weight: $medium;

    @include mobile {
      display: none;
    }
  }

  .notes {
    .notes-list {
      display: flex;
      flex-flow: column-reverse;
    }

    .note {
      display: grid;
      grid-template-columns: 32px 1fr;
      gap: 22px;
      margin-bottom: 18px;

      &:hover {
        .data .name .we-balloon {
          display: block;
        }
      }

      .rounded-picture {
        margin-top: 4px;
      }

      .data {
        font-size: 14px;
        font-weight: $regular;
        color: var(--text-2);
        position: relative;

        .name {
          margin-bottom: 5px;
          color: var(--text-1);
          padding-right: 60px;

          .time {
            margin-left: 6px;
            color: var(--text-2);
          }
        }

        .menu-btn {
          position: absolute;
          top: 0;
          right: 0;
        }

        .remove {
          margin-left: 6px;
          cursor: pointer;
          border-radius: 3px;
          padding: 0 7px;
          color: var(--red);
          font-weight: $medium;

          &:hover {
            background: var(--action);
          }
        }

        .message {
          user-select: text;
          word-break: break-word;
        }
      }
    }

    .message-input {
      display: grid;
      grid-template-columns: 32px 1fr;
      gap: 10px;
      margin-bottom: 4px;

      .rounded-picture {
        margin-top: 6px;
      }

      .we-input {
        margin-bottom: 0;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 8px;
        min-width: 76px;
      }
    }
  }

  .history {
    &.email {
      .log {
        .data {
          .text {
            margin-bottom: 8px;
          }
        }
      }
    }

    .we-not-found {
      position: relative;
    }

    .log {
      display: grid;
      grid-template-columns: 32px 1fr;
      gap: 22px;
      margin-bottom: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 12px;

        &::before {
          display: none;
        }
      }

      &::before {
        content: "";
        width: 1px;
        height: calc(100% - 20px);
        background: var(--line);
        position: absolute;
        left: 15px;
        top: 42px;
      }

      .we-steps {
        max-width: 200px;
      }

      .circle-icon {
        height: 32px;
        width: 32px;
        border-radius: 32px;
        background: var(--action);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-2);

        svg {
          height: 14px;
          width: 14px;
        }
      }

      .rounded-picture {
        margin-top: 4px;
      }

      .data {
        font-size: 14px;
        font-weight: $regular;
        color: var(--text-2);

        .email {
          font-size: 13px;
          color: var(--text-3);
          margin-bottom: 4px;
        }

        .text {
          color: var(--text-1);

          span.action {
            font-weight: $semibold;
          }
        }

        .date {
          font-size: 13px;
        }
      }
    }
  }

  .related-deals {
    min-height: 330px;
    position: relative;

    .deal {
      display: grid;
      grid-template-columns: 32px 1fr;
      gap: 22px;
      margin-bottom: 16px;
      position: relative;
      padding: 8px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid var(--line);

      &:last-child {
        margin-bottom: 12px;

        &::before {
          display: none;
        }
      }

      &:hover {
        background: var(--background-1-hover);
      }

      &::before {
        content: "";
        width: 1px;
        height: 18px;
        background: var(--action-hover);
        position: absolute;
        left: 24px;
        top: 57px;
      }

      .rounded-picture {
        margin-top: 4px;
      }

      .data {
        font-size: 14px;
        font-weight: $regular;
        color: var(--text-1);

        .icons {
          display: flex;
          color: var(--text-2);
          margin-top: 4px;
          margin-bottom: 2px;

          svg {
            height: 14px;
            width: auto;
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }

  .related-contacts {
    min-height: 330px;
    position: relative;

    .contact {
      display: grid;
      grid-template-columns: 42px 1fr;
      align-items: center;
      gap: 14px;
      margin-bottom: 16px;
      position: relative;
      padding: 8px 14px;
      border-radius: 5px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 12px;

        &::before {
          display: none;
        }
      }

      &:hover {
        background: var(--background-1-hover);
      }

      &::before {
        content: "";
        width: 1px;
        height: 20px;
        background: var(--action-hover);
        position: absolute;
        left: 24px;
        top: 57px;
      }

      .contact-data {
        font-size: 14px;
        font-weight: $regular;
        color: var(--text-1);

        .email {
          color: var(--text-2);
        }
      }
    }
  }
}
</style>
