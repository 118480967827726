import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "create-webform-success": "Form was created with success!",
    "create-webform-error": "Wasn't possible to create form!",
    "update-webform-success": "Form was updated with success!",
    "update-webform-error": "Wasn't possible to update form!",
    "delete-webform-error": "Wasn't possible to delete form!",
    "update-favicon-webform-error": "Wasn't possible to update form favicon!",
    "update-logo-webform-error": "Wasn't possible to update form logo!",
    "update-terms-webform-error": "Wasn't possible to update terms!"
  },
  "pt-BR": {
    "create-webform-success": "O formulário foi criado com sucesso!",
    "create-webform-error": "Não foi possível criar o formulário!",
    "update-webform-success": "O formulário web foi atualizado!",
    "update-webform-error": "Não foi possível atualizar o formulário!",
    "delete-webform-error": "Não foi possível deletar o formulário!",
    "update-favicon-webform-error": "Não foi possível atualizar o Favicon!",
    "update-logo-webform-error": "Não foi possível atualizar a Logo!",
    "update-terms-webform-error":
      "Não foi possível atualizar os Termos e Serviços!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    forms: [],
    form: {}
  },
  getters: {
    getWebforms: state => state.forms,
    getWebform: state => state.form
  },
  mutations: {
    setWebforms: (state, payload) => {
      state.forms = payload;
    },
    setWebform: (state, payload) => {
      state.form = payload;
    }
  },
  actions: {
    webformsRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/forms`)
          .then(resp => {
            const forms = resp.data;
            commit("setWebforms", forms);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    webformRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/forms/${payload}`)
          .then(resp => {
            const form = resp.data;

            if (form.email_body?.length > 0) {
              form.email_body = Vue.options.filters.plainTextEmailToMention(
                form.email_body
              );
            }
            commit("setWebform", form);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    createWebformRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/forms`, payload)
          .then(resp => {
            const form = resp.data;
            commit("setWebform", form);

            const snackBar = {
              title: i18n.t("create-webform-success"),
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(form);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateWebformRequest({ commit }, payload) {
      return new Promise(resolve => {
        if (payload.email_body?.length > 0) {
          payload.email_body = Vue.options.filters.mentionToPlainTextEmail(
            payload.email_body
          );
        }
        Vue.prototype.$http
          .put(`/forms/${payload.id}`, payload)
          .then(resp => {
            const form = resp.data;
            commit("setWebform", form);

            const snackBar = {
              title: i18n.t("update-webform-success"),
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteWebformRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/forms/${payload.id}`, payload)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateFaviconWebformRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("image", "favicon");
      formData.append("brand_picture", payload);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/forms/${payload.formId}/uploadImage`, formData)
          .then(resp => {
            const form = resp.data;
            commit("setWebform", form);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-favicon-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateLogoWebformRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("image", "brand");
      formData.append("brand_picture", payload);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/forms/${payload.formId}/uploadImage`, formData)
          .then(resp => {
            const form = resp.data;
            commit("setWebform", form);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-logo-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateTermsWebformRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("file", payload);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/forms/${payload.formId}/uploadTerm`, formData)
          .then(resp => {
            const form = resp.data;
            commit("setWebform", form);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-terms-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateWebformPasswordRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/forms/${payload.id}/updatePassword`, {
            password: payload.password
          })
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-webform-success"),
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-webform-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
