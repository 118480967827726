<i18n>
{
  "en": {
    "label-1": "Edit html",
    "label-2": "Bold",
    "label-3": "Italic",
    "label-4": "Underline",
    "label-6": "Add link",
    "label-7": "Add image",
    "label-8": "Title 1",
    "label-9": "Title 2",
    "label-10": "Title 3",
    "label-11": "Unordered list",
    "label-12": "Ordered list",
    "label-13": "Undo",
    "label-14": "Redo"
  },
  "pt-BR": {
    "label-1": "Editar html",
    "label-2": "Negrito",
    "label-3": "Itálico",
    "label-4": "Sublinhar",
    "label-6": "Adicionar link",
    "label-7": "Adicionar imagem",
    "label-8": "Título 1",
    "label-9": "Título 2",
    "label-10": "Título 3",
    "label-11": "Lista não-ordenada",
    "label-12": "Lista ordenada",
    "label-13": "Desfazer",
    "label-14": "Refazer"
  }
}
</i18n>

<template>
  <div id="we-editor">
    <div class="editor-label">
      {{ label }}
    </div>
    <div
      class="we-editor-content"
      :class="{ focused, html: htmlView }"
      @click="focused = true"
      v-click-outside="blur"
    >
      <div class="editor-actions" v-if="editor">
        <we-icon-button
          v-if="enableHtml"
          type="square"
          size="small"
          icon="code"
          :name="$t('label-1')"
          :active="htmlView"
          @click="toggleHtml"
        />
        <div class="horizontal-line" v-if="enableHtml"></div>
        <template v-if="!htmlView">
          <we-icon-button
            type="square"
            size="small"
            icon="bold"
            :name="$t('label-2')"
            :active="editor.isActive('bold')"
            @click="
              editor
                .chain()
                .focus()
                .toggleBold()
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            icon="italic"
            :name="$t('label-3')"
            :active="editor.isActive('italic')"
            @click="
              editor
                .chain()
                .focus()
                .toggleItalic()
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            icon="underline"
            :name="$t('label-4')"
            :active="editor.isActive('underline')"
            @click="
              editor
                .chain()
                .focus()
                .toggleUnderline()
                .run()
            "
          />
          <add-link
            v-if="setUrlModal"
            @setUrl="setLink"
            @close="setUrlModal = false"
          />
          <we-icon-button
            type="square"
            size="small"
            icon="link"
            :name="$t('label-6')"
            :active="editor.isActive('link')"
            @click="setUrlModal = true"
          />
          <add-image
            v-if="setImageModal"
            @addImage="addImage"
            @close="setImageModal = false"
          />
          <we-icon-button
            type="square"
            size="small"
            icon="image"
            :name="$t('label-7')"
            @click="setImageModal = true"
          />
          <we-icon-button
            type="square"
            size="small"
            text="H1"
            :name="$t('label-8')"
            :active="editor.isActive('heading', { level: 1 })"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            text="H2"
            :name="$t('label-9')"
            :active="editor.isActive('heading', { level: 2 })"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            text="H3"
            :name="$t('label-10')"
            :active="editor.isActive('heading', { level: 3 })"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 3 })
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            icon="list-ul"
            :name="$t('label-11')"
            :active="editor.isActive('bulletList')"
            @click="
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .run()
            "
          />
          <we-icon-button
            type="square"
            size="small"
            icon="list-ol"
            :name="$t('label-12')"
            :active="editor.isActive('orderedList')"
            @click="
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .run()
            "
          />
        </template>
        <we-icon-button
          type="square"
          size="small"
          icon="undo-alt"
          :name="$t('label-13')"
          @click="
            editor
              .chain()
              .focus()
              .undo()
              .run()
          "
        />
        <we-icon-button
          type="square"
          size="small"
          icon="redo-alt"
          :name="$t('label-14')"
          @click="
            editor
              .chain()
              .focus()
              .redo()
              .run()
          "
        />
      </div>
      <editor-content :editor="editor" />
      <we-code-editor
        v-if="htmlView"
        @input="setEditorHtml"
        language="markup"
        v-model="html"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import suggestion from "@/plugins/suggestion";
export default {
  components: {
    EditorContent
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: String,
    enableHtml: Boolean,
    disableActions: Boolean
  },
  data() {
    return {
      editor: null,
      focused: false,
      html: `<div></div>`,
      htmlView: false,
      setUrlModal: false,
      setImageModal: false
    };
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    }
  },
  methods: {
    setEditorHtml(value) {
      this.editor.commands.setContent(value);
      this.$emit("input", value);
    },
    toggleHtml() {
      this.htmlView = !this.htmlView;

      if (this.htmlView) {
        this.html = this.editor.getHTML();
      }
    },
    blur() {
      this.focused = false;
    },
    setLink(url) {
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    addImage(url) {
      if (url) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: url })
          .run();
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Link,
        Image,
        Mention.configure({
          HTMLAttributes: {
            class: "mention"
          },
          suggestion
        })
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss">
.ProseMirror {
  color: var(--text-1);

  &:focus {
    outline: none;
  }
}
#we-editor {
  .editor-label {
    font-size: 12px;
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 4px;
    padding-left: 13px;
    text-align: left;
    line-height: 1;
    letter-spacing: initial;
    display: flex;
    align-items: center;
  }
  .we-editor-content {
    background: var(--background-1);
    border: 1px solid var(--line);
    border-radius: 7px;
    overflow: hidden;

    &.html {
      .ProseMirror {
        display: none;
      }

      #we-code-editor {
        display: block;
      }
    }

    #we-code-editor {
      display: none;

      .my-editor {
        border-radius: 0;
      }
    }

    .mention {
      background: var(--primary-bg);
      color: var(--primary);
      border-radius: 5px;
      padding-left: 0;
      padding-right: 7.5px;
      margin-right: 1px;
      box-decoration-break: clone;
      display: inline-block;
      position: relative;
      font-weight: $semibold;

      &::after {
        content: "";
        background: var(--primary-bg);
        min-width: 6px;
        height: 18px;
        left: 1px;
        position: absolute;
      }
    }

    &.focused {
      border-color: var(--text-3);

      .editor-actions {
        border-color: var(--text-3);
      }
    }

    .editor-actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--line);
      padding: 4px 12px;
      background: var(--background-2);

      .we-icon-button {
        margin-right: 1px;

        &:last-child {
          margin-right: 0;
        }
      }

      .horizontal-line {
        width: 1px;
        height: 32px;
        background: var(--line);
        margin: 0 8px;
      }
    }

    .ProseMirror {
      padding: 12px;

      /* Basic editor styles */
      & * {
        margin: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
      }

      p {
        font-size: 13px;
        font-weight: $regular;
        line-height: 1.5;
        color: #000;
      }

      code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
      }

      pre {
        background: #0d0d0d;
        color: var(--background-1);
        font-family: $font;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
      }

      hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
      }
    }
  }
}
</style>
