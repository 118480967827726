import i18n from "@/plugins/i18n";
import Vue from "vue";
import store from "@/store";

const messages = {
  en: {
    "filter-entity-1": "Pipeline",
    "filter-entity-2": "Card",
    "filter-entity-3": "Company",
    "filter-entity-4": "Contact",
    "filter-entity-5": "User",
    "filter-entity-6": "Product",
    "filter-entity-7": "Bill",
    "filters-contact-field-1": "First name",
    "filters-contact-field-2": "Last name",
    "filters-contact-field-3": "Gender",
    "filters-contact-field-4": "Birthdate",
    "filters-contact-field-5": "Email",
    "filters-contact-field-6": "Phone number",
    "filters-contact-field-7": "Document",
    "filters-contact-field-8": "City",
    "filters-contact-field-9": "State",
    "filters-contact-field-10": "Country",
    "filters-contact-field-11": "Created at",
    "filters-contact-field-12": "Updated at",
    "filters-pipeline-field-1": "Name",
    "filters-pipeline-field-2": "Tags",
    "filters-pipeline-field-3": "Responsible",
    "filters-pipeline-field-4": "Workflow stage",
    "filters-pipeline-field-5": "Value",
    "filters-pipeline-field-6": "Abandon reason",
    "filters-pipeline-field-7": "Abandoned at",
    "filters-pipeline-field-8": "Created at",
    "filters-pipeline-field-9": "Updated at",
    "filters-pipeline-field-10": "Products",
    "filters-pipeline-field-11": "Contacts",
    "filters-pipeline-field-12": "Companies",
    "filters-deal-field-1": "Name",
    "filters-deal-field-2": "Tags",
    "filters-deal-field-3": "Responsible",
    "filters-deal-field-4": "Pipeline",
    "filters-deal-field-5": "Workflow stage",
    "filters-deal-field-6": "Value",
    "filters-deal-field-7": "Abandon reason",
    "filters-deal-field-8": "Abandoned at",
    "filters-deal-field-9": "Created at",
    "filters-deal-field-10": "Updated at",
    "filters-deal-field-11": "Products",
    "filters-deal-field-12": "Contacts",
    "filters-deal-field-13": "Companies",
    "filters-company-field-1": "Corporate name",
    "filters-company-field-2": "Commercial name",
    "filters-company-field-3": "Site",
    "filters-company-field-4": "Email",
    "filters-company-field-5": "Phone number",
    "filters-company-field-6": "Document",
    "filters-company-field-7": "City",
    "filters-company-field-8": "State",
    "filters-company-field-9": "Country",
    "filters-company-field-10": "Created at",
    "filters-company-field-11": "Updated at",
    "filters-user-field-1": "Name",
    "filters-user-field-2": "Last name",
    "filters-user-field-3": "Occupation",
    "filters-user-field-4": "Job role",
    "filters-user-field-5": "Gender",
    "filters-user-field-6": "Birthdate",
    "filters-user-field-7": "Email",
    "filters-user-field-8": "Phone number",
    "filters-user-field-9": "Document",
    "filters-user-field-10": "City",
    "filters-user-field-11": "State",
    "filters-user-field-12": "Country",
    "filters-user-field-13": "Created at",
    "filters-user-field-14": "Updated at",
    "filters-product-field-1": "Name",
    "filters-product-field-2": "Color",
    "filters-product-field-3": "Price",
    "filters-product-field-4": "SKU",
    "filters-bill-field-1": "Description",
    "filters-bill-field-2": "Type",
    "filters-bill-field-3": "Status",
    "filters-bill-field-4": "Method",
    "filters-bill-field-5": "Payment date",
    "filters-bill-field-6": "Due date",
    "filters-bill-field-7": "Bill url",
    "filters-bill-field-8": "Total",
    "filters-bill-field-9": "Subtotal",
    "filters-bill-field-10": "Responsible user",
    "filters-bill-field-11": "Cost center",
    "filters-bill-field-12": "Created at",
    "filters-bill-field-13": "Updated at",
    "filters-bill-field-14": "Tags",
    "filters-task-field-1": "Is finished",
    "filters-task-field-2": "Status",
    "filters-task-field-3": "Responsible",
    "filters-task-field-4": "Created at",
    "filters-task-field-5": "Updated at",
    "filters-task-field-6": "Type",
    "filters-task-field-7": "Name",
    "filters-task-field-8": "Start date",
    "filters-task-field-9": "End date",
    "filters-operator-1": "Equal",
    "filters-operator-2": "Not equal",
    "filters-operator-3": "Like",
    "filters-operator-4": "Not like",
    "filters-operator-5": "Greater than",
    "filters-operator-6": "Greater than or equal",
    "filters-operator-7": "Less than",
    "filters-operator-8": "Less than or equal",
    "filterSaves-error": "Wasn't possible to load filters!",
    "filterSave-error": "Wasn't possible to load filter data!",
    "create-filterSave-error": "Wasn't possible to create filter!",
    "update-filterSave-success": "Filter updated with success!",
    "update-filterSave-error": "Wasn't possible to update filter!",
    "delete-filterSave-error": "Wasn't possible to delete filter!",
    "delete-filtered-records-success": "All data was successfully deleted!",
    "delete-filtered-records-error": "Wasn't possible to delete!",
    "update-filtered-records-success": "All data was successfully edited!",
    "update-filtered-records-error": "Wasn't possible to edit!"
  },
  "pt-BR": {
    "filter-entity-1": "Pipeline",
    "filter-entity-2": "Card",
    "filter-entity-3": "Empresa",
    "filter-entity-4": "Contato",
    "filter-entity-5": "Usuário",
    "filter-entity-6": "Produto",
    "filter-entity-7": "Lançamento",
    "filters-contact-field-1": "Nome",
    "filters-contact-field-2": "Sobrenome",
    "filters-contact-field-3": "Gênero",
    "filters-contact-field-4": "Data de nascimento",
    "filters-contact-field-5": "Email",
    "filters-contact-field-6": "Telefone",
    "filters-contact-field-7": "Documento",
    "filters-contact-field-8": "Cidade",
    "filters-contact-field-9": "Estado",
    "filters-contact-field-10": "País",
    "filters-contact-field-11": "Criado em",
    "filters-contact-field-12": "Atualizado em",
    "filters-pipeline-field-1": "Nome",
    "filters-pipeline-field-2": "Etiquetas",
    "filters-pipeline-field-3": "Responsável",
    "filters-pipeline-field-4": "Estágio no pipeline",
    "filters-pipeline-field-5": "Valor",
    "filters-pipeline-field-6": "Motivo de abandono",
    "filters-pipeline-field-7": "Abandonado em",
    "filters-pipeline-field-8": "Criado em",
    "filters-pipeline-field-9": "Atualizado em",
    "filters-pipeline-field-10": "Produtos",
    "filters-pipeline-field-11": "Contatos",
    "filters-pipeline-field-12": "Empresas",
    "filters-deal-field-1": "Nome",
    "filters-deal-field-2": "Etiquetas",
    "filters-deal-field-3": "Responsável",
    "filters-deal-field-4": "Pipeline",
    "filters-deal-field-5": "Estágio no pipeline",
    "filters-deal-field-6": "Valor",
    "filters-deal-field-7": "Motivo de abandono",
    "filters-deal-field-8": "Abandonado em",
    "filters-deal-field-9": "Criado em",
    "filters-deal-field-10": "Atualizado em",
    "filters-deal-field-11": "Produtos",
    "filters-deal-field-12": "Contatos",
    "filters-deal-field-13": "Empresas",
    "filters-company-field-1": "Razão social",
    "filters-company-field-2": "Nome fantasia",
    "filters-company-field-3": "Site",
    "filters-company-field-4": "Email",
    "filters-company-field-5": "Telefone",
    "filters-company-field-6": "Documento",
    "filters-company-field-7": "Cidade",
    "filters-company-field-8": "Estado",
    "filters-company-field-9": "País",
    "filters-company-field-10": "Criado em",
    "filters-company-field-11": "Atualizado em",
    "filters-user-field-1": "Nome",
    "filters-user-field-2": "Sobrenome",
    "filters-user-field-3": "Profissão",
    "filters-user-field-4": "Cargo",
    "filters-user-field-5": "Gênero",
    "filters-user-field-6": "Data de nascimento",
    "filters-user-field-7": "Email",
    "filters-user-field-8": "Telefone",
    "filters-user-field-9": "Documento",
    "filters-user-field-10": "Cidade",
    "filters-user-field-11": "Estado",
    "filters-user-field-12": "País",
    "filters-user-field-13": "Criado em",
    "filters-user-field-14": "Atualizado em",
    "filters-product-field-1": "Nome",
    "filters-product-field-2": "Cor",
    "filters-product-field-3": "Preço",
    "filters-product-field-4": "SKU",
    "filters-bill-field-1": "Descrição",
    "filters-bill-field-2": "Tipo",
    "filters-bill-field-3": "Status",
    "filters-bill-field-4": "Método",
    "filters-bill-field-5": "Data de pagamento",
    "filters-bill-field-6": "Data de vencimento",
    "filters-bill-field-7": "Link de lançamento",
    "filters-bill-field-8": "Total",
    "filters-bill-field-9": "Subtotal",
    "filters-bill-field-10": "Usuário reponsável",
    "filters-bill-field-11": "Centro de custo",
    "filters-bill-field-12": "Criado em",
    "filters-bill-field-13": "Atualizado em",
    "filters-bill-field-14": "Etiqueta",
    "filters-task-field-1": "Finalizada",
    "filters-task-field-2": "Status",
    "filters-task-field-3": "Responsável",
    "filters-task-field-4": "Criado em",
    "filters-task-field-5": "Atualizado em",
    "filters-task-field-6": "Tipo",
    "filters-task-field-7": "Nome",
    "filters-task-field-8": "Data de início",
    "filters-task-field-9": "Data de término",
    "filters-operator-1": "Igual",
    "filters-operator-2": "Diferente",
    "filters-operator-3": "Contém",
    "filters-operator-4": "Não contém",
    "filters-operator-5": "Maior",
    "filters-operator-6": "Maior igual",
    "filters-operator-7": "Menor",
    "filters-operator-8": "Menor igual",
    "filterSaves-error": "Não foi possível carregar os filtros salvos!",
    "filterSave-error": "Não foi possível carregar o filtro!",
    "create-filterSave-error": "Não foi possível criar o filtro!",
    "update-filterSave-success": "Filtro atualizado com sucesso!",
    "update-filterSave-error": "Não foi possível atualizar o filtro!",
    "delete-filterSave-error": "Não foi possível deletar o filtro!",
    "delete-filtered-records-success":
      "Os dados filtrados foram deletados com sucesso!",
    "delete-filtered-records-error":
      "Não foi possível deletar os dados filtrados!",
    "update-filtered-records-success":
      "Os dados filtrados foram editados com sucesso!",
    "update-filtered-records-error":
      "Não foi possível editar os dados filtrados!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    filterActive: false,
    fields: [
      {
        id: 0,
        filterField: "",
        operator: "like",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      }
    ],
    massUpdateFields: [
      {
        id: 0,
        filterField: "",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      }
    ],
    filterEntityList: [
      {
        text: i18n.t("filter-entity-1"),
        value: "pipeline"
      },
      {
        text: i18n.t("filter-entity-2"),
        value: "deal"
      },
      {
        text: i18n.t("filter-entity-3"),
        value: "company"
      },
      {
        text: i18n.t("filter-entity-4"),
        value: "contact"
      },
      {
        text: i18n.t("filter-entity-5"),
        value: "user"
      },
      {
        text: i18n.t("filter-entity-6"),
        value: "product"
      },
      {
        text: i18n.t("filter-entity-7"),
        value: "bill"
      }
    ],
    contactFields: [
      {
        text: i18n.t("filters-contact-field-1"),
        value: "first_name"
      },
      {
        text: i18n.t("filters-contact-field-2"),
        value: "last_name"
      },
      {
        text: i18n.t("filters-contact-field-3"),
        value: "gender"
      },
      {
        text: i18n.t("filters-contact-field-4"),
        value: "birthdate"
      },
      {
        text: i18n.t("filters-contact-field-5"),
        value: "email"
      },
      {
        text: i18n.t("filters-contact-field-6"),
        value: "phone1"
      },
      {
        text: i18n.t("filters-contact-field-7"),
        value: "document"
      },
      {
        text: i18n.t("filters-contact-field-8"),
        value: "address_city"
      },
      {
        text: i18n.t("filters-contact-field-9"),
        value: "address_state"
      },
      {
        text: i18n.t("filters-contact-field-10"),
        value: "address_country"
      },
      {
        text: i18n.t("filters-contact-field-11"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-contact-field-12"),
        value: "updated_at"
      }
    ],
    pipelineFields: [
      {
        text: i18n.t("filters-pipeline-field-1"),
        value: "name"
      },
      {
        text: i18n.t("filters-pipeline-field-2"),
        value: "tags"
      },
      {
        text: i18n.t("filters-pipeline-field-3"),
        value: "user_id"
      },
      {
        text: i18n.t("filters-pipeline-field-4"),
        value: "pipeline_stage_id"
      },
      {
        text: i18n.t("filters-pipeline-field-10"),
        value: "product_id"
      },
      {
        text: i18n.t("filters-pipeline-field-5"),
        value: "amount"
      },
      {
        text: i18n.t("filters-pipeline-field-11"),
        value: "contact_id"
      },
      {
        text: i18n.t("filters-pipeline-field-12"),
        value: "company_id"
      },
      {
        text: i18n.t("filters-pipeline-field-6"),
        value: "lost_reason"
      },
      {
        text: i18n.t("filters-pipeline-field-7"),
        value: "closed_at"
      },
      {
        text: i18n.t("filters-pipeline-field-8"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-pipeline-field-9"),
        value: "updated_at"
      }
    ],
    dealFields: [
      {
        text: i18n.t("filters-deal-field-4"),
        value: "pipeline_id"
      },
      {
        text: i18n.t("filters-deal-field-5"),
        value: "pipeline_stage_id"
      },
      {
        text: i18n.t("filters-deal-field-1"),
        value: "name"
      },
      {
        text: i18n.t("filters-deal-field-2"),
        value: "tags"
      },
      {
        text: i18n.t("filters-deal-field-3"),
        value: "user_id"
      },
      {
        text: i18n.t("filters-deal-field-11"),
        value: "product_id"
      },
      {
        text: i18n.t("filters-deal-field-6"),
        value: "amount"
      },
      {
        text: i18n.t("filters-deal-field-12"),
        value: "contact_id"
      },
      {
        text: i18n.t("filters-deal-field-13"),
        value: "company_id"
      },
      {
        text: i18n.t("filters-deal-field-7"),
        value: "lost_reason"
      },
      {
        text: i18n.t("filters-deal-field-8"),
        value: "closed_at"
      },
      {
        text: i18n.t("filters-deal-field-9"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-deal-field-10"),
        value: "updated_at"
      }
    ],
    companyFields: [
      {
        text: i18n.t("filters-company-field-1"),
        value: "company"
      },
      {
        text: i18n.t("filters-company-field-2"),
        value: "name"
      },
      {
        text: i18n.t("filters-company-field-3"),
        value: "website"
      },
      {
        text: i18n.t("filters-company-field-4"),
        value: "email"
      },
      {
        text: i18n.t("filters-company-field-5"),
        value: "phone1"
      },
      {
        text: i18n.t("filters-company-field-6"),
        value: "document"
      },
      {
        text: i18n.t("filters-company-field-7"),
        value: "address_city"
      },
      {
        text: i18n.t("filters-company-field-8"),
        value: "address_state"
      },
      {
        text: i18n.t("filters-company-field-9"),
        value: "address_country"
      },
      {
        text: i18n.t("filters-company-field-10"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-company-field-11"),
        value: "updated_at"
      }
    ],
    userFields: [
      {
        text: i18n.t("filters-user-field-1"),
        value: "first_name"
      },
      {
        text: i18n.t("filters-user-field-2"),
        value: "last_name"
      },
      {
        text: i18n.t("filters-user-field-3"),
        value: "job"
      },
      {
        text: i18n.t("filters-user-field-4"),
        value: "role"
      },
      {
        text: i18n.t("filters-user-field-5"),
        value: "gender"
      },
      {
        text: i18n.t("filters-user-field-6"),
        value: "birthdate"
      },
      {
        text: i18n.t("filters-user-field-7"),
        value: "email"
      },
      {
        text: i18n.t("filters-user-field-8"),
        value: "phone1"
      },
      {
        text: i18n.t("filters-user-field-9"),
        value: "document"
      },
      {
        text: i18n.t("filters-user-field-10"),
        value: "address_city"
      },
      {
        text: i18n.t("filters-user-field-11"),
        value: "address_state"
      },
      {
        text: i18n.t("filters-user-field-12"),
        value: "address_country"
      },
      {
        text: i18n.t("filters-user-field-13"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-user-field-14"),
        value: "updated_at"
      }
    ],
    productFields: [
      {
        text: i18n.t("filters-product-field-1"),
        value: "name"
      },
      {
        text: i18n.t("filters-product-field-2"),
        value: "color"
      },
      {
        text: i18n.t("filters-product-field-3"),
        value: "price"
      },
      {
        text: i18n.t("filters-product-field-4"),
        value: "sku"
      }
    ],
    billFields: [
      {
        text: i18n.t("filters-bill-field-1"),
        value: "description"
      },
      {
        text: i18n.t("filters-bill-field-14"),
        value: "tags"
      },
      {
        text: i18n.t("filters-bill-field-2"),
        value: "type"
      },
      {
        text: i18n.t("filters-bill-field-3"),
        value: "status"
      },
      {
        text: i18n.t("filters-bill-field-4"),
        value: "method"
      },
      {
        text: i18n.t("filters-bill-field-5"),
        value: "paid_at"
      },
      {
        text: i18n.t("filters-bill-field-6"),
        value: "due_date"
      },
      {
        text: i18n.t("filters-bill-field-7"),
        value: "url"
      },
      {
        text: i18n.t("filters-bill-field-8"),
        value: "total"
      },
      {
        text: i18n.t("filters-bill-field-9"),
        value: "subtotal"
      },
      {
        text: i18n.t("filters-bill-field-10"),
        value: "user_id"
      },
      {
        text: i18n.t("filters-bill-field-11"),
        value: "cost_center"
      },
      {
        text: i18n.t("filters-bill-field-12"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-bill-field-13"),
        value: "updated_at"
      }
    ],
    taskFields: [
      {
        text: i18n.t("filters-task-field-6"),
        value: "task_type_id"
      },
      {
        text: i18n.t("filters-task-field-7"),
        value: "name"
      },
      {
        text: i18n.t("filters-task-field-1"),
        value: "is_finished"
      },
      {
        text: i18n.t("filters-task-field-8"),
        value: "start"
      },
      {
        text: i18n.t("filters-task-field-9"),
        value: "end"
      },
      {
        text: i18n.t("filters-task-field-2"),
        value: "status"
      },
      {
        text: i18n.t("filters-task-field-3"),
        value: "responsible_user_id"
      },
      {
        text: i18n.t("filters-task-field-4"),
        value: "created_at"
      },
      {
        text: i18n.t("filters-task-field-5"),
        value: "updated_at"
      }
    ],
    operatorItems: [
      {
        text: i18n.t("filters-operator-1"),
        value: "="
      },
      {
        text: i18n.t("filters-operator-2"),
        value: "<>"
      },
      {
        text: i18n.t("filters-operator-3"),
        value: "like"
      },
      {
        text: i18n.t("filters-operator-4"),
        value: "not like"
      },
      {
        text: i18n.t("filters-operator-5"),
        value: ">"
      },
      {
        text: i18n.t("filters-operator-6"),
        value: ">="
      },
      {
        text: i18n.t("filters-operator-7"),
        value: "<"
      },
      {
        text: i18n.t("filters-operator-8"),
        value: "<="
      }
    ],
    filterSaves: [],
    currentFilterSave: {}
  },
  getters: {
    getFields: state => state.fields,
    getMassUpdateFields: state => state.massUpdateFields,
    getFilterEntityList: state => state.filterEntityList,
    getContactFields: state => state.contactFields,
    getCompanyFields: state => state.companyFields,
    getDealFields: state => state.dealFields,
    getBillFields: state => state.billFields,
    getTaskFields: state => state.taskFields,
    getPipelineFields: state => state.pipelineFields,
    getUserFields: state => state.userFields,
    getProductFields: state => state.productFields,
    getFilterActive: state => state.filterActive,
    getOperatorItems: state => state.operatorItems,
    getFilterSaves: state => state.filterSaves,
    getCurrentFilterSave: state => state.currentFilterSave
  },
  mutations: {
    setFields: (state, payload) => {
      state.fields = payload;
    },
    addFields: (state, payload) => {
      state.fields.push(payload);
    },
    removeField: (state, index) => {
      state.fields.splice(index, 1);
    },
    setMassUpdateFields: (state, payload) => {
      state.massUpdateFields = payload;
    },
    addUpdateFields: (state, payload) => {
      state.massUpdateFields.push(payload);
    },
    removeUpdateFields: (state, index) => {
      state.massUpdateFields.splice(index, 1);
    },
    resetFilters: state => {
      state.filterActive = false;
      state.fields = [
        {
          id: 0,
          filterField: "",
          operator: "like",
          valueField: {
            value: "",
            type: "text",
            mask: ""
          }
        }
      ];
      store.commit("resetMassUpdateFields");
    },
    resetMassUpdateFields: state => {
      state.massUpdateFields = [
        {
          id: 0,
          filterField: "",
          valueField: {
            value: "",
            type: "text",
            mask: ""
          }
        }
      ];
    },
    setFilterActive: (state, boolean) => {
      state.filterActive = boolean;
    },
    setFilterSaves: (state, payload) => {
      state.filterSaves = payload;
    },
    setCurrentFilterSave: (state, payload) => {
      state.currentFilterSave = payload;
    }
  },
  actions: {
    updateFilteredRecords({ getters, commit }, payload) {
      let params = getters.getExportParams;
      params.payload.values = payload;

      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/${params.entity}/filter`, params.payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-filtered-records-success"),
              status: "success"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-filtered-records-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteFilteredRecords({ getters, commit }) {
      const params = getters.getExportParams;
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/${params.entity}/filter`, {
            data: params.payload
          })
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-filtered-records-success"),
              status: "success"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-filtered-records-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterSavesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/filters`, {
            params: {
              entity: payload?.entity
            }
          })
          .then(resp => {
            const filterSaves = resp.data.data;
            commit("setFilterSaves", filterSaves);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filterSaves-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterSaveRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/filters/${id}`)
          .then(resp => {
            const filterSave = resp.data;
            commit("setCurrentFilterSave", filterSave);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filterSave-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createFilterSaveRequest({ commit }, filterSave) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/filters`, filterSave)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentFilterSave", data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-filterSave-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateFilterSaveRequest({ state, commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/filters/${state.currentFilterSave.id}`, payload)
          .then(resp => {
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-filterSave-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-filterSave-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteFilterSaveRequest({ dispatch, commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/filters/${id}`)
          .then(resp => {
            dispatch("filterSavesRequest");
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-filterSave-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
