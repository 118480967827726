<i18n>
{
  "en": {
    "title": "Add link",
    "input-1": "Insert url",
    "add": "Add"
  },
  "pt-BR": {
    "title": "Adicionar link",
    "input-1": "Insira a url",
    "add": "Adicionar"
  }
}
</i18n>

<template>
  <modal-action @close="closeModal" :columns="1">
    <template #view>
      <div class="modal" id="add_link">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="link" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button class="close" icon="times" @click="closeModal" />
          </div>
        </div>
        <div class="modal-body">
          <div class="section">
            <we-input type="text" v-model="link" :label="$t('input-1')" />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('add')"
            icon="plus"
            :loading="loadingBtn"
            @click="setUrl"
            :disabled="!link"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      link: "",
      dragovered: false,
      loadingBtn: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setUrl() {
      this.$emit("setUrl", this.link);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">
#action_modal {
  .modal#add_link {
    width: 100%;
    max-width: 361px;
    height: fit-content;

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .modal-body {
      display: grid;
      grid-template-columns: 1fr;
      padding: 26px 37px;

      .section {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
