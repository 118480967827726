var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"we-input we-input-contacts"},'div',_vm.$attrs,false),[(_vm.loading || _vm.loadingRequests)?_c('div',{staticClass:"we-input-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"18","width":"3"}})],1):_vm._e(),(_vm.label)?_c('div',{staticClass:"we-input-label"},[_c('span',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.label))]),(_vm.tooltip)?_c('we-icon-tooltip',{attrs:{"icon":['far', 'question-circle']},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.tooltip)+" ")]},proxy:true}],null,false,1845573592)}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"field"},[_c('v-autocomplete',{staticClass:"we-input-field contacts",attrs:{"menu-props":{ contentClass: 'contacts' },"hide-details":_vm.hideDetails,"outlined":"","dense":"","no-filter":"","multiple":_vm.multiple,"item-text":"first_name","item-value":"id","return-object":"","placeholder":_vm.placeholder,"readonly":_vm.readonly,"rules":_vm.inputRules,"is-required":_vm.isRequired,"items":_vm.itemsAndSelected},on:{"update:search-input":function($event){return _vm.findContacts($event)},"blur":_vm.onBlur,"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(_vm.searchLoading)?_c('div',{staticClass:"search-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"18","width":"3"}})],1):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?[_c('span',{staticClass:"first-selected"},[_c('we-rounded-picture',{attrs:{"picture":item.picture,"size":"24","text":_vm.$options.filters.firstLetters(
                  ((item.first_name) + " " + (item.last_name))
                )}}),_c('span',{staticClass:"selected-name"},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1)]:_vm._e(),(index === 1)?_c('span',{staticClass:"number"},[_vm._v(" +"+_vm._s(_vm.payload.length - 1)+" ")]):_vm._e()]}},{key:"item",fn:function(data){return [_c('we-rounded-picture',{attrs:{"picture":data.item.picture,"size":"24","text":_vm.$options.filters.firstLetters(
              ((data.item.first_name) + " " + (data.item.last_name))
            )}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(((data.item.first_name) + " " + (data.item.last_name)))}})],1)]}},{key:"append-item",fn:function(){return [(!_vm.searchLoading && _vm.canLoadMore)?_c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({ active: _vm.canLoadMore, callback: _vm.waypointCallback }),expression:"{ active: canLoadMore, callback: waypointCallback }"}],ref:"load",staticClass:"loader"}):_vm._e()]},proxy:true}]),model:{value:(_vm.payload),callback:function ($$v) {_vm.payload=$$v},expression:"payload"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }