<i18n>
{
  "en": {
    "title": "Welcome to wepipe! 🎉",
    "description-1": "To better use your",
    "description-2": "14 days trial of Scale plan",
    "description-3": ", here it's some helpful resources to get started:",
    "section-1": "Learn how to use",
    "section-2": "Get help",
    "ready": "Ready to go?",
    "button": "Create pipeline"
  },
  "pt-BR": {
    "title": "Seja bem-vindo à wepipe! 🎉",
    "description-1": "Para ter uma melhor experiência no seu teste de",
    "description-2": "14 dias gratuitos no plano Scale",
    "description-3": ", veja alguns recursos que disponibilizamos para você se tornar um wexpert:",
    "section-1": "Aprenda a usar o sistema",
    "section-2": "Consiga ajuda",
    "ready": "Pronto para começar?",
    
    "button": "Criar meu pipeline"
  }
}
</i18n>

<template>
  <modal-action class="onboarding" v-if="isAdmin && opened">
    <template #view>
      <div class="modal" id="onboarding">
        <div class="modal-body">
          <h1 class="we-title">{{ $t("title") }}</h1>
          <p class="description">
            {{ $t("description-1") }} <b>{{ $t("description-2") }}</b
            >{{ $t("description-3") }}
          </p>
          <h3 class="section-title">
            {{ $t("section-1") }}
          </h3>
          <div class="onboarding-grid">
            <a
              v-for="item in learningItems"
              :item="item"
              :key="item.name"
              target="_blank"
              :href="item.url"
            >
              <we-card-icon
                class="button"
                color="primary"
                :icon="item.icon"
                :label="item.label"
                :title="item.name"
              >
                <template #sublabel>
                  <font-awesome-icon icon="play" />
                  {{ item.time }}
                </template>
              </we-card-icon>
            </a>
          </div>
          <h3 class="section-title">
            {{ $t("section-2") }}
          </h3>
          <div class="onboarding-grid">
            <a
              v-for="item in helpItems"
              :item="item"
              :key="item.name"
              target="_blank"
              :href="item.url"
            >
              <we-card-icon
                class="button"
                :icon="item.icon"
                :label="item.label"
                :title="item.name"
              />
            </a>
          </div>
          <div class="ready">
            <h2 class="ready-title">
              {{ $t("ready") }}
            </h2>
            <we-button
              class="primary"
              :text="$t('button')"
              @click="openCreatePipeline"
            />
          </div>
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
export default {
  computed: {
    opened() {
      return this.$store.getters.getOnboardingOpened;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    learningItems() {
      return this.$store.getters.getLearningItems;
    },
    helpItems() {
      return this.$store.getters.getHelpItems;
    }
  },
  methods: {
    openCreatePipeline() {
      this.$store.commit("setOnboardingOpened", false);
      this.$router.push({ name: "createPipelineOnboarding" });
    }
  }
};
</script>

<style lang="scss">
#action_modal.onboarding {
  z-index: 8;
  .modal#onboarding {
    max-width: 900px;

    @include mobile {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .modal-body {
      padding: 42px;

      @include sm {
        padding: 30px 15px;
      }
      h1.we-title {
        font-size: 36px;
        font-weight: $semibold;
        line-height: 42px;
        color: var(--text-1);
        margin-bottom: 18px;
      }

      p.description {
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 32px;

        b {
          font-weight: $semibold;
        }
      }

      .section-title {
        font-size: 16px;
        font-weight: $semibold;
        margin-bottom: 16px;
      }

      .onboarding-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 12px;
        margin-bottom: 32px;
      }

      .ready {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding-top: 32px;
        border-top: 1px solid var(--line);
        gap: 18px;

        @include mobile {
          display: block;
        }

        .ready-title {
          font-size: 24px;

          @include mobile {
            margin-bottom: 24px;
          }
        }

        .we-button {
          justify-self: flex-end;
          margin: initial;

          @include mobile {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
