<i18n>
{
  "en": {
    "required": "required"
  },
  "pt-BR": {
    "required": "obrigatório"
  }
}
</i18n>

<template>
  <div class="we-input we-input-deals" v-bind="$attrs">
    <div v-if="loading || loadingRequests" class="we-input-loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="18"
        width="3"
      ></v-progress-circular>
    </div>
    <div v-if="label" class="we-input-label">
      <span class="label-text">{{ label }}</span>
      <we-icon-tooltip :icon="['far', 'question-circle']" v-if="tooltip">
        <template #text>
          {{ tooltip }}
        </template>
      </we-icon-tooltip>
    </div>
    <div class="field">
      <v-autocomplete
        class="we-input-field deals"
        :menu-props="{ contentClass: 'deals' }"
        :hide-details="hideDetails"
        outlined
        dense
        no-filter
        :multiple="multiple"
        item-text="first_name"
        item-value="id"
        return-object
        v-model="payload"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="itemsAndSelected"
        @update:search-input="findDeals($event)"
        @blur="onBlur"
        @focus="onFocus"
      >
        <template #prepend-item>
          <div v-if="searchLoading" class="search-loading">
            <v-progress-circular
              indeterminate
              color="primary"
              size="18"
              width="3"
            ></v-progress-circular>
          </div>
        </template>
        <template #selection="{ item, index }">
          <template v-if="index === 0">
            <span class="first-selected">
              <span class="selected-name">
                {{ item.name }}
              </span>
            </span>
          </template>
          <span class="number" v-if="index === 1">
            +{{ payload.length - 1 }}
          </span>
        </template>
        <template #item="data">
          <v-list-item-content>
            <we-text-tooltip :text="data.item.name" />
            <div class="description">
              <font-awesome-icon icon="columns" />
              <b>{{ data.item.pipeline }}</b>
              <font-awesome-icon icon="signal" />
              <b>{{ data.item.pipeline_stage }}</b>
            </div>
          </v-list-item-content>
        </template>
        <template #append-item>
          <div
            v-if="!searchLoading && canLoadMore"
            ref="load"
            v-waypoint="{ active: canLoadMore, callback: waypointCallback }"
            class="loader"
          ></div>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "WeInput",
  inheritAttrs: false,
  props: {
    tooltipName: String,
    label: String,
    tooltip: String,
    inputLabel: String,
    hint: String,
    counter: String,
    maxlength: String,
    prefix: String,
    suffix: String,
    mask: [String, Array],
    maxDate: String,
    minDate: String,
    placeholder: String,
    hideDetails: {
      type: [String, Boolean],
      default: "auto"
    },
    rules: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, Number]
    }
  },
  data() {
    return {
      payload: this.value,
      searchLoading: false,
      loadingRequests: false,
      items: [],
      response: {
        deals: [],
        page: {}
      },
      firstEvent: true,
      searchValue: "%"
    };
  },
  watch: {
    payload: {
      handler() {
        this.onInput();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    fieldName() {
      return this.label ? this.label : this.placeholder;
    },
    requiredRules() {
      return this.isRequired
        ? [v => !!v || `${this.fieldName} ${this.$t("required")}`]
        : [];
    },
    inputRules() {
      return [...this.requiredRules, ...this.rules];
    },
    canLoadMore() {
      return this.response?.last_page != this.response?.current_page;
    },
    itemsAndSelected() {
      if (!this.payload) return this.items;

      if (Array.isArray(this.payload)) return [...this.payload, ...this.items];

      return [this.payload, ...this.items];
    }
  },
  methods: {
    ...mapActions(["findDealsRequest", "dealRequest"]),
    onFocus() {
      this.loadDeals({ page: 1 });
    },
    onBlur() {},
    onInput() {
      if (!this.payload) {
        return;
      }

      this.$emit("input", this.payload);
    },
    async loadDeals({ page }) {
      this.searchLoading = true;

      const response = await this.findDealsRequest({
        attribute: "name",
        value: this.searchValue,
        updateLocal: true,
        page: page
      });

      if (Array.isArray(response.data)) {
        this.response = response;
        this.items = [...this.items, ...response.data];
      }
      this.searchLoading = false;
    },
    waypointCallback({ going, direction }) {
      const evt = { going, direction };
      if (evt.going === "in" && this.firstEvent) {
        this.firstEvent = false;
        this.loadMoreDeals();
      } else if (
        evt.going === "in" &&
        ["top", "right"].includes(evt.direction)
      ) {
        this.loadMoreDeals();
      }
    },
    loadMoreDeals() {
      const nextPage = this.response?.current_page + 1;
      if (this.response?.last_page >= nextPage) {
        this.loadDeals({ page: nextPage });
      }
    },
    findDeals(val) {
      this.items = [];
      this.firstEvent = true;
      if (val?.length > 0) {
        this.searchLoading = true;
      } else {
        this.searchValue = "%";
        this.loadDeals({ page: 1 });
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (val?.length > 0) {
          this.searchValue = val;
          await this.loadDeals({ page: 1 });
        }
      }, 600);
    }
  },
  async mounted() {
    this.loadingRequests = true;

    if (Array.isArray(this.payload)) {
      this.loadingRequests = false;
      return;
    }

    if (this.payload && !isNaN(this.payload)) {
      const deal = await this.dealRequest(this.payload);
      this.payload = deal.data;
    }

    this.loadingRequests = false;
  }
};
</script>
