<template>
  <div class="we-not-found">
    <div class="not-found-content">
      <div class="we-title">
        <slot name="title" />
      </div>
      <div class="description">
        <slot name="description" />
      </div>
      <div class="actions">
        <slot name="actions" />
      </div>
      <div class="picture">
        <slot name="picture" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss">
.we-not-found {
  text-align: center;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  &.relative {
    position: relative;
  }

  &.small {
    padding: 25px;
    .not-found-content {
      .we-title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
      }

      .description {
        font-size: 14px;
      }

      .picture {
        img {
          max-height: 150px;
        }
      }
    }
  }

  .not-found-content {
    margin: auto 0;
    .we-title {
      font-size: 24px;
      line-height: 30px;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 18px;

      b {
        font-weight: $semibold;
      }
    }

    .description {
      color: var(--text-2);
      font-weight: $medium;
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 40px;

      b {
        font-weight: $semibold;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex-wrap: wrap;
    }

    .picture {
      display: block;
      width: 100%;

      img {
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
      }
    }
  }
}
</style>
