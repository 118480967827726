var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"rounded":"","left":_vm.left,"right":_vm.right,"offset-x":"","content-class":"we-deal-contact","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.fromDeal)?[_c('div',{staticClass:"select-deal-contact"},[_c('we-rounded-picture',{attrs:{"size":"24","picture":_vm.contact.picture,"name":((_vm.contact.first_name) + " " + (_vm.contact.last_name)),"text":_vm.$options.filters.firstLetters(
                ((_vm.contact.first_name) + " " + (_vm.contact.last_name))
              )}}),_c('we-text-tooltip',{attrs:{"text":((_vm.contact.first_name) + " " + (_vm.contact.last_name)),"maxWidth":"fit-content"}})],1)]:[_vm._t("action")]],2)]}}])},[_c('v-list',[_c('div',{staticClass:"top"},[_c('we-rounded-picture',{attrs:{"size":"72","picture":_vm.contact.picture,"name":((_vm.contact.first_name) + " " + (_vm.contact.last_name)),"text":_vm.$options.filters.firstLetters(
            ((_vm.contact.first_name) + " " + (_vm.contact.last_name))
          )}}),_c('div',{staticClass:"data"},[_c('we-text-tooltip',{staticClass:"contact-name",attrs:{"text":((_vm.contact.first_name) + " " + (_vm.contact.last_name)),"maxWidth":"fit-content"}}),_c('we-text-tooltip',{staticClass:"contact-email",attrs:{"text":_vm.contact.email,"maxWidth":"fit-content"}}),_c('div',{staticClass:"socials"},_vm._l((_vm.socials),function(social){return _c('we-icon-button',{key:social.text,staticClass:"small",attrs:{"icon":social.icon,"name":social.text,"direction":"bottom"},on:{"click":social.click}})}),1)],1)],1),_c('div',{staticClass:"data-fields"},[_c('we-data-field',{attrs:{"label":_vm.$t('phone'),"value":_vm.contact.phone1}}),_c('we-data-field',{attrs:{"label":_vm.$t('gender'),"value":_vm.contact.gender}})],1),_c('div',{staticClass:"actions"},_vm._l((_vm.actions),function(action){return _c('we-button',{key:action.text,staticClass:"action",class:action.class,attrs:{"text":action.text,"icon":action.icon},on:{"click":action.click}})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }