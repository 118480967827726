<i18n>
{
  "en": {
    "title-create": "New Save",
    "title-update": "Update Save",
    "input-1": "Name",
    "input-3": "Enable for all users",
    "create": "Create save",
    "update": "Update save",
    "delete": "Delete save"
  },
  "pt-BR": {
    "title-create": "Salvar Novo Filtro",
    "title-update": "Editar Filtro Selecionado",
    "input-1": "Nome",
    "input-3": "Visível para todos usuários",
    "create": "Salvar filtro",
    "update": "Atualizar filtro",
    "delete": "Deletar filtro"
  }
}
</i18n>

<template>
  <modal-action @close="closeModal" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon :icon="filterSaveExist ? 'edit' : 'save'" />
            </div>
            {{ filterSaveExist ? $t("title-update") : $t("title-create") }}
          </div>
          <div class="options">
            <we-icon-button
              v-if="filterSaveExist"
              :icon="['far', 'trash-alt']"
              :name="$t('delete')"
              @click="deleteFilterSave"
            />
            <we-icon-button icon="times" @click="closeModal" />
          </div>
        </div>
        <v-form ref="form" class="modal-body">
          <div class="fields">
            <we-input
              type="text"
              :label="$t('input-1')"
              v-model="filterSave.name"
              is-required
            />
            <v-switch
              v-model="filterSave.is_public"
              :label="$t('input-3')"
              hide-details
            />
          </div>
        </v-form>
        <div class="bottom">
          <we-button
            class="green"
            :text="filterSaveExist ? $t('update') : $t('create')"
            :icon="filterSaveExist ? 'check' : 'plus'"
            :loading="btnLoading"
            @click="filterSaveAction"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      loading: true,
      filterSave: {
        name: "",
        is_public: false,
        entity: "",
        parameters: ""
      }
    };
  },
  computed: {
    entityList() {
      return this.$store.getters.getFilterEntityList;
    },
    filterSaveExist() {
      return this.filterSave.id;
    },
    dealCustomFields() {
      return this.$store.getters.getDealCustomFieldsInputs;
    },
    customFieldsList() {
      let items = [];
      if (this.dealCustomFields.length > 0) {
        items = [...this.dealCustomFields].map(e => {
          return {
            name: e.label,
            id: e.id
          };
        });
      }

      return items;
    }
  },
  methods: {
    ...mapActions([
      "updateFilterSaveRequest",
      "createFilterSaveRequest",
      "deleteFilterSaveRequest",
      "filterSaveRequest",
      "filterSavesRequest"
    ]),
    async deleteFilterSave() {
      this.loading = true;
      this.$store.commit("setCurrentFilterSave", {});
      await this.deleteFilterSaveRequest(this.filterSave.id);
      this.closeModal();
      this.loading = false;
    },
    filterSaveAction() {
      const validated = this.$refs.form.validate();

      if (validated) {
        const mappedFields = this.$store.getters.getFields.map(e => {
          const filter = {
            field: e.filterField,
            operator: e.operator,
            value: e.valueField.value
          };

          if (["false", "true"].includes(filter.operator)) {
            filter.value = filter.operator;
            filter.operator = "=";
          }

          if (["null"].includes(filter.operator)) {
            filter.value = "";
            filter.operator = "=";
          }
          return filter;
        });

        this.filterSave.filters = mappedFields;
        this.filterSaveExist ? this.editSave() : this.createSave();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async editSave() {
      this.btnLoading = true;
      await this.updateFilterSaveRequest(this.filterSave);
      await this.filterSavesRequest();
      this.closeModal();
      this.btnLoading = false;
    },
    async createSave() {
      this.btnLoading = true;
      await this.createFilterSaveRequest(this.filterSave);
      await this.filterSavesRequest();
      this.closeModal();
      this.btnLoading = false;
    }
  },
  async mounted() {
    this.filterSave = this.$store.getters.getCurrentFilterSave;
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
#action_modal.columns-1 .view .modal {
  width: 414px;
}
</style>
