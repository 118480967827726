import store from "@/store";
import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "companies-error": "Wasn't possible to load companies!",
    "company-error": "Wasn't possible to load company data!",
    "create-company-error": "Wasn't possible to create company!",
    "update-company-success": "Company updated with success!",
    "update-company-error": "Wasn't possible to update company!",
    "update-company-picture-error":
      "Wasn't possible to update company picture!",
    "delete-company-picture-error":
      "Wasn't possible to delete company picture!",
    "delete-company-error": "Wasn't possible to delete company!",
    "find-companies-error": "Wasn't possible to find companies!",
    "search-companies-error": "Wasn't possible to search companies!",
    "filter-companies-error": "Wasn't possible to filter companies!",
    "company-contacts-error": "Wasn't possible to load related contacts!"
  },
  "pt-BR": {
    "companies-error": "Não foi possível carregar as empresas!",
    "company-error": "Não foi possível carregar os dados da empresa!",
    "create-company-error": "Não foi possível criar a empresa!",
    "update-company-success": "Empresa atualizada com sucesso!",
    "update-company-error": "Não foi possível atualizar a empresa!",
    "update-company-picture-error":
      "Não foi possível atualizar a foto da empresa!",
    "delete-company-picture-error":
      "Não foi possível deletar a foto da empresa!",
    "delete-company-error": "Não foi possível deletar a empresa!",
    "find-companies-error": "Não foi possível pesquisar as empresas!",
    "search-companies-error": "Não foi possível realizar a pesquisa!",
    "filter-companies-error": "Não foi possível realizar o filtro!",
    "company-contacts-error":
      "Não foi possível carregar os contatos relacionados à empresa!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    companies: [],
    currentCompany: {},
    companyCustomFields: null,
    companiesPages: {
      last: 1
    },
    companiesSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    companyContacts: {}
  },
  getters: {
    getCompanies: state => state.companies,
    getCurrentCompany: state => state.currentCompany,
    getCompanyCustomFields: state => state.companyCustomFields,
    getCompaniesPages: state => state.companiesPages,
    getCompaniesSort: state => state.companiesSort,
    getCompanyContacts: state => state.companyContacts
  },
  mutations: {
    setCompanies: (state, payload) => {
      state.companies = payload;
    },
    setCompaniesPages: (state, payload) => {
      state.companiesPages = payload;
    },
    setCompaniesSort: (state, payload) => {
      state.companiesSort = payload;
    },
    setCurrentCompany: (state, payload) => {
      state.currentCompany = payload;
    },
    clearCurrentCompanyCep: state => {
      state.currentCompany.address_latitude = "";
      state.currentCompany.address_longitude = "";
      state.currentCompany.address_street = "";
      state.currentCompany.address_block = "";
      state.currentCompany.address_city = "";
      state.currentCompany.address_state = "";
      state.currentCompany.address_country = "";
    },
    setCurrentCompanyCep: (state, payload) => {
      state.currentCompany.address_latitude = payload?.latitude;
      state.currentCompany.address_longitude = payload?.longitude;
      state.currentCompany.address_street = payload?.logradouro;
      state.currentCompany.address_block = payload?.bairro;
      state.currentCompany.address_city = payload?.cidade.nome;
      state.currentCompany.address_state = payload?.estado.sigla;
      state.currentCompany.address_country = "Brasil";
    },
    setCompanyCustomFieldsValues: (state, payload) => {
      state.companyCustomFields = payload;
    },
    clearCompanyCustomFieldsValues: state => {
      if (state.companyCustomFields) {
        Object.keys(state.companyCustomFields).forEach(index => {
          state.companyCustomFields[index] = "";
        });
      }
    },
    createCompanyCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.companyCustomFields = { ...object, ...state.companyCustomFields };
    },
    setCompanyContacts: (state, payload) => {
      state.companyContacts = payload;
    }
  },
  actions: {
    companiesRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};

      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/companies`, {
            params: {
              page: page,
              ...orderBy
            }
          })
          .then(resp => {
            const companies = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            if (!payload.updateLocal) {
              commit("setCompaniesPages", page);
              commit("setCompanies", companies);
            }

            resolve({ companies, page });
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("companies-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    companyRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/companies/${id}`)
          .then(resp => {
            const company = resp.data;
            commit("setCurrentCompany", company);
            commit("clearCompanyCustomFieldsValues");
            if (company.custom_fields) {
              commit("setCompanyCustomFieldsValues", company.custom_fields[0]);
            }
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("company-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createCompanyRequest({ commit }, company) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/companies`, company)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentCompany", data);
            store.dispatch("companiesRequest", { page: 1 });
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-company-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateCompanyRequest({ state, commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/companies/${state.currentCompany.id}`, payload)
          .then(resp => {
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-company-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-company-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateCompanyPictureRequest({ state, commit }, payload) {
      let formData = new FormData();
      formData.append("profile_picture", payload);

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(
            `/companies/${state.currentCompany.id}/uploadProfilePicture`,
            formData
          )
          .then(resp => {
            commit("setCurrentCompany", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-company-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteCompanyPictureRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/companies/${id}/removeProfilePicture`)
          .then(resp => {
            commit("setCurrentCompany", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-company-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteCompanyRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/companies/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-company-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    findCompaniesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/companies/find`, {
            params: { ...payload }
          })
          .then(resp => {
            const companies = resp.data.data;

            if (!payload?.updateLocal) {
              commit("setCompanies", companies);
            }

            resolve(resp.data);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("find-companies-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    searchCompaniesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/companies/search`, { params: payload })
          .then(resp => {
            const companies = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setCompaniesPages", page);

            commit("setCompanies", companies);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-companies-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterCompaniesRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/companies/export",
        entity: "companies",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/companies/filter?paginate=true`, payload)
          .then(resp => {
            const companies = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setCompaniesPages", page);

            commit("setCompanies", companies);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-companies-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    companyContactsRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/companies/${id}/contacts`)
          .then(resp => {
            const companyContacts = resp.data;
            commit("setCompanyContacts", companyContacts);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("company-contacts-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
