import router from "@/router/index.js";
import store from "@/store";
import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "user-role-1": "Administrator",
    "user-role-2": "Agent",
    "user-role-3": "Restricted agent",
    "user-role-4": "External agent",
    "user-role-5": "Manager",
    "user-role-6": "Visitor",
    "users-error": "Wasn't possible to load users!",
    "user-error": "Wasn't possible to load user data!",
    "logged-user-error-description": "Please login again to access wepipe.",
    "create-user-error": "Wasn't possible to create user!",
    "update-user-success": "User updated with success!",
    "update-user-error": "Wasn't possible to update user!",
    "delete-user-error": "Wasn't possible to delete user!",
    "update-user-picture-error": "Wasn't possible to update user picture!",
    "delete-user-picture-error": "Wasn't possible to delete user picture!",
    "delete-user-success": "User deleted with success!",
    "find-users-error": "Wasn't possible to find users!",
    "search-users-error": "Wasn't possible to search users!",
    "filter-users-error": "Wasn't possible to filter users!"
  },
  "pt-BR": {
    "user-role-1": "Administrador",
    "user-role-2": "Agente",
    "user-role-3": "Agente restrito",
    "user-role-4": "Agente externo",
    "user-role-5": "Gerente",
    "user-role-6": "Visitante",
    "users-error": "Não foi possível carregar os usuários!",
    "user-error": "Não foi possível carregar os dados do usuário!",
    "logged-user-error-description":
      "Faça o login novamente para ter acesso à plataforma.",
    "create-user-error": "Não foi possível criar o usuário!",
    "update-user-success": "Usuário atualizado com sucesso!",
    "update-user-error": "Não foi possível atualizar o usuário!",
    "delete-user-error": "Não foi possível deletar o usuário!",
    "update-user-picture-error":
      "Não foi possível atualizar a foto do usuário!",
    "delete-user-picture-error": "Não foi possível deletar a foto do usuário!",
    "delete-user-success": "Usuário deletado com sucesso!",
    "find-users-error": "Não foi possível pesquisar usuários!",
    "search-users-error": "Não foi possível realizar a pesquisa!",
    "filter-users-error": "Não foi possível realizar o filtro!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    users: [],
    currentUser: {},
    loggedUser: null,
    userCustomFields: null,
    usersPages: {
      last: 1
    },
    usersSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    userRoleList: [
      {
        text: i18n.t("user-role-1"),
        value: "admin"
      },
      {
        text: i18n.t("user-role-2"),
        value: "agent"
      },
      {
        text: i18n.t("user-role-3"),
        value: "restricted agent"
      },
      {
        text: i18n.t("user-role-4"),
        value: "external agent"
      },
      {
        text: i18n.t("user-role-5"),
        value: "manager"
      },
      {
        text: i18n.t("user-role-6"),
        value: "visitor"
      }
    ],
    firebaseRegistrationId: null
  },
  getters: {
    getUsers: state => state.users,
    getCurrentUser: state => state.currentUser,
    getLoggedUser: state => (state.loggedUser ? state.loggedUser : {}),
    getLoggedUserIsAdmin: state => {
      return (
        state.loggedUser && ["admin", "manager"].includes(state.loggedUser.role)
      );
    },
    getLoggedUserId: state =>
      localStorage.getItem("logged-user-id")
        ? localStorage.getItem("logged-user-id")
        : state.loggedUser.id,
    getUserCustomFields: state => state.userCustomFields,
    getUsersPages: state => state.usersPages,
    getUsersSort: state => state.usersSort,
    getUserRoleList: state => state.userRoleList,
    getFirebaseRegistrationId: state =>
      state.firebaseRegistrationId ||
      localStorage.getItem("we-firebase-registration-id")
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload;
    },
    setUsersPages: (state, payload) => {
      state.usersPages = payload;
    },
    setUsersSort: (state, payload) => {
      state.usersSort = payload;
    },
    setCurrentUser: (state, payload) => {
      state.currentUser = payload;
    },
    setLoggedUser: (state, payload) => {
      state.loggedUser = payload;

      const encodedRole = btoa(payload.role);

      localStorage.setItem("we-UR", encodedRole);
      localStorage.setItem("logged-user-id", payload.id);
    },
    clearCurrentUserCep: state => {
      state.currentUser.address_latitude = "";
      state.currentUser.address_longitude = "";
      state.currentUser.address_street = "";
      state.currentUser.address_block = "";
      state.currentUser.address_city = "";
      state.currentUser.address_state = "";
      state.currentUser.address_country = "";
    },
    setCurrentUserCep: (state, payload) => {
      state.currentUser.address_latitude = payload?.latitude;
      state.currentUser.address_longitude = payload?.longitude;
      state.currentUser.address_street = payload?.logradouro;
      state.currentUser.address_block = payload?.bairro;
      state.currentUser.address_city = payload?.cidade.nome;
      state.currentUser.address_state = payload?.estado.sigla;
      state.currentUser.address_country = "Brasil";
    },
    setUserCustomFieldsValues: (state, payload) => {
      state.userCustomFields = payload;
    },
    clearUserCustomFieldsValues: state => {
      if (state.userCustomFields) {
        Object.keys(state.userCustomFields).forEach(index => {
          state.userCustomFields[index] = "";
        });
      }
    },
    createUserCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.userCustomFields = { ...object, ...state.userCustomFields };
    },
    setFirebaseRegistrationId: (state, payload) => {
      state.firebaseRegistrationId = payload;
      localStorage.setItem(
        "we-firebase-registration-id",
        state.firebaseRegistrationId
      );
    }
  },
  actions: {
    usersRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/users`, {
            params: {
              page: page,
              ...orderBy,
              paginate: payload?.paginate === false ? payload.paginate : true
            }
          })
          .then(resp => {
            let users = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            if (payload?.paginate === false) {
              users = resp.data;
            }

            if (!payload?.updateLocal) {
              commit("setUsersPages", page);
              commit("setUsers", users);
            } else {
              users = resp.data;
            }

            resolve({ users, page });
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("users-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    userRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/users/${id}`)
          .then(resp => {
            const user = resp.data;
            commit("setCurrentUser", user);
            commit("clearUserCustomFieldsValues");
            if (user.custom_fields) {
              commit("setUserCustomFieldsValues", user.custom_fields[0]);
            }
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("user-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    loggedUserRequest({ commit, getters, dispatch }) {
      const userId = localStorage.getItem("logged-user-id");
      const registrationId = getters.getFirebaseRegistrationId;
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/users/${userId}`)
          .then(resp => {
            const user = resp.data;
            commit("setLoggedUser", user);

            if (
              !user.firebase_registration_ids ||
              !Array.isArray(user.firebase_registration_ids)
            ) {
              user.firebase_registration_ids = [];
            }

            if (
              registrationId?.length > 0 &&
              !user.firebase_registration_ids.includes(registrationId)
            ) {
              user.firebase_registration_ids.push(registrationId);
              dispatch("updateUserRequest", user);
            }

            resolve(resp);
          })
          .catch(err => {
            commit("resetToken");
            if (typeof userId != Number) {
              const snackBar = {
                title: i18n.t("user-error"),
                description: i18n.t("logged-user-error-description"),
                status: "error"
              };

              commit("addSnackBar", snackBar);
            }
            resolve(err);
          });
      });
    },
    createUserRequest({ commit }, user) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/users`, user)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentUser", data);
            store.dispatch("usersRequest");
            router
              .push({ name: "user", params: { userId: data.id } })
              .catch(() => {});
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-user-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateUserRequest({ commit, getters }, payload) {
      const loggedUser = getters.getLoggedUser;

      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/users/${payload.id}`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-user-success"),
              status: "success"
            };

            if (payload.id === loggedUser.id) {
              commit("setLoggedUser", resp.data);
            }

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-user-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    deleteUserRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/users/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-user-success"),
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-user-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateUserPictureRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("profile_picture", payload.picture);

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/users/${payload.id}/uploadProfilePicture`, formData)
          .then(resp => {
            commit("setCurrentUser", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-user-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteUserPictureRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/users/${id}/removeProfilePicture`)
          .then(resp => {
            commit("setCurrentUser", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-user-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    findUsersRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/users/find`, {
            params: { ...payload }
          })
          .then(resp => {
            const users = resp.data.data;

            if (!payload?.updateLocal) {
              commit("setUsers", users);
            }

            resolve(resp.data);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("find-users-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    searchUsersRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/users/search`, { params: payload })
          .then(resp => {
            const users = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setUsersPages", page);

            commit("setUsers", users);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-users-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterUsersRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/users/export",
        entity: "users",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/users/filter?paginate=true`, payload)
          .then(resp => {
            const users = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setUsersPages", page);
            commit("setUsers", users);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-users-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
