<template>
  <v-app id="app" class="theme-v2">
    <router-view />
    <we-lightbox />
    <we-snack-bar />
    <new-version />
  </v-app>
</template>

<script>
import "@/styles/_font.scss";
import "@/styles/_colors.scss";
import "@/styles/_forms.scss";
import "@/styles/_menus.scss";
import "@/styles/_cards.scss";
import "@/styles/_animations.scss";
import "@/styles/_tour.scss";
import firebaseMessaging from "./plugins/firebaseMessaging";

export default {
  mixins: [firebaseMessaging],
  mounted() {
    const theme = localStorage.getItem("theme");

    if (theme === "dark") {
      this.$vuetify.theme.dark = true;
    }
    if (theme === "light") {
      this.$vuetify.theme.dark = false;
    }
  },
  created() {
    window.addEventListener(
      "beforeinstallprompt",
      e => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.$store.commit("setInstallPwa", {
          available: true,
          deferredPrompt: e
        });
      },
      { once: true }
    );
  },
  beforeRouteUpdate(to, from, next) {
    next();
  }
};
</script>

<style lang="scss">
body,
html {
  font-family: $font;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;

  &:hover {
    color: var(--text-1);
  }
}

//Dark theme
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  &.v-application {
    color: var(--text-1);
  }

  .v-card {
    &::before {
      background: var(--action);
    }

    &.v-card--link {
      &:focus:before {
        opacity: 0.3;
      }
    }

    .v-ripple__container {
      background: var(--background-2);
      opacity: 0.1;
    }
  }

  .v-list {
    background: var(--background-1);
  }

  .v-card {
    background: var(--background-1);
  }

  .v-picker__body {
    background: var(--background-2);
  }

  .v-time-picker-clock {
    background: var(--background-1);
  }

  .v-date-picker-table .v-btn.v-btn--active {
    background: var(--primary-bg) !important;
    color: var(--text-1) !important;
    font-weight: $semibold;
  }
}

h2 {
  font-size: 1.3em;
}

button {
  &:focus {
    outline: 0;
  }
}

.only-desktop {
  @include mobile {
    display: none !important;
  }
}

.only-mobile {
  @include desktop {
    display: none !important;
  }
}

$scrollbar-width: 5px;
$scrollbar-padding: 15px;

@include desktop {
  *::-webkit-scrollbar-track {
    background-color: var(--inherit);
    opacity: 0;
    pointer-events: all;
  }

  *::-webkit-scrollbar {
    height: (($scrollbar-padding * 2) + $scrollbar-width);
    width: 6px;
    height: 6px;
    background-color: var(--inherit);
    opacity: 0;
    pointer-events: all;
  }

  *::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: var(--action-hover);
    background-clip: padding-box;
    pointer-events: all;

    &:hover {
      background-color: var(--primary);
    }
  }

  .hide-scrollbar {
    @include desktop {
      mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 6.5px, black 6.5px);
      mask-size: 100% 20000px;
      mask-position: left bottom;
      -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 6.5px, black 6.5px);
      -webkit-mask-size: 100% 20000px;
      -webkit-mask-position: left bottom;
      transition: mask-position 0.3s, -webkit-mask-position 0.3s;

      &:hover {
        mask-position: left top;
        -webkit-mask-position: left top;
      }
    }
  }

  .x-scrollbar {
    &::-webkit-scrollbar-track {
      background-color: var(--inherit);
      opacity: 0;
    }

    &::-webkit-scrollbar {
      height: (($scrollbar-padding * 2) + $scrollbar-width);
      height: 12px;
      background-color: var(--inherit);
      opacity: 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 12px;
      background-color: var(--action-hover);
      background-clip: padding-box;

      &:hover {
        background-color: var(--primary);
      }
    }
  }
}
</style>
