<i18n>
{
  "en": {
    "title": "New version available!",
    "description": "Update the new version and check out the new features.",
    "cancel": "Cancel",
    "install": "Update",
    "install-error-title": "Wasn't possible to update!",
    "install-error-description": "Please press Ctrl + F5 and try to update again."
  },
  "pt-BR": {
    "title": "Nova versão disponível",
    "description": "Atualize para a nova versão e confira as novas funcionalidades.",
    "cancel": "Cancelar",
    "install": "Atualizar",
    "install-error-title": "Não foi possível atualizar!",
    "install-error-description": "Por favor, pressione Ctrl + F5 e tente atualizar novamente."
  }
}
</i18n>

<template>
  <modal-action v-if="opened" @close="close">
    <template #view>
      <div class="modal" id="new_version">
        <div class="modal-body">
          <div class="we-title">
            {{ $t("title") }}
          </div>
          <div class="we-description">
            {{ $t("description") }}
          </div>
          <img
            src="@/assets/undraw/upgrade.svg"
            :alt="$t('title')"
            width="184"
            height="134"
          />
        </div>
        <div class="bottom double-action">
          <we-button class="disabled" :text="$t('cancel')" @click="close" />
          <we-button
            class="primary"
            :text="$t('install')"
            @click="updateVersion"
            cy="confirm-install"
            :loading="loading"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      loading: false,
      registration: null
    };
  },
  methods: {
    updateVersion() {
      this.loading = true;
      if (this.registration?.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
        this.close();
      } else {
        const snackBar = {
          title: this.$t("install-error-title"),
          description: this.$t("install-error-description"),
          time: 10000,
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        this.loading = false;
      }
    },
    showNewVersionDialog(e) {
      this.registration = e.detail;
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  },
  created() {
    document.addEventListener(
      "serviceWorkerUpdateEvent",
      this.showNewVersionDialog,
      {
        once: true
      }
    );
  }
};
</script>

<style lang="scss" scoped>
#action_modal {
  z-index: 8;

  .modal#new_version {
    width: 100%;
    max-width: 420px;
    min-height: 340px;
    height: fit-content;

    @include mobile {
      display: block;
      position: fixed;
      bottom: 15px;
      left: 15px;
      width: calc(100vw - 30px);
      max-width: 100%;
      height: 300px;
      border-radius: 10px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 42px 45px;

      @include mobile {
        padding: 30px 30px;
      }

      .we-title {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        font-style: normal;
        font-weight: $semibold;
        color: var(--text-1);
        margin-bottom: 17px;

        @include mobile {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .we-description {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        font-weight: $regular;
        color: var(--text-2);
        margin-bottom: 32px;

        @include mobile {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }

      img {
        @include mobile {
          width: 100%;
          max-width: 150px;
          height: auto;
        }
      }
    }

    .bottom {
      @include mobile {
        position: absolute;
        background: var(--background-1);
        border-top: none;
        height: 70px;
      }
    }
  }
}
</style>
