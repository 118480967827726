<template>
  <div id="modal-entity">
    <router-view />
    <transition appear name="fade">
      <we-shadow @click="closeModal" />
    </transition>
    <transition appear name="slide-left">
      <div id="entity" @dragover="dragover" :class="{ dragovered }">
        <div class="dragover" @dragleave="dragleave" @drop="drop">
          Solte os arquivos a serem carregados
        </div>
        <div class="head">
          <div class="options">
            <slot name="actions" />
          </div>
          <div class="data">
            <slot name="data" />
          </div>
          <nav class="right-menu">
            <v-tooltip
              left
              v-for="(item, i) in menuItems"
              :key="i"
              :disabled="!isDesktop"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  :href="`#${item.id}`"
                  class="nav-item link"
                  :class="{ active: i === menuItemActive }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon :icon="item.icon" />
                </a>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </nav>
          <we-nav-x
            class="only-mobile"
            v-if="menuItems && menuItems.length > 0"
            :items="menuItems"
            :activeIndex="currentMenuItem"
            @select="selectMenuItem"
          />
        </div>
        <div class="body" v-scroll.self="onScroll">
          <slot name="body" />
        </div>
        <div class="bottom active">
          <slot name="bottom" />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    menuItems: Array,
    dragEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dragovered: false,
      menuItemActive: 0,
      currentMenuItem: 0
    };
  },
  methods: {
    selectMenuItem(index) {
      this.currentMenuItem = index;
      this.$emit("currentMenuItem", this.menuItems[index].id);
    },
    closeModal() {
      let menuOpened = false;
      const menus = document.querySelectorAll(".v-menu__content");

      menus.forEach(e => {
        if (e.classList.contains("menuable__content__active")) {
          menuOpened = true;
        }
      });

      if (!menuOpened) {
        this.$emit("close");
      }
    },
    dragover(e) {
      if (!this.dragEnabled) return;
      e.preventDefault();
      this.dragovered = true;
    },
    dragleave(e) {
      if (!this.dragEnabled) return;
      e.preventDefault();
      this.dragovered = false;
    },
    drop(event) {
      if (!this.dragEnabled) return;
      event.preventDefault();
      this.dragovered = false;
      this.$emit("drop", event);
    },
    onScroll(event) {
      const scroll = event.srcElement.scrollTop + 56;
      const sections = Array.from(
        document.querySelectorAll(".body section")
      ).map(e => {
        return {
          offsetTop: e.offsetTop
        };
      });

      const activeSection =
        sections?.length -
        sections.reverse().findIndex(e => scroll > e.offsetTop);

      this.menuItemActive = activeSection - 1;
    }
  }
};
</script>

<style lang="scss">
#modal-entity {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &.contact,
  &.company,
  &.user {
    #entity {
      grid-template-rows: minmax(0, 105px) minmax(0, 1fr) minmax(0, 65px);
    }
  }
}

#entity {
  height: calc(100% - 20px);
  width: 600px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--background-1);
  border-radius: 10px;
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.25);
  z-index: 4;
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(0, 96px) minmax(0, 1fr) minmax(0, 65px);

  @include mobile {
    height: 100%;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    top: 0;
    right: 0;
    display: block;
  }

  &.dragovered {
    .dragover {
      display: flex;
    }
  }

  .dragover {
    background: rgba(var(--background-1-rgb), 0.9);
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--primary);
    font-weight: $semibold;
  }

  .head {
    height: 100%;
    background: var(--background-2);
    border-bottom: 1px solid var(--line);
    position: relative;
    z-index: 6;

    @include mobile {
      height: fit-content;
    }

    .options {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;

      @include mobile {
        top: 0;
        right: 0;
      }
    }

    .data {
      height: 100%;
      padding: 8px 25px;

      @include mobile {
        padding: 8px 15px;
        padding-bottom: 0;
      }

      .data-grid {
        display: grid;
        align-items: center;
        height: 100%;
        grid-template-columns: minmax(0, 79px) minmax(0, 1fr);
        gap: 16px;
        padding-right: 70px;

        @include mobile {
          padding-right: 0;
          padding-bottom: 8px;
        }

        &.small {
          grid-template-columns: minmax(0, 30px) minmax(0, 1fr);
        }

        .entity-icon {
          display: flex;
          align-items: center;

          svg {
            color: var(--text-2);
            height: 32px;
            width: auto;
          }
        }

        .data-info {
          .name {
            font-size: 18px;
            font-weight: $medium;
            color: var(--text-1);

            @include mobile {
              font-size: 16px;
              padding-right: 80px;
            }
          }

          .data-list {
            display: flex;
            align-items: center;

            @include mobile {
              flex-flow: column;
              align-items: flex-start;
            }

            span {
              font-size: 14px;
              color: var(--text-2);
              margin-right: 12px;
              position: relative;
              font-weight: $medium;

              @include mobile {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &.v-tooltip {
                @include mobile {
                  display: none;
                }
              }

              &.plan {
                b {
                  color: var(--text-1);
                  text-transform: capitalize;
                  font-weight: $semibold;
                }
              }

              &.price {
                color: var(--text-1);
                font-weight: $semibold;
              }

              &:last-child {
                &::before {
                  content: initial;
                }
              }

              &::before {
                content: "•";
                font-size: 16px;
                position: absolute;
                top: -1px;
                right: -9px;
                font-weight: $medium;
                color: var(--text-2);

                @include mobile {
                  content: initial;
                }
              }
            }
          }
        }
      }

      .data-title {
        display: flex;
        padding: 0 25px;
        position: relative;
        align-items: center;
        pointer-events: none;
        z-index: 1;
        font-weight: $regular;
        font-size: 18px;
        line-height: 27px;
        color: var(--text-1);
      }
    }

    .right-menu {
      position: absolute;
      right: 6px;
      top: 121px;
      width: 38px;
      z-index: 1;

      @include mobile {
        display: none;
      }

      .link {
        width: 100%;
        height: 38px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        position: relative;
        cursor: pointer;
        pointer-events: initial;

        &:hover {
          svg {
            color: var(--text-2);
          }
        }

        &.active {
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 100%;
            right: 0;
            top: 0;
            background: var(--primary);
            border-radius: 3px;
          }
          svg {
            color: var(--text-1);
          }
        }

        svg {
          height: 24px;
          width: auto;
          color: var(--action-hover);
        }
      }
    }
  }

  .body {
    padding: 24px 56px 24px 25px;
    height: 100%;
    overflow-y: scroll;
    position: relative;

    @include mobile {
      padding: 20px 15px;
      padding-bottom: 240px;
      overflow-y: auto;
    }

    .hide-mobile {
      @include mobile {
        display: none !important;
      }
    }

    section {
      margin-bottom: 24px;

      &#activities {
        min-height: 700px;
        position: relative;

        @include mobile {
          min-height: fit-content;
        }
      }

      &#custom-fields {
        .fields {
          &.fill {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          .fill-input {
            grid-column-start: initial;
            grid-column-end: initial;
          }

          .double-column {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        font-weight: $semibold;
        color: var(--text-2);
        font-size: 16px !important;
        margin-bottom: 16px;

        @include mobile {
          display: none;
        }

        .icon {
          width: 30px;
          display: flex;
          align-items: center;

          svg {
            height: 18px;
            width: auto;
          }
        }
      }

      .fields {
        padding-left: 23px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
        row-gap: 16px;

        @include mobile {
          display: block;
          padding: 0;
        }

        &.fill {
          grid-template-columns: 1fr;
        }

        .we-input {
          @include mobile {
            margin-bottom: 20px;
          }
        }

        .fill-input,
        .double-column {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .product-list {
          .product-item {
            display: grid;
            grid-template-columns: 60px minmax(0, 1fr);
            align-items: center;
            gap: 12px;
            margin: 0 auto;
            margin-bottom: 14px;
            height: 60px;
            max-width: 400px;

            &:last-child {
              margin-bottom: 0;
            }

            .product-picture {
              width: 100%;
              height: 50px;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
              }
            }

            .product-content {
              .product-name {
                font-size: 14px;
                font-weight: $medium;
                color: var(--text-1);
                margin-bottom: 2px;
              }

              .product-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .price-quantity {
                  display: flex;
                  align-items: center;
                }

                .quantity-selector {
                  display: flex;
                  align-items: center;
                  pointer-events: all;
                  border: 1px solid var(--line);
                  border-radius: 18px;
                  height: 36px;
                  overflow: hidden;
                  margin-left: 16px;

                  &:hover {
                    border-color: var(--text-2);
                  }

                  .quantity-button {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;

                    &:hover {
                      background: var(--primary-bg);

                      svg {
                        color: var(--primary);
                      }
                    }

                    svg {
                      width: 11px;
                      height: auto;
                      color: var(--text-2);
                    }
                  }

                  input {
                    border: none;
                    background: var(--inherit);
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: $semibold;
                    color: var(--text-2);
                    width: 31px;
                    text-align: center;
                    -moz-appearance: textfield;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }

                    &:focus {
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                    }
                  }
                }

                .product-price {
                  color: var(--green);
                  font-weight: $medium;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 100%;
    border-top: 1px solid var(--line);
    background: var(--background-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    z-index: 5;

    @include mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      padding: 0 15px;
    }

    button {
      min-width: 176px;

      @include mobile {
        min-width: fit-content;
      }
    }

    .history {
      height: 50px;
      display: flex;
      align-items: center;

      .icon {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 13px;
        svg {
          width: 18px;
          height: auto;
          color: var(--text-2);
        }
      }

      .data {
        .item {
          color: var(--text-2);
          font-size: 12px;
          font-weight: $regular;

          @include mobile {
            font-size: 10px;
          }

          span {
            font-weight: $semibold;
          }
        }
      }
    }
  }
}
</style>
