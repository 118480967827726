import { render, staticRenderFns } from "./WeDealCalculator.vue?vue&type=template&id=3fe0a9a0&"
import script from "./WeDealCalculator.vue?vue&type=script&lang=js&"
export * from "./WeDealCalculator.vue?vue&type=script&lang=js&"
import style0 from "./WeDealCalculator.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./WeDealCalculator.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VMenu})
