import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "messages-error": "Wasn't possible to load the emails!"
  },
  "pt-BR": {
    "messages-error": "Não foi possível carregar os e-mails!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    messages: [],
    messagePages: {}
  },
  getters: {
    getMessages: state => state.messages,
    getMessagePages: state => state.messagePages
  },
  mutations: {
    resetMessages: state => {
      state.messages = [];
      state.messagePages = {};
    },
    setMessages: (state, payload) => {
      state.messages = [...state.messages, ...payload];
    },
    setMessagePages: (state, payload) => {
      state.messagePages = payload;
    }
  },
  actions: {
    messagesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/logs/messages`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const messages = resp.data.data;

            const page = {
              current: resp.data.current_page,
              last: resp.data.last_page
            };

            commit("setMessagePages", page);
            commit("setMessages", messages);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("messages-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
