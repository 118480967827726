import Vue from "vue";
import axios from "axios";
import store from "@/store";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: process.env.VUE_APP_API,
      headers: {
        "Content-Type": "application/json"
      }
    });
    Vue.prototype.$http.interceptors.request.use(
      config => {
        const token = localStorage.getItem("user-token");

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    Vue.prototype.$strapi = axios.create({
      baseURL: "https://strapi.wepipe.com.br/api/",
      headers: {
        "Content-Type": "application/json"
      }
    });

    Vue.prototype.$strapi.interceptors.request.use(
      config => {
        const token = localStorage.getItem("user-token");

        const locale = localStorage.getItem("we-locale") || navigator.language;

        config.params = {
          ...config.params,
          locale: locale.includes("pt-") ? "pt-BR" : "en"
        };

        if (token) {
          config.headers.Authorization = `Bearer f00dbf8b212cae3681c7e1e04f244e3f845439229829ee6b58f14e7f3122e7d6234474fd337e4a47f0aa44a87a963ee8d1882ec7b5a9b44d3eea020e6389a77851f34cc6def699dde284b9f621186a2bd2419b7da7666978b2f294ae6a26879258f7e00c37aa1174134b94e1d24f8472fc4aad655b9437ed4b32762d3578fe85`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    Vue.prototype.$http.interceptors.response.use(
      config => config,
      error => {
        const snackBar = {
          time: 5000,
          status: "error"
        };

        store.commit("setSnackBar", snackBar);
        if (
          typeof error.response.data === "object" &&
          error.response.data !== null
        ) {
          const errors = error.response.data;

          const errorsArr = Object.keys(errors).map(e => {
            const value = errors[e][0];

            return value;
          });

          const snackBar = {
            description: errorsArr,
            time: 5000,
            status: "error"
          };

          store.commit("setSnackBar", snackBar);
        }

        if (error.response.status === 402) {
          const snackBar = {
            title: error.response.data.error,
            description: "",
            status: "warning"
          };
          store.commit("setSnackBar", snackBar);
          store.commit("addSnackBar");
          store.commit("ableSnackBar", false);

          if (error.response.data.code === "E4022") {
            store.commit("openFreeTrialEnded");
          } else if (error.response.data.code === "E4023") {
            store.commit("openPendingPayment");
          } else {
            store.commit("openUpgradePlan");
          }
        }

        return Promise.reject(error);
      }
    );
  }
});
