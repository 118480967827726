<i18n>
{
  "en": {
    "notifications": "Notifications",
    "notification-not-found": "Not found notifications",
    "today": "Today",
    "previous": "Previous"
  },
  "pt-BR": {
    "notifications": "Notificações",
    "notification-not-found": "Nenhuma notificação encontrada",
    "today": "Hoje",
    "previous": "Anteriores"
  }
}
</i18n>

<template>
  <div class="we-notification">
    <div class="menu-header">{{ $t("notifications") }}</div>
    <div class="menu-content">
      <div v-if="notifications.length === 0" class="not-found">
        {{ $t("notification-not-found") }}
      </div>
      <div v-else class="notifications-list">
        <template v-if="todayNotifications.length > 0">
          <div class="list-details">
            <div class="date">{{ $t("today") }}</div>
          </div>
          <we-notification-card
            v-for="notification in todayNotifications"
            :key="notification.id"
            :notification="notification"
            @click="$emit('close')"
          />
        </template>
        <template v-if="prevNotifications.length > 0">
          <div class="list-details">
            <div class="date">{{ $t("previous") }}</div>
          </div>
          <we-notification-card
            v-for="notification in prevNotifications"
            :key="notification.id"
            :notification="notification"
            @click="$emit('close')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    },
    todayNotifications() {
      return this.notifications.filter(e => {
        const notificationDate = e.created_at.toDate();

        return this.getDifferenceBetweenTodayDate(notificationDate) < 24;
      });
    },
    prevNotifications() {
      return this.notifications.filter(e => {
        const notificationDate = e.created_at.toDate();

        return this.getDifferenceBetweenTodayDate(notificationDate) >= 24;
      });
    }
  },
  methods: {
    getDifferenceBetweenTodayDate(date) {
      const todayDate = new Date();

      const diffTime = Math.abs(todayDate - date);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

      return diffHours;
    }
  }
};
</script>

<style lang="scss">
.we-notification {
  max-width: 420px;
  width: 420px;
  background: var(--background-1);
  position: relative;

  &.rounded {
    border-radius: 7px !important;
  }

  .menu-header {
    padding: 13px 20px;
    font-size: 18px;
    line-height: 27px;
    font-weight: $regular;
    color: var(--text-1);
  }

  .not-found {
    font-size: 14px;
    line-height: 17px;
    font-weight: $regular;
    color: var(--text-2);
    text-align: center;
    border-top: 1px solid var(--line);
    padding: 13px 20px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notifications-list {
    border-top: 1px solid var(--line);
    padding-bottom: 20px;
    max-height: 500px;
    overflow-y: auto;

    .list-details {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      line-height: 16.5px;
      font-weight: $medium;
      padding: 12px 20px 4px 20px;

      .date {
        text-transform: uppercase;
        color: var(--text-2);
      }

      .read {
        color: var(--action-hover);
        cursor: pointer;

        &:hover {
          color: var(--text-1);
        }
      }
    }
  }
}
</style>
