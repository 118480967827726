<i18n>
{
  "en": {
    "search": "Search for name",
    "clear": "Clear selected"
  },
  "pt-BR": {
    "search": "Pesquise pelo nome",
    "clear": "Limpar selecionados"
  }
}
</i18n>

<template>
  <div class="we-user-selector" :class="{ disabled }">
    <div class="user-list">
      <div
        class="user-icon"
        v-for="(user, i) in firstUsers"
        :class="{ active: isSelected(user.id) }"
        :key="user.id"
        :style="{ zIndex: firstUsers.length - i }"
        @click="selectUser(user.id)"
        :cy="`${cy}-${i}`"
      >
        <we-rounded-picture
          :disabled="disabled"
          :picture="user.picture"
          :name="`${user.first_name} ${user.last_name}`"
          :text="
            $options.filters.firstLetters(
              `${user.first_name} ${user.last_name}`
            )
          "
          size="38"
        />
      </div>
    </div>
    <we-balloon
      :closeOnContentClick="false"
      maxHeight="350px"
      @input="search = ''"
      :disabled="disabled"
    >
      <template #action>
        <div
          class="user-icon more"
          :class="{ active: lastUsersSelected }"
          v-if="filteredUsers.length > usersToShow"
          :cy="`${cy}-more`"
        >
          +{{ filteredUsers.length - usersToShow }}
        </div>
      </template>
      <template #balloon>
        <we-input type="search" v-model="search" :inputLabel="$t('search')" />
        <div v-if="usersIdSelected.length > 0" class="reset" @click="reset">
          {{ $t("clear") }}
        </div>
        <div
          class="balloon-item"
          v-for="user in searchedUsers"
          :key="user.id"
          @click="selectUser(user.id)"
          :class="{ active: isSelected(user.id) }"
          :cy="`${cy}-more-users`"
        >
          <we-rounded-picture
            :disabled="disabled"
            :picture="user.picture"
            size="28"
            :text="
              $options.filters.firstLetters(
                `${user.first_name} ${user.last_name}`
              )
            "
          />
          {{ user.first_name }} {{ user.last_name }}
        </div>
      </template>
    </we-balloon>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    disabled: Boolean,
    selectedPipeline: {
      type: Object,
      default: () => {
        return {
          id: 0
        };
      }
    },
    cy: String
  },
  data() {
    return {
      searchLoading: false,
      usersToShow: 5,
      users: [],
      search: "",
      firstEvent: true
    };
  },
  computed: {
    filteredUsers() {
      return (
        this.users.filter(e => {
          if (!e.pipelines || e.pipelines?.length <= 0) return true;

          return e.pipelines?.includes(this?.selectedPipeline?.id);
        }) || []
      );
    },
    firstUsers() {
      return this.filteredUsers.slice(0, this.usersToShow) || [];
    },
    lastUsers() {
      return this.filteredUsers.slice(
        this.usersToShow,
        this.filteredUsers?.length
      );
    },
    searchedUsers() {
      return this.filteredUsers.filter(
        e =>
          e.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
          e.last_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    usersIdSelected() {
      return this.$store.getters.getUsersIdSelected;
    },
    lastUsersSelected() {
      return this.lastUsers.find(e => this.usersIdSelected?.includes(e.id));
    }
  },
  methods: {
    ...mapActions(["usersRequest"]),
    selectUser(userId) {
      this.$store.commit("setUsersSelected", userId);
      this.$emit("selectUser");
    },
    isSelected(userId) {
      return this.usersIdSelected?.includes(userId);
    },
    reset() {
      this.$store.commit("resetUsersSelected");
      this.$emit("selectUser");
    },
    async doRequests() {
      const payload = {
        updateLocal: true,
        paginate: false
      };
      const { users } = await this.usersRequest(payload);

      if (users?.length > 0) {
        this.users = users;
      }
    }
  },
  mounted() {
    this.$store.commit("resetUsersSelected");
    this.doRequests();
  }
};
</script>

<style lang="scss">
.we-user-selector {
  display: flex;
  align-items: center;
  margin-right: 10px;
  z-index: 0;

  &.disabled {
    pointer-events: none;
  }

  .user-list {
    display: flex;
    align-items: center;
  }

  .user-icon {
    height: 38px;
    width: 38px;
    border-radius: 38px;
    cursor: pointer;
    margin-left: -8px;
    transition: transform ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: var(--background-1);

    &.more {
      background: var(--action);
      font-size: 13px;
      color: var(--text-2);
      font-weight: $semibold;

      &.active {
        background: var(--primary);
        color: var(--background-1);
        font-weight: $semibold;
      }

      &:first-child {
        margin-left: -8px;
      }

      &::after {
        content: initial;
      }
    }

    &.active {
      &::after {
        border-color: var(--primary);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: 10;
      border: 2px solid var(--background-2);
      border-radius: 40px;
      pointer-events: none;
    }

    &:hover {
      transform: translateY(-3px);
    }

    &:first-child {
      margin-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
</style>
