<i18n>
{
  "en": {
    "data-section-1": "Account Details",
    "data-section-input-1": "Corporate name",
    "data-section-input-2": "E-mail",
    "data-section-input-3": "Website",
    "data-section-input-4": "Owner name",
    "data-section-input-5": "Owner surname",
    "data-section-input-6": "CNPJ",
    "data-section-input-7": "Total of employees",
    "data-section-input-8": "Owner phone",
    "data-section-input-9": "Secondary phone",
    "data-section-input-10": "Plan",
    "data-section-input-11": "Domain",
    "data-section-input-12": "Department",
    "data-section-input-13": "Market of operation",
    "data-section-input-14": "Source",
    "data-section-input-15": "Language",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "save": "Save updates",
    "save-mobile": "Save",
    "department-1": "Customer Service",
    "department-2": "Product Development",
    "department-3": "Financial and Shopping",
    "department-4": "Legal",
    "department-5": "Logistics",
    "department-6": "Marketing",
    "department-7": "Human Resources",
    "department-8": "Supplies",
    "department-9": "Information Technology",
    "department-10": "Sales",
    "department-11": "Other",
    "employee-1": "1 free user",
    "employee-2": "1 to 5 users",
    "employee-3": "6 to 15 users",
    "employee-4": "16 to 30 users",
    "employee-5": "More than 30 users",
    "market-1": "Food",
    "market-2": "Construction",
    "market-3": "Education",
    "market-4": "Eletronics",
    "market-5": "Entertainment",
    "market-6": "Financial",
    "market-7": "Governmental",
    "market-8": "Properties",
    "market-9": "Industry",
    "market-10": "Insurance",
    "market-11": "Technology",
    "market-12": "Tourism",
    "market-13": "Retail",
    "market-14": "Other",
    "source-1": "Recommendation",
    "source-2": "Other",
    "language-1": "Portuguese (Brazil)",
    "language-2": "English",
    "plan": "Plano",
    "update-customer-error-title": "Wasn't possible to update the account info!",
    "update-customer-error-description": "Check if you filled out all required fields correctly"
  },
  "pt-BR": {
    "data-section-1": "Dados da Conta",
    "data-section-input-1": "Razão social",
    "data-section-input-2": "E-mail",
    "data-section-input-3": "Website",
    "data-section-input-4": "Nome do responsável",
    "data-section-input-5": "Sobrenome do responsável",
    "data-section-input-6": "CNPJ",
    "data-section-input-7": "Total de colaboradores",
    "data-section-input-8": "Telefone do responsável",
    "data-section-input-9": "Telefone secundário",
    "data-section-input-10": "Plano",
    "data-section-input-11": "Domínio",
    "data-section-input-12": "Departamento",
    "data-section-input-13": "Mercado de atuação",
    "data-section-input-14": "Origem",
    "data-section-input-15": "Idioma",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "save": "Salvar alterações",
    "save-mobile": "Salvar",
    "department-1": "Atendimento ao Cliente",
    "department-2": "Desenvolvimento de Produto",
    "department-3": "Financeiro e Compras",
    "department-4": "Jurídico",
    "department-5": "Logística",
    "department-6": "Marketing",
    "department-7": "Recursos Humanos",
    "department-8": "Suprimentos",
    "department-9": "Tecnologia da Informação",
    "department-10": "Vendas",
    "department-11": "Outro",
    "employee-1": "1 usuário gratuito",
    "employee-2": "1 a 5 usuários",
    "employee-3": "6 a 15 usuários",
    "employee-4": "16 a 30 usuários",
    "employee-5": "Mais de 30 usuários",
    "market-1": "Alimentação",
    "market-2": "Construção",
    "market-3": "Educação",
    "market-4": "Eletrônicos",
    "market-5": "Entretenimento",
    "market-6": "Financeiro",
    "market-7": "Governamental",
    "market-8": "Imóveis",
    "market-9": "Indústria",
    "market-10": "Seguros",
    "market-11": "Tecnologia",
    "market-12": "Turismo",
    "market-13": "Varejo",
    "market-14": "Outro",
    "source-1": "Indicação",
    "source-2": "Outro",
    "language-1": "Português (Brasil)",
    "language-2": "Inglês",
    "plan": "Plano",
    "update-customer-error-title": "Não foi possível atualizar os dados da conta!",
    "update-customer-error-description": "Verifique se preencheu todos os campos obrigatórios corretamente"
  }
}
</i18n>

<template>
  <modal-entity class="customer" @close="closeCustomer" :drag-enabled="false">
    <template #actions>
      <we-icon-button class="big-mobile" icon="times" @click="closeCustomer" />
    </template>
    <template #data>
      <div class="data-grid small">
        <div class="entity-icon">
          <font-awesome-icon icon="user-circle" />
        </div>
        <div class="data-info">
          <we-text-tooltip
            class="name"
            :text="`${customer.company}`"
            lines="1"
          />
          <div class="data-list">
            <span class="plan" :class="customer.subscription_plan">
              {{ $t("plan") }}:
              <b>
                {{ customer.subscription_plan }}
              </b>
            </span>
            <span
              v-if="
                customer && customer.document && customer.document.length > 0
              "
            >
              {{ customer.document }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form" v-model="createValid">
        <section id="profile" name="profile">
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="user-circle" />
            </div>
            <div class="section-name">
              {{ $t("data-section-1") }}
            </div>
          </div>
          <div class="fields">
            <we-input
              v-model="customer.company"
              class="fill-input"
              isRequired
              type="text"
              :label="$t('data-section-input-1')"
            />
            <we-input
              readonly
              class="fill-input"
              v-model="customer.email"
              isRequired
              type="email"
              :label="$t('data-section-input-2')"
            />
            <we-input
              class="fill-input"
              v-model="customer.website"
              type="text"
              :label="$t('data-section-input-3')"
            />
            <we-input
              v-model="customer.first_name"
              type="text"
              :label="$t('data-section-input-4')"
            />
            <we-input
              v-model="customer.last_name"
              type="text"
              :label="$t('data-section-input-5')"
            />
            <we-input
              v-model="customer.document"
              type="text"
              :label="$t('data-section-input-6')"
              :mask="'##.###.###/####-##'"
              is-required
            />
            <we-input
              v-model="customer.total_employees"
              type="global-select"
              :label="$t('data-section-input-7')"
              :items="employeesList"
              is-required
            />
            <we-input
              v-model="customer.phone1"
              type="text"
              :label="$t('data-section-input-8')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
              is-required
            />
            <we-input
              v-model="customer.phone2"
              type="text"
              :label="$t('data-section-input-9')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              v-model="customer.subscription_plan"
              readonly
              type="text"
              :label="$t('data-section-input-10')"
            />
            <we-input
              readonly
              v-model="customer.domain"
              type="text"
              :label="$t('data-section-input-11')"
            />
            <we-input
              class="fill-input"
              v-model="customer.department"
              type="global-select"
              :label="$t('data-section-input-12')"
              :items="departmentList"
              is-required
            />
            <we-input
              class="fill-input"
              v-model="customer.market"
              type="global-select"
              :label="$t('data-section-input-13')"
              :items="marketList"
              is-required
            />
            <we-input
              v-model="customer.source"
              type="select"
              :label="$t('data-section-input-14')"
              :items="[
                'Facebook',
                'Instagram',
                'Twitter',
                'Linkedin',
                'Google',
                $t('source-1'),
                $t('source-2')
              ]"
              is-required
            />
            <we-input
              v-model="customer.language"
              type="global-select"
              :label="$t('data-section-input-15')"
              :items="[
                { text: $t('language-1'), value: 'pt-BR' },
                { text: $t('language-2'), value: 'en' }
              ]"
              @input="changeLanguage"
              is-required
            />
          </div>
        </section>
      </v-form>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ customer.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ customer.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateCustomer(customer)"
        class="green mobile-icon"
        :text="$t('save')"
        :mobile-text="$t('save-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loading: true,
      btnLoading: false,
      imgLoading: false,
      updateSystemLanguage: false,
      picture: {},
      departmentList: [
        {
          text: this.$t("department-1"),
          value: "Customer Service"
        },
        {
          text: this.$t("department-2"),
          value: "Product Development"
        },
        {
          text: this.$t("department-3"),
          value: "Financial and Shopping"
        },
        { text: this.$t("department-4"), value: "Legal" },
        { text: this.$t("department-5"), value: "Logistics" },
        { text: this.$t("department-6"), value: "Marketing" },
        { text: this.$t("department-7"), value: "Human Resources" },
        { text: this.$t("department-8"), value: "Supplies" },
        {
          text: this.$t("department-9"),
          value: "Information Technology"
        },
        { text: this.$t("department-10"), value: "Sales" },
        { text: this.$t("department-11"), value: "Other" }
      ],
      employeesList: [
        { text: this.$t("employee-1"), value: "1 gratuito" },
        { text: this.$t("employee-2"), value: "1 até 5" },
        { text: this.$t("employee-3"), value: "5 até 10" },
        { text: this.$t("employee-4"), value: "10 até 15" },
        { text: this.$t("employee-5"), value: "mais de 15" }
      ],
      marketList: [
        {
          text: this.$t("market-1"),
          value: "Food"
        },
        {
          text: this.$t("market-2"),
          value: "Construction"
        },
        {
          text: this.$t("market-3"),
          value: "Education"
        },
        { text: this.$t("market-4"), value: "Eletronics" },
        { text: this.$t("market-5"), value: "Entertainment" },
        { text: this.$t("market-6"), value: "Financial" },
        { text: this.$t("market-7"), value: "Governmental" },
        { text: this.$t("market-8"), value: "Properties" },
        {
          text: this.$t("market-9"),
          value: "Industry"
        },
        { text: this.$t("market-10"), value: "Insurance" },
        { text: this.$t("market-11"), value: "Technology" },
        { text: this.$t("market-12"), value: "Tourism" },
        { text: this.$t("market-13"), value: "Retail" },
        { text: this.$t("market-14"), value: "Other" }
      ]
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    }
  },
  methods: {
    ...mapActions(["customerRequest", "updateCustomerRequest"]),
    changeLanguage(value) {
      this.updateSystemLanguage = true;
      localStorage.setItem("we-locale", value);
      this.$root.$i18n.locale = value;
    },
    async updateCustomer(customer) {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.btnLoading = true;

        let payload = { ...customer };
        delete payload.domain;
        delete payload.subscription_plan;

        await this.updateCustomerRequest({
          ...payload
        });
        this.btnLoading = false;
        if (this.updateSystemLanguage) window.location.reload(true);
      } else {
        const snackBar = {
          title: this.$t("update-customer-error-title"),
          description: this.$t("update-customer-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    closeCustomer() {
      this.$emit("close");
    }
  },
  async mounted() {
    await this.customerRequest();
    this.$refs.form.validate();
    this.loading = false;
  }
};
</script>

<style lang="scss"></style>
