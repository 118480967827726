import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "logs-error": "Wasn't possible to load the logs!"
  },
  "pt-BR": {
    "logs-error": "Não foi possível carregar o histórico!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    logs: [],
    logPages: {}
  },
  getters: {
    getLogs: state => state.logs,
    getLogPages: state => state.logPages
  },
  mutations: {
    resetLogs: state => {
      state.logs = [];
      state.logPages = {};
    },
    setLogs: (state, payload) => {
      state.logs = [...state.logs, ...payload];
    },
    setLogPages: (state, payload) => {
      state.logPages = payload;
    }
  },
  actions: {
    logsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/logs`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const logs = resp.data.data;

            const page = {
              current: resp.data.current_page,
              last: resp.data.last_page
            };

            commit("setLogPages", page);
            commit("setLogs", logs);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("logs-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
