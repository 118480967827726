<template>
  <div id="board-form" v-scroll.self="onScroll">
    <div class="form-grid" :class="{ 'has-menu': menu }">
      <slot name="form-content" />
      <div class="menu-column" v-if="menu">
        <div class="sticky">
          <nav class="menu-items">
            <v-tooltip
              left
              v-for="(item, i) in menu"
              :key="i"
              :disabled="!isDesktop"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  :href="`#${item.id}`"
                  class="menu-item"
                  :class="{ active: i === menuItemActive }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon :icon="item.icon" />
                </a>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: Array
  },
  data() {
    return {
      menuItemActive: 0
    };
  },
  methods: {
    onScroll(event) {
      const scroll = event.srcElement.scrollTop + 56;
      const sections = Array.from(
        document.querySelectorAll(".body section")
      ).map(e => {
        return {
          offsetTop: e.offsetTop
        };
      });

      const activeSection =
        sections?.length -
        sections.reverse().findIndex(e => scroll > e.offsetTop);

      this.menuItemActive = activeSection - 1;
    }
  }
};
</script>

<style lang="scss">
#board-form {
  background: var(--background-1);
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @include mobile {
    overflow-y: hidden;
    height: fit-content;
  }

  .form-grid {
    max-width: 767px;
    height: 100%;
    margin: 0 auto;
    padding: 0 50px 34px 50px;

    @include mobile {
      padding: 0;
    }

    &.has-menu {
      max-width: 800px;
      display: grid;
      grid-template-columns: 1fr 40px;
      gap: 24px;

      .sticky {
        position: sticky;
        top: 0;

        .menu-items {
          padding-top: 48px;
          display: flex;
          flex-flow: column;

          .menu-item {
            width: 38px;
            height: 38px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 6px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 3px;
              height: 22px;
              right: 0;
              top: 9px;
              background: var(--inherit);
              border-radius: 3px;
            }

            &:hover {
              background: var(--background-1-hover);
            }

            &.active {
              background: var(--background-1-hover);

              &::before {
                background: var(--primary);
              }

              svg {
                color: var(--text-1);
              }
            }

            svg {
              height: 24px;
              width: auto;
              color: var(--action-hover);
            }
          }
        }
      }
    }

    .content {
      .body {
        overflow-y: auto;
        padding-bottom: 350px;
        .section {
          .section-name {
            display: grid;
            grid-template-columns: 18px 1fr;
            gap: 15px;
            font-size: 16px;
            font-weight: $semibold;
            color: var(--text-2);
            margin-bottom: 28px;
            align-items: center;
            padding-top: 48px;
            border-bottom: 1px solid var(--line);
            padding-bottom: 8px;

            @include mobile {
              padding-top: 24px;
            }

            svg {
              color: var(--text-2);
              height: 18px;
              width: auto;
              margin: 0 auto;
            }

            .text {
              display: flex;
              align-items: center;

              .we-icon-tooltip {
                margin-left: 8px;
              }

              .url {
                color: var(--primary);
                margin-left: 8px;
                cursor: pointer;

                &:hover {
                  color: var(--primary-hover);
                }
              }
            }
          }

          .section-fields {
            padding-left: 31px;
            display: grid;
            grid-template-columns: $column-size $column-size;
            gap: 17px;

            .v-input {
              margin: 0;
              align-self: center;
            }

            .double-column {
              grid-column-start: 1;
              grid-column-end: 3;
            }

            .fit-content {
              width: fit-content;
            }
          }

          .dynamic-fields {
            padding: 0 0 0 31px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) 120px minmax(0, 1fr) 40px;
            align-items: flex-start;
            gap: 16px;
            margin-bottom: 14px;

            &.one-condition {
              grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            }

            &:first-child {
              .we-icon-button {
                display: none;
              }
            }

            .we-icon-button {
              margin-top: 15px;
              margin-bottom: 8px;
            }

            .we-input.pagination .label {
              padding-left: 13px;
            }
          }
        }
      }
    }
  }
}
</style>
