<i18n>
{
  "en": {
    "menu-item-1": "Pipelines",
    "menu-item-2": "Tasks",
    "menu-item-3": "Cards list",
    "menu-item-4": "Contacts",
    "menu-item-5": "Companies",
    "menu-item-6": "Users",
    "menu-item-7": "Dashboards",
    "menu-item-8": "Automations",
    "menu-item-9": "Financial",
    "menu-item-10": "Settings",
    "menu-item-12": "Records",
    "menu-item-13": "Profile",
    "admin": "Administrator",
    "domain": "Domain",
    "version": "Version",
    "soon": "Soon"
  },
  "pt-BR": {
    "menu-item-1": "Pipelines",
    "menu-item-2": "Tarefas",
    "menu-item-3": "Cards",
    "menu-item-4": "Contatos",
    "menu-item-5": "Empresas",
    "menu-item-6": "Usuários",
    "menu-item-7": "Dashboards",
    "menu-item-8": "Automações",
    "menu-item-9": "Financeiro",
    "menu-item-10": "Configurações",
    "menu-item-12": "Registros",
    "menu-item-13": "Perfil",
    "admin": "Administrador",
    "domain": "Domínio",
    "version": "Versão",
    "soon": "Em breve"
  }
}
</i18n>

<template>
  <section id="nav-left" :class="{ collapsed }">
    <div class="nav-sections">
      <div class="logo">
        <router-link :to="{ name: 'pipelines' }">
          <img
            v-if="customer && customer.brand"
            :src="customer.brand"
            :alt="`${customer.company} Logo`"
          />
          <img
            v-else-if="$vuetify.theme.dark"
            src="@/assets/logo-white.svg"
            alt="Wepipe Logo"
          />
          <img v-else src="@/assets/logo.svg" alt="Wepipe Logo" />
        </router-link>
      </div>
      <nav class="menu">
        <router-link
          v-for="item in defaultMenu"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          class="item"
          :class="{
            'show-mobile': item.showMobile,
            mobile: item.showDesktop === false
          }"
          active-class="active"
        >
          <v-tooltip
            :disabled="!collapsed"
            content-class="we-icon-tooltip-content"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="item-content">
                <div class="icon">
                  <font-awesome-icon :icon="item.icon" />
                </div>
                <span class="menu-name">
                  {{ item.name }}
                </span>
                <we-label
                  v-if="item.inProgress"
                  :text="$t('soon')"
                  class="small orange"
                />
              </div>
            </template>
            <span>
              {{ item.name }}
            </span>
          </v-tooltip>
        </router-link>
      </nav>
      <nav class="menu admin" v-if="isAdmin">
        <div class="label">{{ $t("admin") }}</div>
        <router-link
          v-for="item in filteredAdminMenu"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          class="item"
          :class="{
            'show-mobile': item.showMobile,
            'only-mobile': item.showDesktop === false
          }"
          active-class="active"
        >
          <v-tooltip
            :disabled="!collapsed"
            content-class="we-icon-tooltip-content"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="item-content">
                <div class="icon">
                  <font-awesome-icon :icon="item.icon" />
                </div>
                <span class="menu-name">
                  {{ item.name }}
                </span>
                <we-label
                  v-if="item.inProgress"
                  :text="$t('soon')"
                  class="small orange"
                />
              </div>
            </template>
            <span>
              {{ item.name }}
            </span>
          </v-tooltip>
        </router-link>
      </nav>
      <div class="customer-app">
        <div class="item domain">
          <span class="app-label"> {{ $t("domain") }}: </span>
          <b>{{ domain }}</b>
        </div>
        <div class="item version">
          <span class="app-label"> {{ $t("version") }}: </span>
          <b>{{ version }}</b>
        </div>
      </div>
    </div>
    <div class="collapsible-bar" @click="collapseMenu">
      <div class="circle-button">
        <font-awesome-icon icon="chevron-left" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
      adminMenu: [
        {
          routeName: "users",
          name: this.$t("menu-item-6"),
          icon: "user-tie",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "dashboardDeals",
          name: this.$t("menu-item-7"),
          icon: "chart-bar",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "workflows",
          name: this.$t("menu-item-8"),
          icon: "wand-magic-sparkles",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "bills",
          name: this.$t("menu-item-9"),
          icon: "file-invoice-dollar",
          showMobile: false,
          showDesktop: true,
          rolesEnabled: ["admin"]
        },
        {
          routeName: "configurations",
          name: this.$t("menu-item-10"),
          icon: "cog",
          showMobile: false,
          showDesktop: true
        }
      ]
    };
  },
  computed: {
    defaultMenu() {
      return [
        {
          routeName: "pipelines",
          name: this.$t("menu-item-1"),
          icon: "columns",
          showMobile: true,
          showDesktop: true
        },
        // {
        //   routeName: "messages",
        //   name: this.$t("menu-item-11"),
        //   icon: "comments",
        //   showMobile: false,
        //   showDesktop: true,
        //   inProgress: true
        // },
        {
          routeName: this.isDesktop ? "tasksCalendar" : "tasksList",
          name: this.$t("menu-item-2"),
          icon: ["far", "calendar-check"],
          showMobile: true,
          showDesktop: true
        },
        {
          routeName: "deals",
          name: this.$t("menu-item-3"),
          icon: "note-sticky",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "contacts",
          name: this.$t("menu-item-4"),
          icon: "users",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "companies",
          name: this.$t("menu-item-5"),
          icon: "building",
          showMobile: false,
          showDesktop: true
        },
        {
          routeName: "records",
          name: this.$t("menu-item-12"),
          icon: ["far", "address-card"],
          showMobile: true,
          showDesktop: false
        },
        {
          routeName: "profile",
          name: this.$t("menu-item-13"),
          icon: "user-circle",
          showMobile: true,
          showDesktop: false
        }
      ];
    },
    version() {
      return process?.env?.VUE_APP_VERSION || "0";
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    filteredAdminMenu() {
      return this.adminMenu.filter(e => {
        if (e.rolesEnabled && !e.rolesEnabled.includes(this.loggedUser.role))
          return false;

        return true;
      });
    },
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    customer() {
      return this.$store.getters.getCustomer;
    }
  },
  methods: {
    collapseMenu() {
      this.collapsed = !this.collapsed;
      this.updateCollapse(this.collapsed);
    },
    updateCollapse(value) {
      this.$emit("collapse", value);
      localStorage.setItem("we-nav-collapsed", value);
    },
    changeLocale(locale) {
      localStorage.setItem("we-locale", locale);
      this.$root.$i18n.locale = locale;
    }
  },
  mounted() {
    this.collapsed = localStorage.getItem("we-nav-collapsed") === "true";
    this.updateCollapse(this.collapsed);
  }
};
</script>

<style lang="scss">
#nav-left {
  border-right: 1px solid var(--line);
  height: 100vh;
  position: relative;
  background: var(--background-1);

  @include mobile {
    grid-row: 2;
    z-index: 2;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  }

  &.collapsed {
    width: 60px;

    @include mobile {
      width: 100vw;
    }

    .logo {
      a {
        width: 100%;
        padding: 0 8px;
      }
    }

    .customer-app {
      display: none;
    }

    .menu {
      .label {
        width: 29px;
        overflow: hidden;
        white-space: nowrap;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 9px;
      }

      .item {
        .item-content {
          .menu-name,
          #we-label {
            @include desktop {
              display: none;
            }
          }

          .icon {
            margin: 0;

            .we-icon-tooltip {
              cursor: pointer;
              svg {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .collapsible-bar {
      .circle-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .nav-sections {
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;

    @include mobile {
      height: 100%;
      display: block;
      overflow: hidden;
      direction: auto;
    }

    div,
    nav {
      direction: ltr;
    }
  }

  .logo {
    min-height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      display: none;
    }

    a {
      width: 122px;
      height: 29px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }
  }
  .menu {
    padding: 19px 8px;
    border-top: 1px solid var(--line);

    @include mobile {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
    }

    &:last-child {
      border-bottom: 0;
    }

    &.admin {
      border-bottom: 0;

      @include mobile {
        display: none;
      }
    }

    .label {
      font-size: 12px;
      font-weight: $medium;
      padding-left: 16px;
      margin-bottom: 9px;
      color: var(--text-3);
    }

    .item {
      @include mobile {
        display: none;
      }

      &.mobile {
        @include desktop {
          display: none;
        }
      }

      &.show-mobile {
        @include mobile {
          display: flex;
          flex: 1;
          height: 100%;
          .item-content {
            display: flex;
          }
        }
      }

      &:hover {
        .item-content {
          background: var(--background-1-hover);

          @include mobile {
            background: initial;
          }
        }
      }

      &.active {
        .item-content {
          color: var(--primary);
          background: var(--primary-bg);

          @include mobile {
            color: var(--text-1);
            background: initial;
          }

          .icon .we-icon-tooltip svg {
            color: var(--text-1);

            @include mobile {
              color: var(--text-1);
            }
          }
        }
      }

      &:last-child {
        .item-content {
          margin-bottom: 0;
        }
      }

      .item-content {
        height: 40px;
        display: flex;
        align-items: center;
        color: var(--text-2);
        font-size: 13px;
        font-weight: $semibold;
        padding: 0 6px;
        border-radius: 7px;
        margin-bottom: 8px;
        position: relative;

        @include mobile {
          height: 100%;
          flex-flow: column;
          justify-content: center;
          padding: 0 6px;
          font-size: 10px;
          display: none;
          margin-bottom: 0;
          flex: 1;
        }

        .icon {
          width: 32px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 6px;

          @include mobile {
            width: 100%;
            height: fit-content;
            margin-right: 0;
            margin-bottom: 4px;
          }

          svg {
            height: 18px;
            width: 18px;

            @include mobile {
              height: 22px;
            }
          }
        }

        .menu-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        #we-label {
          position: absolute;
          top: 13px;
          right: 13px;
        }
      }
    }
  }

  .customer-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--text-2);
    padding-bottom: 20px;

    b {
      margin-left: 6px;
      font-weight: $semibold;
    }
  }

  .collapsible-bar {
    width: 9px;
    height: 100%;
    position: absolute;
    right: -9px;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      &::before {
        background: var(--primary);
      }

      .circle-button {
        background: var(--primary);
        border-color: var(--primary);

        svg {
          color: var(--background-1);
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
    }

    .circle-button {
      min-height: 19px;
      min-width: 19px;
      border-radius: 19px;
      border: 1px solid var(--line);
      background: var(--background-1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: -9px;

      svg {
        width: 7px;
        height: auto;
        color: var(--text-3);
      }
    }
  }
}
</style>
