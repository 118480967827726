<i18n>
{
  "en": {
    "title": "SMS Rates",
    "description-1": "Sending SMS is charged by the telephone operators and has a cost of",
    "description-2": "for each message sent"
  },
  "pt-BR": {
    "title": "Tarifas de SMS",
    "description-1": "O envio de SMS é tarifado pelas operadoras de telefonia e tem um custo de",
    "description-2": "por mensagem enviada"
  }
}
</i18n>

<template>
  <div class="we-sms-tax">
    <img
      src="@/assets/undraw/mobile-message.svg"
      alt="Upgrade"
      width="108"
      height="78"
    />
    <div class="tax-content">
      <div class="message-title">
        {{ $t("title") }}
      </div>
      <div class="message-description">
        {{ $t("description-1") }}
        <b class="green--text">{{ $root.$n(0.35, "currency") }}</b>
        {{ $t("description-2") }}.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.we-sms-tax {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  .tax-content {
    margin-left: 25px;
    font-weight: $medium;

    .message-title {
      font-size: 18px;
    }

    .message-description {
      font-size: 14px;
      color: var(--text-2);

      b {
        font-weight: $semibold;
      }
    }
  }
}
</style>
