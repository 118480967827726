import Vue from "vue";
import Vuetify, { VApp, VTextField } from "vuetify/lib";

export default new Vuetify({
  components: {
    VApp,
    VTextField
  },
  icons: {
    iconfont: "faSvg"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#0C80F2",
        secondary: "#DADADE",
        grey: "#424c70",
        accent: "#9AB0FB",
        error: "#FF4757",
        red: "#FF4757",
        info: "#1A1E2C",
        success: "#25b660",
        green: "#25b660",
        warning: "#ffa502",
        indigo: "#0060B8",
        orange: "#FF704A",
        blue: "#549CFC",
        purple: "#A26BFF"
      },
      dark: {
        primary: "#0C80F2",
        secondary: "#454C64",
        grey: "#D9DFF2",
        accent: "#9AB0FB",
        error: "#FF4757",
        red: "#FF4757",
        info: "#FFF",
        success: "#25b660",
        green: "#25b660",
        warning: "#ffa502",
        indigo: "#0060B8",
        orange: "#FF704A",
        blue: "#549CFC",
        purple: "#A26BFF"
      }
    }
  }
});

Vue.use(Vuetify);
