<i18n>
{
  "en": {
    "return": "Return",
    "notifications": "Notifications"
  },
  "pt-BR": {
    "return": "Voltar",
    "notifications": "Notificações"
  }
}
</i18n>

<template>
  <section id="nav-top">
    <customer v-if="showCustomer" @close="showCustomer = false" />
    <change-password
      v-if="showChangePassword"
      @close="showChangePassword = false"
    />
    <div class="top">
      <div class="content">
        <div
          class="mobile-header"
          :class="{ 'only-desktop': hideMobileHeader }"
        >
          <div class="header-action left">
            <we-icon-button
              v-if="returnPage"
              class="nav-return big-mobile"
              direction="bottom"
              :name="$t('return')"
              icon="chevron-left"
              iconSize="small"
              @click="
                $router.push({ name: returnPage });
                $emit('return');
              "
            />
          </div>
          <div class="we-title">
            {{ title }}
          </div>
          <div class="header-action right"></div>
        </div>
        <div class="mobile-subheader" :class="{ 'pd-top': hideMobileHeader }">
          <slot name="top-content" />
        </div>
      </div>
      <div class="functions">
        <we-trial-remaining />
        <v-menu
          rounded
          bottom
          left
          offset-y
          content-class="we-notification-menu"
          v-model="showNotifications"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ attrs, on }">
            <div class="menu-btn" v-bind="attrs" v-on="on">
              <we-icon-button
                @click.native="showNotifications = true"
                direction="bottom"
                :amount="unreadNotifications"
                :name="$t('notifications')"
                :icon="{ prefix: 'far', iconName: 'bell' }"
              />
            </div>
          </template>
          <we-notification @close="showNotifications = false" />
        </v-menu>
        <we-profile
          @password="showChangePassword = true"
          @customer="showCustomer = true"
        />
      </div>
    </div>
    <slot name="menu" />
  </section>
</template>

<script>
import Customer from "@/views/Admin/Customer/Index.vue";
import ChangePassword from "@/views/Global/ChangePassword.vue";
export default {
  props: {
    returnPage: String,
    title: String,
    hideMobileHeader: Boolean
  },
  components: {
    Customer,
    ChangePassword
  },
  data() {
    return {
      showCustomer: false,
      showChangePassword: false,
      showNotifications: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    },
    notifications() {
      return this.$store.getters.getNotifications;
    },
    unreadNotifications() {
      return this.$store.getters.getUnreadNotifications;
    }
  }
};
</script>

<style lang="scss">
#nav-top {
  border-bottom: 1px solid var(--line);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  @include mobile {
    width: 100vw;
    overflow: initial;
    height: fit-content;
  }

  .top {
    padding: 14px 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mobile {
      padding: 0;
      width: 100vw;
      max-width: 100vw;
    }

    .content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;

      @include mobile {
        height: fit-content;
        display: block;
        width: 100vw;
        max-width: 100vw;
      }

      .mobile-header {
        display: flex;
        align-items: center;

        @include mobile {
          display: grid;
          grid-template-columns: 50px 1fr 50px;
          height: 50px;
          align-items: center;
        }

        .header-action {
          @include mobile {
            height: 50px;
            width: 50px;
          }
        }

        .nav-return {
          position: absolute;
          top: 14.5px;
          left: 5px;

          @include mobile {
            top: 0;
            left: 0;
          }
        }

        .we-title {
          font-size: 24px;
          font-weight: $regular;
          color: var(--text-1);
          margin-right: 16px;

          @include mobile {
            font-size: 16px;
            font-weight: $semibold;
            margin: 0;
            text-align: center;
          }
        }
      }

      .mobile-subheader {
        display: flex;
        align-items: center;
        flex: 1;

        &.pd-top {
          @include mobile {
            padding-top: 5px;
          }
        }

        @include mobile {
          align-items: flex-start;
          width: calc(100vw - 30px);
          margin: 0 auto;
          padding: 0;
        }

        .domain {
          font-size: 24px;
          font-weight: $regular;
          color: var(--text-1);
          margin-right: 16px;
          color: var(--primary);
          cursor: pointer;

          &:hover {
            color: var(--primary-hover);
          }

          svg {
            height: 24px;
            width: 24px;
          }

          @include mobile {
            font-size: 16px;
            font-weight: $semibold;
            margin: 0;
            text-align: center;
          }
        }

        .header-action {
          @include mobile {
            position: absolute;
            right: 15px;
            top: 5px;
          }
        }

        .balance {
          border-left: 1px solid var(--line);
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding-left: 16px;
          height: 40px;
          margin-right: 16px;

          @include mobile {
            padding: 0;
            border: none;
            margin-right: 12px;
          }

          .balance-label {
            font-size: 11px;
            line-height: 11px;
            font-weight: $medium;
            margin-bottom: 4px;
            text-transform: uppercase;
            color: var(--text-2);
            display: flex;
            align-items: center;

            .we-icon-tooltip {
              margin-left: 4px;
            }

            svg {
              height: 13px;
              width: auto;
            }
          }

          .balance-value {
            font-size: 18px;
            line-height: 18px;
            font-weight: $semibold;
            color: var(--green);
            white-space: nowrap;

            @include mobile {
              font-size: 16px;
              line-height: 16px;
            }

            &.negative {
              color: var(--red);
            }
          }
        }

        .we-button {
          margin-right: 8px;

          @include mobile {
            margin-right: 0;
            margin-left: 8px;
          }
        }

        .we-input {
          flex: 1;
          max-width: 389px;
          margin-right: 16px;

          @include mobile {
            margin-right: 0;
            max-width: 100%;
            width: fit-content;
            flex: 1;
            padding-bottom: 8px;
          }
        }

        .action {
          width: fit-content;
        }

        .select-rounded {
          display: flex;
          height: 40px;
          width: fit-content;
          font-size: 14px;
          line-height: 14px;
          font-weight: $medium;
          color: var(--text-2);
          border-radius: 7px;
          background: var(--action);
          margin-right: 18px;

          @include mobile {
            display: none;
          }

          .option {
            height: 100%;
            width: 145px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 14px;

              svg {
                height: 15px;
              }
            }

            &:first-child {
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }

            &:last-child {
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }

            &:hover {
              background: var(--action-hover);
            }

            &.active {
              background: var(--primary);
              color: var(--white);
            }
          }
        }
      }
    }

    .functions {
      display: flex;

      @include mobile {
        display: none;
      }

      .we-trial-remaining {
        margin-right: 8px;
      }

      .we-profile {
        width: fit-content;
      }

      .we-picture-button {
        margin-left: 8px;
      }
    }
  }
}
</style>
