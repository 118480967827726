<i18n>
{
  "en": {
    "title": "Account suspended for non-payment",
    "description": "We still haven't identified the payment of your last invoice, we would like to know what happened and how we can help you. Check the charge we sent via email or contact us via chat.",
    "obs": "* Payments made by bank slip may take up to 24 hours to be processed. To expedite the unlocking of your account, please send us an image or PDF of your bank slip payment.",
    "btn": "Send bank slip",
    "hello": "Hello, "
  },
  "pt-BR": {
    "title": "Conta suspensa por falta de pagamento",
    "description": "Ainda não identificamos o pagamento da sua última fatura, gostaríamos de saber o que aconteceu e como podemos ajudá-lo. Verifique a cobrança que enviamos por e-mail ou entre em contato via chat.",
    "obs": "* Pagamentos realizados por boleto bancário podem demorar até 24h para serem processados. Para agilizar o desbloqueio da sua conta nos envie uma imagem ou PDF do comprovante de pagamento.",
    "btn": "Enviar comprovante",
    "hello": "Olá, "
  }
}
</i18n>

<template>
  <modal-action :columns="1" v-if="pendingPaymentOpened">
    <template #view>
      <div class="modal" id="pending_payment">
        <div class="modal-body">
          <div class="we-title">
            {{ $t("title") }}
          </div>
          <div class="we-description">
            {{ $t("hello") }}
            <b>{{ loggedUser.first_name }}</b
            >!
            {{ $t("description") }}
          </div>
          <img
            src="@/assets/undraw/pending-payment.svg"
            :alt="$t('title')"
            width="275"
            height="174"
          />
          <div class="obs">
            {{ $t("obs") }}
          </div>
          <we-button
            :text="$t('btn')"
            class="primary"
            href="https://form.wepipe.com.br/wepipe/suporte"
            target="_blank"
          />
          <we-privacy-policy />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    pendingPaymentOpened() {
      return this.$store.getters.getPendingPaymentOpened;
    }
  },
  methods: {
    ...mapActions(["loggedUserRequest"])
  },
  async mounted() {
    this.loading = true;

    if (!this.loggedUser || Object.keys(this.loggedUser).length === 0) {
      await this.loggedUserRequest();
    }
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
#action_modal {
  z-index: 8;

  .modal#pending_payment {
    width: 100%;
    max-width: 565px;
    height: fit-content;

    @include mobile {
      height: 100%;
      max-width: 100%;
      display: block;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      @include mobile {
        max-height: 100%;
        padding: 15px;
        padding-bottom: 80px;
      }

      .we-title {
        font-size: 24px;
        font-weight: $semibold;
        margin-bottom: 22px;
        text-align: center;
      }

      .we-description {
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 32px;
        color: var(--text-2);
        text-align: center;
        max-width: 543px;
      }

      .obs {
        font-size: 13px;
        font-weight: $regular;
        margin-bottom: 24px;
        color: var(--text-2);
        text-align: center;
      }

      .we-button {
        margin-bottom: 24px;
      }

      img {
        margin-bottom: 32px;
      }
    }
  }
}
</style>
