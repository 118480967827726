import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "login-error": "Wasn't possible to login!",
    "recovery-success": "The recovery code was sent to your email!",
    "recovery-success-description":
      "Check your inbox or spam to get your code and create a new password.",
    "recovery-error": "Wasn't possible to send a code to your email!",
    "update-password-success": "New password created with success!",
    "update-password-error": "Wasn't possible to create a new password!"
  },
  "pt-BR": {
    "login-error": "Não foi possível fazer login!",
    "recovery-success": "Código enviado para seu e-mail!",
    "recovery-success-description":
      "Verifique sua caixa de entrada e copie o código para criar uma nova senha.",
    "recovery-error": "Não foi possível enviar o código para seu E-mail!",
    "update-password-success": "Nova senha criada com sucesso!",
    "update-password-error": "Não foi possível criar uma nova senha!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    token: localStorage.getItem("user-token") || "",
    status: "",
    domain: ""
  },
  getters: {
    isAuthenticated: state => {
      const isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);

      const wepipeChromeExtensionToken =
        process.env.VUE_APP_CHROME_EXTENSION_ID ||
        "idembckknekkklnbgijpnelcnjhpglda";

      if (state.token.length > 0) {
        if (isChrome) {
          chrome.runtime?.sendMessage(wepipeChromeExtensionToken, {
            data: {
              userToken: state.token,
              domain: state.domain
            }
          });
        }

        return true;
      }

      if (isChrome) {
        chrome.runtime?.sendMessage(wepipeChromeExtensionToken, {
          data: {
            userToken: "",
            domain: ""
          }
        });
      }

      return false;
    },
    authStatus: state => state.status,
    getDomain: state => {
      if (state.domain) {
        return state.domain;
      } else if (localStorage.getItem("domain")) {
        return localStorage.getItem("domain");
      }
      return "wepipe";
    }
  },
  mutations: {
    authSuccess: (state, token) => {
      state.status = "success";
      state.token = token;
    },
    setDomain: (state, payload) => {
      state.domain = payload;
      localStorage.setItem("domain", payload);
    },
    resetToken(state) {
      localStorage.removeItem("user-token");
      localStorage.removeItem("logged-user-id");
      localStorage.removeItem("we-UR");
      state.loggedUser = null;
      state.token = "";
    }
  },
  actions: {
    authRequest({ commit, getters, dispatch }, auth) {
      const registrationId = getters.getFirebaseRegistrationId;
      return new Promise(resolve => {
        Vue.prototype.$http
          .post("/auth/login", auth)
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("user-token", token);
            commit("setDomain", resp.data.domain);
            commit("setLoggedUser", user);
            commit("authSuccess", token);

            if (
              !user.firebase_registration_ids ||
              !Array.isArray(user.firebase_registration_ids)
            ) {
              user.firebase_registration_ids = [];
            }

            if (
              registrationId?.length > 0 &&
              !user.firebase_registration_ids.includes(registrationId)
            ) {
              user.firebase_registration_ids.push(registrationId);
              dispatch("updateUserRequest", user);
            }

            resolve(true);
          })
          .catch(() => {
            const snackBar = {
              title: i18n.t("login-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");

            commit("resetToken");
            resolve(false);
          });
      });
    },
    authLogout({ commit }) {
      return new Promise(resolve => {
        commit("resetToken");
        delete Vue.prototype.$http.defaults.headers.common["token"];
        resolve();
      });
    },
    recoveryCodeRequest({ commit }, email) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post("/users/requestPasswordCode", email)
          .then(resp => {
            const snackBar = {
              title: i18n.t("recovery-success"),
              description: i18n.t("recovery-success-description"),
              status: "success",
              time: 10000
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("recovery-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updatePasswordRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post("/users/updatePassword", payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("update-password-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-password")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
