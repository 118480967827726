<i18n>
{
  "en": {
    "title": "Install the wepipe app and use it in full screen.",
    "button": "Install now!"
  },
  "pt-BR": {
    "title": "Instale o app da wepipe e utilize em tela cheia.",
    "button": "Instalar agora!"
  }
}
</i18n>

<template>
  <div class="we-install-pwa" v-if="installPwa.available">
    <div class="we-title">
      {{ $t("title") }}
    </div>
    <we-button
      class="primary centered"
      :text="$t('button')"
      icon="download"
      @click="installNow"
    />
  </div>
</template>

<script>
export default {
  computed: {
    installPwa() {
      return this.$store.getters.getInstallPwa;
    }
  },
  methods: {
    async installNow() {
      this.installPwa.deferredPrompt.prompt();
      await this.installPwa.deferredPrompt.userChoice;
      this.$store.commit("setInstallPwa", {
        available: false,
        deferredPrompt: null
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.we-install-pwa {
  border: 1px solid var(--primary);
  background: var(--primary-bg);
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 24px;
  text-align: center;
  display: none;

  @include mobile {
    display: block;
  }
  .we-title {
    font-size: 16px;
    font-weight: $medium;
    color: var(--text-1);
    margin-bottom: 8px;
  }
}
</style>
