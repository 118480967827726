<i18n>
{
  "en": {
    "upgrade": "Upgrade account",
    "select-plan": "Select plan",
    "grow-item-2": "Up to 5 pipelines",
    "grow-item-3": "Up to 5 web forms",
    "grow-item-4": "Up to 30 automations",
    "grow-item-5": "Up to 30 custom fields",
    "grow-item-7": "Up to 1 GB file upload per user",
    "grow-item-8": "Chat support",
    "scale-item-2": "Up to 50 pipelines",
    "scale-item-3": "Up to 50 web forms",
    "scale-item-4": "Up to 100 automations",
    "scale-item-5": "Up to 100 custom fields",
    "scale-item-7": "Up to 10 GB file upload per user",
    "scale-item-8": "API or Zapier access",
    "scale-item-9": "Chat or call support",
    "select": "Select",
    "selected": "Selected!",
    "scale-obs": "Those features is related to default Scale plan. If you want something customized send a message on the chat.",
    "charge-type": "Select charge type",
    "monthly": "Monthly",
    "user": "user",
    "yearly": "Annually",
    "of-discount": "off discount",
    "billed-monthly": "Price if billed monthly.",
    "summary": "Summary",
    "users": "users",
    "total-users-obs": "Minimum 10 users to upgrade plan.",
    "more-users": "I want more users",
    "type-number": "Type a number",
    "has-coupon": "I have a coupon",
    "insert-code": "Insert code",
    "subtotal": "Subtotal",
    "discount": "Discount",
    "total": "Total",
    "finish-payment": "Finish payment",
    "subscription-title": "Subscription successfully completed!",
    "subscription-description": "The upgrade was completed and you can now enjoy all the features of the contracted plan. Pay the invoice due in 5 days by clicking the button below.",
    "subscription-action": "Pay now!",
    "subscription-obs": "* Payment can be made by bank transfer or credit card. The electronic invoice for the contracted service will be sent by email within 48 hours.",
    "document": "Document",
    "corporate-name": "Corporate Name",
    "free-user": "Free user",
    "subscribe-today": "Subscribe today to redeem",
    "valid-for": "Valid for the first subscription only",
    "no-document-1": "This is the brazilian company number.",
    "no-document-2": "If you are an international company,",
    "no-document-3": " please contact our team."
  },
  "pt-BR": {
    "upgrade": "Fazer upgrade",
    "select-plan": "Selecione o plano",
    "grow-item-2": "Até 10 pipelines",
    "grow-item-3": "Até 5 formulários web",
    "grow-item-4": "Até 30 automações",
    "grow-item-5": "Até 30 campos customizados",
    "grow-item-7": "1 GB de arquivos por usuário",
    "grow-item-8": "Suporte via chat",
    "scale-item-2": "Até 50 pipelines",
    "scale-item-3": "Até 50 formulários web",
    "scale-item-4": "Até 100 automações",
    "scale-item-5": "Até 100 campos customizados",
    "scale-item-7": "10 GB de arquivos por usuário",
    "scale-item-8": "Acesso à API ou Zapier",
    "scale-item-9": "Suporte via chat ou call",
    "select": "Selecione",
    "selected": "Selecionado!",
    "scale-obs": "Essas funcionalidades são do plano Scale padrão. Caso queira um plano mais customizado entre em contato comnossa equipe de vendas.",
    "charge-type": "Selecione o tipo de cobrança",
    "monthly": "Mensal",
    "user": "usuário",
    "yearly": "Anual",
    "of-discount": "de desconto",
    "billed-monthly": "Preço se for faturado mensalmente.",
    "summary": "Sumário",
    "users": "usuários",
    "total-users-obs": "Quantidade mínima de 10 usuários para upgrade do plano.",
    "more-users": "Desejo mais usuários",
    "type-number": "Digite um número",
    "has-coupon": "Tenho cupom promocional",
    "insert-code": "Insira o código",
    "subtotal": "Subtotal",
    "discount": "Desconto",
    "total": "Total",
    "finish-payment": "Finalizar pagamento",
    "subscription-title": "Assinatura realizada com sucesso!",
    "subscription-description": "O upgrade foi realizado e você já pode usufruir de todos os recursos do plano contratado. Efetue o pagamento da fatura com vencimento em 5 dias clicando no botão abaixo.",
    "subscription-action": "Efetuar pagamento",
    "subscription-obs": "* O pagamento pode ser efetuado por boleto bancário ou cartão de crédito. A Nota Fiscal eletrônica para o serviço contratado será enviada por e-mail em até 48h.",
    "document": "CNPJ",
    "corporate-name": "Razão Social",
    "free-user": "Usuário gratuito",
    "subscribe-today": "Assine hoje para resgatar",
    "valid-for": "Válido apenas para a primeira assinatura",
    "no-document-1": "O CNPJ é necessário para conseguirmos emitir a nota fiscal.",
    "no-document-2": "Caso queria contratar como pessoa física, entre em contato",
    "no-document-3": " com nossa equipe de suporte."
  }
}
</i18n>

<template>
  <modal-action class="upgrade" @close="closeModal" :columns="1">
    <template #view>
      <div class="modal" id="upgrade_plan">
        <div class="modal-body" v-if="!formSubmitted && customer">
          <v-form ref="form" class="column upgrade">
            <we-icon-button
              v-if="!blockedSystem"
              class="close"
              icon="times"
              @click="closeModal"
            />
            <div class="we-title">{{ $t("upgrade") }}</div>
            <div class="upgrade-label">{{ $t("select-plan") }}</div>
            <div class="plans">
              <div
                class="box grow"
                :class="{ active: invoice.subscription_plan === 'grow' }"
                @click="selectGrow"
              >
                <div class="box-content">
                  <div class="plan-name">Grow</div>
                  <div class="features">
                    <div
                      v-for="item in growFeatures"
                      :key="item.name"
                      class="feature"
                    >
                      <div class="icon">
                        <font-awesome-icon :icon="item.icon" />
                      </div>
                      <span> {{ item.name }} </span>
                    </div>
                  </div>
                </div>
                <div class="selected">
                  {{
                    invoice.subscription_plan === "grow"
                      ? $t("selected")
                      : $t("select")
                  }}
                </div>
              </div>
              <div
                class="box scale"
                :class="{ active: invoice.subscription_plan === 'scale' }"
                @click="selectScale"
              >
                <div class="box-content">
                  <div class="plan-name">
                    Scale
                    <we-icon-tooltip :icon="['far', 'question-circle']">
                      <template #text>
                        {{ $t("scale-obs") }}
                      </template>
                    </we-icon-tooltip>
                  </div>
                  <div class="features">
                    <div
                      v-for="item in scaleFeatures"
                      :key="item.name"
                      class="feature"
                    >
                      <div class="icon">
                        <font-awesome-icon :icon="item.icon" />
                      </div>
                      <span> {{ item.name }} </span>
                    </div>
                  </div>
                </div>
                <div class="selected">
                  {{
                    invoice.subscription_plan === "scale"
                      ? $t("selected")
                      : $t("select")
                  }}
                </div>
              </div>
            </div>
            <div class="upgrade-label">{{ $t("charge-type") }}</div>
            <div class="charges">
              <div
                class="box"
                :class="{ active: invoice.subscription_cycle === 'monthly' }"
                @click="selectMonthly"
              >
                <div class="charge-name">{{ $t("monthly") }}</div>
                <div class="price">
                  <b>{{ prices.plan | valueToReais }}</b> / {{ $t("user") }}
                </div>
              </div>
              <div
                class="box"
                :class="{ active: invoice.subscription_cycle === 'yearly' }"
                @click="selectYearly"
              >
                <div class="charge-name">
                  {{ $t("yearly") }}
                  <div class="discount">
                    {{ discountPercentage }}% {{ $t("of-discount") }}
                  </div>
                </div>
                <div class="price">
                  <b>
                    {{ (prices.plan - prices.planDiscount) | valueToReais }}
                  </b>
                  / {{ $t("user") }}
                  <we-icon-tooltip :icon="['far', 'question-circle']">
                    <template #text>{{ $t("billed-monthly") }}</template>
                  </we-icon-tooltip>
                </div>
              </div>
            </div>
            <div class="customer-data">
              <div class="customer-col">
                <div class="upgrade-label" v-if="$i18n.locale === 'pt-BR'">
                  {{ $t("document") }}
                  <we-icon-tooltip :icon="['far', 'question-circle']">
                    <template #text>
                      {{ $t("no-document-1") }}
                      <br />{{ $t("no-document-2") }}<br />{{
                        $t("no-document-3")
                      }}
                    </template>
                  </we-icon-tooltip>
                </div>
                <we-input
                  v-if="$i18n.locale === 'pt-BR'"
                  type="text"
                  placeholder="00.000.000/0000-00"
                  mask="##.###.###/####-##"
                  v-model="customer.document"
                  is-required
                  hide-details
                />
              </div>
              <div class="customer-col">
                <div class="upgrade-label">
                  {{ $t("corporate-name") }}
                </div>
                <we-input
                  v-if="$i18n.locale === 'pt-BR'"
                  type="text"
                  v-model="customer.company"
                  is-required
                  hide-details
                />
              </div>
            </div>
          </v-form>
          <div class="column summary">
            <div class="summary-top">
              <div class="we-title">{{ $t("summary") }}</div>
              <div class="cart">
                <div class="cart-item">
                  <div class="item-name">
                    <div class="name">
                      Plano {{ invoice.subscription_plan }}
                    </div>
                    <div class="price">{{ prices.plan | valueToReais }}</div>
                  </div>
                  <div class="item-label">
                    {{
                      invoice.subscription_cycle === "monthly"
                        ? $t("monthly")
                        : $t("yearly")
                    }}
                    <span
                      class="discount"
                      v-if="invoice.subscription_cycle === 'yearly'"
                      >{{ discountPercentage }}% {{ $t("of-discount") }}</span
                    >
                  </div>
                  <div class="item-label">
                    {{ customer.total_users }} {{ $t("users") }}
                    <we-icon-tooltip :icon="['far', 'question-circle']">
                      <template #text>
                        {{ $t("total-users-obs") }}
                      </template>
                    </we-icon-tooltip>
                  </div>
                  <div
                    class="users-number"
                    @click="openedNumberOfUsers = !openedNumberOfUsers"
                  >
                    {{ $t("more-users") }}
                  </div>
                  <we-input
                    type="number"
                    :placeholder="$t('type-number')"
                    v-model="customer.total_users"
                    v-if="openedNumberOfUsers"
                    :min="minUsers"
                  />
                </div>
              </div>
              <div class="resume">
                <div
                  class="promo-code"
                  @click="openedPromotion = !openedPromotion"
                >
                  {{ $t("has-coupon") }}
                </div>
                <we-input
                  type="text"
                  mask="XXXXXXXXXXXXXXXX"
                  :placeholder="$t('insert-code')"
                  v-model="invoice.subscription_coupon"
                  v-if="openedPromotion"
                />
                <we-card-icon
                  v-if="invoice.subscription_coupon === '1FREEUSEREMAIL'"
                  icon="award"
                  :label="$t('free-user')"
                  :title="$t('subscribe-today')"
                  color="green"
                />
                <div
                  v-if="invoice.subscription_coupon === '1FREEUSEREMAIL'"
                  class="obs"
                >
                  * {{ $t("valid-for") }}
                </div>
                <div class="resume-item">
                  <div class="name">{{ $t("subtotal") }}</div>
                  <div class="price">{{ subTotal | valueToReais }}</div>
                </div>
                <div class="resume-item">
                  <div class="name">{{ $t("discount") }}</div>
                  <div class="price">
                    -
                    {{ subTotalDiscount | valueToReais }}
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-bottom">
              <div class="total-item">
                <div class="name">{{ $t("total") }}</div>
                <div class="price">
                  {{ (subTotal - subTotalDiscount).toFixed(2) | valueToReais }}
                </div>
              </div>
              <we-button
                class="primary"
                :text="$t('finish-payment')"
                :loading="btnLoading"
                @click="doUpgrade"
              />
            </div>
          </div>
        </div>
        <we-not-found v-else>
          <template #title>{{ $t("subscription-title") }}</template>
          <template #description>
            {{ $t("subscription-description") }}
            <we-button
              class="success"
              :text="$t('subscription-action')"
              @click="openInvoice"
            />
            <div class="obs">
              {{ $t("subscription-obs") }}
            </div>
          </template>
          <template #picture>
            <img
              src="@/assets/undraw/credit-card.svg"
              alt="Onboarding"
              width="230"
            />
          </template>
        </we-not-found>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      minUsers: 10,
      growFeatures: [
        {
          icon: "columns",
          name: this.$t("grow-item-2")
        },
        {
          icon: "file-lines",
          name: this.$t("grow-item-3")
        },
        {
          icon: "wand-magic-sparkles",
          name: this.$t("grow-item-4")
        },
        {
          icon: "folder-plus",
          name: this.$t("grow-item-5")
        },
        {
          icon: "cloud-arrow-up",
          name: this.$t("grow-item-7")
        },
        {
          icon: "headset",
          name: this.$t("grow-item-8")
        }
      ],
      scaleFeatures: [
        {
          icon: "columns",
          name: this.$t("scale-item-2")
        },
        {
          icon: "file-lines",
          name: this.$t("scale-item-3")
        },
        {
          icon: "wand-magic-sparkles",
          name: this.$t("scale-item-4")
        },
        {
          icon: "folder-plus",
          name: this.$t("scale-item-5")
        },
        {
          icon: "cloud-arrow-up",
          name: this.$t("scale-item-7")
        },
        {
          icon: "headset",
          name: this.$t("scale-item-9")
        },
        {
          icon: "server",
          name: this.$t("scale-item-8")
        }
      ],
      btnLoading: false,
      loading: false,
      formSubmitted: false,
      openedPromotion: false,
      openedNumberOfUsers: false,
      invoice: {
        subscription_plan: "grow",
        subscription_cycle: "monthly",
        subscription_paid_users: 0,
        subscription_coupon: ""
      },
      prices: {
        plan: 0,
        planDiscount: 0,
        codeDiscount: 0
      },
      GROW_PRICE: this.$i18n.locale === "pt-BR" ? 69.9 : 29.9,
      SCALE_PRICE: this.$i18n.locale === "pt-BR" ? 89.9 : 39.9,
      GROW_DISCOUNT: 10,
      SCALE_DISCOUNT: 10
    };
  },
  computed: {
    queryParams() {
      return this.$store.getters.getQueryParams;
    },
    customer() {
      return this.$store.getters.getCustomer;
    },
    invoices() {
      return this.$store.getters.getInvoices;
    },

    blockedSystem() {
      return this.$store.getters.getBlockedSystem;
    },
    subTotal() {
      let subTotal = 0;

      subTotal = this.prices?.plan * this.customer?.total_users;

      if (this.invoice.subscription_cycle === "yearly") {
        subTotal = subTotal * 12;
      }

      return subTotal.toFixed(2);
    },
    subTotalDiscount() {
      if (this.invoice.subscription_cycle === "yearly") {
        return this.prices.planDiscount * this.customer.total_users * 12;
      }

      return 0;
    },
    discountPercentage() {
      let discount = 0;

      discount =
        100 -
        ((this.prices.plan - this.prices.planDiscount) * 100) /
          this.prices.plan;

      return Math.floor(discount);
    }
  },
  methods: {
    ...mapActions([
      "createInvoiceRequest",
      "updateCustomerRequest",
      "invoicesRequest",
      "customerRequest"
    ]),
    openInvoice() {
      window.open(this.invoices[0]?.invoiceUrl, "_blank");
    },
    closeModal() {
      if (!this.blockedSystem) {
        this.$store.commit("closeUpgradePlan");
      }
    },
    selectGrow() {
      this.invoice.subscription_plan = "grow";
      this.prices.plan = this.GROW_PRICE;
    },
    selectScale() {
      this.invoice.subscription_plan = "scale";
      this.prices.plan = this.SCALE_PRICE;
    },
    selectMonthly() {
      this.invoice.subscription_cycle = "monthly";
      this.setDiscount();
    },
    selectYearly() {
      this.invoice.subscription_cycle = "yearly";
      this.setDiscount();
    },
    setDiscount() {
      this.prices.planDiscount = this.GROW_DISCOUNT;

      if (this.invoice.subscription_plan === "scale") {
        this.prices.planDiscount = this.SCALE_DISCOUNT;
      }
    },
    async doUpgrade() {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.btnLoading = true;

        this.invoice.subscription_paid_users = this.customer.total_users;

        let customerPayload = { ...this.customer };

        delete customerPayload.total_users;
        delete customerPayload.domain;
        delete customerPayload.subscription_plan;

        await this.updateCustomerRequest(customerPayload);
        await this.createInvoiceRequest(this.invoice);
        await this.invoicesRequest();

        this.formSubmitted = true;
        this.$store.commit("setBlockedSystem", false);

        this.btnLoading = false;
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.selectGrow();
    this.selectMonthly();
    if (!this.customer) {
      await this.customerRequest();
    }

    if (this.queryParams.coupon?.length > 0) {
      this.invoice.subscription_coupon = this.queryParams.coupon;
      this.openedPromotion = true;
    }

    if (this.customer.total_users > 10) {
      this.minUsers = this.customer.total_users;
    } else {
      this.customer.total_users = 10;
    }

    this.loading = false;
  }
};
</script>

<style lang="scss">
#action_modal.upgrade {
  z-index: 8;

  .modal#upgrade_plan {
    width: 100%;
    max-width: 954px;
    min-height: 669px;
    height: fit-content;

    @include mobile {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
    }

    .modal-body {
      display: grid;
      grid-template-columns: 1fr 280px;
      padding: 0;

      @include mobile {
        height: 100%;
        display: block;
        padding-bottom: 80px;
      }

      .column {
        padding: 28px 24px;
        position: relative;

        @include mobile {
          padding: 15px;
        }

        &.upgrade {
          border-right: 1px solid var(--line);

          @include mobile {
            border: none;
            border-bottom: 1px solid var(--line);
            padding-bottom: 40px;
          }
        }

        .close {
          position: absolute;
          top: 15px;
          right: 15px;
          display: none;

          @include mobile {
            display: flex;
          }
        }

        .we-input {
          .we-input-label {
            display: none;
          }
        }

        .discount {
          background: var(--red);
          color: var(--white);
          font-size: 10px;
          line-height: 10px;
          font-weight: $semibold;
          letter-spacing: 0.5px;
          border-radius: 3px;
          padding: 3px 5px;
          text-transform: uppercase;
          margin-left: 6px;
        }

        .we-title {
          font-size: 24px;
          color: var(--text-1);
          font-weight: $semibold;
          margin-bottom: 9px;
        }

        .upgrade-label {
          font-size: 14px;
          color: var(--text-2);
          margin-bottom: 11px;
          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }
        }

        .plans,
        .charges {
          @include mobile {
            overflow-y: auto;
            width: calc(100% + 30px);
            margin-left: -15px;
          }
        }

        .plans,
        .charges,
        .customer-data {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
          margin-bottom: 19px;

          .box {
            border: 1px solid var(--line);
            border-radius: 10px;
            padding: 15px;
            cursor: pointer;

            @include mobile {
              min-width: 270px;
            }

            &:first-child {
              @include mobile {
                margin-left: 15px;
              }
            }

            &:last-child {
              @include mobile {
                margin-right: 15px;
              }
            }

            &:hover {
              border-color: var(--text-2);
              .plan-name,
              b,
              svg {
                color: var(--text-2);
              }
            }

            &.active {
              border-color: var(--primary);

              .charge-name,
              .price {
                color: var(--primary);
              }

              .feature {
                color: var(--text-1);
              }
              .plan-name,
              b,
              svg,
              .selected {
                color: var(--primary);
              }
            }
          }
        }

        .customer-data {
          margin: 0;
          margin-bottom: -8px;
        }

        .plans {
          .box {
            height: 100%;
            min-height: 300px;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            .plan-name {
              font-size: 18px;
              font-weight: $medium;
              color: var(--text-2);
              text-align: center;
              margin-bottom: 17px;
              display: flex;
              align-items: center;
              justify-content: center;

              .we-icon-tooltip {
                margin-left: 4px;

                svg {
                  height: 16px;
                }
              }
            }

            .features {
              .feature {
                font-size: 14px;
                color: var(--text-2);
                font-weight: $medium;
                display: grid;
                grid-template-columns: 20px 1fr;
                align-items: center;
                gap: 14px;
                margin-bottom: 10px;

                b {
                  font-weight: $semibold;
                }
                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  svg {
                    height: 18px;
                    width: 16px;
                  }
                }
              }
            }

            .selected {
              font-size: 14px;
              font-weight: $medium;
              color: var(--text-2);
              text-align: center;
            }
          }
        }

        .charges {
          .box {
            .charge-name {
              font-size: 14px;
              font-weight: $medium;
              color: var(--text-2);
              text-align: center;
              margin-bottom: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 12px;
            }

            .price {
              text-align: center;
              font-size: 14px;
              color: var(--text-2);
              display: flex;
              justify-content: center;
              align-items: center;

              b {
                font-weight: $semibold;
                font-size: 18px;
                margin-right: 4px;
              }

              .we-icon-tooltip {
                margin-left: 4px;

                svg {
                  height: 16px;
                }
              }
            }
          }
        }

        &.summary {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          .summary-top {
            .cart {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 1px solid var(--line);
              .cart-item {
                .item-name {
                  display: flex;
                  justify-content: space-between;
                  text-transform: capitalize;
                  font-size: 14px;
                  color: var(--text-1);
                  margin-bottom: 4px;
                  font-weight: $medium;
                }
                .item-label {
                  font-size: 14px;
                  line-height: 16px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;
                  color: var(--text-2);

                  .we-icon-tooltip {
                    margin-left: 4px;

                    svg {
                      height: 16px;
                    }
                  }
                }
              }

              .users-number {
                font-size: 14px;
                color: var(--text-1);
                text-decoration: underline;
                margin-bottom: 8px;
                cursor: pointer;
              }
              .we-input {
                margin-bottom: 12px;
              }
            }

            .resume {
              padding-bottom: 20px;
              border-bottom: 1px solid var(--line);
              .promo-code {
                font-size: 14px;
                color: var(--text-1);
                text-decoration: underline;
                margin-bottom: 8px;
                cursor: pointer;
              }
              .we-input {
                margin-bottom: 12px;
              }
              .we-card-icon {
                margin-bottom: 8px;
              }
              .obs {
                font-size: 12px;
                line-height: 14px;
                color: var(--text-2);
                margin-bottom: 12px;
                text-align: center;
              }
              .resume-item {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: var(--text-2);
                margin-bottom: 4px;
                font-weight: $medium;
              }
            }
          }

          .summary-bottom {
            .total-item {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              color: var(--text-1);
              margin-bottom: 15px;
              font-weight: $medium;

              @include mobile {
                margin-top: 15px;
              }

              .price {
                font-weight: $semibold;
              }
            }

            .we-button {
              width: 100%;
            }
          }
        }
      }
    }

    .we-not-found {
      background: var(--background-1);

      .we-title {
        max-width: 100%;
        font-weight: $semibold;
      }

      .description {
        .we-button {
          margin: 18px auto;
        }
        .obs {
          font-weight: $regular;
          font-size: 13px;
        }
      }

      .picture {
        img {
          max-width: 250px;
        }
      }
    }
  }
}
</style>
