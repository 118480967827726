<i18n>
{
  "en": {
    "drop": "Drop above or click to select from pc",
    "title": "Add image",
    "add": "Add",
    "or": "Or",
    "url": "Add image URL"
  },
  "pt-BR": {
    "drop": "Arraste ou clique para selecionar do computador",
    "title": "Adicionar imagem",
    "add": "Adicionar",
    "or": "Ou",
    "url": "Adicione a URL da imagem"
  }
}
</i18n>

<template>
  <modal-action @close="closeModal" :columns="1">
    <template #view>
      <div class="modal" id="add_image">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button class="close" icon="times" @click="closeModal" />
          </div>
        </div>
        <div class="modal-body">
          <div class="section">
            <we-file-upload
              :loading="loading"
              @change="onFileChange"
              height="90px"
              padding="0"
              :info="$t('drop')"
            />
          </div>
          <div class="or">
            <span>{{ $t("or") }}</span>
          </div>
          <div class="section">
            <we-input type="text" v-model="previewImgUrl" :label="$t('url')" />
          </div>
          <div class="preview-img" v-if="previewImgUrl.length > 0">
            <div class="we-label">
              Pré-visualização da imagem
            </div>
            <img :src="previewImgUrl" alt="Image preview" />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('add')"
            icon="plus"
            @click="addImageLink"
            :disabled="!previewImgUrl"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      previewImgUrl: ""
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    }
  },
  methods: {
    ...mapActions(["createFileRequest"]),
    closeModal() {
      this.$emit("close");
    },
    async onFileChange(file) {
      if (!file) return;

      this.loading = true;

      const fileResponse = await this.createFileRequest({
        entity: "email",
        record_id: this.customer?.id || 0,
        file: file
      });

      this.loading = false;

      if (!fileResponse?.data?.url) return;

      this.previewImgUrl = fileResponse.data.url;
    },
    async addImage(url) {
      this.$emit("addImage", url);
      this.closeModal();
    },
    async addImageLink() {
      this.$emit("addImage", this.previewImgUrl);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">
#action_modal {
  .modal#add_image {
    width: 100%;
    max-width: 480px;
    height: fit-content;

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .modal-body {
      display: grid;
      grid-template-columns: 1fr;
      padding: 26px 37px;

      .or {
        text-align: center;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: $medium;
        color: var(--text-3);
        margin: 32px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          position: relative;
          display: block;
          width: fit-content;
          z-index: 1;
          padding: 0 18px;
          background: var(--background-1);
        }

        &::after {
          content: "";
          background: var(--line);
          height: 1px;
          width: 100%;
          position: absolute;
          top: 8px;
          left: 0;
        }
      }

      .section {
        &:last-child {
          margin-bottom: 0;
        }

        .drop-file {
          display: grid;
          height: 85px;

          .drop {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            color: var(--text-2);
            cursor: pointer;
            border-radius: 10px;
            background: var(--action);
            margin-bottom: 8px;
            height: 85px;
            position: relative;

            &:hover {
              background: var(--action-hover);
            }

            .we-title {
              font-size: 14px;
              font-weight: $medium;
            }

            .subtitle {
              font-size: 13px;
              font-weight: $regular;
              max-width: 241px;
              text-align: center;
            }
          }
        }
      }

      .preview-img {
        margin-top: 16px;
        .we-label {
          font-size: 12px;
          font-weight: 500;
          color: var(--text-2);
          margin-bottom: 3px;
          padding-left: 13px;
        }

        img {
          max-width: 100%;
          max-height: 200px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
</style>
