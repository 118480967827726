import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "cep-error": "Wasn't possible check the CEP!"
  },
  "pt-BR": {
    "cep-error": "Não foi possível verificar o CEP!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    cep: {}
  },
  getters: {
    getCep: state => state.cep
  },
  mutations: {
    setCep: (state, payload) => {
      state.cep = payload;
    }
  },
  actions: {
    cepRequest({ commit }, payload) {
      if (i18n.locale != "pt-BR") return;
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/cep/${payload}`)
          .then(resp => {
            const cep = resp.data;

            commit("setCep", cep);

            if (resp.data?.cidade) {
              commit("setCurrentContactCep", cep);
              commit("setCurrentCompanyCep", cep);
              commit("setCurrentUserCep", cep);
            } else {
              commit("clearCurrentContactCep");
              commit("clearCurrentCompanyCep");
              commit("clearCurrentUserCep");
            }

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("cep-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
