import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "auth-returns-error": "Wasn't possible to authenticate!",
    "auth-returns-error-description":
      "Please check if you fill the correct token and store id and try again.",
    "sync-returns-success": "Successfully synced!",
    "sync-returns-error": "Wasn't possible to synchronize products!",
    "create-returnsForm-success": "Returns form was created with success!",
    "create-returnsForm-error": "Wasn't possible to create returns form!",
    "update-returnsForm-success": "Returns form was updated with success!",
    "update-returnsForm-error": "Wasn't possible to update returns form!",
    "delete-returnsForm-error": "Wasn't possible to delete returns form!",
    "update-favicon-returnsForm-error":
      "Wasn't possible to update returns form favicon!",
    "update-logo-returnsForm-error":
      "Wasn't possible to update returns form logo!",
    "update-terms-returnsForm-error": "Wasn't possible to update terms!"
  },
  "pt-BR": {
    "auth-returns-error": "Não foi possível autenticar!",
    "auth-returns-error-description":
      "Verifique se inseriu o token e o id da loja corretamente e tente novamente.",
    "sync-returns-success": "Produtos sincronizados com sucesso!",
    "sync-returns-error": "Não foi possível sincronizar os produtos!",
    "create-returnsForm-success":
      "O formulário de devoluções foi criado com sucesso!",
    "create-returnsForm-error":
      "Não foi possível criar o formulário de devoluções!",
    "update-returnsForm-success": "O formulário de devoluções foi atualizado!",
    "update-returnsForm-error":
      "Não foi possível atualizar o formulário de devoluções!",
    "delete-returnsForm-error":
      "Não foi possível deletar o formulário de devoluções!",
    "update-favicon-returnsForm-error": "Não foi possível atualizar o Favicon!",
    "update-logo-returnsForm-error": "Não foi possível atualizar a Logo!",
    "update-terms-returnsForm-error":
      "Não foi possível atualizar os Termos e Serviços!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    forms: [],
    form: {}
  },
  getters: {
    getReturns: state => state.forms,
    getReturnsForm: state => state.form
  },
  mutations: {
    setReturns: (state, payload) => {
      state.forms = payload;
    },
    setReturnsForm: (state, payload) => {
      state.form = payload;
    }
  },
  actions: {
    returnsPlatformAuthRequest({ commit }, payload) {
      const platform = payload.platform.toLowerCase();
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/plugins/${platform}/auth`, payload)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("auth-returns-error"),
              description: i18n.t("auth-returns-error-description"),
              status: "error"
            };
            commit("addSnackBar", snackBar);
            resolve(err);
          });
      });
    },
    returnsSyncProductsRequest({ commit }, payload) {
      const platform = payload.platform.toLowerCase();
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/plugins/${platform}/products`, payload)
          .then(resp => {
            const snackBar = {
              title: i18n.t("sync-returns-success"),
              description: "",
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("sync-returns-error"),
              description: "",
              status: "error"
            };
            commit("addSnackBar", snackBar);
            resolve(err);
          });
      });
    },
    returnsRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/addons/returns`)
          .then(resp => {
            const forms = resp.data;
            commit("setReturns", forms);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    returnsFormRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/addons/returns/${id}`)
          .then(resp => {
            const form = resp.data;
            commit("setReturnsForm", form);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    createReturnsFormRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/addons/returns`, payload)
          .then(resp => {
            const form = resp.data;
            commit("setReturnsForm", form);

            const snackBar = {
              title: i18n.t("create-returnsForm-success"),
              description: "",
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(form);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-returnsForm-error"),
              description: "",
              status: "error"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateReturnsFormRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/addons/returns/${payload.id}`, payload)
          .then(resp => {
            const form = resp.data;
            commit("setReturnsForm", form);

            const snackBar = {
              title: i18n.t("update-returnsForm-success"),
              description: "",
              status: "success"
            };
            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-returnsForm-error"),
              description: "",
              status: "error"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteReturnsFormRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/addons/returns/${payload.id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-returnsForm-error"),
              description: "",
              status: "error"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateFaviconReturnsFormRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("type", "favicon");
      formData.append("file", payload);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/addons/returns/${payload.formId}/image`, formData)
          .then(resp => {
            const form = resp.data;
            commit("setReturnsForm", form);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-favicon-returnsForm-error"),
              description: "",
              status: "error"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateLogoReturnsFormRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("type", "brand");
      formData.append("file", payload);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/addons/returns/${payload.formId}/image`, formData)
          .then(resp => {
            const form = resp.data;
            commit("setReturnsForm", form);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-logo-returnsForm-error"),
              description: "",
              status: "error"
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
