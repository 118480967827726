var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"we-editor"}},[_c('div',{staticClass:"editor-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blur),expression:"blur"}],staticClass:"we-editor-content",class:{ focused: _vm.focused, html: _vm.htmlView },on:{"click":function($event){_vm.focused = true}}},[(_vm.editor)?_c('div',{staticClass:"editor-actions"},[(_vm.enableHtml)?_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"code","name":_vm.$t('label-1'),"active":_vm.htmlView},on:{"click":_vm.toggleHtml}}):_vm._e(),(_vm.enableHtml)?_c('div',{staticClass:"horizontal-line"}):_vm._e(),(!_vm.htmlView)?[_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"bold","name":_vm.$t('label-2'),"active":_vm.editor.isActive('bold')},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleBold()
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"italic","name":_vm.$t('label-3'),"active":_vm.editor.isActive('italic')},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleItalic()
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"underline","name":_vm.$t('label-4'),"active":_vm.editor.isActive('underline')},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()}}}),(_vm.setUrlModal)?_c('add-link',{on:{"setUrl":_vm.setLink,"close":function($event){_vm.setUrlModal = false}}}):_vm._e(),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"link","name":_vm.$t('label-6'),"active":_vm.editor.isActive('link')},on:{"click":function($event){_vm.setUrlModal = true}}}),(_vm.setImageModal)?_c('add-image',{on:{"addImage":_vm.addImage,"close":function($event){_vm.setImageModal = false}}}):_vm._e(),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"image","name":_vm.$t('label-7')},on:{"click":function($event){_vm.setImageModal = true}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","text":"H1","name":_vm.$t('label-8'),"active":_vm.editor.isActive('heading', { level: 1 })},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleHeading({ level: 1 })
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","text":"H2","name":_vm.$t('label-9'),"active":_vm.editor.isActive('heading', { level: 2 })},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleHeading({ level: 2 })
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","text":"H3","name":_vm.$t('label-10'),"active":_vm.editor.isActive('heading', { level: 3 })},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleHeading({ level: 3 })
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"list-ul","name":_vm.$t('label-11'),"active":_vm.editor.isActive('bulletList')},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleBulletList()
              .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"list-ol","name":_vm.$t('label-12'),"active":_vm.editor.isActive('orderedList')},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()}}})]:_vm._e(),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"undo-alt","name":_vm.$t('label-13')},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .undo()
            .run()}}}),_c('we-icon-button',{attrs:{"type":"square","size":"small","icon":"redo-alt","name":_vm.$t('label-14')},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .redo()
            .run()}}})],2):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}}),(_vm.htmlView)?_c('we-code-editor',{attrs:{"language":"markup"},on:{"input":_vm.setEditorHtml},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }