<i18n>
{
  "en": {
    "on-stage": "on stage",
    "move-here": "Move here",
    "move": "Move",
    "here": "Here"
  },
  "pt-BR": {
    "on-stage": "no estágio",
    "move-here": "Mover aqui",
    "move": "Mover",
    "here": "Aqui"
  }
}
</i18n>

<template>
  <div class="we-deal-steps">
    <div
      class="step"
      :class="{
        finished: i < currentIndex,
        current: isCurrent(i)
      }"
      v-for="(step, i) in steps"
      :key="i"
    >
      <div class="left-arrow"></div>
      <v-tooltip top :disabled="!tooltipIsActive(step) || !isDesktop">
        <template v-slot:activator="{ on, attrs }">
          <div class="step-text" v-bind="attrs" v-on="on">
            {{ step.days | hoursToDaysAndHours }}
          </div>
          <div class="step-text mobile" v-bind="attrs" v-on="on">
            {{ step.tooltip }}
          </div>
        </template>
        <span>
          {{ step.days | hoursToDaysAndHoursExtended }} {{ $t("on-stage") }}
          {{ step.tooltip }}
        </span>
      </v-tooltip>
      <v-tooltip top :disabled="!tooltipIsActive(step) || !isDesktop">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="move-here"
            v-bind="attrs"
            v-on="on"
            @click="$emit('selectStep', step)"
          >
            <font-awesome-icon icon="chevron-right" />
            <span class="action-text">
              {{ isCurrent(i) ? $t("here") : $t("move") }}
            </span>
          </div>
        </template>
        <span>{{ $t("move-here") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: String,
    steps: {
      type: Array,
      default: () => [
        {
          days: 0,
          tooltip: ""
        }
      ]
    }
  },
  computed: {
    currentIndex() {
      return this.steps.findIndex(e => e.tooltip === this.current);
    }
  },
  methods: {
    tooltipIsActive(step) {
      return step.tooltip.length > 0;
    },
    isCurrent(i) {
      return this.currentIndex === i;
    }
  }
};
</script>

<style lang="scss">
.we-deal-steps {
  display: flex;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @include mobile {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .step {
    height: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: $semibold;
    color: var(--line);
    background: var(--background-2);
    border: 1px solid var(--line);
    position: relative;
    margin-left: 2px;

    @include mobile {
      height: 40px;
      width: fit-content;
      padding: 0 60px 0 25px;
      font-size: 13px;
      flex-flow: column;
      justify-content: center;
      color: var(--text-2);
    }

    &:not(.current):hover {
      .move-here {
        display: flex;
      }
    }

    &.finished {
      background: var(--green);
      border-color: var(--green);
      color: var(--background-1);

      &:after,
      &:before {
        border-color: transparent transparent transparent var(--green);
      }

      .left-arrow {
        &:after,
        &:before {
          border-color: transparent transparent transparent var(--background-1);
          left: -2px;
        }
      }

      .move-here {
        border-color: var(--green);
        color: var(--background-1);
      }
    }

    &.current {
      border-color: var(--green);
      color: var(--green);

      &:before {
        border-color: transparent transparent transparent var(--green);
      }

      .left-arrow {
        &:before {
          border-color: transparent transparent transparent var(--green);
        }
      }

      .move-here {
        background: var(--inherit);
        color: var(--green);
        border-left: 1px solid var(--green);
        pointer-events: none;
      }
    }

    &:first-child {
      margin-left: 0;

      @include mobile {
        padding-left: 15px;
      }

      .left-arrow {
        display: none;
      }
    }

    &.last-child {
      &:after,
      &:before {
        content: initial;
      }
    }

    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 2;
    }

    &:after {
      top: 0px;
      border-color: transparent transparent transparent var(--background-2);
      border-width: 7px;

      @include mobile {
        border-width: 19px;
      }
    }

    &:before {
      top: -1px;
      border-color: transparent transparent transparent var(--line);
      border-width: 8px;

      @include mobile {
        border-width: 20px;
      }
    }

    .step-text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        font-size: 11px;
        line-height: 11px;
        height: 11px;
        justify-content: flex-start;
        font-weight: $semibold;
        white-space: nowrap;
      }

      &.desktop {
        @include mobile {
          display: none;
        }
      }

      &.mobile {
        text-transform: uppercase;
        font-weight: $semibold;
        margin-bottom: 4px;
        font-size: 13px;
        @include desktop {
          display: none;
        }
      }
    }

    .left-arrow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      z-index: 1;

      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 1;
      }

      &:after {
        top: 0px;
        border-color: transparent transparent transparent var(--background-2);
        border-width: 7px;

        @include mobile {
          border-width: 19px;
        }
      }

      &:before {
        top: -1px;
        border-color: transparent transparent transparent var(--line);
        border-width: 8px;

        @include mobile {
          border-width: 20px;
        }
      }
    }

    .move-here {
      position: absolute;
      top: -1px;
      width: 100%;
      z-index: 1;
      display: none;
      align-items: center;
      justify-content: center;
      background: var(--inherit);
      color: var(--text-2);
      width: 15px;
      height: calc(100% + 2px);
      z-index: 3;
      cursor: pointer;
      border-left: 1px solid var(--line);
      right: 0;

      @include mobile {
        display: flex;
        width: 45px;
      }

      svg {
        width: 8px;
        position: relative;
        left: 2px;

        @include mobile {
          display: none;
        }
      }

      .action-text {
        font-size: 12px;
        font-weight: $semibold;
        margin-right: -9px;

        @include desktop {
          display: none;
        }
      }

      &:hover {
        @include desktop {
          background: var(--text-2);
          color: var(--background-1);

          &:after {
            border-color: transparent transparent transparent var(--text-2);
          }
        }
      }

      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 1;

        @include mobile {
          left: 44px;
        }
      }

      &:after {
        top: 0px;
        border-color: transparent transparent transparent var(--inherit);
        border-width: 8px;

        @include mobile {
          border-width: 20px;
        }
      }

      &:before {
        top: -1px;
        border-color: transparent transparent transparent var(--inherit);
        border-width: 8px;

        @include mobile {
          border-width: 20px;
        }
      }
    }
  }
}
</style>
