import Vue from "vue";
import store from "@/store";
import i18n from "@/plugins/i18n.js";

const locales = {
  en: {
    created: "created",
    updated: "updated",
    moved: "moved",
    deleted: "deleted",
    "sent-file": "sent a file",
    "deleted-file": "deleted a file",
    "the-contact": "Contact",
    "the-lead": "lead",
    "the-company": "company",
    "the-user": "user",
    "the-bill": "bill",
    yes: "yes",
    no: "no",
    contacts: "Contacts",
    companies: "Companies",
    deals: "Deals",
    users: "Users",
    workflows: "Workflows",
    products: "Products",
    bills: "Bills",
    tasks: "Tasks",
    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    and: "and",
    "tomorrow-at": "Tomorrow at",
    "today-at": "Today at"
  },
  "pt-BR": {
    created: "criou",
    updated: "atualizou",
    moved: "moveu",
    deleted: "deletou",
    "sent-file": "enviou um arquivo",
    "deleted-file": "deletou um arquivo",
    "the-contact": "o contato",
    "the-lead": "o card",
    "the-company": "a empresa",
    "the-user": "o usuário",
    "the-bill": "o lançamento",
    yes: "sim",
    no: "não",
    contacts: "Contatos",
    companies: "Empresas",
    deals: "Cards",
    users: "Usuários",
    workflows: "Pipelines",
    products: "Produtos",
    bills: "Lançamentos",
    tasks: "Tarefas",
    day: "dia",
    days: "dias",
    hour: "hora",
    hours: "horas",
    and: "e",
    "tomorrow-at": "Amanhã às",
    "today-at": "Hoje às"
  }
};

export default locales;

Vue.filter("clearPhoneNumber", function(string) {
  if (!string) return "";
  const clearPhoneNumber = string
    .split("(")
    .join("")
    .split(")")
    .join("")
    .split(" ")
    .join("")
    .split("-")
    .join("");

  return clearPhoneNumber;
});

Vue.filter("minZero", function(value) {
  if (!value) return 0;
  if (value < 0) return 0;

  return value;
});

Vue.filter("dateTime", function(value) {
  if (!value) return "-";
  const date = new Date(value.replace(/-/g, "/"));

  return i18n.d(date, "shortDateTime");
});

Vue.filter("date", function(value) {
  if (!value) return "";
  const date = new Date(value.replace(/-/g, "/"));

  return i18n.d(date, "shortOnlyDate");
});

Vue.filter("cpf", function(value) {
  if (!value) return "-";
  value = value.toString();

  let dot = ".";
  let position = 3;
  let output = [value.slice(0, position), dot, value.slice(position)].join("");
  position = 7;
  output = [output.slice(0, position), dot, output.slice(position)].join("");
  position = 11;
  output = [output.slice(0, position), "-", output.slice(position)].join("");

  return value.includes(".") ? value : output;
});

Vue.filter("logAction", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value === "create") {
    text = i18n.t("created");
  } else if (value === "update") {
    text = i18n.t("updated");
  } else if (value === "delete") {
    text = i18n.t("deleted");
  } else if (value === "picture uploaded") {
    text = i18n.t("sent-file");
  } else if (value === "picture removed") {
    text = i18n.t("deleted-file");
  } else if (value === "move") {
    text = i18n.t("moved");
  }

  return text;
});

Vue.filter("logEntity", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value === "contact") {
    text = i18n.t("the-contact");
  } else if (value === "deal") {
    text = i18n.t("the-lead");
  } else if (value === "company") {
    text = i18n.t("the-company");
  } else if (value === "user") {
    text = i18n.t("the-user");
  } else if (value === "bill") {
    text = i18n.t("the-bill");
  }

  return text;
});

Vue.filter("fileSize", function(value) {
  if (!value) return "";
  value = value.toString();

  return value.slice(0, -3) + "kb";
});

Vue.filter("fileExtension", function(value) {
  if (!value) return "";
  value = value.toString();

  const ext = value.split(".");
  let extWithParams = ext[ext.length - 1];

  if (extWithParams.includes("?")) {
    extWithParams = extWithParams.split("?")[0];
  }

  return extWithParams.toLowerCase();
});

Vue.filter("moreThanOne", function(value) {
  if (!value) return "-";

  return value > 1 ? `+ ${value - 1}` : "";
});

Vue.filter("convertRole", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value.length > 0) {
    let roles = store.getters.getUserRoleList || [];

    text = roles.find(e => e.value === value).text;
  }

  return text;
});

Vue.filter("booleanToString", function(value) {
  if (!value) return "";

  let text = "";

  if (value) {
    text = i18n.t("yes");
  } else {
    text = i18n.t("no");
  }

  return text;
});

Vue.filter("dealTaskDate", function(value) {
  if (!value) return "";
  const date = new Date(value.replace(/-/g, "/"));

  return i18n.d(date, "dealTaskDate");
});

Vue.filter("brDateText", function(value) {
  if (!value) return "";
  let date = new Date(value.replace(/-/g, "/"));
  date.setDate(date.getDate());

  return i18n.d(date, "longOnlyDate");
});

Vue.filter("brDateTextWithoutYear", function(value) {
  if (!value) return "";
  let date = new Date(value.replace(/-/g, "/"));
  date.setDate(date.getDate());

  return i18n.d(date, "dayAndMonth");
});

Vue.filter("dealTaskHour", function(value) {
  if (!value) return "";
  const date = new Date(value.replace(/-/g, "/"));
  return i18n.d(date, "hoursAndMinutes");
});

Vue.filter("valueToReais", function(value) {
  let price = 0;

  if (value) {
    price = Number(value);
  }

  return i18n.n(price, "currency");
});

Vue.filter("entityToPtBr", function(value) {
  if (!value) return "";

  if (value === "contacts") {
    return i18n.t("contacts");
  } else if (value === "companies" || value === "companys") {
    return i18n.t("companies");
  } else if (value === "deals") {
    return i18n.t("deals");
  } else if (value === "users") {
    return i18n.t("users");
  } else if (value === "pipelines") {
    return i18n.t("workflows");
  } else if (value === "products") {
    return i18n.t("products");
  } else if (value === "bills") {
    return i18n.t("bills");
  } else if (["taskslist", "calendar"].includes(value)) {
    return i18n.t("tasks");
  }

  return "";
});

Vue.filter("billStatusToPtBr", function(value) {
  if (!value) return "";

  if (store.getters.getBillStatusList) {
    return store.getters.getBillStatusList.find(e => e.value === value).text;
  }

  return "";
});

Vue.filter("billTypesToPtBr", function(value) {
  if (!value) return "";

  if (store.getters.getBillTypesList) {
    return store.getters.getBillTypesList.find(e => e.value === value).text;
  }

  return "";
});

Vue.filter("convertToCSV", function(value) {
  if (!value) return "";

  let array = typeof objArray != "object" ? JSON.parse(value) : value;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
});

Vue.filter("hoursToDaysAndHours", function(value) {
  let text = "0h";

  if (value) {
    let winDays = Math.floor(Number(value) / 24);
    let winHours = Number(value) % 24;
    text = winDays > 0 ? `${winDays}d${winHours}h` : `${winHours}h`;
  }

  return text;
});

Vue.filter("dateToTimeRemaining", function(value) {
  if (!value) return "";
  let today = new Date();
  const date = new Date(value.replace(/-/g, "/"));
  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();
  const dateHoursAndMinutes = `${dateHours}:${
    dateMinutes < 10 ? "0" + dateMinutes : dateMinutes
  }`;

  const tomorrowMidnight = new Date(today);
  tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
  tomorrowMidnight.setHours(0, 0, 0, 0);

  const msBetweenTomorrowAndNow = tomorrowMidnight - today;
  const msBetweenDateAndNow = date - today;

  if (msBetweenDateAndNow < 0) {
    return Vue.options.filters.dealTaskDate(value);
  }
  const days = Math.floor(msBetweenDateAndNow / (24 * 60 * 60 * 1000));
  const daysms = msBetweenDateAndNow % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));

  const daysmsBetweenTomorrowAndNow =
    msBetweenTomorrowAndNow % (24 * 60 * 60 * 1000);
  const hoursBetweenTomorrowAndNow = Math.floor(
    daysmsBetweenTomorrowAndNow / (60 * 60 * 1000)
  );

  if (days <= 0 && hours >= hoursBetweenTomorrowAndNow) {
    return `${i18n.t("tomorrow-at")} ${dateHoursAndMinutes}`;
  }

  if (days === 1 && hours < hoursBetweenTomorrowAndNow) {
    return `${i18n.t("tomorrow-at")} ${dateHoursAndMinutes}`;
  }

  if (days <= 0) {
    return `${i18n.t("today-at")} ${dateHoursAndMinutes}`;
  }

  return Vue.options.filters.dealTaskDate(value);
});

Vue.filter("timeRemaining", function(value) {
  if (!value) return "";
  let today = new Date();
  const date = new Date(value.replace(/-/g, "/"));

  const tomorrowMidnight = new Date(today);
  tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
  tomorrowMidnight.setHours(0, 0, 0, 0);

  const msBetweenTomorrowAndNow = tomorrowMidnight - today;
  const msBetweenDateAndNow = date - today;

  if (msBetweenDateAndNow < 0) {
    return "LATE";
  }
  const days = Math.floor(msBetweenDateAndNow / (24 * 60 * 60 * 1000));
  const daysms = msBetweenDateAndNow % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));

  const daysmsBetweenTomorrowAndNow =
    msBetweenTomorrowAndNow % (24 * 60 * 60 * 1000);
  const hoursBetweenTomorrowAndNow = Math.floor(
    daysmsBetweenTomorrowAndNow / (60 * 60 * 1000)
  );

  if (days <= 0 && hours > hoursBetweenTomorrowAndNow) {
    return `DELIVER_SOON`;
  }

  if (days === 1 && hours < hoursBetweenTomorrowAndNow) {
    return `DELIVER_SOON`;
  }

  if (days <= 0) {
    return `DELIVER_SOON`;
  }

  return "";
});

Vue.filter("hoursToDaysAndHoursExtended", function(value) {
  let text = "0 horas";

  if (value) {
    const winDays = Math.floor(Number(value) / 24);
    const winHours = Number(value) % 24;

    const winDaysExtended =
      winDays != 1
        ? `${winDays} ${i18n.t("days")}`
        : `${winDays} ${i18n.t("day")}`;
    const winHoursExtended =
      winHours != 1
        ? `${winHours} ${i18n.t("hours")}`
        : `${winHours} ${i18n.t("hour")}`;

    text =
      winDays > 0
        ? `${winDaysExtended} ${i18n.t("and")} ${winHoursExtended}`
        : `${winHoursExtended}`;
  }

  return text;
});

Vue.filter("isJsonString", function(value) {
  if (!value) return false;

  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
});

Vue.filter("isUrl", function(string) {
  if (!string) return false;

  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
});

Vue.filter("isoDate", function(date) {
  if (typeof date.getMonth != "function") return "";

  const isoDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();

  return isoDate;
});

Vue.filter("plainTextVariableToMention", function(string) {
  if (!string) return false;

  const bracesToMention = string.replace(
    /<temporary>\{(.*?)\}<\/temporary>/g,
    function(value) {
      const valueWithoutBraces = value.slice(12, -13);

      return `<span data-type="mention" class="mention" data-id="${valueWithoutBraces}" contenteditable="false">{${valueWithoutBraces}</span>`;
    }
  );

  return bracesToMention;
});

Vue.filter("plainTextEmailToMention", function(string) {
  if (!string) return false;

  const bracesToMention = string.replace(/\{(.*?)\}/g, function(value) {
    const valueWithoutBraces = value.slice(1, -1);

    return `<span data-type="mention" class="mention" data-id="${valueWithoutBraces}" contenteditable="false">{${valueWithoutBraces}</span>`;
  });

  return bracesToMention;
});

Vue.filter("mentionToPlainTextEmail", function(string) {
  if (!string) return false;

  const dom = document.createElement("div");
  dom.classList.add("dom");
  dom.innerHTML = string;
  dom.querySelectorAll(".mention").forEach(e => {
    const variable = e.getAttribute("data-id");
    const temporary = document.createElement("temporary");
    e.replaceWith(temporary);
    temporary.innerHTML = `{${variable}}`;
  });
  const withoutOpenedTemporary = dom.outerHTML.replace("<temporary>", "");
  const withoutClosedTemporary = withoutOpenedTemporary.replace(
    "</temporary>",
    ""
  );

  return withoutClosedTemporary;
});

Vue.filter("mentionToPlainTextVariable", function(string) {
  if (!string) return false;

  const dom = document.createElement("div");
  dom.classList.add("dom");
  dom.innerHTML = string;
  dom.querySelectorAll(".mention").forEach(e => {
    const variable = e.getAttribute("data-id");
    const temporary = document.createElement("temporary");
    e.replaceWith(temporary);
    temporary.innerHTML = `{${variable}}`;
  });

  return dom.outerHTML;
});

Vue.filter("firstLetters", function(string) {
  if (!string) return "";

  const firstLetters = string
    .split(" ")
    .map(word => word[0])
    .join("");

  return firstLetters.length <= 2
    ? firstLetters
    : `${firstLetters[0]}${firstLetters[1]}`;
});
