import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "contacts-error": "Wasn't possible to load contacts!",
    "contact-error": "Wasn't possible to load contact data!",
    "create-contact-error": "Wasn't possible to create contact!",
    "update-contact-success": "Contact updated with success!",
    "update-contact-error": "Wasn't possible to update contact!",
    "update-contact-picture-error":
      "Wasn't possible to update contact picture!",
    "delete-contact-picture-error":
      "Wasn't possible to delete contact picture!",
    "delete-contact-success": "Contact deleted with success!",
    "delete-contact-error": "Wasn't possible to delete contact!",
    "find-contacts-error": "Wasn't possible to find contacts!",
    "search-contacts-error": "Wasn't possible to search contacts!",
    "filter-contacts-error": "Wasn't possible to filter contacts!",
    "contact-deals-error": "Wasn't possible to load related contacts!"
  },
  "pt-BR": {
    "contacts-error": "Não foi possível carregar os contatos!",
    "contact-error": "Não foi possível carregar os dados do contato!",
    "create-contact-error": "Não foi possível criar o contato!",
    "update-contact-success": "Contato atualizado com sucesso!",
    "update-contact-error": "Não foi possível atualizar o contato!",
    "update-contact-picture-error":
      "Não foi possível atualizar a foto do contato!",
    "delete-contact-picture-error":
      "Não foi possível deletar a foto do contato!",
    "delete-contact-success": "O contato foi deletado com sucesso!",
    "delete-contact-error": "Não foi possível deletar o contato!",
    "find-contacts-error": "Não foi possível pesquisar contatos!",
    "search-contacts-error": "Não foi possível realizar a pesquisa!",
    "filter-contacts-error": "Não foi possível realizar o filtro!",
    "contact-deals-error":
      "Não foi possível carregar os cards relacionadas ao contato!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    contacts: [],
    currentContact: {
      first_name: "",
      last_name: ""
    },
    contactCustomFields: null,
    contactsPages: {
      last: 1
    },
    contactsSort: {
      orderBy: "",
      direction: "",
      name: ""
    },
    contactDeals: {}
  },
  getters: {
    getContacts: state => state.contacts,
    getCurrentContact: state => state.currentContact,
    getContactCustomFields: state => state.contactCustomFields,
    getContactsPages: state => state.contactsPages,
    getContactsSort: state => state.contactsSort,
    getContactDeals: state => state.contactDeals
  },
  mutations: {
    setContacts: (state, payload) => {
      state.contacts = payload;
    },
    setContactsPages: (state, payload) => {
      state.contactsPages = payload;
    },
    setContactsSort: (state, payload) => {
      state.contactsSort = payload;
    },
    setCurrentContact: (state, payload) => {
      state.currentContact = payload;
    },
    clearCurrentContactCep: state => {
      state.currentContact.address_latitude = "";
      state.currentContact.address_longitude = "";
      state.currentContact.address_street = "";
      state.currentContact.address_block = "";
      state.currentContact.address_city = "";
      state.currentContact.address_state = "";
      state.currentContact.address_country = "";
    },
    setCurrentContactCep: (state, payload) => {
      state.currentContact.address_latitude = payload?.latitude;
      state.currentContact.address_longitude = payload?.longitude;
      state.currentContact.address_street = payload?.logradouro;
      state.currentContact.address_block = payload?.bairro;
      state.currentContact.address_city = payload?.cidade.nome;
      state.currentContact.address_state = payload?.estado.sigla;
      state.currentContact.address_country = "Brasil";
    },
    setContactCustomFieldsValues: (state, payload) => {
      state.contactCustomFields = payload;
    },
    clearContactCustomFieldsValues: state => {
      if (state.contactCustomFields) {
        Object.keys(state.contactCustomFields).forEach(index => {
          state.contactCustomFields[index] = "";
        });
      }
    },
    createContactCustomFields: (state, payload) => {
      let object = {};

      payload.forEach(e => {
        object[e.name] = "";
      });

      state.contactCustomFields = { ...object, ...state.contactCustomFields };
    },
    setContactDeals: (state, payload) => {
      state.contactDeals = payload;
    }
  },
  actions: {
    contactsRequest({ commit }, payload) {
      const page = payload?.page > 1 ? payload?.page : 1;
      const orderBy = payload?.orderBy
        ? { orderBy: payload.orderBy, direction: payload.direction }
        : {};
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/contacts`, {
            params: {
              page: page,
              ...orderBy
            }
          })
          .then(resp => {
            const contacts = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            if (!payload.updateLocal) {
              commit("setContactsPages", page);
              commit("setContacts", contacts);
            }

            resolve({ contacts, page });
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("contacts-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    contactRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/contacts/${id}`)
          .then(resp => {
            const contact = resp.data;
            commit("setCurrentContact", contact);
            commit("clearContactCustomFieldsValues");
            if (contact.custom_fields) {
              commit("setContactCustomFieldsValues", contact.custom_fields[0]);
            }
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("contact-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    createContactRequest({ commit }, contact) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/contacts`, contact)
          .then(resp => {
            const data = resp.data;
            commit("setCurrentContact", data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("create-contact-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateContactRequest({ state, commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/contacts/${state.currentContact.id}`, payload)
          .then(resp => {
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-contact-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-contact-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateContactPictureRequest({ state, commit }, payload) {
      let formData = new FormData();
      formData.append("profile_picture", payload);

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(
            `/contacts/${state.currentContact.id}/uploadProfilePicture`,
            formData
          )
          .then(resp => {
            commit("setCurrentContact", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-contact-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteContactPictureRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/contacts/${id}/removeProfilePicture`)
          .then(resp => {
            commit("setCurrentContact", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-contact-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteContactRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/contacts/${id}`)
          .then(resp => {
            const snackBar = {
              title: i18n.t("delete-contact-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-contact-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    findContactsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/contacts/find`, {
            params: { ...payload }
          })
          .then(resp => {
            const contacts = resp.data.data;

            if (!payload?.updateLocal) {
              commit("setContacts", contacts);
            }

            resolve(resp.data);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("find-contacts-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    searchContactsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/contacts/search`, { params: payload })
          .then(resp => {
            const contacts = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setContactsPages", page);

            commit("setContacts", contacts);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("search-contacts-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    filterContactsRequest({ commit }, payload) {
      const exportParams = {
        endpoint: "/contacts/export",
        entity: "contacts",
        payload: { filters: payload.filters }
      };
      commit("setExportParams", exportParams);
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/contacts/filter?paginate=true`, payload)
          .then(resp => {
            const contacts = resp.data.data;
            let page = {};

            if (resp.data?.data) {
              delete resp.data.data;
              page = resp.data;
            }

            commit("setContactsPages", page);

            commit("setContacts", contacts);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("filter-contacts-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    contactDealsRequest({ commit }, id) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/contacts/${id}/cards`)
          .then(resp => {
            const contactDeals = resp.data;
            commit("setContactDeals", contactDeals);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("contact-deals-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
