<i18n>
{
  "en": {
    "update": "Update picture",
    "delete": "Delete picture"
  },
  "pt-BR": {
    "update": "Atualizar foto",
    "delete": "Remover foto"
  }
}
</i18n>

<template>
  <div
    class="we-picture-change"
    :class="[type]"
    :style="{ minWidth: size + 'px', minHeight: size + 'px' }"
  >
    <we-rounded-picture
      :type="entity"
      :picture="picture"
      :name="name"
      :size="size"
    />
    <div class="action">
      <input
        type="file"
        id="upload"
        ref="file"
        class="picture"
        accept="image/x-png, image/gif, image/jpeg"
        @change="$emit('onFileChange', $event)"
      />
      <we-icon-button
        class="icon-green"
        icon="camera"
        :name="$t('update')"
        @click="selectImage"
      />
      <we-icon-button
        color="icon-red"
        :icon="{ prefix: 'far', iconName: 'trash-alt' }"
        :name="$t('delete')"
        @click="$emit('delete')"
      />
    </div>
    <we-loading-overflow class="small" :loading="loading" />
  </div>
</template>

<script>
export default {
  props: {
    size: String,
    picture: String,
    name: String,
    loading: Boolean,
    type: String,
    entity: String
  },
  methods: {
    selectImage() {
      this.$refs.file.click();
    }
  }
};
</script>

<style lang="scss">
.we-picture-change {
  min-width: 96px;
  min-height: 96px;
  background: var(--background-1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: initial;

  &.square {
    border-radius: 10px;

    .rounded-picture {
      border-radius: 7px;
      border: 1px solid var(--line);
    }
  }

  &:hover {
    .action {
      display: flex;
    }

    .rounded-picture {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.4;
      }
    }
  }

  .action {
    display: none;
    pointer-events: initial;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @include mobile {
      display: flex;
      bottom: -4px;
      height: fit-content;
    }

    .we-icon-button {
      @include mobile {
        background: var(--background-1);
        background: hsla(0, 0%, 100%, 0.8);
        backdrop-filter: saturate(180%) blur(1px);
        width: 30px;
        min-width: 30px;
        height: 30px;
        border: 1px solid var(--line);
        margin: 0 2px;
      }

      svg {
        @include mobile {
          height: 14px;
          width: 1em;
        }
      }
    }

    #upload {
      width: 100%;
      height: 100%;
      opacity: 0;
      -moz-opacity: 0;
      filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
}
</style>
