<i18n>
{
  "en": {
    "gender": "Gender",
    "phone": "Phone number",
    "action-1": "See contact",
    "action-2": "Send email",
    "action-3": "WhatsApp",
    "social-1": "Open LinkedIn",
    "social-2": "Open Instagram",
    "social-3": "Open Facebook",
    "social-4": "Open Twitter",
    "email-error-title": "Isn't possible to send the email!",
    "email-error-description": "The email isn't filled or is incorret.",
    "whatsapp-error-title": "Isn't possible to open WhatsApp!",
    "whatsapp-error-description": "The telefone number isn't filled or is incorrect.",
    "social-error-title": "Social media not registered!"
  },
  "pt-BR": {
    "gender": "Gênero",
    "phone": "Telefone",
    "action-1": "Ver contato",
    "action-2": "Enviar email",
    "action-3": "WhatsApp",
    "social-1": "Abrir LinkedIn",
    "social-2": "Abrir Instagram",
    "social-3": "Abrir Facebook",
    "social-4": "Abrir Twitter",
    "email-error-title": "Não é possível enviar email!",
    "email-error-description": "O email não está preenchido ou está incorreto.",
    "whatsapp-error-title": "Não é possível abrir o WhatsApp!",
    "whatsapp-error-description": "O número de telefone não está preenchido ou está incorreto.",
    "social-error-title": "Rede social não cadastrada!"
  }
}
</i18n>

<template>
  <v-menu
    rounded
    :left="left"
    :right="right"
    offset-x
    content-class="we-deal-contact"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on">
        <template v-if="fromDeal">
          <div class="select-deal-contact">
            <we-rounded-picture
              size="24"
              :picture="contact.picture"
              :name="`${contact.first_name} ${contact.last_name}`"
              :text="
                $options.filters.firstLetters(
                  `${contact.first_name} ${contact.last_name}`
                )
              "
            />
            <we-text-tooltip
              :text="`${contact.first_name} ${contact.last_name}`"
              maxWidth="fit-content"
            />
          </div>
        </template>
        <template v-else>
          <slot name="action" />
        </template>
      </div>
    </template>
    <v-list>
      <div class="top">
        <we-rounded-picture
          size="72"
          :picture="contact.picture"
          :name="`${contact.first_name} ${contact.last_name}`"
          :text="
            $options.filters.firstLetters(
              `${contact.first_name} ${contact.last_name}`
            )
          "
        />
        <div class="data">
          <we-text-tooltip
            :text="`${contact.first_name} ${contact.last_name}`"
            maxWidth="fit-content"
            class="contact-name"
          />
          <we-text-tooltip
            :text="contact.email"
            maxWidth="fit-content"
            class="contact-email"
          />
          <div class="socials">
            <we-icon-button
              class="small"
              v-for="social in socials"
              :key="social.text"
              :icon="social.icon"
              :name="social.text"
              direction="bottom"
              @click="social.click"
            />
          </div>
        </div>
      </div>
      <div class="data-fields">
        <we-data-field :label="$t('phone')" :value="contact.phone1" />
        <we-data-field :label="$t('gender')" :value="contact.gender" />
      </div>
      <div class="actions">
        <we-button
          v-for="action in actions"
          :key="action.text"
          class="action"
          :class="action.class"
          @click="action.click"
          :text="action.text"
          :icon="action.icon"
        />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    contact: {
      type: [Object, Number]
    },
    pipeline: {
      type: [Object]
    },
    stage: Object,
    left: {
      type: Boolean
    },
    right: {
      type: Boolean
    },
    fromDeal: {
      type: Boolean
    }
  },
  computed: {
    socials() {
      return [
        {
          text: this.$t("social-1"),
          icon: ["fab", "linkedin-in"],
          click: () => this.openSocial(this.contact.social_media_linkedin)
        },
        {
          text: this.$t("social-2"),
          icon: ["fab", "instagram"],
          click: () => this.openSocial(this.contact.social_media_instagram)
        },
        {
          text: this.$t("social-3"),
          icon: ["fab", "facebook-f"],
          click: () => this.openSocial(this.contact.social_media_facebook)
        },
        {
          text: this.$t("social-4"),
          icon: ["fab", "twitter"],
          click: () => this.openSocial(this.contact.social_media_twitter)
        }
      ];
    },
    actions() {
      return [
        {
          class: "disabled",
          text: this.$t("action-1"),
          icon: "external-link-alt",
          click: () => this.openContact()
        },
        {
          class: "primary",
          text: this.$t("action-2"),
          icon: "envelope",
          click: () => this.sendEmail()
        },
        {
          class: "success",
          text: this.$t("action-3"),
          icon: ["fab", "whatsapp"],
          click: () => this.openWhatsapp()
        }
      ];
    }
  },
  methods: {
    openContact() {
      if (this.contact?.id) {
        const routeData = this.$router.resolve({
          name: "contact",
          params: { contactId: this.contact.id }
        });
        window.open(routeData.href, "_blank");
      }
    },
    sendEmail() {
      if (this.contact?.email?.length > 0) {
        window.open(`mailto:${this.contact.email}`);
      } else {
        const snackBar = {
          title: this.$t("email-error-title"),
          description: this.$t("email-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    openWhatsapp() {
      if (this.contact?.phone1?.length > 0) {
        let formatedPhone = this.contact.phone1
          .split("(")
          .join("")
          .split(")")
          .join("")
          .split(" ")
          .join("")
          .split("-")
          .join("");
        let formatedMessage = encodeURI(
          this.stage.whatsapp_default_message || ""
        );
        window.open(
          `https://wa.me/55${formatedPhone}${
            formatedMessage != "" ? "?text=" + formatedMessage : ""
          }`,
          "_blank"
        );
      } else {
        const snackBar = {
          title: this.$t("whatsapp-error-title"),
          description: this.$t("whatsapp-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    openSocial(link) {
      if (!this.$options.filters.isUrl(link)) {
        const snackBar = {
          title: this.$t("social-error-title"),
          description: "",
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        return;
      }

      window.open(link, "_blank");
    }
  }
};
</script>

<style lang="scss">
.select-deal-contact {
  width: 100%;
  height: 38px;
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  align-items: center;
  padding: 0 13.5px;
  border-radius: 7px;
  margin-bottom: 8px;

  &:hover {
    background: var(--action);
    cursor: pointer;
  }

  .we-text-tooltip {
    font-size: 14px;
    font-weight: $medium;
    color: var(--text-1);
  }
}

.we-deal-contact {
  max-width: 380px;
  width: 100%;
  padding: 24px;
  background: var(--background-1);

  &.rounded {
    border-radius: 7px !important;
  }

  .v-list {
    padding: 0;
    .top {
      display: grid;
      grid-template-columns: 72px minmax(0, 1fr);
      align-items: center;
      gap: 14px;
    }

    .data {
      .contact-name {
        font-size: 16px;
        line-height: 18px;
        color: var(--text-1);
      }

      .contact-email {
        color: var(--text-2);
        font-size: 14px;
      }

      .socials {
        display: flex;
        align-items: center;
        margin-left: -8px;
      }
    }
  }

  .data-fields {
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
    gap: 10px;
  }

  .actions {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 8px;

    .action {
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
</style>
