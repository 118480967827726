<template>
  <router-link :to="{ name: item.routeName }" custom v-slot="{ navigate }">
    <a
      v-if="item.routeName.includes('https')"
      class="we-config-item"
      :href="item.routeName"
      target="_blank"
    >
      <div class="icon">
        <font-awesome-icon :icon="item.icon" />
      </div>
      <div class="item-data">
        <div class="item-name">
          {{ item.name }}
        </div>
        <div class="item-description">
          {{ item.description }}
        </div>
      </div>
    </a>
    <div v-else class="we-config-item" @click="navigate" role="link">
      <div class="icon">
        <font-awesome-icon :icon="item.icon" />
      </div>
      <div class="item-data">
        <div class="item-name">
          {{ item.name }}
        </div>
        <div class="item-description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: Object
  }
};
</script>

<style lang="scss">
.we-config-item {
  background: var(--background-1);
  border-radius: 10px;
  padding: 24px 24px;
  border: 1px solid var(--line);
  text-align: center;
  cursor: pointer;
  display: block;
  position: relative;

  @include mobile {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--line);
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 14px;
    text-align: left;
    align-items: center;
    padding: 12px 15px;
  }

  &:hover {
    border: 1px solid var(--primary);

    @include mobile {
      border: none;
      border-bottom: 1px solid var(--line);
    }
  }

  .icon {
    margin: 0 auto;
    margin-bottom: 16px;

    @include mobile {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      height: 26px;
      width: 26px;
      color: var(--primary);

      @include mobile {
        height: 1em;
      }
    }
  }

  .item-name {
    font-size: 16px;
    line-height: 18px;
    font-weight: $semibold;
    color: var(--text-2);
    margin-bottom: 12px;

    @include mobile {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 4px;
      color: var(--text-1);
    }
  }

  .item-label {
    background: var(--green);
    font-size: 9px;
    line-height: 14px;
    font-weight: $semibold;
    border-radius: 2px;
    padding: 0 4px;
    position: absolute;
    top: 20px;
    right: 24px;
    color: var(--background-1);
    text-transform: uppercase;
  }

  .item-description {
    font-size: 13px;
    line-height: 16px;
    font-weight: $regular;
    color: var(--text-2);

    @include mobile {
      color: var(--text-3);
      font-size: 11px;
      line-height: 13px;
    }
  }
}
</style>
