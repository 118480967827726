import Vue from "vue";
import App from "./App.vue";
import "./plugins/deepMerge";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/axios";
import "./plugins/waypoint";
import "./plugins/fontawesome";
import "./plugins/mask";
import "./plugins/scrollactive";
import "./plugins/clickOut";
import "./plugins/components";
import "./plugins/filters";
import "./plugins/vuetifyMoney";
import "./plugins/dragResize";
import "./plugins/mixins";
import "./plugins/firebase";
import "./plugins/tour";
import "./plugins/markdown";
import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
