<i18n>
{
  "en": {
    "message": "This is the remaining period of the free trial, please upgrade your plan to clear this message.",
    "days": "Days",
    "remaining": "Remaining"
  },
  "pt-BR": {
    "message": "Esse é o período restante do teste gratuito, faça o upgrade de plano para sumir essa mensagem.",
    "days": "Dias",
    "remaining": "Restantes"
  }
}
</i18n>

<template>
  <v-tooltip
    bottom
    content-class="we-trial-remaining-tooltip"
    v-if="daysRemaining > 0 && isAdmin"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="we-trial-remaining"
        v-bind="attrs"
        v-on="on"
        @click="$store.commit('openUpgradePlan')"
      >
        <font-awesome-icon icon="rocket" />
        <div class="remaining-content">
          <div class="days">{{ daysRemaining }} {{ $t("days") }}</div>
          <div class="remaining">
            {{ $t("remaining") }}
          </div>
        </div>
      </div>
    </template>
    <span> {{ $t("message") }} </span>
  </v-tooltip>
</template>

<script>
export default {
  computed: {
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    customerStatus() {
      return this.$store.getters.getCustomerStatus;
    },
    daysRemaining() {
      if (!this.customerStatus?.created_at) return 0;

      const today = new Date();
      const freeTrialExceededAt = new Date(
        this.customerStatus.free_trial_exceeded_at
      );
      const freeTrialNormalized = new Date(
        freeTrialExceededAt.getTime() -
          freeTrialExceededAt.getTimezoneOffset() * -60000
      );

      const msTimeDifference = freeTrialNormalized - today;

      return Math.ceil(msTimeDifference / (1000 * 60 * 60 * 24));
    }
  }
};
</script>

<style lang="scss">
.we-trial-remaining-tooltip {
  max-width: 300px;
  text-align: center;
}

.we-trial-remaining {
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 0 12px;
  cursor: pointer;

  &:hover {
    background: var(--background-1-hover);
  }

  svg {
    height: 20px;
    width: auto;
    color: var(--primary);
    margin-right: 10px;
  }

  .remaining-content {
    .days {
      text-transform: uppercase;
      font-weight: $semibold;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 3px;
      color: var(--primary);
    }

    .remaining {
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: var(--text-2);
      font-weight: $semibold;
    }
  }
}
</style>
