import Vue from "vue";
import i18n from "@/plugins/i18n";

const messages = {
  en: {
    "customer-error": "Wasn't possible to load company account!",
    "update-customer-success": "Company account updated with success!",
    "update-customer-error": "Wasn't possible to update company account!",
    "update-customer-brand-error": "Wasn't possible to update account logo!",
    "delete-customer-picture-error": "Wasn't possible to delete account logo!",
    "delete-customer-error": "Wasn't possible to delete account!"
  },
  "pt-BR": {
    "customer-error": "Não foi possível carregar os dados da conta!",
    "update-customer-success": "Dados da conta atualizados com sucesso!",
    "update-customer-error": "Não foi possível atualizar os dados da conta!",
    "update-customer-brand-error":
      "Não foi possível atualizar a logo da conta!",
    "delete-customer-picture-error":
      "Não foi possível deletar a logo da conta!",
    "delete-customer-error": "Não foi possível deletar a conta!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    customer: null,
    customerStatus: null
  },
  getters: {
    getCustomer: state => state.customer,
    getCustomerStatus: state => state.customerStatus
  },
  mutations: {
    setCustomer: (state, payload) => {
      state.customer = payload;
    },
    setCustomerStatus: (state, payload) => {
      state.customerStatus = payload;
    }
  },
  actions: {
    customerRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customers`)
          .then(resp => {
            const customer = resp.data[0];
            customer.ip_list = !customer.ip_list ? [] : customer.ip_list;
            commit("setCustomer", customer);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("customer-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateCustomerRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .put(`/customers`, payload)
          .then(resp => {
            const customer = resp.data;
            commit("setCustomer", customer);
            resolve(resp);

            const snackBar = {
              title: i18n.t("update-customer-success"),
              status: "success"
            };

            commit("addSnackBar", snackBar);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-customer-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateCustomerBrandRequest({ commit }, payload) {
      let formData = new FormData();
      formData.append("brand_picture", payload);

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/customers/uploadBrand`, formData)
          .then(resp => {
            const customer = resp.data;
            commit("setCustomer", customer);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("update-customer-brand-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteCustomerPictureRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/customers/removeCustomerPicture`)
          .then(resp => {
            commit("setCustomer", resp.data);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-customer-picture-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    deleteCustomerRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/customers`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-customer-error")
            };
            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    customerStatusRequest({ commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/customers/status`)
          .then(resp => {
            const status = resp.data;
            commit("setCustomerStatus", status);

            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    }
  }
};
